import { GET_SESSIONS_BY_SITE_URL } from "../../constants/api";
import axios from "../fetch/fetch";

export const getSessionsBySiteUrl = async (siteUrl: string): Promise<any> => {
    try {
        const res = await axios.get(`${GET_SESSIONS_BY_SITE_URL}?siteUrl=${siteUrl}`);
        return res.data;
    } catch (err) {
        return [];
    }
};

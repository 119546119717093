import { LOADING_IN_BUTTON_CLASS_NAME, LOADING_IN_BUTTON_DEFAULT_SIZE } from "./constants";
import LoadingPage from "../LoadingPage/LoadingPage";

const LoadingButton = () => {
  return (
    <div className={LOADING_IN_BUTTON_CLASS_NAME}>
      <LoadingPage loadingIconSizeRatio={LOADING_IN_BUTTON_DEFAULT_SIZE}></LoadingPage>
    </div>
  );
};

export default LoadingButton;

import React, { useState, useEffect } from "react";
import {
    FORM_DATA_GROUP_AND_SUB_GROUP_KEY,
    FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY,
    FORM_DATA_INCIDENTS_KEY,
    MAUDE_TABLE_SEARCH_RESULT_ID,
    MAX_NUMBER_FOI_TEXT_RESULT,
    NUMBER_OF_INCIDENTS_ITEM_TO_FIND_GROUP_AND_SUB_GROUP,
    TABLE_COLOR_BLACK,
    TABLE_COLOR_BLUE,
    TOP_DOWN_CLASS_NAME,
    TR_TAG,
} from "./constants";
import BottomUp1stColumn from "../../Components/BottomUp1stColumn/BottomUp1stColumn";
import { searchMaude } from "../../apiRequests/bottomUp/searchMaude";
import { FIND_GROUP_AND_SUB_GROUP, GENERATE_HAZARD_LIST_FROM_MAUDE, GENERATE_HAZARD_MASTER_LIST_MAUDE_TABLE } from "../../constants/api";
import { GroupAndSubGroups } from "../../types/GroupAndSubGroups";
import GroupAndSubGroupsTable from "../../Components/BottomUp2nd/GroupAndSubGroupTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL, subsampleArray } from "../../utils/commonUtils";
import HazardMasterListMaudeTable from "../../Components/BottomUp3rd/HazardMasterListMaudeTable";
import { HazardMasterListMaude } from "../../types/HazardMasterListMaude";
import HazardListTable from "../../Components/HazardListTable/HazardList";
import {
    BLANK_ARRAY,
    BOOTSTRAP_CONTAINER_FLUID,
    GROUP_AND_SUB_GROUP_IN_DB,
    HAZARD_LIST_IN_DB,
    HAZARD_MASTER_LIST_MAUDE_IN_DB,
    MAUDE_SEARCH_RESULT_IN_DB,
    SELECTED_PRODUCTS_IN_DB,
} from "../../constants/appConstants";
import "./style.css";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import { MauDeRowData, Product } from "./types";
import { COLOR_LISTS } from "../Risk/constants";
import { Button, Modal } from "react-bootstrap";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { useSaveDataToDatabase } from "../../hooks/useSaveDataToDatabase";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";

const BottomUp = () => {
    const [isSearchingData, setIsSearchingData] = useState<boolean>(false);
    const [isDisableButtonAllButtons, setIsDisableButton] = useState<boolean>(false);
    const [maudeTableData, setMaudeTableData] = useState<MauDeRowData[]>([]);
    const [isProcessingData, setIsProcessingData] = useState(false);
    const [isGeneratingHazardMasterListMaudeTable, setIsGeneratingHazardMaudeTable] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<GroupAndSubGroups[]>([]);
    const [hazardMasterListMaudeData, sethazardMasterListMaudeData] = useState<HazardMasterListMaude[]>([]);
    const [hazardListData, setHazardListData] = useState<string[]>([]);
    const [isGeneratingHazardList, setIsGeneratingHazardList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [isShowPopupSelectProduct, setIsShowPopupSelectProduct] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [isShowModalToAskUserToInputMoreSpecificKeywords, setIsShowModalToAskUserToInputMoreSpecificKeywords] = useState<boolean>(false);
    const sessionIdFromUrl = getSessionIdFromURL();
    const saveDataToDatabase = useSaveDataToDatabase();
    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                const stateData = await loadPageState(sessionIdFromUrl);
                const producsts = extractProducts(stateData.stateDetail[MAUDE_SEARCH_RESULT_IN_DB] || []);
                setProducts(producsts);
                setSelectedProducts(stateData.stateDetail[SELECTED_PRODUCTS_IN_DB] || []);
                const mauDeRowData = convertDataToMauDeRowData(stateData.stateDetail[MAUDE_SEARCH_RESULT_IN_DB] || []);
                setMaudeTableData(mauDeRowData);
                convertGroupDataAndSetToState(stateData.stateDetail[GROUP_AND_SUB_GROUP_IN_DB] || BLANK_ARRAY);
                convertHazardMasterListDataAndSetToState(stateData.stateDetail[HAZARD_MASTER_LIST_MAUDE_IN_DB] || BLANK_ARRAY);
                convertHazardListDataAndSetToState(stateData.stateDetail[HAZARD_LIST_IN_DB] || BLANK_ARRAY);
                setIsLoading(false);
            } catch (e) {
                setProducts([]);
                setGroupData([]);
                sethazardMasterListMaudeData([]);
                setHazardListData([]);
                setIsLoading(false);
            }
        };
        loadData();
    }, [sessionIdFromUrl]);

    const convertGroupDataAndSetToState = (groupData: string) => {
        const outputFormat = [{ group: "", sub_groups: [{ sub_group_name: "", incidents_index: [] }] }];
        let data: GroupAndSubGroups[] = convertIncompleteJSONToCompleteJSON(groupData, outputFormat, []);
        setGroupData(data);
    };

    const convertHazardMasterListDataAndSetToState = (hazardMasterListMaude: string) => {
        const outputFormat = [{ main_category: "", sub_category: "", hazards: [] }];
        let data: HazardMasterListMaude[] = convertIncompleteJSONToCompleteJSON(hazardMasterListMaude, outputFormat, []);
        sethazardMasterListMaudeData(data);
    };

    const convertHazardListDataAndSetToState = (hazardListMaude: string) => {
        let data: string[] = convertIncompleteJSONToCompleteJSON(hazardListMaude, [], []);
        setHazardListData(data);
    };

    const extractProducts = (data: string[][]): Product[] => {
        const products: Product[] = [];
        for (const item of data) {
            const productName = item[1];
            const productInList = products.find((product) => product.name === productName);
            if (productInList) {
                productInList.count += 1;
            } else {
                products.push({
                    name: productName,
                    count: 1,
                });
            }
        }
        return sortProductByCount(products);
    };

    const addColorToProduct = (product: Product, index: number): Product => {
        product.color = COLOR_LISTS[index % COLOR_LISTS.length];
        return product;
    };

    const sortProductByCount = (products: Product[]): Product[] => {
        return products
            .sort((a, b) => {
                return b.count - a.count;
            })
            .map(addColorToProduct);
    };

    const convertDataToMauDeRowData = (data: string[][]): MauDeRowData[] => {
        return data.map((item) => {
            return {
                foi: item[0],
                brandName: item[1],
            };
        });
    };

    const showPopupAskToInputMoreSpecificKeywords = () => {
        setIsShowModalToAskUserToInputMoreSpecificKeywords(true);
    };

    const handleSearch = async (keywords: string, brandName: string) => {
        setMaudeTableData([]);
        setIsDisableButton(true);
        setIsSearchingData(true);
        const data = await searchMaude(keywords, brandName, sessionIdFromUrl);
        if (data.length > MAX_NUMBER_FOI_TEXT_RESULT) {
            showPopupAskToInputMoreSpecificKeywords();
        } else {
            const producsts = extractProducts(data);
            setProducts(producsts);
            setSelectedProducts([]);
            const mauDeRowData = convertDataToMauDeRowData(data);
            setMaudeTableData(mauDeRowData);
            setIsShowPopupSelectProduct(true);
        }
        setIsSearchingData(false);
        setIsDisableButton(false);
    };

    const handleProcessData = async () => {
        const incidents = getDataFromSelectedProductsThenSubSample().map((item, index) => [index + 1, item.foi]);
        findGroupsAndSubGroups(incidents);
    };

    const getDataFromSelectedProductsThenSubSample = (): Array<MauDeRowData> => {
        const data = maudeTableData.filter((item) => selectedProducts.includes(item.brandName));
        return subsampleArray(data, NUMBER_OF_INCIDENTS_ITEM_TO_FIND_GROUP_AND_SUB_GROUP);
    };

    const findGroupsAndSubGroups = async (incidents: any[], part: number = 0) => {
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: FIND_GROUP_AND_SUB_GROUP,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsDisableButton(true);
                setIsProcessingData(true);
            },
            onMessage: function (fullMessage: string): void {
                convertGroupDataAndSetToState(fullMessage);
            },
            onClose: function (): void {
                setIsProcessingData(false);
                setIsDisableButton(false);
            },
            onError: () => {
                setIsProcessingData(false);
                setIsDisableButton(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_INCIDENTS_KEY,
                    value: JSON.stringify(incidents),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const handleGenerateHazardTable = async () => {
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_HAZARD_MASTER_LIST_MAUDE_TABLE,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsDisableButton(true);
                setIsGeneratingHazardMaudeTable(true);
            },
            onMessage: function (fullMessage: string): void {
                convertHazardMasterListDataAndSetToState(fullMessage);
            },
            onClose: function (): void {
                setIsGeneratingHazardMaudeTable(false);
                setIsDisableButton(false);
            },
            onError: () => {
                setIsGeneratingHazardMaudeTable(false);
                setIsDisableButton(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_GROUP_AND_SUB_GROUP_KEY,
                    value: JSON.stringify(groupData),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    

    const handleSelectIncident = (incident: number) => {
        try {
            const table = document.getElementById(MAUDE_TABLE_SEARCH_RESULT_ID);
            if (!table) {
                return;
            }
            table.style.color = TABLE_COLOR_BLACK;
            const rows = table.getElementsByTagName(TR_TAG);
            for (let i = 0; i < rows.length; i++) {
                rows[i].style.color = TABLE_COLOR_BLACK;
            }
            rows[incident].scrollIntoView({ behavior: "smooth", block: "center" });
            rows[incident].style.color = TABLE_COLOR_BLUE;
        } catch (error) {
            console.error(error);
        }
    };

    const onSaveSelectedProducts = async (newSelectedProducts: string[]) => {
        setSelectedProducts(newSelectedProducts);
        await saveDataToDatabase(sessionIdFromUrl, SELECTED_PRODUCTS_IN_DB, newSelectedProducts);
        setIsShowPopupSelectProduct(false);
    };

    const generateHazardList = async () => {
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_HAZARD_LIST_FROM_MAUDE,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingDataAndToggleButton(true);
            },
            onMessage: function (fullMessage: string): void {
                convertHazardListDataAndSetToState(fullMessage);
            },
            onClose: function (): void {
                setIsGeneratingDataAndToggleButton(false);
            },
            onError: () => {
                setIsGeneratingDataAndToggleButton(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY,
                    value: JSON.stringify(hazardMasterListMaudeData),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const setIsGeneratingDataAndToggleButton = (isGeneratingData: boolean) => {
        setIsGeneratingHazardList(isGeneratingData);
        setIsDisableButton(isGeneratingData);
    };

    const renderBottom1stColumn = () => {
        return (
            <BottomUp1stColumn
                onSearch={handleSearch}
                isDisableAllButtons={isDisableButtonAllButtons}
                isSearch={isSearchingData}
                data={maudeTableData}
                isProcessingData={isProcessingData}
                onProcessData={handleProcessData}
                products={products}
                isShowPopupSelectProduct={isShowPopupSelectProduct}
                setIsShowPopupSelectProduct={setIsShowPopupSelectProduct}
                selectedProducts={selectedProducts}
                onSaveSelectedProducts={onSaveSelectedProducts}
            ></BottomUp1stColumn>
        );
    };

    const renderGroupAndSubGroupColumn = () => {
        return (
            <GroupAndSubGroupsTable
                groupData={groupData}
                onGenerateHazardTable={handleGenerateHazardTable}
                isDisableButton={isDisableButtonAllButtons}
                isProcessingData={isGeneratingHazardMasterListMaudeTable}
                selectIncident={handleSelectIncident}
                setGroupData={handleChangeGroupData}
            />
        );
    };

    const handleChangeGroupData = (data: GroupAndSubGroups[]) => {
        setGroupData(data);
        saveDataToDatabase(sessionIdFromUrl, GROUP_AND_SUB_GROUP_IN_DB, JSON.stringify(data));
    };

    const renderHazardMasterListMaudeTable = () => {
        return (
            <HazardMasterListMaudeTable
                data={hazardMasterListMaudeData}
                isProcessingData={isGeneratingHazardList}
                isDisableButton={isDisableButtonAllButtons}
                onGenrateHazardList={generateHazardList}
                shoudDisplayButtonGenerate={true}
                isGeneratingData={isGeneratingHazardMasterListMaudeTable}
                onDataChange={handleChangeHazadMasterList}
            />
        );
    };

    const handleChangeHazadMasterList = (data: HazardMasterListMaude[]) => {
        sethazardMasterListMaudeData(data);
        saveDataToDatabase(sessionIdFromUrl, HAZARD_MASTER_LIST_MAUDE_IN_DB, JSON.stringify(data));
    };

    const renderHazardListTable = () => {
        return <HazardListTable data={hazardListData} customClassName="col-2" isGeneratingData={isGeneratingHazardList} />;
    };

    const renderModalToAskUserInputMoreSpecificKeyword = () => {
        return (
            <Modal show={isShowModalToAskUserToInputMoreSpecificKeywords} centered>
                <Modal.Body>
                    <div>{`We found more than ${MAX_NUMBER_FOI_TEXT_RESULT} records. Please input more specific keywords to reduce the number of records.`}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsShowModalToAskUserToInputMoreSpecificKeywords(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const renderContent = () => {
        return (
            <div className="row w-100">
                {renderBottom1stColumn()}
                {renderGroupAndSubGroupColumn()}
                {renderHazardMasterListMaudeTable()}
                {renderHazardListTable()}
                {renderModalToAskUserInputMoreSpecificKeyword()}
            </div>
        );
    };
    return <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>{isLoading ? <LoadingPage /> : renderContent()}</div>;
};

export default BottomUp;

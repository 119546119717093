let navigate: any = null;

export const setNavigator = (navFn: any) => {
    navigate = navFn;
};

export const redirectTo = (path: string) => {
    if (navigate) {
        navigate(path);
    } else {
        console.error("Navigator has not been set.");
    }
};

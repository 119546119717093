import { MISSING_RISKS_BACKGROUND_COLOR } from "../../constants/appConstants";
import { AddedMissingRiskWithGeneratedMissingFields } from "../../pages/MissingRisks/Tab_AddedMissingRisks/Tab_AddedMissingRisks";
import { getDataOfAddedMissingRisks } from "../../utils/commonUtils";
import MasterTable from "../MasterTable/MasterTable";

export interface CSVData {
  header: string[];
  rows: string[][];
  columnWidth?: string[];
  riskSummaryColumnIndex: number;
  is_missing_issue_key: boolean;
  key_of_issue_key: string;
  addedMissingRisks: AddedMissingRisk[];
  column_issue_key_index: number;
  probability_and_severity_column_index:{
    probability: number;
    severity: number;
  }
}

export interface AddedMissingRisk {
  risk: string;
  subcategories: string[];
  data: string[];
  issueKey: string;
  severity: string;
  prob: string;
}

export const DEFAULT_CSV_DATA: CSVData = {
  header: [],
  rows: [],
  riskSummaryColumnIndex: 0,
  is_missing_issue_key: false,
  key_of_issue_key: "",
  addedMissingRisks: [],
  column_issue_key_index: 0,
  probability_and_severity_column_index:{
    probability: -1,
    severity: -1,
  }
};

export const CSVDataTable = (props: { 
  tableName: string;
   csvData: CSVData; 
   fileName: string; tableId: string;
   isDisplayingMissingRisks?: boolean;
   addedMissingRisksWithGeneratedMissingFields: AddedMissingRiskWithGeneratedMissingFields[];
}) => {
  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th>#</th>
          {props.csvData.header.map((key: string, index: number) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
      </thead>
    );
  };


  
  const renderTableBody = () => {
    return (
      <tbody>
        {props.csvData.rows.map((row: string[], index: number) => renderRow(row, index))}
        {props.isDisplayingMissingRisks && props.csvData.addedMissingRisks && props.csvData.addedMissingRisks.map((addedMissingRisk: AddedMissingRisk, index: number) =>  {
          const row = getDataOfAddedMissingRisks(addedMissingRisk, props.addedMissingRisksWithGeneratedMissingFields); 
          return renderRow(row, index + props.csvData.rows.length, true);
        })}
      </tbody>
    );
  };

  const renderRow = (row: string[], rowIndex: number, isMissingRisks: boolean = false) => {
    return (
      <tr key={rowIndex} style={{
        backgroundColor: isMissingRisks ? MISSING_RISKS_BACKGROUND_COLOR : "white"
      }}>
        <td>{rowIndex + 1}</td>
        {row.map((cell: string, index: number) => (
          <td key={index}>{cell.toString()}</td>
        ))}
      </tr>
    );
  }


  const renderColumnWidth = () => {
    return (
      <colgroup>
        <col style={{ width: "2%" }} />
        {props.csvData.header.map((text: string, index: number) => (
          <col key={text} />
        ))}
      </colgroup>
    );
  };

  
  return (
    <div
      className="row"
      style={{
        height: "90vh",
        overflow: "scroll",
      }}
    >
      <div className="col-12">
        <MasterTable id={props.tableId} fileName={props.fileName} data={props.csvData} isGeneratingData={false}>
          <>
            {renderColumnWidth()}
            {renderTableHeader()}
            {renderTableBody()}          </>
        </MasterTable>
      </div>
    </div>
  );
};

import React from "react";
import { BLANK_STRING } from "../../../constants/appConstants";
import { CSVData } from "../../../Components/CSVTable/CSVTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../../utils/commonUtils";
import { CATEGORIZE_RISK } from "../../../constants/api";
import { CSVDataToText, convertCSVToString } from "../../../utils/convertToCSVDataInString";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../../utils/commonFetchEventSource";
import CategorizeRisk, {
    CATEGORIES_TABLE,
    CategoryData,
    MAX_TIKTOKEN_FOR_CATEGORIZE_RISK,
} from "../../../Components/CategoryRiskTable/CategorizeRisk";
import { SubCategoriesOfEachRisk } from "../../../Components/CategoryRiskTable/Table/CategoryRiskTable";
import { clearOldSubcategoryRiskAPI } from "../../../apiRequests/categorizeRisk/clearOldCategoryRiskAPI";
import { encode } from "gpt-tokenizer";
import { updateKeyOfPageStateByOldKeyAPI } from "../../../apiRequests/pageStates/updateKeyOfPageStateByOldKeyAPI";
import { CATEGORY_RISK_C1_PREFIX, CATEGORY_RISK_C2_PREFIX } from "../constants";

interface Props {
    csvData: CSVData;
    c1CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
    c2CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
    setC1CategoryResultOfAllRows: React.Dispatch<React.SetStateAction<SubCategoriesOfEachRisk[]>>;
    setC2CategoryResultOfAllRows: React.Dispatch<React.SetStateAction<SubCategoriesOfEachRisk[]>>;
    c1Category: CategoryData;
    c2Category: CategoryData;
    initRiskSubcategories: (rows: string[][]) => SubCategoriesOfEachRisk[];
    isGeneratingData: boolean;
    setIsGeneratingData: (isGeneratingData: boolean) => void;
}
const Tab_CategorizeRisk = (props: Props) => {
    const {
        csvData,
        c1CategoryResultOfAllRows,
        c2CategoryResultOfAllRows,
        setC1CategoryResultOfAllRows,
        setC2CategoryResultOfAllRows,
        c1Category,
        c2Category,
        initRiskSubcategories,
        isGeneratingData,
        setIsGeneratingData,
    } = props;
    const sessionIdFromUrl = getSessionIdFromURL();

    const renderTabCategorizeRisks = () => {
        return (
            <CategorizeRisk
                csvData={csvData}
                c1CategoryResultOfAllRows={c1CategoryResultOfAllRows}
                c2CategoryResultOfAllRows={c2CategoryResultOfAllRows}
                c1Category={c1Category}
                c2Category={c2Category}
                sessionIdFromUrl={sessionIdFromUrl}
                handleCategoryRisk={handleCategoryRisk}
                isGeneratingData={isGeneratingData}
            />
        );
    };

    const handleCategoryRisk = async () => {
        setIsGeneratingData(true);
        setC1CategoryResultOfAllRows(initRiskSubcategories(csvData.rows));
        setC2CategoryResultOfAllRows(initRiskSubcategories(csvData.rows));
        await clearOldSubcategoryRiskAPI(sessionIdFromUrl);
        categorizeRiskAPI(0);
    };

    const categorizeRiskAPI = async (additionalColumnIndex: number, startRowIndex: number = 0) => {
        const numberOfRowsEachQuery = computeMaximumNumberOfRowsToConvert(startRowIndex);
        const endRowNumber = startRowIndex + numberOfRowsEachQuery;
        const csvDataToProcess: CSVDataToText = sliceAndConvertToCsvData(startRowIndex, endRowNumber);

        let fullMessagePart = BLANK_STRING;
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: CATEGORIZE_RISK,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                fullMessagePart = BLANK_STRING;
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                fullMessagePart = fullMessage;

                handleAddNewCategoriesToAdditionalData(fullMessage, additionalColumnIndex);
            },
            onClose: function (): void {
                const lastItemIndex = handleAddNewCategoriesToAdditionalData(fullMessagePart, additionalColumnIndex);

                if (stillHaveDataToProcess(lastItemIndex + 1)) {
                    if (endRowNumber !== lastItemIndex) {
                        const prefix = additionalColumnIndex === 0 ? CATEGORY_RISK_C1_PREFIX : CATEGORY_RISK_C2_PREFIX;
                        updateKeyOfPageStateByOldKeyAPI(
                            sessionIdFromUrl,
                            prefix + startRowIndex.toString() + "_" + endRowNumber.toString(),
                            prefix + startRowIndex.toString() + "_" + lastItemIndex.toString()
                        );
                    }
                    categorizeRiskAPI(additionalColumnIndex, lastItemIndex);
                } else {
                    additionalColumnIndex = additionalColumnIndex + 1;
                    if (additionalColumnIndex < CATEGORIES_TABLE.length) {
                        categorizeRiskAPI(additionalColumnIndex);
                    } else {
                        setIsGeneratingData(false);
                    }
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: "riskData",
                    value: JSON.stringify(convertCSVToString(csvDataToProcess, startRowIndex)),
                },
                {
                    key: "category",
                    value: CATEGORIES_TABLE[additionalColumnIndex],
                },
                {
                    key: "startEndIndex",
                    value: startRowIndex.toString() + "_" + endRowNumber.toString(),
                },
            ],
        };
        await commonFetchEventSource(paramsFetchEventSource);
    };

    const handleAddNewCategoriesToAdditionalData = (fullMessage: string, additionalColumnIndex: number) => {
        let lastItemIndex: number = -1;
        try {
            const data = convertIncompleteJSONToCompleteJSON(fullMessage, [{ issueKey: "", subcategories: [] }], []);

            if (data) {
                if (!additionalColumnIndex) {
                    setC1CategoryResultOfAllRows((prevState) => {
                        const { lastUpdatedIndex, updatedData } = updateCategories(prevState, data);
                        lastItemIndex = lastUpdatedIndex;
                        return updatedData;
                    });
                } else {
                    setC2CategoryResultOfAllRows((prevState) => {
                        const { lastUpdatedIndex, updatedData } = updateCategories(prevState, data);
                        lastItemIndex = lastUpdatedIndex;
                        return updatedData;
                    });
                }
            }
        } catch (e) {}
        return lastItemIndex + 1;
    };

    const updateCategories = (
        prevData: SubCategoriesOfEachRisk[],
        data: SubCategoriesOfEachRisk[]
    ): { lastUpdatedIndex: number; updatedData: SubCategoriesOfEachRisk[] } => {
        let lastUpdatedIndex: number = -1;
        let updatedData = [...prevData];
        try {
            data.forEach((item) => {
                const itemIndex = prevData.findIndex((newItem) => newItem.issueKey === item.issueKey);
                if (itemIndex !== -1) {
                    updatedData[itemIndex] = item;
                    lastUpdatedIndex = itemIndex;
                }
            });

            return { lastUpdatedIndex, updatedData };
        } catch (e) {
            return { lastUpdatedIndex, updatedData };
        }
    };

    const computeMaximumNumberOfRowsToConvert = (startRowIndex: number) => {
        for (let i = startRowIndex; i <= csvData.rows.length - 1; i++) {
            const csvDataToProcess: CSVDataToText = sliceAndConvertToCsvData(startRowIndex, i);
            const tokens = encode(JSON.stringify(convertCSVToString(csvDataToProcess, startRowIndex)));
            const tokensLength = tokens.length;

            if (tokensLength > MAX_TIKTOKEN_FOR_CATEGORIZE_RISK) {
                return i - 1 - startRowIndex;
            }
        }
        return csvData.rows.length - startRowIndex;
    };

    const sliceAndConvertToCsvData = (startIndex: number, endIndex: number): CSVDataToText => {
        const rows = csvData.rows.slice(startIndex, endIndex);
        const csvDataToProcess: CSVDataToText = {
            header: csvData.header,
            rows: rows,
        };
        return csvDataToProcess;
    };

    const stillHaveDataToProcess = (endRowNumber: number) => {
        return endRowNumber < csvData.rows.length;
    };

    return renderTabCategorizeRisks();
};

export default Tab_CategorizeRisk;

import React, { useState, useEffect } from "react";
import "./style.css";
import { FILE_PAGE, TABLE_NAME_HAZARD, TOP_DOWN_CLASS_NAME, FILE_TABLE_ID } from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID, COMPETITOR_WARNINGS, BLANK_STRING } from "../../constants/appConstants";
import { Button } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import MasterTable from "../../Components/MasterTable/MasterTable";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { convertTextToCSVFormat } from "../../apiRequests/csvFileProcess/convert_text_to_csv_format";
import { convertIncompleteJSONToCompleteJSON } from "../../utils/commonUtils";
interface RowData {
    text: string;
    page: any;
    section: any;
}

const File = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const sessionIdFromUrl = window.location.pathname.split("/")[2];
    const [inputText, setInputText] = useState<string>(BLANK_STRING);
    const [csvData, setCsvData] = useState<string[][]>([]);
    useEffect(() => {
        removeCsvData();
        const loadDataFromFirebase = async () => {
            setIsLoading(true);
            const data = await getDataFromAPI(COMPETITOR_WARNINGS, {
                text: BLANK_STRING,
                csvData: [],
            });
            setCsvData(data.csvData);
            setInputText(data.text);
            setIsLoading(false);
        };

        loadDataFromFirebase();
    }, [sessionIdFromUrl]);

    const getDataFromAPI = async (key: string, defaultData: any) => {
        try {
            const stateData = await loadPageState(sessionIdFromUrl);
            const result = stateData.stateDetail.csv;
            let data = { ...defaultData };
            const parsedCSVData = convertIncompleteJSONToCompleteJSON(result, [], []);
            data.csvData = parsedCSVData;

            try {
                data.text = stateData.stateDetail.text;
            } catch (e) {
                data.text = BLANK_STRING;
            }
            return data;
        } catch (e) {
            return defaultData;
        }
    };

    const CSVDataTable = (props: { tableName: string; data: string[][] }) => {
        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        {/* <th>#</th> */}
                        {props.data[0].map((key: string, index: number) => (
                            <th key={index}>{key}</th>
                        ))}
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            return (
                <tbody>
                    {props.data.slice(1).map((row: string[], index: number) => {
                        return (
                            <tr key={index}>
                                {/* <td>{index + 1}</td> */}
                                {/* {row.map((cell: string, index: number) => (
                  <td key={index}>{cell}</td>
                ))}
                 */}
                                {props.data[0].map((key: string, index: number) => {
                                    let data = "";
                                    if (index < row.length) {
                                        data = row[index];
                                    }
                                    return <td key={index + row[index]}>{row[index]}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            );
        };
        return (
            <div
                className="row"
                style={{
                    height: "90vh",
                    overflow: "scroll",
                }}
            >
                <div className="col-12">
                    <MasterTable id={FILE_TABLE_ID} fileName={FILE_PAGE} data={props.data} isGeneratingData={true}>
                        <>
                            {renderTableHeader()}
                            {renderTableBody()}
                        </>
                    </MasterTable>
                </div>
            </div>
        );
    };

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CSVDataTable tableName={TABLE_NAME_HAZARD} data={csvData} />
                </div>
            </div>
        );
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const renderInputField = () => {
        return (
            <div className="row">
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={40}
                    value={inputText}
                    onChange={handleInputChange}
                ></textarea>
            </div>
        );
    };
    const removeCsvData = () => {
        setCsvData([]);
    };
    const handleProcessButtonClick = async () => {
        removeCsvData();
        setIsGeneratingData(true);
        const data = await convertTextToCSVFormat(inputText, sessionIdFromUrl);
        try {
            setCsvData(JSON.parse(data));
        } catch (e) {
            console.log(e);
        }
        setIsGeneratingData(false);
    };
    const renderButtonProcessData = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Button onClick={handleProcessButtonClick} disabled={isGeneratingData}>
                        {isGeneratingData ? <LoadingButton /> : "Process Data"}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="row w-100">
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <div className="row">
                        <div className="col-3">
                            {renderButtonProcessData()}
                            {renderInputField()}
                        </div>
                        <div className="col-9">{csvData.length > 0 && renderTable()}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default File;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  isLoggedIn: boolean;
  isLoading: boolean;
  userId: string;
}

const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: true,
  userId: "",
};

export const authState = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginState: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
  },
});

export const { setIsLoading, setLoginState, setUserInfo } = authState.actions;
export default authState.reducer;

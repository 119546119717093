import { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import {
    COLOR_LISTS,
    HAZARD_LIST_RISK_TABLE_ID,
    GENERATE_MITIGATION_TEXT,
    GENERATE_PROBABILITY_TEXT,
    JUSTIFICATION_TEXT,
    MITIGATION_INFORMATION_SAFETY_TEXT,
    MITIGATION_INHERENTLY_SAFE_DESIGN_TEXT,
    MITIGATION_PROTECTIVE_MEASURE_TEXT,
    PROBABILITY_HIGH,
    PROBABILITY_LOW,
    PROBABILITY_MEDIUM,
    PROBABILITY_TEXT,
    RISK_CATEGORIES_KEY,
    RISK_CHARACTERISTICS_KEY,
    RISK_CONTENT_CLASSNAME,
    RISK_HAZARD_KEY,
    RISK_PAGE_CLASS_NAME,
    RISK_RATING_TEXT,
    RISK_TABLE_CLASS_NAME,
    RISK_TABLE_CONTAINER,
    SCENARIO_TEXT,
    SEVERITY_TEXT,
    TAB_NAME_CLASS,
    RISK_TABLE_ID,
} from "./constants";
import { MOCK_HAZARD_LIST } from "./mockHazardLit";
import { TABS_DEFAULT_DATA } from "./tableC2";
import { Hazard, ITabData, RiskData, RiskTableProps } from "../../types/RiskDataType";
import { BUTTON_PROCESS_DATA_CLASS_NAME } from "../../Components/BottomUp1stColumn/constants";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import MOCK_CHARACTERISTICS from "./characteristics.json";
import { GENERATE_MITIGATION, GENEREATE_SCENARIOS_AND_PROBABILITY } from "../../constants/api";
import { BLANK_STRING } from "../../constants/appConstants";
import "./style.css";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import MasterTable from "../../Components/MasterTable/MasterTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../utils/commonUtils";
import { deletePageStateDetailAPI, loadPageState } from "../../apiRequests/pageStates/pagestate";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";

export const C2_TABLE_DATA_IN_LOCAL_STORAGE_KEY = "c2TableData";

function Risk() {
    const [key, setKey] = useState(TABS_DEFAULT_DATA[0].category);
    const [c2Table, setC2Table] = useState<ITabData[]>([...TABS_DEFAULT_DATA]);
    const [isGeneratingScenarios, setIsGeneratingScenarios] = useState<boolean>(false);
    const [hazards, setHazards] = useState<Hazard[]>(MOCK_HAZARD_LIST);
    const [disableAllButtons, setDisableAllButtons] = useState<boolean>(false);
    const [isGeneratingMitigation, setIsGeneratingMitigation] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const sessionIdFromUrl = getSessionIdFromURL();
    useEffect(() => {
        clearTableData();
        getLatestPageStateFromAPI();
        addColorToHazards();
    }, [sessionIdFromUrl]);

    const getLatestPageStateFromAPI = async () => {
        setIsLoading(true);
        const stateData = await loadPageState(sessionIdFromUrl);
        handleScenarioHistory(stateData);
        sortTableWithHazardListOrder();
        handleMitigationHistory(stateData);
        setIsLoading(false);
    };

    const handleScenarioHistory = (stateData: any) => {
        for (const key in stateData.stateDetail) {
            if (!key.includes("MITIGATION")) {
                const scenariosInString = stateData.stateDetail[key];
                const [category, hazard] = key.split("-");
                const outputFormat = [{ scenario: "", reason: "", category: "", prob: "" }];
                const scenarios = convertIncompleteJSONToCompleteJSON(scenariosInString, outputFormat, []);
                parseScenariosHistoryData(scenarios, category, hazard);
            }
        }
    };

    const handleMitigationHistory = (stateData: any) => {
        for (const key in stateData.stateDetail) {
            if (key.includes("MITIGATION")) {
                const mitigationsInString = stateData.stateDetail[key];
                const [_, tabIndexInString] = key.split("-");
                const tabIndex: number = parseInt(tabIndexInString);
                const outputFormat = [
                    { index: 0, mitigation_inherently_safe_design: "", mitigation_protective_measure: "", mitigation_information_for_safety: "" },
                ];
                const mitigations = convertIncompleteJSONToCompleteJSON(mitigationsInString, outputFormat, []);
                updateMigigationHistory(tabIndex, mitigations);
            }
        }
    };

    const sortTableWithHazardListOrder = () => {
        setC2Table((prevState) => {
            return prevState.map((item) => {
                return {
                    ...item,
                    tableData: {
                        ...item.tableData,
                        data: item.tableData.data.sort((a, b) => {
                            const aIndex = MOCK_HAZARD_LIST.findIndex((hazard) => hazard.hazard === a.hazard);
                            const bIndex = MOCK_HAZARD_LIST.findIndex((hazard) => hazard.hazard === b.hazard);
                            return aIndex - bIndex;
                        }),
                    },
                };
            });
        });
    };

    const updateMigigationHistory = (tabIndex: number, data: any[]) => {
        setC2Table((prevState) => {
            data.forEach((item, index) => {
                const idx = parseInt(item.index);
                prevState[tabIndex].tableData.data[idx] = {
                    ...prevState[tabIndex].tableData.data[idx],
                    ...item,
                };
            });
            return [...prevState];
        });
    };

    const parseScenariosHistoryData = (scenarios: any[], category: string, hazard: string) => {
        setC2Table((prevState) => {
            const hazardIndex = hazards.findIndex((item) => item.hazard === hazard);

            const riskData = scenarios.map((item: any) => {
                return {
                    ...item,
                    severity: hazards[hazardIndex].severity,
                    hazard: hazard,
                };
            });
            const categoryIndex = TABS_DEFAULT_DATA.findIndex((item) => item.category === category);
            prevState[categoryIndex].tableData.data = prevState[categoryIndex].tableData.data.concat(riskData);

            return [...prevState];
        });
    };

    const addColorToHazards = () => {
        setHazards((prevState) => {
            return prevState.map((item, index) => {
                return {
                    ...item,
                    color: COLOR_LISTS[index % COLOR_LISTS.length],
                };
            });
        });
    };

    const handleSelectHazard = (hazard: Hazard) => {
        try {
            const tables = document.getElementsByClassName(RISK_TABLE_CLASS_NAME);
            for (let i = 0; i < tables.length; i++) {
                const table = tables[i];
                const rows = table.getElementsByClassName(hazard.hazard);
                if (rows.length > 0) {
                    rows[0].scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    function renderHazardList() {
        return (
            <div>
                <MasterTable fileName={RISK_HAZARD_KEY} data={hazards} isGeneratingData={true} id={HAZARD_LIST_RISK_TABLE_ID}>
                    <>
                        <thead>
                            <tr>
                                <th style={{ width: "5%" }}>#</th>
                                <th style={{ width: "10%" }}>Hazard</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hazards.map((item, index) => {
                                return (
                                    <tr
                                        key={index}
                                        style={{
                                            backgroundColor: item.color,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleSelectHazard(item)}
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.hazard + `(${SEVERITY_TEXT}: ${item.severity})`}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </>
                </MasterTable>
                {renderButtonProcessData(GENERATE_PROBABILITY_TEXT, onGenerateScenarios, isGeneratingScenarios, disableAllButtons)}
                {renderButtonProcessData(GENERATE_MITIGATION_TEXT, handleGenerateMitigation, isGeneratingMitigation, disableAllButtons)}
            </div>
        );
    }

    const handleGenerateMitigation = async () => {
        moveAllRenderingDataToData();
        const promises = c2Table.map((item, index) => {
            return generateMitigationForEachTab(index);
        });
        await Promise.all(promises);
    };

    const moveAllRenderingDataToData = () => {
        setC2Table((prevState) => {
            const newState = prevState.map((item) => {
                return {
                    ...item,
                    tableData: {
                        ...item.tableData,
                        data: [...item.tableData.data, ...item.tableData.renderringData],
                        renderringData: [],
                    },
                };
            });

            return newState;
        });
    };

    const setTabIsLoading = (tabIndex: number, isLoading: boolean) => {
        setC2Table((prevState) => {
            prevState[tabIndex].tableData.isLoading = isLoading;
            if (!isLoading) {
                disableLoadingIfAllTabsAreDone(prevState);
            }
            return [...prevState];
        });
    };

    const generateMitigationForEachTab = async (tabIndex: number) => {
        const allScenarios = [...c2Table[tabIndex].tableData.data];
        c2Table[tabIndex].tableData.isLoading = true;
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_MITIGATION,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setDisableAllButtons(true);
                setIsGeneratingMitigation(true);
            },
            onMessage: function (fullMessage: string): void {
                const outputFormat = [
                    { index: 0, mitigation_inherently_safe_design: "", mitigation_protective_measure: "", mitigation_information_for_safety: "" },
                ];
                const mitigations = convertIncompleteJSONToCompleteJSON(fullMessage, outputFormat, []);
                updateMigitationBasedOnTabIndex(tabIndex, mitigations);
            },
            onClose: function (): void {
                disableLoadingForThisTab(tabIndex);
            },
            onError: () => {
                disableLoadingForThisTab(tabIndex);
            },
            formDataKeyValue: [
                {
                    key: "scenarios",
                    value: JSON.stringify(
                        allScenarios.map((item, index) => {
                            return {
                                scenario: item.scenario,
                                reason: item.reason,
                                index: index,
                            };
                        })
                    ),
                },
                {
                    key: "categoryIndex",
                    value: tabIndex.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const onGenerateScenarios = async () => {
        setIsGeneratingScenarios(true);
        setDisableAllButtons(true);
        clearTableData();
        const sessionId = getSessionIdFromURL();
        await deletePageStateDetailAPI(sessionId);
        await generateScenariosDataForAllTabsForTheFirstHazard();
    };

    const clearTableData = () => {
        setC2Table((prevState) => {
            prevState.forEach((item) => {
                item.tableData.data = [];
                item.tableData.renderringData = [];
            });
            return [...prevState];
        });
    };

    const updateRenderingDataOfTableBasedOnIndex = (tabIndex: number, hazardIndex: number, data: any) => {
        setC2Table((prevState) => {
            prevState[tabIndex].tableData.renderringData = data.map((item: any) => {
                return {
                    ...item,
                    severity: hazards[hazardIndex].severity,
                    hazard: hazards[hazardIndex].hazard,
                };
            });
            return [...prevState];
        });
    };

    const updateMigitationBasedOnTabIndex = (index: number, data: any[]) => {
        if (data.length === 0) return;
        const dataLength = data.length;
        try {
            setC2Table((prevState) => {
                let tabData = prevState[index].tableData.data;
                tabData[dataLength - 1] = {
                    ...tabData[dataLength - 1],
                    ...data[dataLength - 1],
                };
                if (data.length === 1) return [...prevState];
                tabData[data.length - 2] = {
                    ...tabData[data.length - 2],
                    ...data[data.length - 2],
                };
                return [...prevState];
            });
        } catch (error) {}
    };

    const moveRenderingDataToData = (index: number) => {
        setC2Table((prevState) => {
            prevState[index].tableData.data = prevState[index].tableData.data.concat(prevState[index].tableData.renderringData);
            prevState[index].tableData.renderringData = [];
            return [...prevState];
        });
    };

    const generateEachTabDataBasedOnTabIndexAndHazard = async (tabIndex: number, hazardIndex: number = 0) => {
        const hazard = hazards[hazardIndex].hazard;
        const characteristics = MOCK_CHARACTERISTICS;
        c2Table[tabIndex].tableData.isLoading = true;
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENEREATE_SCENARIOS_AND_PROBABILITY,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingScenarios(true);
                setDisableAllButtons(true);
            },
            onMessage: function (fullMessage: string): void {
                const outputFormat = [{ scenario: "", reason: "", category: "", prob: "" }];
                const scenarios = convertIncompleteJSONToCompleteJSON(fullMessage, outputFormat, []);
                updateRenderingDataOfTableBasedOnIndex(tabIndex, hazardIndex, scenarios);
            },
            onClose: function (): void {
                moveRenderingDataToData(tabIndex);
                setTimeout(() => {
                    if (stillHasHazardToProcess(hazardIndex)) {
                        generateEachTabDataBasedOnTabIndexAndHazard(tabIndex, hazardIndex + 1);
                    } else {
                        disableLoadingForThisTab(tabIndex);
                    }
                }, 200);
            },
            onError: () => {
                disableLoadingForThisTab(tabIndex);
            },
            formDataKeyValue: [
                {
                    key: RISK_HAZARD_KEY,
                    value: hazard,
                },
                {
                    key: RISK_CHARACTERISTICS_KEY,
                    value: JSON.stringify(characteristics),
                },
                {
                    key: RISK_CATEGORIES_KEY,
                    value: JSON.stringify(c2Table[tabIndex].events_circumstances),
                },
                {
                    key: "category",
                    value: c2Table[tabIndex].category,
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const disableLoadingForThisTab = (tabIndex: number) => {
        setTabIsLoading(tabIndex, false);
    };

    const stillHasHazardToProcess = (hazardIndex: number) => {
        return hazardIndex < hazards.length - 1;
    };

    const disableLoadingIfAllTabsAreDone = (c2Table: ITabData[]) => {
        setTimeout(() => {
            const isDone = c2Table.every((item) => {
                return !item.tableData.isLoading;
            });
            if (isDone) {
                setIsGeneratingScenarios(false);
                setDisableAllButtons(false);
                setIsGeneratingMitigation(false);
            }
        }, 1000);
    };

    const generateScenariosDataForAllTabsForTheFirstHazard = async () => {
        const promises = c2Table.map((item, index) => {
            return generateEachTabDataBasedOnTabIndexAndHazard(index);
        });
        await Promise.all(promises);
    };

    const renderButtonProcessData = (text: string, onGenerateScenarios: () => void, isLoading: boolean, isDisabled: boolean) => {
        return (
            <Button className={BUTTON_PROCESS_DATA_CLASS_NAME} onClick={() => onGenerateScenarios()} disabled={isDisabled}>
                {isLoading ? <LoadingButton /> : text}
            </Button>
        );
    };

    const renderRiskContent = () => {
        return (
            <div
                className={RISK_CONTENT_CLASSNAME}
                style={{
                    margin: 10,
                    maxWidth: "100%",
                }}
            >
                <div className="col-2">{renderHazardList()}</div>
                <div className={`col-10`}>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k as string)}>
                        {c2Table.map((item: ITabData, index: number) => {
                            return (
                                <Tab
                                    eventKey={item.category}
                                    title={
                                        <span className={TAB_NAME_CLASS}>
                                            {item.category}
                                            {item.tableData.isLoading && <LoadingButton />}
                                        </span>
                                    }
                                >
                                    {key === item.category && <RiskTable {...item.tableData} currentTab={key} hazardList={hazards} />}
                                </Tab>
                            );
                        })}
                    </Tabs>
                </div>
            </div>
        );
    };

    return <div className={`${RISK_PAGE_CLASS_NAME}`}>{isLoading ? <LoadingPage /> : renderRiskContent()}</div>;
}

function RiskTable(props: RiskTableProps) {
    const [csvData, setCsvData] = useState<any>();
    useEffect(() => {
        try {
            const newCsvData = props.renderringData.concat(props.data).flatMap((data) => {
                return {
                    [SCENARIO_TEXT]: data.scenario,
                    [JUSTIFICATION_TEXT]: data.reason,
                    [SEVERITY_TEXT]: data.severity,
                    [PROBABILITY_TEXT]: data.prob,
                    [RISK_RATING_TEXT]: data.riskRating,
                    [MITIGATION_INHERENTLY_SAFE_DESIGN_TEXT]: data.mitigation_inherently_safe_design,
                    [MITIGATION_PROTECTIVE_MEASURE_TEXT]: data.mitigation_protective_measure,
                    [MITIGATION_INFORMATION_SAFETY_TEXT]: data.mitigation_information_for_safety,
                };
            });

            setCsvData(newCsvData);
        } catch (e) {
            console.log(e);
        }
    }, [props.renderringData, props.data]);

    const RISK_MAP: { [key: number]: { [key: number]: string } } = {
        5: {
            1: PROBABILITY_HIGH,
            2: PROBABILITY_HIGH,
            3: PROBABILITY_HIGH,
            4: PROBABILITY_HIGH,
            5: PROBABILITY_HIGH,
        },
        4: {
            1: PROBABILITY_LOW,
            2: PROBABILITY_MEDIUM,
            3: PROBABILITY_HIGH,
            4: PROBABILITY_HIGH,
            5: PROBABILITY_HIGH,
        },
        3: {
            1: PROBABILITY_LOW,
            2: PROBABILITY_MEDIUM,
            3: PROBABILITY_MEDIUM,
            4: PROBABILITY_HIGH,
            5: PROBABILITY_HIGH,
        },
        2: {
            1: PROBABILITY_LOW,
            2: PROBABILITY_LOW,
            3: PROBABILITY_MEDIUM,
            4: PROBABILITY_MEDIUM,
            5: PROBABILITY_MEDIUM,
        },
        1: {
            1: PROBABILITY_LOW,
            2: PROBABILITY_LOW,
            3: PROBABILITY_LOW,
            4: PROBABILITY_LOW,
            5: PROBABILITY_MEDIUM,
        },
    };

    const renderRiskRating = (riskData: RiskData) => {
        try {
            const severity = extractLevelFromString(riskData.severity);
            const probability = extractLevelFromString(riskData.prob);

            return RISK_MAP[severity][probability] || BLANK_STRING;
        } catch (error) {}

        return BLANK_STRING;
    };

    const extractLevelFromString = (str: string) => {
        try {
            return parseInt(str.split(".")[0]);
        } catch (err) {
            return -1;
        }
    };

    const renderTable = () => {
        return (
            <MasterTable
                fileName={`${RISK_TABLE_CLASS_NAME}_${props.currentTab}`}
                className={RISK_TABLE_CLASS_NAME}
                data={csvData}
                isGeneratingData={true}
                id={RISK_TABLE_ID}
            >
                <>
                    {renderTableHeader()}
                    {renderTableBody()}
                </>
            </MasterTable>
        );
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th style={{ width: "3%" }}>#</th>
                    <th style={{ width: "10%%" }}>{SCENARIO_TEXT}</th>
                    <th style={{ width: "20%" }}>{JUSTIFICATION_TEXT}</th>
                    <th style={{ width: "7%" }}>{SEVERITY_TEXT}</th>
                    <th style={{ width: "7%" }}>{PROBABILITY_TEXT}</th>
                    <th style={{ width: "4%" }}>{RISK_RATING_TEXT}</th>
                    <th style={{ width: "15%" }}>{MITIGATION_INHERENTLY_SAFE_DESIGN_TEXT}</th>
                    <th style={{ width: "15%" }}>{MITIGATION_PROTECTIVE_MEASURE_TEXT}</th>
                    <th style={{ width: "15%" }}>{MITIGATION_INFORMATION_SAFETY_TEXT}</th>
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.data.concat(props.renderringData).map((item, index) => {
                    const riskRating = renderRiskRating(item);
                    const backgroundColorFromHazard = props.hazardList.find((hazard) => {
                        return hazard.hazard === item.hazard;
                    })?.color;
                    return (
                        <tr
                            key={index}
                            style={{
                                backgroundColor: backgroundColorFromHazard,
                            }}
                            className={item.hazard}
                        >
                            <td>{index + 1}</td>
                            <td>
                                {item.scenario} ({item.hazard})
                            </td>
                            <td>{item.reason}</td>
                            <td>{item.severity}</td>
                            <td>{item.prob}</td>
                            <td>{riskRating}</td>
                            <td>{item.mitigation_inherently_safe_design}</td>
                            <td>{item.mitigation_protective_measure}</td>
                            <td>{item.mitigation_information_for_safety}</td>
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    return <div className={RISK_TABLE_CONTAINER}>{renderTable()}</div>;
}

export default Risk;

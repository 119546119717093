export const TOP_DOWN_CLASS_NAME = "top-down";
export const MAUDE_TABLE_SEARCH_RESULT_ID = "maude-table-search-result";
export const FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY =
  "hazard_master_list_maude";
export const FORM_DATA_GROUP_AND_SUB_GROUP_KEY = "groups_and_sub_groups";
export const FORM_DATA_INCIDENTS_KEY = "incidents";
export const TR_TAG = "tr";
export const TABLE_COLOR_BLACK = "black";
export const TABLE_COLOR_BLUE = "blue";
export const NUMBER_OF_INCIDENTS_ITEM_TO_FIND_GROUP_AND_SUB_GROUP = 50;
export const MAX_NUMBER_FOI_TEXT_RESULT = 1000;

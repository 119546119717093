import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import {
    ACCEPT_JSON_INPUT,
    COMPETIOR_PAGE,
    ERROR_MESSAGES_NO_FILE,
    ERROR_MESSAGES_NO_FILE_SELECTED,
    FILE_KEY,
    INPUT_FILE_CLASS_NAME,
    INPUT_GROUP_CLASS_NAME,
    INPUT_TYPE_FILE,
    INPUT_UPLOAD_FILE_ID,
    TABLE_NAME_HAZARD,
    TOP_DOWN_CLASS_NAME,
    UPLOAD_TEXT,
    COMPETIOR_TABLE_ID,
} from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID, COMPETITOR_WARNINGS } from "../../constants/appConstants";
import { Button } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import { COMPETITOR_API } from "../../constants/api";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import MasterTable from "../../Components/MasterTable/MasterTable";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../utils/commonUtils";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";

interface RowData {
    text: string;
    page: any;
    section: any;
}

const Competitor = () => {
    const [hazards, setHazard] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const sessionIdFromUrl = getSessionIdFromURL();

    useEffect(() => {
        const loadDataFromFirebase = async () => {
            setIsLoading(true);
            setHazard([]);
            try {
                const warns = await getDataFromAPI();
                setHazard(warns);
            } catch (e) {
                console.error(e);
            }

            setIsLoading(false);
        };

        loadDataFromFirebase();
    }, [sessionIdFromUrl]);

    const getDataFromAPI = async () => {
        const stateData = await loadPageState(sessionIdFromUrl);
        const hazardListInString = stateData.stateDetail.hazard_list;
        const hazardList = convertIncompleteJSONToCompleteJSON(hazardListInString, [], []);
        return hazardList;
    };

    const renderInputAndButtonUploadFile = () => {
        return (
            <div className="col-6">
                <div className={INPUT_GROUP_CLASS_NAME}>
                    <input
                        type={INPUT_TYPE_FILE}
                        className={INPUT_FILE_CLASS_NAME}
                        id={INPUT_UPLOAD_FILE_ID}
                        accept={ACCEPT_JSON_INPUT}
                        onChange={handleFileInputChange}
                    />
                    <Button onClick={handleUploadButtonClick} disabled={!isFileSelected || isGeneratingData}>
                        {isGeneratingData ? <LoadingButton /> : UPLOAD_TEXT}
                    </Button>
                </div>
            </div>
        );
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFileSelected(!!event.target.files?.length);
    };

    const clearTablesData = () => {
        setHazard([]);
    };

    function setHardListOfCombinePageData(data: string[]) {
        setHazard(data);
    }

    const handleUploadButtonClick = async () => {
        const fileInput = document.getElementById(INPUT_UPLOAD_FILE_ID) as HTMLInputElement;
        if (!fileInput) return console.error(ERROR_MESSAGES_NO_FILE);
        if (!fileInput.files) return console.error(ERROR_MESSAGES_NO_FILE);
        const file = fileInput.files[0];
        if (!file) {
            console.error(ERROR_MESSAGES_NO_FILE_SELECTED);
            return;
        }
        clearTablesData();
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: COMPETITOR_API,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                const hazardList = convertIncompleteJSONToCompleteJSON(fullMessage, [], []);
                setHardListOfCombinePageData(hazardList);
            },
            onClose: function (): void {
                setIsGeneratingData(false);
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: FILE_KEY,
                    value: file,
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const HazardTable = (props: { tableName: string; data: string[] }) => {
        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>#</th>
                        <th scope="col">{props.tableName}</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            return (
                <tbody>
                    {props.data.map((hazard: string, index: number) => (
                        <tr key={hazard}>
                            <td>{index + 1}</td>
                            <td>{hazard}</td>
                        </tr>
                    ))}
                </tbody>
            );
        };
        return (
            <div
                className="row"
                style={{
                    height: "90vh",
                    overflow: "scroll",
                }}
            >
                <div className="col-12">
                    <MasterTable id={COMPETIOR_TABLE_ID} fileName={COMPETIOR_PAGE} data={props.data} isGeneratingData={true}>
                        <>
                            {renderTableHeader()}
                            {renderTableBody()}
                        </>
                    </MasterTable>
                </div>
            </div>
        );
    };

    const renderHazardTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <HazardTable tableName={TABLE_NAME_HAZARD} data={hazards} />
                </div>
            </div>
        );
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="row w-100">
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <div className="col-12">
                        {renderInputAndButtonUploadFile()}
                        {renderHazardTable()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Competitor;

import React, { useEffect, useRef } from "react";

import { Button, Table } from "react-bootstrap";
import { DOWNLOAD_CSV_TEXT, MASTER_TABLE_BUTTON_DOWNLOAD_CSV, MASTER_TABLE_CLASS_NAME } from "./constants";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
    data: any;
    isGeneratingData: boolean;
    children: React.ReactElement;
    className?: string;
    id: string;
    isHover?: boolean;
    fileName: string;
}
const MasterTable = (props: Props) => {
    const tFootRef = useRef<any>(null);

    useEffect(() => {
        try {
            if (props.isGeneratingData) {
                tFootRef?.current?.scrollIntoView({ behavior: "smooth" });
            }
        } catch (error) {
            console.log(error);
        }
    }, [props.data]);

    const getCellInputValues = (cell: HTMLElement): string[] => {
        const inputs = Array.from(cell.querySelectorAll("input"));
        return inputs.map((input) => (input as HTMLInputElement).value);
    };

    const getCellTextareaValues = (cell: HTMLElement): string[] => {
        const textareas = Array.from(cell.querySelectorAll("textarea"));
        return textareas.map((textarea) => (textarea as HTMLTextAreaElement).value);
    };

    const getCellInnerText = (cell: HTMLElement): string => {
        console.log(cell.innerText);
        return cell.innerText;
    };

    const getCellData = (cell: HTMLElement): string => {
        const allValues = [...getCellInputValues(cell), ...getCellTextareaValues(cell)];
        console.log("'" + allValues.join("\n\n") + "'");
        return allValues.length > 0 ? allValues.join(" \n\n") : getCellInnerText(cell);
    };

    const escapeCSVString = (data: string): string => {
        return '"' + data.replace(/,$/, "").replace(/"/g, '""') + '"';
    };

    const processRowSpans = (pendingRowSpans: any[], row: string[]) => {
        for (let span of pendingRowSpans) {
            if (span.count > 0) {
                row.push(span.value);
                span.count--;
            }
        }
    };

    const createDownloadLink = (filename: string, csvString: string): HTMLAnchorElement => {
        const link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("target", "_blank");
        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvString));
        link.setAttribute("download", filename);
        return link;
    };

    const triggerDownload = (link: HTMLAnchorElement) => {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadCsvString = (csvString: string) => {
        const filename = props.fileName + ".csv";
        const downloadLink = createDownloadLink(filename, csvString);
        triggerDownload(downloadLink);
    };

    const downloadTableAsCsv = (table_id: string, separator = ",") => {
        const rows = document.querySelectorAll("#" + table_id + " tr");
        const csv = [];
        const pendingRowSpans = [];

        for (var i = 0; i < rows.length; i++) {
            const row: string[] = [];
            processRowSpans(pendingRowSpans, row);

            const cols = rows[i].querySelectorAll("td, th") as NodeListOf<HTMLElement>;
            for (var j = 0; j < cols.length; j++) {
                const data = escapeCSVString(getCellData(cols[j]));
                row.push(data);

                const rowspan = cols[j].getAttribute("rowspan");
                if (rowspan) {
                    const rowspanValue = parseInt(rowspan, 10);
                    pendingRowSpans.push({ count: rowspanValue - 1, value: data });
                }
            }
            csv.push(row.join(separator));
        }

        const csv_string = csv.join("\n");
        downloadCsvString(csv_string);
    };

    return (
        <div className={MASTER_TABLE_CLASS_NAME}>
            <Button className={MASTER_TABLE_BUTTON_DOWNLOAD_CSV} variant="primary" onClick={() => downloadTableAsCsv(props.id)}>
                <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Table bordered size="sm" hover={props.isHover} className={props.className} id={props.id}>
                {props.children}
                <tfoot ref={tFootRef}></tfoot>
            </Table>
        </div>
    );
};

export default MasterTable;

import React, { useState, useEffect } from "react";
import "./style.css";
import { CATEGORY_RISK_CLASS_NAME, CATEGORY_WITH_COUNT_KEY, ITEM_TOP_COUNT_KEY } from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID } from "../../constants/appConstants";
import { Button, Table } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import { CategoryRiskTable, CategoryRiskTableData, SubCategoriesOfEachRisk } from "./Table/CategoryRiskTable";

import MasterTable from "../MasterTable/MasterTable";
import { CSVData } from "../CSVTable/CSVTable";

export const RISK_TABLE_NAME = "Risk";
export const CATEGORIES_TABLE = ["C1", "C2"];
export const MAX_TIKTOKEN_FOR_CATEGORIZE_RISK = 6000;

export const DEFAULT_CSV_DATA_FOR_RISK_DATA: CategoryRiskTableData = {
    header: [],
    rows: [],
    addedMissingRisks: [],
    columnWidth: [],
    c1SubCategories: [],
    c2SubCategories: [],
    riskSummaryColumnIndex: 0,
    is_missing_issue_key: false,
    key_of_issue_key: "",
    column_issue_key_index: 0,
    probability_and_severity_column_index: {
        probability: 0,
        severity: 0,
    },
};

export interface SelectedSubCategory {
    text: string;
    top: number;
}

export interface CategoryData {
    subcategory: string[];
}

export interface SubCategoryWithCountRisk {
    text: string;
    topCount: number[];
}

export interface CategoryDataWithCountRisk {
    subcategory: SubCategoryWithCountRisk[];
}

interface CategorizeRiskProps {
    csvData: CSVData;
    handleCategoryRisk: () => void;
    c1Category: CategoryData;
    c2Category: CategoryData;
    sessionIdFromUrl: string;
    isGeneratingData: boolean;
    c1CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
    c2CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
}

const CategorizeRisk = (props: CategorizeRiskProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<CategoryRiskTableData>({
        ...DEFAULT_CSV_DATA_FOR_RISK_DATA,
    });
    const [selectedSubcategories, setSelectedSubcategories] = useState<SelectedSubCategory[]>([]);
    useEffect(() => {
        const loadSessionData = async () => {
            setIsLoading(true);

            try {
                if (props.csvData) {
                    try {
                        setCsvData((prevData) => {
                            return {
                                ...prevData,
                                ...props.csvData,
                                header: [...props.csvData.header, ...CATEGORIES_TABLE],
                                columnWidth: props.csvData.columnWidth || [],
                                c1SubCategories: props.c1CategoryResultOfAllRows,
                                c2SubCategories: props.c2CategoryResultOfAllRows,
                            };
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        };

        loadSessionData();
    }, [props.csvData]);
    useEffect(() => {
        setCsvData((prevData) => {
            return {
                ...prevData,
                c1SubCategories: props.c1CategoryResultOfAllRows,
                c2SubCategories: props.c2CategoryResultOfAllRows,
            };
        });
    }, [props.c1CategoryResultOfAllRows, props.c2CategoryResultOfAllRows]);

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CategoryRiskTable
                        csvData={csvData}
                        selectedSubCategories={selectedSubcategories}
                        c1Category={props.c1Category}
                        c2Category={props.c2Category}
                    />
                </div>
            </div>
        );
    };

    const renderButtonCategorizeRisk = () => {
        return (
            <Button
                onClick={handleCategoryRisk}
                disabled={props.isGeneratingData}
                style={{
                    marginLeft: "20px",
                }}
            >
                {props.isGeneratingData ? <LoadingButton /> : "Categorize Risk"}
            </Button>
        );
    };

    const handleCategoryRisk = async () => {
        setSelectedSubcategories([]);
        props.handleCategoryRisk();
    };

    const renderTableC1Category = () => {
        return renderTableCategory(props.c1Category, csvData.c1SubCategories, CATEGORIES_TABLE[0]);
    };

    const renderTableC2Category = () => {
        return renderTableCategory(props.c2Category, csvData.c2SubCategories, CATEGORIES_TABLE[1]);
    };

    const countSubcategory = (categoryType: CategoryData, additionalData: SubCategoriesOfEachRisk[]): CategoryDataWithCountRisk => {
        return {
            subcategory: categoryType.subcategory
                .map((item) => {
                    const topCount = [0, 0, 0];
                    additionalData.forEach((additionalItem: SubCategoriesOfEachRisk) => {
                        try {
                            if (additionalItem.subcategories && additionalItem.subcategories.length > 0) {
                                for (let i = 0; i < additionalItem.subcategories.length; i++) {
                                    if (additionalItem.subcategories[i] === item) {
                                        topCount[i] = topCount[i] + 1;
                                    }
                                }
                            }
                        } catch (e) {}
                    });
                    return {
                        topCount: topCount,
                        text: item,
                    };
                })
                .sort((a, b) => {
                    return sumNumbers(b.topCount) - sumNumbers(a.topCount);
                }),
        };
    };

    const sumNumbers = (numbers: number[]) => {
        try {
            return numbers.reduce((a, b) => a + b, 0);
        } catch (e) {
            return 0;
        }
    };
    const isSelectedSubCategory = (subCategory: string, top: number, selectedSubCategories: SelectedSubCategory[]) => {
        const item = selectedSubCategories.find((item: SelectedSubCategory) => item.text === subCategory);
        if (item) {
            if (top === -1) {
                return true;
            }
            return item.top === top;
        }
        return false;
    };
    const isSelectedSubCategoryWithoutCheckTop = (subCategory: string, selectedSubCategories: SelectedSubCategory[]) => {
        const item = selectedSubCategories.find((item: SelectedSubCategory) => item.text === subCategory);
        if (item) {
            return true;
        }
        return false;
    };

    const selectSubcategory = (subcategory: string, top: number) => {
        if (isSelectedSubCategory(subcategory, -1, selectedSubcategories)) {
            const selectedItem = selectedSubcategories.find((item: SelectedSubCategory) => item.text === subcategory);
            if (selectedItem?.top === top) {
                setSelectedSubcategories((prevState) => {
                    return prevState.filter((item) => item.text !== subcategory);
                });
            } else {
                setSelectedSubcategories((prevState) => {
                    return prevState.map((item) => {
                        if (item.text === subcategory) {
                            console.log("change top", top);
                            return {
                                ...item,
                                top: top,
                            };
                        }
                        return item;
                    });
                });
            }
        } else {
            setSelectedSubcategories((prevState) => {
                return [
                    ...prevState,
                    {
                        text: subcategory,
                        top: top,
                    },
                ];
            });
        }
    };

    const handleSelectSubcategory = (event: any, subcategory: string, top: number) => {
        event.stopPropagation();
        selectSubcategory(subcategory, top);
    };

    const renderTableCategory = (categoryType: CategoryData, columnRiskData: SubCategoriesOfEachRisk[], tableName: string) => {
        const categoryTypeWithCount = countSubcategory(categoryType, columnRiskData);
        return (
            <div className="col-2">
                <h2>{tableName}</h2>
                <div
                    style={{
                        height: "90vh",
                        overflow: "auto",
                    }}
                >
                    <MasterTable id={tableName} data={columnRiskData} fileName={tableName} isGeneratingData={false}>
                        <>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Subcategories</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryTypeWithCount.subcategory.map((item, index) => {
                                    return (
                                        <tr
                                            key={CATEGORY_WITH_COUNT_KEY + index + item.text}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: isSelectedSubCategory(item.text, -1, selectedSubcategories) ? "yellow" : "white",
                                            }}
                                            onClick={() => selectSubcategory(item.text, -1)}
                                        >
                                            <td>{index + 1}</td>
                                            <td>
                                                <span>
                                                    {item.text} ({sumNumbers(item.topCount)})
                                                </span>
                                                [
                                                {item.topCount.map((count, top) => {
                                                    return (
                                                        <span
                                                            key={ITEM_TOP_COUNT_KEY + top + item.text + count}
                                                            onClick={(e) => handleSelectSubcategory(e, item.text, top)}
                                                            style={{
                                                                color:
                                                                    isSelectedSubCategory(item.text, top, selectedSubcategories) &&
                                                                    selectedSubcategories.find(
                                                                        (selectedItem: SelectedSubCategory) =>
                                                                            selectedItem.text === item.text && selectedItem.top === top
                                                                    )
                                                                        ? "red"
                                                                        : "black",
                                                            }}
                                                        >
                                                            {count}
                                                            {top === item.topCount.length - 1 ? "" : ", "}
                                                        </span>
                                                    );
                                                })}
                                                ]
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </>
                    </MasterTable>
                </div>
            </div>
        );
    };

    return (
        <div className={`${CATEGORY_RISK_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="w-100">
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <div className="row">
                        <div className="col-8">
                            {renderButtonCategorizeRisk()}
                            {csvData.rows.length > 0 && renderTable()}
                        </div>
                        {renderTableC1Category()}
                        {renderTableC2Category()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategorizeRisk;

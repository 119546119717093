import { Fragment, useCallback, useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import AuthenticationRoute from "../Components/AuthenticationRoute/AuthenticationRoute";
import LoadingPage from "../Components/LoadingPage/LoadingPage";
import { APP_URLS, ID_PARAMS, TAB_ID_PARAMS } from "../constants/appConstants";
import { ACCOUNT_TYPE_KEY, ATLASSIAN_ACCOUNT_TYPE, START_UP_DELAY, VERIFY_SESSION_PERIOD } from "../constants/authentication";
import { verifySessionThunk, verifySessionAtlassianThunk } from "../store/auth/thunkMiddlewares";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import HomePage from "../pages/HomePage/HomePage";
import Navigator from "../Components/Navigator/Navigator";
import TopDown from "../pages/TopDown/TopDown";
import BottomUp from "../pages/BottomUp/BottomUp";
import Combine from "../pages/Combine/Combine";
import Risk from "../pages/Risk/Risk";
import Competitor from "../pages/Competitor/Competitor";
import File from "../pages/File/File";
import StandardizeOccurenceAndSeverity from "../pages/Standardize_Occurerence_Severity/StandardizeOccurenceAndSeverity";
import GenerateCharacteristicsForSafetyFromRiskTable from "../pages/GenerateCharacteristicsForSafetyFromRiskTable/GenerateCharacteristicsForSafetyFromRiskTable";
import CategorizeRisk from "../pages/CategorizeRisk/CategorizeRisk";
import MissingRisks from "../pages/MissingRisks/MissingRisks";
import { setNavigator } from "../utils/navigator";
import JiraProject from "../pages/JiraProject/JiraProject";
import SignInPage from "../pages/SignInPage/SignInPage";
import CallBackSignPage from "../pages/SignInPage/CallBackSignPage";
import Cookies from "js-cookie";

const RouterWrapper = () => {
    const auth = useAppSelector((state: any) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAuthenticated = auth.isLoggedIn;
    const checkAuthenticationStatus = useCallback(() => {
        const accountType = Cookies.get(ACCOUNT_TYPE_KEY);
        if (accountType === ATLASSIAN_ACCOUNT_TYPE) {
            dispatch(verifySessionAtlassianThunk(navigate));
        } else {
            dispatch(verifySessionThunk(navigate));
        }
        setTimeout(() => {
            return checkAuthenticationStatus();
        }, VERIFY_SESSION_PERIOD);
    }, []);

    useEffect(() => {
        setTimeout(checkAuthenticationStatus, START_UP_DELAY);
    }, []);

    if (auth.isLoading) {
        return <LoadingPage />;
    }

    return (
        <Fragment>
            <div
                className="container-fluid"
                style={{
                    height: "100%",
                }}
            >
                <div
                    className="row"
                    style={{
                        height: "100%",
                    }}
                >
                    <div className="col-md-1 ml-sm-auto col-lg-1">{isAuthenticated && <Navigator userId={auth.userId}></Navigator>}</div>
                    <main className="col-md-11 ml-sm-auto col-lg-11">
                        <Outlet />
                    </main>
                </div>
            </div>
        </Fragment>
    );
};

const Router = () => {
    const auth = useAppSelector((state: any) => state.auth);
    const isAuthenticated = auth.isLoggedIn;

    const navigate = useNavigate();

    useEffect(() => {
        setNavigator(navigate);
    }, [navigate]);

    return (
        <Routes>
            <Route path={APP_URLS.SIGN_IN_CALLBACK} element={<CallBackSignPage />} />
            <Route path={"/"} element={<RouterWrapper />}>
                <Route path={APP_URLS.HOMEPAGE} element={<HomePage />} />
                <Route path={APP_URLS.TOP_DOWN + ID_PARAMS} element={<TopDown />} />
                <Route path={APP_URLS.BOTTOM_UP + ID_PARAMS} element={<BottomUp />} />
                <Route path={APP_URLS.COMBINE + ID_PARAMS} element={<Combine />} />
                <Route path={APP_URLS.RISK + ID_PARAMS} element={<Risk />} />
                <Route path={APP_URLS.COMPETITOR + ID_PARAMS} element={<Competitor />} />
                <Route path={APP_URLS.FILE + ID_PARAMS} element={<File />} />
                <Route path={APP_URLS.STANDARDIZE_OCCURRENCE_AND_SEVERITY + ID_PARAMS} element={<StandardizeOccurenceAndSeverity />} />
                <Route
                    path={APP_URLS.GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE + ID_PARAMS}
                    element={<GenerateCharacteristicsForSafetyFromRiskTable />}
                />
                <Route path={APP_URLS.CATEGORIZE_RISK + ID_PARAMS} element={<CategorizeRisk isShowFileUpload={true} />} />
                <Route path={APP_URLS.MISSING_RISKS + ID_PARAMS + TAB_ID_PARAMS} element={<MissingRisks />} />
                <Route path={APP_URLS.JIRA_RISK + ID_PARAMS} element={<JiraProject />} />
                <Route
                    path={APP_URLS.SIGN_IN}
                    element={<AuthenticationRoute path={APP_URLS.HOMEPAGE} authed={isAuthenticated} component={<SignInPage />} />}
                />
                <Route path={"*"} element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};
export default Router;

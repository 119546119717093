import React, { useState, useEffect } from "react";
import "./style.css";
import {
    ACCEPT_EXCEL_INPUT,
    CHARACTERISTICS_KEY_FOR_RISK_TABLE,
    CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE_ID,
    ERROR_MESSAGES_NO_FILE,
    ERROR_MESSAGES_NO_FILE_SELECTED,
    FILE_KEY,
    INPUT_FILE_CLASS_NAME,
    INPUT_GROUP_CLASS_NAME,
    INPUT_TYPE_FILE,
    INPUT_UPLOAD_FILE_ID,
    TOP_DOWN_CLASS_NAME,
    UPLOAD_TEXT,
} from "./constants";
import {
    BOOTSTRAP_CONTAINER_FLUID,
    BLANK_STRING,
    IS_USE_CSV_DATA_SLICE_KEY,
    SPLIT_QUESTION_PART,
    TOKEN_SPLIT_3_TIMES,
    MIN_TOKEN_PER_CALL_CHARACTERISTIC,
    TOKEN_SPLIT_2_TIMES,
    SYSTEM_PROMPT_TOKEN,
    TOKEN_SPLIT_1_TIME,
} from "../../constants/appConstants";
import { Button } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { CSVData, CSVDataTable, DEFAULT_CSV_DATA } from "../../Components/CSVTable/CSVTable";
import { readExcelFileData } from "../../apiRequests/generateCharacteristicsForSafetyFromRisk/readExcelFileData";
import default_question_characteristics from "../../Components/ColumnCharacteristics/topdown-chracteristic.json";

import {
    convertIncompleteJSONToCompleteJSON,
    deepCopyJsonFile,
    getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber,
    getSessionIdFromURL,
    saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase,
} from "../../utils/commonUtils";
import { GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE } from "../../constants/api";
import { CSVDataToText, convertCSVToString } from "../../utils/convertToCSVDataInString";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";
import ColumnCharacteristics from "../../Components/ColumnCharacteristics/ColumnCharacteristics";
import { CharacteristicQuestionDto } from "../../types/CharacteristicQuestion";
import { encode } from "gpt-tokenizer";
import { useSaveDataToDatabase } from "../../hooks/useSaveDataToDatabase";

const RISK_TABLE_NAME = "Risk";

const GenerateCharacteristicsForSafetyFromRiskTable = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<CSVData>({ ...DEFAULT_CSV_DATA });
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const [characteristic, setCharacteristic] = useState<CharacteristicQuestionDto[][]>([]);
    const [streamingCharacteristicsForSafetyFromRisk, setStreamingCharacteristicsForSafetyFromRisk] = useState<string>("");
    const [characteristicsPart, setCharacteristicsPart] = useState<number>(0);
    const [isUseCSVDataSlice, setIsUseCSVDataSlice] = useState<boolean>(false);
    const [splitQuestionPart, setSpitQuestionPart] = useState<number>(0);
    const sessionIdFromUrl = getSessionIdFromURL();
    const saveDataToDatabase = useSaveDataToDatabase();
    useEffect(() => {
        const loadSessionData = async () => {
            setIsLoading(true);
            setCsvData({ ...DEFAULT_CSV_DATA });
            setCharacteristic(JSON.parse(JSON.stringify(default_question_characteristics)));
            setStreamingCharacteristicsForSafetyFromRisk("");
            try {
                const data = await getDataFromAPI();
                if (data != null) {
                    if (data.csvData != null) {
                        try {
                            setCsvData(data.csvData);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    setCharacteristicFromStateDetail(data);
                }
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        };

        loadSessionData();
    }, [sessionIdFromUrl]);

    const setCharacteristicFromStateDetail = (data: any) => {
        const updateNewCharacteristic = (key: number, characteristicsList: any, splitQuestionPart: number) => {
            let questionListIndex = 0;
            if (data.isUseCSVDataSlice) {
                questionListIndex = getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(key, splitQuestionPart);
            }
            if (!newCharacteristic[questionListIndex]) {
                newCharacteristic[questionListIndex] = deepCopyJsonFile(default_question_characteristics);
            }

            characteristicsList.forEach((characteristic: any) => {
                const index = newCharacteristic[questionListIndex].findIndex(
                    (item: any) => item.questionID.toString() === characteristic.questionID.toString()
                );
                if (index !== -1) {
                    newCharacteristic[questionListIndex][index] = {
                        ...newCharacteristic[questionListIndex][index],
                        ...characteristic,
                        part: CHARACTERISTICS_KEY_FOR_RISK_TABLE + key,
                        isFinished: true,
                    };
                }
            });
        };
        setSpitQuestionPart(data.splitQuestionPart);
        setIsUseCSVDataSlice(data.isUseCSVDataSlice);
        let newCharacteristic: CharacteristicQuestionDto[][] = [];

        for (const key in data) {
            if (!key.startsWith(CHARACTERISTICS_KEY_FOR_RISK_TABLE)) continue;

            try {
                const value = data[key];
                const outputFormat = [{ questionID: "", answer: "", explanation: "" }];
                const characteristicsList = convertIncompleteJSONToCompleteJSON(value, outputFormat, []);
                const keyInNumber = key.replace(CHARACTERISTICS_KEY_FOR_RISK_TABLE, "");
                updateNewCharacteristic(Number(keyInNumber), characteristicsList, data.splitQuestionPart);
            } catch (e) {
                console.log(e);
            }
        }
        setCharacteristic(newCharacteristic);
    };

    const getDataFromAPI = async (): Promise<any> => {
        try {
            const stateData = await loadPageState(sessionIdFromUrl);
            return stateData.stateDetail;
        } catch (e) {
            return null;
        }
    };

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CSVDataTable
                        tableId={CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE_ID}
                        tableName={RISK_TABLE_NAME}
                        csvData={csvData}
                        fileName={RISK_TABLE_NAME}
                        addedMissingRisksWithGeneratedMissingFields={[]}
                    />
                </div>
            </div>
        );
    };

    const renderInputAndButtonUploadFile = () => {
        return (
            <div className="col-6">
                <div className={INPUT_GROUP_CLASS_NAME}>
                    <input
                        type={INPUT_TYPE_FILE}
                        className={INPUT_FILE_CLASS_NAME}
                        id={INPUT_UPLOAD_FILE_ID}
                        accept={ACCEPT_EXCEL_INPUT}
                        onChange={handleFileInputChange}
                    />
                    <Button onClick={handleUploadButtonClick} disabled={!isFileSelected || isGeneratingData}>
                        {isGeneratingData ? <LoadingButton /> : UPLOAD_TEXT}
                    </Button>
                </div>
            </div>
        );
    };

    const handleUploadButtonClick = async () => {
        const fileInput = document.getElementById(INPUT_UPLOAD_FILE_ID) as HTMLInputElement;
        if (!fileInput) return console.error(ERROR_MESSAGES_NO_FILE);
        if (!fileInput.files) return console.error(ERROR_MESSAGES_NO_FILE);
        const file = fileInput.files[0];
        if (!file) {
            console.error(ERROR_MESSAGES_NO_FILE_SELECTED);
            return;
        }
        setCsvData({ ...DEFAULT_CSV_DATA });
        const formData = new FormData();
        formData.append(FILE_KEY, file);
        setIsGeneratingData(true);
        try {
            const data = await readExcelFileData(formData, sessionIdFromUrl);
            if (data.rows) {
                setCsvData(data);
            }
        } catch (e) {
            console.log(e);
        }
        setIsGeneratingData(false);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFileSelected(!!event.target.files?.length);
    };
    const removeCharacteristicsForSafetyPageState = () => {
        setCharacteristic([deepCopyJsonFile(default_question_characteristics)]);
    };
    const handleGenerateCharacteristicsForSafetyFromRiskButtonClick = async () => {
        setIsGeneratingData(true);
        removeCharacteristicsForSafetyPageState();
        let split = 0;
        const tokens = encode(JSON.stringify(convertCSVToString(csvData)));
        const totalTokens = tokens.length + SYSTEM_PROMPT_TOKEN;

        if (totalTokens < TOKEN_SPLIT_3_TIMES) {
            if (totalTokens < TOKEN_SPLIT_1_TIME) {
                split = 1;
            } else if (totalTokens < TOKEN_SPLIT_2_TIMES) {
                split = 2;
            } else if (totalTokens >= TOKEN_SPLIT_3_TIMES) {
                split = 3;
            }
            fetchCharacteristicsForSafetyFromRisk(split, 1, 0, false, totalTokens);
            saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase(saveDataToDatabase, sessionIdFromUrl, split, BLANK_STRING);
            setIsUseCSVDataSlice(false);
            setSpitQuestionPart(split);
        } else {
            let maxTokenPerCall = 0;

            if (totalTokens % TOKEN_SPLIT_3_TIMES > MIN_TOKEN_PER_CALL_CHARACTERISTIC) {
                split = 3;
                maxTokenPerCall = TOKEN_SPLIT_3_TIMES;
            } else {
                split = 2;
                maxTokenPerCall = TOKEN_SPLIT_2_TIMES;
            }

            fetchCharacteristicsForSafetyFromRisk(split, 1, 0, true, maxTokenPerCall);
            saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase(saveDataToDatabase, sessionIdFromUrl, split, "true");
            setIsUseCSVDataSlice(true);
            setSpitQuestionPart(split);
        }
    };

    const fetchCharacteristicsForSafetyFromRisk = async (
        split: number,
        part: number,
        startRowIndex: number,
        isUseCSVDataSlice: boolean,
        maxTokenPerCall: number,
        partCsv: number = 0
    ) => {
        let formDataKeyValue = [];
        let csvDataToProcess: CSVDataToText = {
            header: [],
            rows: [],
        };
        let endRowIndex = 0;

        if (isUseCSVDataSlice) {
            const numberOfRowsEachQuery = computeMaximumNumberOfRowsToConvert(startRowIndex, maxTokenPerCall);
            endRowIndex = startRowIndex + numberOfRowsEachQuery;
            csvDataToProcess = sliceAndConvertToCsvData(startRowIndex, endRowIndex);
            formDataKeyValue.push({ key: "riskData", value: JSON.stringify(convertCSVToString(csvDataToProcess)) });
        } else {
            formDataKeyValue.push({ key: "riskData", value: JSON.stringify(convertCSVToString(csvData)) });
        }

        formDataKeyValue.push({ key: "part", value: (part + partCsv).toString() }, { key: "split", value: split.toString() });
        setCharacteristicsPart(part + partCsv);
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                setStreamingCharacteristicsForSafetyFromRisk(fullMessage);
            },
            onClose: function (): void {
                part = part + 1;

                if (isUseCSVDataSlice) {
                    if (part <= split) {
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, part, startRowIndex, true, maxTokenPerCall, partCsv);
                    } else if (stillHaveDataToProcess(endRowIndex)) {
                        partCsv = part - 1;
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, 1, endRowIndex, true, maxTokenPerCall, partCsv);
                    } else {
                        setIsGeneratingData(false);
                    }
                } else {
                    if (part <= split) {
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, part, 0, false, maxTokenPerCall);
                    } else {
                        setIsGeneratingData(false);
                    }
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: formDataKeyValue,
        };

        commonFetchEventSource(paramsFetchEventSource);
    };

    const computeMaximumNumberOfRowsToConvert = (startRowIndex: number, maxTokenPerCall: number) => {
        for (let i = startRowIndex; i <= csvData.rows.length - 1; i++) {
            const csvDataToProcess: CSVDataToText = sliceAndConvertToCsvData(startRowIndex, i);
            const tokens = encode(JSON.stringify(convertCSVToString(csvDataToProcess, startRowIndex)));
            const tokensLength = tokens.length;

            if (tokensLength > maxTokenPerCall) {
                return i - 1 - startRowIndex;
            }
        }
        return csvData.rows.length - startRowIndex;
    };

    const sliceAndConvertToCsvData = (startIndex: number, endIndex: number): CSVDataToText => {
        const rows = csvData.rows.slice(startIndex, endIndex);
        const csvDataToProcess: CSVDataToText = {
            header: csvData.header,
            rows: rows,
        };
        return csvDataToProcess;
    };
    const stillHaveDataToProcess = (endRowIndex: number) => {
        return endRowIndex < csvData.rows.length;
    };

    const renderButtonGenerateCharacteristicsForSafetyFromRisk = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Button
                        onClick={handleGenerateCharacteristicsForSafetyFromRiskButtonClick}
                        disabled={isGeneratingData || csvData.rows.length === 0}
                    >
                        {isGeneratingData ? <LoadingButton /> : "Generate Characteristics for Safety from Risk"}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="w-100">
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <div className="row">
                        <div className="col-8">
                            {renderInputAndButtonUploadFile()}
                            {csvData.rows.length > 0 && renderTable()}
                        </div>
                        <div className="col-4">
                            {renderButtonGenerateCharacteristicsForSafetyFromRisk()}
                            <ColumnCharacteristics
                                data={streamingCharacteristicsForSafetyFromRisk}
                                dataInList={characteristic}
                                sessionId={sessionIdFromUrl}
                                onGenerate={() => {}}
                                isDisableButton={false}
                                isGeneratingHazardTable={false}
                                needToGenerateHazard={false}
                                part={Number(characteristicsPart)}
                                setDataInList={setCharacteristic}
                                isUseCSVDataSlice={isUseCSVDataSlice}
                                splitQuestionPart={splitQuestionPart}
                                keyOfPage={CHARACTERISTICS_KEY_FOR_RISK_TABLE}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GenerateCharacteristicsForSafetyFromRiskTable;

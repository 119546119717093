import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../constants/appConstants";
import {
  ERROR_CODE_CONTAINER,
  LINK_CONTAINER_BTN,
  NUMBER_FOUR_CLASS,
  NUMBER_ZERO_CLASS,
  SCREEN_READER_TEXT,
} from "./constants";
import "./styles.css";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const renderErrorCode = () => {
    return (
      <section className={ERROR_CODE_CONTAINER}>
        <span className={NUMBER_FOUR_CLASS}>
          <span className={SCREEN_READER_TEXT}>4</span>
        </span>
        <span className={NUMBER_ZERO_CLASS}>
          <span className={SCREEN_READER_TEXT}>0</span>
        </span>
        <span className={NUMBER_FOUR_CLASS}>
          <span className={SCREEN_READER_TEXT}>4</span>
        </span>
      </section>
    );
  };

  const onClickHomepageBtn = () => {
    navigate(`../${APP_URLS.HOMEPAGE}`);
  };

  const renderHomepageButton = () => {
    return (
      <div className={LINK_CONTAINER_BTN}>
        <button onClick={onClickHomepageBtn}>Go Back</button>
      </div>
    );
  };

  return (
    <div className="not-found-page">
      <h1>Page Not Found</h1>
      {renderErrorCode()}
      {renderHomepageButton()}
    </div>
  );
};

export default NotFoundPage;

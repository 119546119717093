import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import classes from "./styles.module.css";
import { DEFAULT_LOADING_SIZE_RATIO, LOADING_ICON_COLOR } from "./constants";
import { getScreenWidthInPixels } from "../../utils/commonUtils";

export interface LoadingPageProps {
  loadingIconSizeRatio?: number;
}

const LoadingPage = (props: LoadingPageProps) => {
  const loadingIconSizeRatio = props.loadingIconSizeRatio || DEFAULT_LOADING_SIZE_RATIO;
  return (
    <div className={classes["patent-loading-page"]}>
      <ClipLoader size={loadingIconSizeRatio * getScreenWidthInPixels()} color={LOADING_ICON_COLOR} loading />
    </div>
  );
};
export default LoadingPage;

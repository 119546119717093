import { ITabData, RiskTableProps } from "../../types/RiskDataType";

const EMPTY_TABLE_DATA: RiskTableProps = {
    data: [],
    renderringData: [],
    severity: "",
    isLoading: false,
    hazardList: []
}

export const TABS_DEFAULT_DATA: ITabData[] = [
    {
        "category": "Requirements", 
        "events_circumstances": [
            "Inadequate specification of design parameters",
             "Inadequate specification of operating parameters",
              "Inadequate specification of performance requirements",
               "Inadequate specification of in-service requirements (e.g. maintenance, reprocessing)", 
               "Inadequate specification of end of life"
        ],
        tableData: {...EMPTY_TABLE_DATA},
    },
    {
        "category": "Manufacturing processes",
        "events_circumstances": [
            "Insufficient control of manufacturing processes",
            "Insufficient control of changes to manufacturing processes",
            "Insufficient control of materials",
            "Insufficient control of materials compatibility information",
            "Insufficient control of subcontractors"
        ],
        tableData: {...EMPTY_TABLE_DATA},
    },
    {
        "category": "Transport and storage",
        "events_circumstances": [
            "Inadequate packaging",
            "Contamination or deterioration",
            "Inappropriate environmental conditions"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Environmental factors",
        "events_circumstances": [
            "Physical factors (e.g. heat, pressure, time)",
            "Chemical factors (e.g. corrosion, degradation, contamination)",
            "Electromagnetic fields (e.g. susceptibility to electromagnetic disturbance)",
            "Inadequate supply of power",
            "Inadequate supply of coolant"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Cleaning, disinfection and sterilization",
        "events_circumstances": [
            "Lack of validated procedures",
            "Inadequate specification of requirements",
            "Inadequate performance of cleaning, disinfection or sterilization"
        ],
        tableData: {...EMPTY_TABLE_DATA}
    },
    {
        "category": "Disposal and scrapping",
        "events_circumstances": [
            "No or inadequate information provided",
            "Use error"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Formulation",
        "events_circumstances": [
            "Biodegradation",
            "Biocompatibility",
            "No information or inadequate specification provided",
            "Incorrect formulations",
            "Use error"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Usability",
        "events_circumstances": [
            "Confusing or missing instructions for use",
            "Complex or confusing control system",
            "Ambiguous or unclear state of the medical device",
            "Ambiguous or unclear presentation of settings, measurements or other information",
            "Misrepresentation of results",
            // "Insufficient visibility, audibility or tactility",
            // "Poor mapping of controls to actions, or of displayed information to actual state",
            // "Controversial modes or mapping as compared to existing equipment",
            // "Use by unskilled or untrained personnel",
            // "Insufficient warning of side effects",
            // "Inadequate warning of hazards associated with re-use of single-use medical devices",
            // "Incorrect measurement and other metrological aspects",
            // "Incompatibility with consumables, accessories, other medical devices",
            // "Incorrect patient identification",
            // "Slips, lapses and mistakes"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Functionality",
        "events_circumstances": [
            "Loss of electrical or mechanical integrity",
            "Deterioration in performance (e.g gradual occlusion of fluid or gas path, change in resistance to flow, electrical conductivity) as result of ageing, wear and repeated use",
            "Failure of a component due to ageing, wear or fatigue"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    },
    {
        "category": "Security",
        "events_circumstances": [
            "Unsecured data ports that are externally accessible (e.g. network, serial or USB ports)",
            "Data without encryption",
            "Software vulnerabilities that can be exploited",
            "Software updates without authenticity confirmation"
        ],
        tableData: {...EMPTY_TABLE_DATA},

    }
]

import { useState } from "react";
import classes from "./styles.module.css";
import { Form, FormGroup, Button } from "react-bootstrap";
import { loginThunk } from "../../store/auth/thunkMiddlewares";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router";
import { ATLASSIAN_LOGIN_API } from "./constants";

const SignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(loginThunk(email, password, navigate));
  };

  const renderSignInForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup controlId="email" className="mt-4">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormGroup>

        <FormGroup controlId="password" className="mt-4">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Sign in
        </Button>
      </Form>
    );
  };

  const renderTitle = () => {
    return <h4 className="mb-4">AIMEDRISK SYSTEM</h4>;
  };

  const onClickLoginBtn = () => {
    window.location.href = ATLASSIAN_LOGIN_API;
};

const renderLoginAtlassianBtn = () => {
    return (
      <>
        <hr/>
        <p>or</p>
        <button className={classes["sign-in-button"]} onClick={onClickLoginBtn}>
              Login with Atlassian
        </button>
      </>
    );
};

  return (
    <div className={classes["sign-in-page"]}>
      {renderTitle()}
      {renderSignInForm()}
      {renderLoginAtlassianBtn()}
    </div>
  );
};

export default SignInPage;

import React from "react";
import { CSVData } from "../../../Components/CSVTable/CSVTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../../utils/commonUtils";
import { GENERATE_MISSING_RISKS } from "../../../constants/api";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../../utils/commonFetchEventSource";
import { HazardTopDownDto } from "../../../types/HazardTopDown";
import { removeMissingRisks } from "../../../apiRequests/missingRisks/missingRisks";
import { isNeedToGenerateRisk, renderMissingRisksTab } from "../Categories_MissingRisks";

interface Props {
    csvData: CSVData;
    subCategoriesHazardData: HazardTopDownDto[];
    countCategoryResult: number[];
    subCategoriesTable: string;
    hazardStateOfC1: HazardTopDownDto[];
    setHazardStateOfC1: React.Dispatch<React.SetStateAction<HazardTopDownDto[]>>;
    setHazardStateOfC2: React.Dispatch<React.SetStateAction<HazardTopDownDto[]>>;
    isGeneratingData: boolean;
    setIsGeneratingData: (isGeneratingData: boolean) => void;
}
const Tab_MissingRisk = (props: Props) => {
    const {
        csvData,
        countCategoryResult,
        subCategoriesHazardData,
        subCategoriesTable,
        setHazardStateOfC1,
        setHazardStateOfC2,
        isGeneratingData,
        setIsGeneratingData,
    } = props;
    const sessionIdFromUrl = getSessionIdFromURL();

    const handleGenerateMissingRisks = async (
        subCategoriesHazardData: HazardTopDownDto[],
        countCategoryResult: number[],
        subCategoriesTable: string = "C1"
    ) => {
        setIsGeneratingData(true);
        await clearAllOldMissingRisksData(subCategoriesTable);
        handleGenerateMissingRiskForEachCategory(0, subCategoriesHazardData, countCategoryResult, subCategoriesTable);
    };
    const clearAllOldMissingRisksData = async (subCategoriesTable: string = "C1") => {
        if (subCategoriesTable === "C1") {
            setHazardStateOfC1((prevState) => {
                return prevState.map((item) => {
                    return {
                        ...item,
                        missing_risks: [],
                    };
                });
            });
        } else {
            setHazardStateOfC2((prevState) => {
                return prevState.map((item) => {
                    return {
                        ...item,
                        missing_risks: [],
                    };
                });
            });
        }

        await removeMissingRisks(sessionIdFromUrl, subCategoriesTable);
    };

    const handleGenerateMissingRiskForEachCategory = (
        indexOfCategory: number,
        subCategoriesHazardData: HazardTopDownDto[],
        countCategoryResult: number[],
        subCategoriesTable: string = "C1"
    ) => {
        if (!isNeedToGenerateRisk(subCategoriesHazardData[indexOfCategory], countCategoryResult[indexOfCategory])) {
            if (indexOfCategory < subCategoriesHazardData.length - 1) {
                handleGenerateMissingRiskForEachCategory(indexOfCategory + 1, subCategoriesHazardData, countCategoryResult, subCategoriesTable);
            } else {
                setIsGeneratingData(false);
            }
            return;
        } else {
            const category = subCategoriesHazardData[indexOfCategory].category;
            const paramsFetchEventSource: ParamsFetchEventSource = {
                url: GENERATE_MISSING_RISKS,
                sessionIdFromUrl: sessionIdFromUrl,
                onOpen: () => {
                    setIsGeneratingData(true);
                },
                onMessage: function (fullMessage: string): void {
                    setMissingRisksDataFromAPI(category, fullMessage, subCategoriesTable);
                },
                onClose: function (): void {
                    if (indexOfCategory < subCategoriesHazardData.length - 1) {
                        handleGenerateMissingRiskForEachCategory(
                            indexOfCategory + 1,
                            subCategoriesHazardData,
                            countCategoryResult,
                            subCategoriesTable
                        );
                    } else {
                        setIsGeneratingData(false);
                    }
                },
                onError: () => {
                    setIsGeneratingData(false);
                },
                formDataKeyValue: [
                    {
                        key: "riskData",
                        value: JSON.stringify(getAllCurrentRisksSummary()),
                    },
                    {
                        key: "category",
                        value: category,
                    },
                    {
                        key: "tableCategory",
                        value: subCategoriesTable,
                    },
                ],
            };
            commonFetchEventSource(paramsFetchEventSource);
        }
    };

    const setMissingRisksDataFromAPI = (category: string, dataInString: string, tableSubcategoryName: string = "C1") => {
        const data = convertIncompleteJSONToCompleteJSON(dataInString, [], []);
        if (tableSubcategoryName === "C1") {
            setHazardStateOfC1((prevState) => {
                return prevState.map((item, index) => {
                    if (shouldUpdateMissingRisksToCategory(item, category, data)) {
                        return {
                            ...item,
                            missing_risks: data,
                        };
                    }
                    return item;
                });
            });
        } else {
            setHazardStateOfC2((prevState) => {
                return prevState.map((item, index) => {
                    if (shouldUpdateMissingRisksToCategory(item, category, data)) {
                        return {
                            ...item,
                            missing_risks: data,
                        };
                    }
                    return item;
                });
            });
        }
    };

    const shouldUpdateMissingRisksToCategory = (categoryHazard: HazardTopDownDto, category: string, missingRisksInStringArray: string[]) => {
        return categoryHazard.category === category && missingRisksInStringArray && missingRisksInStringArray.length > 0;
    };

    const getAllCurrentRisksSummary = () => {
        return csvData.rows.map((row: string[]) => {
            return row[csvData.riskSummaryColumnIndex ? csvData.riskSummaryColumnIndex : 0];
        });
    };

    return renderMissingRisksTab({
        subCategoriesHazardData: subCategoriesHazardData,
        countCategoryResult: countCategoryResult,
        isShowCounting: true,
        isShowMissingRisks: true,
        handleGenerateMissingRisks: handleGenerateMissingRisks,
        subCategoriesTable: subCategoriesTable,
        isGeneratingData: isGeneratingData,
        fileName: "Missing Risks " + subCategoriesTable,
    });
};

export default Tab_MissingRisk;

import React, { useState, useEffect } from "react";
import "./style.css";
import {
    CHARACTERISTICS_KEY_FOR_MISSING_RISK,
    MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX,
    MISSING_RISK_TABS,
    MISSING_RISK_TAB_URLS,
    MITIGATION_FOR_JIRA_KEY,
    MITIGATION_FOR_RISK_DATA_PREFIX,
    TOP_DOWN_CLASS_NAME,
} from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID, BLANK_STRING, HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX, APP_URLS } from "../../constants/appConstants";
import { Tab, Tabs } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { CSVData, DEFAULT_CSV_DATA } from "../../Components/CSVTable/CSVTable";
import default_question_characteristics from "../../Components/ColumnCharacteristics/topdown-chracteristic.json";
import {
    convertIncompleteJSONToCompleteJSON,
    deepCopyJsonFile,
    getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber,
    getSessionIdFromURL,
    mergeAndRemoveDuplicates,
    removeSpecialCharacters,
} from "../../utils/commonUtils";
import { HazardTopDownDto, MissingRisk } from "../../types/HazardTopDown";
import { findIndexOfRiskSummaryColumnInCSVFile } from "../../apiRequests/missingRisks/missingRisks";
import { getRiskCategoryAPI } from "../../apiRequests/categorizeRisk/getRiskCategoryAPI";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { CategoryData } from "../../Components/CategoryRiskTable/CategorizeRisk";
import { SubCategoriesOfEachRisk } from "../CategorizeRisk/CategoryRiskTable/CategoryRiskTable";
import { MitigationData } from "../../Components/CSVDataWIthMitigation/CSVDataWithMitigation";
import Tab_CharacteristicsForSafety from "./Tab_CharacteristicsForSafety/Tab_CharacteristicsForSafety";
import Tab_CategorizeRisk from "./Tab_CategorizeRisk/Tab_CategorizeRisk";
import Tab_MissingRisk from "./Tab_MissingRisk/Tab_MissingRisk";
import Tab_AddedMissingRisks, { AddedMissingRiskWithGeneratedMissingFields } from "./Tab_AddedMissingRisks/Tab_AddedMissingRisks";
import Tab_OriginRisksAndMissingRisks from "./Tab_OriginRisksAndMissingRisks/Tab_OriginRisksAndMissingRisks";
import { CharacteristicQuestionDto } from "../../types/CharacteristicQuestion";
import Tab_Dashboard from "./Tab_Dashboard/Tab_Dashboard";

export interface MitigationForJira {
    mitigation: string;
    type: string;
    rowData: any;
    issueKey?: string;
}
const MissingRisks = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const [characteristic, setCharacteristic] = useState<any[]>([]);
    const sessionIdFromUrl = getSessionIdFromURL();
    const [activeTab, setActiveTab] = useState(MISSING_RISK_TABS[0]);
    const [hazardStateOfC1, setHazardStateOfC1] = useState<HazardTopDownDto[]>([]);
    const [hazardStateOfC2, setHazardStateOfC2] = useState<HazardTopDownDto[]>([]);
    const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
    const [c1CountCategoryResult, setC1CountCategoryResult] = useState<number[]>([]);
    const [c2CountCategoryResult, setC2CountCategoryResult] = useState<number[]>([]);
    const [c1Category, setC1Category] = useState<CategoryData>({ subcategory: [] });
    const [c2Category, setC2Category] = useState<CategoryData>({ subcategory: [] });
    const [c1CategoryResultOfAllRows, setC1CategoryResultOfAllRows] = useState<SubCategoriesOfEachRisk[]>([]);
    const [c2CategoryResultOfAllRows, setC2CategoryResultOfAllRows] = useState<SubCategoriesOfEachRisk[]>([]);
    const [csvData, setCsvData] = useState<CSVData>({ ...DEFAULT_CSV_DATA });
    const [issueKeyPrefix, setIssueKeyPrefix] = useState<string>(BLANK_STRING);
    const [addedMissingRisksWithGeneratedMissingFields, setAddedMissingRisksWithGeneratedMissingFields] = useState<
        AddedMissingRiskWithGeneratedMissingFields[]
    >([]);

    const [mitigationData, setMitigationData] = useState<MitigationData[]>([]);
    const [isUseCSVDataSlice, setIsUseCSVDataSlice] = useState<boolean>(false);
    const [splitQuestionPart, setSpitQuestionPart] = useState<number>(0);
    const [mitigationForJira, setMitigationForJira] = useState<MitigationForJira[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { tabId } = useParams();

    useEffect(() => {
        const foundEntry = Object.entries(MISSING_RISK_TAB_URLS).find(([key, value]) => value === tabId);
        if (foundEntry) {
            const [tabName, value] = foundEntry;
            if (tabName !== activeTab) {
                setActiveTab(tabName);
            }
        }
    }, [location.pathname, activeTab]);

    const setCSVDataWithRelateAttributes = (data: CSVData) => {
        if (!data.addedMissingRisks) {
            data.addedMissingRisks = [];
        }
        setCsvData({ ...data });
        setIssueKeyPrefix(data.key_of_issue_key);
    };

    useEffect(() => {
        const loadSessionData = async () => {
            setIsLoading(true);
            removeStateData();
            try {
                const [data, c1c2Category] = await Promise.all([getDataFromAPI(), getRiskCategoryAPI()]);
                setC1Category(extractSubcategories(c1c2Category["C1"]));
                const c2CategoriesFromAPI = extractSubcategories(c1c2Category["C2"]);
                setC2Category(c2CategoriesFromAPI);
                if (data != null) {
                    if (data.csvData != null) {
                        try {
                            let csvDataFromDB: CSVData = data.csvData;
                            if (csvDataFromDB.riskSummaryColumnIndex) {
                                csvDataFromDB.riskSummaryColumnIndex = Number(csvDataFromDB.riskSummaryColumnIndex);
                            } else {
                                csvDataFromDB.riskSummaryColumnIndex = await findIndexOfRiskSummaryColumnInCSVFile(
                                    sessionIdFromUrl,
                                    csvDataFromDB.header
                                );
                            }

                            setCSVDataWithRelateAttributes(data.csvData);
                            handleSetDataToState(data, c2CategoriesFromAPI);
                            parseMitigationDataFromDB(data);
                            parseAddedMissingRisksWithGeneratedMissingFields(data);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (data[MITIGATION_FOR_JIRA_KEY]) {
                        setMitigationForJira(data[MITIGATION_FOR_JIRA_KEY]);
                    }
                }
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        };

        loadSessionData();
    }, [sessionIdFromUrl]);

    const parseAddedMissingRisksWithGeneratedMissingFields = (data: any) => {
        let result: AddedMissingRiskWithGeneratedMissingFields[] = [];
        try {
            for (const dataKey in data) {
                if (dataKey.startsWith(MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX)) {
                    const addedMissingRisks = convertIncompleteJSONToCompleteJSON(data[dataKey], [], []);
                    result = mergeAndRemoveDuplicates([...result, ...addedMissingRisks], "issueKey");
                }
            }
            setAddedMissingRisksWithGeneratedMissingFields(result);
        } catch (e) {
            console.log(e);
        }
    };

    const removeStateData = () => {
        setCSVDataWithRelateAttributes({ ...DEFAULT_CSV_DATA });
        removeCharacteristicsForSafetyPageState();
        setHazardStateOfC2([]);
        setC1CountCategoryResult([]);
        setC2CountCategoryResult([]);
        setC1CategoryResultOfAllRows([]);
        setC2CategoryResultOfAllRows([]);
        setC1Category({ subcategory: [] });
        setC2Category({ subcategory: [] });
        setMitigationData([]);
    };

    const removeCharacteristicsForSafetyPageState = () => {
        setCharacteristic([deepCopyJsonFile(default_question_characteristics)]);
        setHazardStateOfC1([]);
    };
    const parseMitigationDataFromDB = (data: any) => {
        let result: MitigationData[] = [];
        for (const key in data) {
            if (key.startsWith(MITIGATION_FOR_RISK_DATA_PREFIX)) {
                const outputFormat = [
                    { issueKey: "", mitigation_inherently_safe_design: "", mitigation_protective_measure: "", mitigation_information_for_safety: "" },
                ];
                const mitigationData = convertIncompleteJSONToCompleteJSON(data[key], outputFormat, []);
                result = mergeAndRemoveDuplicates([...result, ...mitigationData], "issueKey");
            }
        }
        setMitigationData(result);
    };

    const handleSetDataToState = (data: any, c2Categories: CategoryData) => {
        setCharacteristicFromStateDetail(data);
        const hazards = extractC1SubcategoriesWithScoreFromDB(data);
        const c1CategoryResultOfAllRowsInDatabase = getAllSubCategoryInDbOfEachTable(data, "C1");
        const c2CategoryResultOfAllRowsInDatabase = getAllSubCategoryInDbOfEachTable(data, "C2");

        let subCategoriesOfColumnRiskC1 = initRiskSubcategories(data.csvData.rows);
        try {
            c1CategoryResultOfAllRowsInDatabase.forEach((item: any, index: number) => {
                if (item.subcategories) {
                    const indexInRow = subCategoriesOfColumnRiskC1.findIndex((category) => category.issueKey === item.issueKey);
                    subCategoriesOfColumnRiskC1[indexInRow].subcategories = item.subcategories;
                }
            });
        } catch (ex) {}

        setC1CategoryResultOfAllRows(subCategoriesOfColumnRiskC1);

        let subCategoriesOfColumnRiskC2 = initRiskSubcategories(data.csvData.rows);
        try {
            c2CategoryResultOfAllRowsInDatabase.forEach((item: any, index: number) => {
                if (item.subcategories) {
                    const indexInRow = subCategoriesOfColumnRiskC2.findIndex((category) => category.issueKey === item.issueKey);
                    subCategoriesOfColumnRiskC2[indexInRow].subcategories = item.subcategories;
                }
            });
        } catch (ex) {}

        setC2CategoryResultOfAllRows(subCategoriesOfColumnRiskC2);
        const c2SubCategoryHazards = addMissingRisksToC2Hazards(data, c2Categories);
        setHazardStateOfC2(c2SubCategoryHazards);

        const c1CountCategories = extractCountCategoryResultFromDb(hazards, c1CategoryResultOfAllRowsInDatabase);
        setC1CountCategoryResult(c1CountCategories);
        const c2CountCategories = extractCountCategoryResultFromDb(c2SubCategoryHazards, c2CategoryResultOfAllRowsInDatabase);
        setC2CountCategoryResult(c2CountCategories);
        setHazardStateOfC1(addMissingRiskToHazards(hazards, data));
    };

    const addMissingRisksToC2Hazards = (data: any, c2CategoriesFromAPI: CategoryData): HazardTopDownDto[] => {
        return c2CategoriesFromAPI.subcategory.map((subcategory) => {
            return {
                category: subcategory,
                description: "",
                score: "100",
                missing_risks: getMissingRisksInDataBase(subcategory, data, "C2"),
            };
        });
    };

    const extractSubcategories = (data: any): CategoryData => {
        const allSubcategories: string[][] = data.map((item: any) => {
            return item.subcategory;
        });
        return {
            subcategory: allSubcategories.flat(),
        };
    };

    const addMissingRiskToHazards = (hazards: HazardTopDownDto[], data: any) => {
        return hazards.map((hazard) => {
            const subcategory = hazard.category;
            const risks: MissingRisk[] = getMissingRisksInDataBase(subcategory, data, "C1");
            hazard.missing_risks = risks;
            return hazard;
        });
    };

    const getMissingRisksInDataBase = (subcategory: string, data: any, tableSubCategories: string): MissingRisk[] => {
        try {
            for (const dataKey in data) {
                if (dataKey.startsWith("missing_risks_")) {
                    const categoryInKey = dataKey.replace("missing_risks_", "");
                    if (categoryInKey.startsWith(tableSubCategories)) {
                        const categoryInDatabase = categoryInKey.replace(tableSubCategories + "_", "");
                        if (removeSpecialCharacters(categoryInDatabase) === removeSpecialCharacters(subcategory)) {
                            const columnData = convertIncompleteJSONToCompleteJSON(data[dataKey].trim(), [], []);
                            return columnData;
                        }
                    }
                }
            }
            return [];
        } catch (e) {
            return [];
        }
    };

    const extractCountCategoryResultFromDb = (hazards: HazardTopDownDto[], c1CategoryResultOfAllRows: SubCategoriesOfEachRisk[]): number[] => {
        return hazards.map((hazard) => {
            let count = 0;
            for (const row of c1CategoryResultOfAllRows) {
                try {
                    const subcategories = row.subcategories;
                    if (subcategories.includes(hazard.category)) {
                        count += 1;
                    }
                } catch (e) {}
            }
            return count;
        });
    };

    const getAllSubCategoryInDbOfEachTable = (data: any, tableName: string): SubCategoriesOfEachRisk[] => {
        const key = tableName;
        let result: SubCategoriesOfEachRisk[] = [];

        for (const dataKey in data) {
            if (dataKey.startsWith(key)) {
                const outputFormat = [{ subcategories: [], issueKey: "" }];
                let columnCateroryData = convertIncompleteJSONToCompleteJSON(data[dataKey].trim(), outputFormat, []);
                result = mergeAndRemoveDuplicates([...result, ...columnCateroryData], "issueKey");
            }
        }

        return sortSubcategoryInDb(result);
    };

    const sortSubcategoryInDb = (data: SubCategoriesOfEachRisk[]) => {
        return data.sort();
    };

    const initRiskSubcategories = (rows: any): SubCategoriesOfEachRisk[] => {
        const initialRiskSubCategory = rows.map((item: any, index: number) => {
            let issueKey = item[0];

            item.forEach((cell: string) => {
                if (issueKeyPrefix && cell.startsWith(issueKeyPrefix)) {
                    issueKey = cell;
                }
            });
            return {
                issueKey: issueKey,
                subcategories: [],
            };
        });
        return initialRiskSubCategory;
    };

    const setCharacteristicFromStateDetail = (data: any) => {
        const updateNewCharacteristic = (key: number, characteristicsList: any, splitQuestionPart: number) => {
            let questionListIndex = 0;
            if (data.isUseCSVDataSlice) {
                questionListIndex = getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(key, splitQuestionPart);
            }
            if (!newCharacteristic[questionListIndex]) {
                newCharacteristic[questionListIndex] = deepCopyJsonFile(default_question_characteristics);
            }

            characteristicsList.forEach((characteristic: any) => {
                const index = newCharacteristic[questionListIndex].findIndex(
                    (item: any) => item.questionID.toString() === characteristic.questionID.toString()
                );
                if (index !== -1) {
                    newCharacteristic[questionListIndex][index] = {
                        ...newCharacteristic[questionListIndex][index],
                        ...characteristic,
                        part: CHARACTERISTICS_KEY_FOR_MISSING_RISK + key,
                        isFinished: true,
                    };
                }
            });
        };
        setSpitQuestionPart(data.splitQuestionPart);
        setIsUseCSVDataSlice(data.isUseCSVDataSlice);
        let newCharacteristic: CharacteristicQuestionDto[][] = [];

        for (const key in data) {
            if (!key.startsWith(CHARACTERISTICS_KEY_FOR_MISSING_RISK)) continue;

            try {
                const value = data[key];
                const outputFormat = [{ questionID: "", answer: "", explanation: "" }];
                const characteristicsList = convertIncompleteJSONToCompleteJSON(value, outputFormat, []);
                const keyInNumber = key.replace(CHARACTERISTICS_KEY_FOR_MISSING_RISK, "");
                updateNewCharacteristic(Number(keyInNumber), characteristicsList, data.splitQuestionPart);
            } catch (e) {
                console.log(e);
            }
        }
        setCharacteristic(newCharacteristic);
    };

    const getDataFromAPI = async (): Promise<any> => {
        try {
            const stateData = await loadPageState(sessionIdFromUrl);
            return stateData.stateDetail;
        } catch (e) {
            return null;
        }
    };

    const handleTabSelect = (tabName: string) => {
        setActiveTab(tabName);
        navigate(`../${APP_URLS.MISSING_RISKS}/${sessionIdFromUrl}/${MISSING_RISK_TAB_URLS[tabName]}`);
    };

    const renderTabs = () => {
        return (
            <Tabs activeKey={activeTab} onSelect={(k) => handleTabSelect(k as string)}>
                <Tab eventKey={MISSING_RISK_TABS[0]} title={MISSING_RISK_TABS[0]}>
                    {activeTab === MISSING_RISK_TABS[0] && renderTabCharacteristicsForSafety()}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[1]} title={MISSING_RISK_TABS[1]}>
                    {activeTab === MISSING_RISK_TABS[1] && renderTabCategorizeRisks()}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[2]} title={MISSING_RISK_TABS[2]}>
                    {activeTab === MISSING_RISK_TABS[2] && renderMissingRisksC1()}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[3]} title={MISSING_RISK_TABS[3]}>
                    {activeTab === MISSING_RISK_TABS[3] && renderMissingRisksC2()}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[4]} title={MISSING_RISK_TABS[4]}>
                    {activeTab === MISSING_RISK_TABS[4] && renderTableOfOriginRisksAndMissingRisks("C1")}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[5]} title={MISSING_RISK_TABS[5]}>
                    {activeTab === MISSING_RISK_TABS[5] && renderTableOfOriginRisksAndMissingRisks("C2")}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[6]} title={MISSING_RISK_TABS[6]}>
                    {activeTab === MISSING_RISK_TABS[6] && renderTabAddedMissingRisks()}
                </Tab>
                <Tab eventKey={MISSING_RISK_TABS[7]} title={MISSING_RISK_TABS[7]}>
                    {activeTab === MISSING_RISK_TABS[7] && renderTabDashboard()}
                </Tab>
            </Tabs>
        );
    };

    const renderTabCharacteristicsForSafety = () => {
        return (
            <Tab_CharacteristicsForSafety
                csvData={csvData}
                characteristic={characteristic}
                setCharacteristic={setCharacteristic}
                hazardStateOfC1={hazardStateOfC1}
                isGeneratingData={isGeneratingData}
                setCSVDataWithRelateAttributes={setCSVDataWithRelateAttributes}
                setIsGeneratingData={setIsGeneratingData}
                issueKeyPrefix={issueKeyPrefix}
                setIssueKeyPrefix={setIssueKeyPrefix}
                setHazardStateOfC1={setHazardStateOfC1}
                setIsDisableButton={setIsDisableButton}
                isDisableButton={isDisableButton}
                removeCharacteristicsForSafetyPageState={removeCharacteristicsForSafetyPageState}
                removeStateData={removeStateData}
                isUseCSVDataSlice={isUseCSVDataSlice}
                setIsUseCSVDataSlice={setIsUseCSVDataSlice}
                splitQuestionPart={splitQuestionPart}
                setSpitQuestionPart={setSpitQuestionPart}
            />
        );
    };

    const renderTabDashboard = () => {
        return <Tab_Dashboard csvData={csvData} addedMissingRisksWithGeneratedMissingFields={addedMissingRisksWithGeneratedMissingFields} />;
    };

    const renderTabCategorizeRisks = () => {
        return (
            <Tab_CategorizeRisk
                csvData={csvData}
                c1CategoryResultOfAllRows={c1CategoryResultOfAllRows}
                c2CategoryResultOfAllRows={c2CategoryResultOfAllRows}
                setC1CategoryResultOfAllRows={setC1CategoryResultOfAllRows}
                setC2CategoryResultOfAllRows={setC2CategoryResultOfAllRows}
                c1Category={c1Category}
                c2Category={c2Category}
                initRiskSubcategories={initRiskSubcategories}
                isGeneratingData={isGeneratingData}
                setIsGeneratingData={setIsGeneratingData}
            />
        );
    };

    const renderTabAddedMissingRisks = () => {
        return (
            <Tab_AddedMissingRisks
                csvData={csvData}
                mitigationData={mitigationData}
                setMitigationData={setMitigationData}
                isGeneratingData={isGeneratingData}
                setIsGeneratingData={setIsGeneratingData}
                addedMissingRisksWithGeneratedMissingFields={addedMissingRisksWithGeneratedMissingFields}
                setAddedMissingRisksWithGeneratedMissingFields={setAddedMissingRisksWithGeneratedMissingFields}
                mitigationForJira={mitigationForJira}
                setMitigationForJira={setMitigationForJira}
            />
        );
    };

    const renderTableOfOriginRisksAndMissingRisks = (table: string = "C1") => {
        return (
            <Tab_OriginRisksAndMissingRisks
                table={table}
                csvData={csvData}
                setCsvData={setCsvData}
                c1CategoryResultOfAllRows={c1CategoryResultOfAllRows}
                c2CategoryResultOfAllRows={c2CategoryResultOfAllRows}
                hazardStateOfC1={hazardStateOfC1}
                hazardStateOfC2={hazardStateOfC2}
            />
        );
    };

    const renderMissingRisksC1 = () => {
        return (
            <Tab_MissingRisk
                csvData={csvData}
                hazardStateOfC1={hazardStateOfC1}
                setHazardStateOfC1={setHazardStateOfC1}
                setHazardStateOfC2={setHazardStateOfC2}
                isGeneratingData={isGeneratingData}
                setIsGeneratingData={setIsGeneratingData}
                subCategoriesHazardData={hazardStateOfC1}
                countCategoryResult={c1CountCategoryResult}
                subCategoriesTable={"C1"}
            />
        );
    };
    const renderMissingRisksC2 = () => {
        return (
            <Tab_MissingRisk
                csvData={csvData}
                hazardStateOfC1={hazardStateOfC1}
                setHazardStateOfC1={setHazardStateOfC1}
                setHazardStateOfC2={setHazardStateOfC2}
                isGeneratingData={isGeneratingData}
                setIsGeneratingData={setIsGeneratingData}
                subCategoriesHazardData={hazardStateOfC2}
                countCategoryResult={c2CountCategoryResult}
                subCategoriesTable={"C2"}
            />
        );
    };

    const extractC1SubcategoriesWithScoreFromDB = (stateData: any) => {
        let data: any[] = [];
        try {
            for (const key in stateData) {
                if (key.startsWith(HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX)) {
                    const hazardsInString = stateData[key];
                    const outputFormat = [{ category: "", description: "", score: 0 }];
                    const hazardPart = convertIncompleteJSONToCompleteJSON(hazardsInString, outputFormat, []);
                    data = data.concat(hazardPart);
                }
            }
        } catch (e) {
            console.log(e);
        }
        return data;
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="w-100">{isLoading ? <LoadingPage /> : <div className="row">{renderTabs()}</div>}</div>
        </div>
    );
};

export default MissingRisks;

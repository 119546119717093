export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "http://localhost:5001";

export const SERCH_MAUDE = `${API_DOMAIN}/search-maude-database`;
export const FIND_GROUP_AND_SUB_GROUP = `${API_DOMAIN}/find-groups-and-sub-group`;
export const GENERATE_HAZARD_MASTER_LIST_MAUDE_TABLE = `${API_DOMAIN}/generate-hazard-master-list-maude`;
export const GENERATE_HAZARD_LIST_FROM_MAUDE = `${API_DOMAIN}/bottom-up-generate-hazard-list`;
export const TOP_DOWN_GENERATE_HAZARD_API = `${API_DOMAIN}/generate-hazards-with-score`;
export const TOP_DOWN_GENERATE_CHARACTERISTIC_API = `${API_DOMAIN}/generate-characteristics`;
export const GENEREATE_SCENARIOS_AND_PROBABILITY = `${API_DOMAIN}/generate-scenarios-and-probability`;
export const GENERATE_MITIGATION = `${API_DOMAIN}/generate-mitigation`;
export const COMPETITOR_API = `${API_DOMAIN}/competitor`;
export const PAGE_STATES_API = `${API_DOMAIN}/page_states`;
export const GET_SESSIONS_BY_USER_ID = `${API_DOMAIN}/sessions/user`;
export const GET_SESSIONS_BY_SITE_URL = `${API_DOMAIN}/sessions/jira`;
export const CREATE_SESSIONS = `${API_DOMAIN}/sessions`;
export const UPDATE_SESSION_NAME = `${API_DOMAIN}/sessions/name`;
export const END_SESSION_API = `${API_DOMAIN}/sessions/end-session`;
export const GET_MENU_ITEMS = `${API_DOMAIN}/menu_items/`;
export const CONVERT_TEXT_TO_CSV_FORMAT = `${API_DOMAIN}/convert_text_to_csv`;
export const STANDZARD_OCCURENCE_SEVERITY = `${API_DOMAIN}/standardize-occurence-severity`;
export const READ_EXCEL_FILE_DATA = `${API_DOMAIN}/read-excel-data`;
export const UPDATE_PAGE_STATE_BY_KEY = `${PAGE_STATES_API}/update_page_state_by_key`;
export const UPDATE_KEY_OF_PAGE_STATE_BY_OLD_KEY = `${PAGE_STATES_API}/update_key_of_page_state_by_old_key`;
export const GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE = `${API_DOMAIN}/generate-characteristics-for-safety-from-risk`;
export const CATEGORIZE_RISK = `${API_DOMAIN}/categorize-risk`;
export const CLEAR_OLD_SUBCATEGORY_RISK = `${CATEGORIZE_RISK}/clear-subcategories`;
export const GET_RISK_CATEGORY = `${API_DOMAIN}/get-risk-category`;
export const GENERATE_MISSING_RISKS = `${API_DOMAIN}/generate-missing-risks`;
export const REMOVE_MISSING_RISKS = `${API_DOMAIN}/remove-missing-risks`;
export const INDEX_OF_RISK_SUMARY_COLUMN = `${API_DOMAIN}/index-of-risk-summary-column`;
export const UPDATE_ISSUE_KEY_PREFIX = `${API_DOMAIN}/update-issue-key-prefix`;
export const ADD_MISSING_RISK_TO_CSV_DATA = `${API_DOMAIN}/add_missing_risk_to_csv_data`;
export const GENERATE_MITIGATION_FOR_RISKS = `${API_DOMAIN}/generate-mitigation-for-risks`;
export const GENERATE_MISSING_FIELDS_FOR_RISKS = `${API_DOMAIN}/generate-missing-fields-for-risks`;
export const CLEAR_ALL_MITIGATION_FOR_RISKS = `${API_DOMAIN}/clear-all-mitigation-for-risks`;
export const LOGIN_API = `${API_DOMAIN}/login`;
export const LOGOUT_API = `${API_DOMAIN}/logout`;
export const CLEAR_ALL_GENERATED_MISSING_FIELDS_FOR_ADDED_RISK = `${API_DOMAIN}/clear-all-generated-missing-fields-for-added-risk`;

export const AUTH_API = `${API_DOMAIN}/auth`;
export const VERIFY_CURRENT_SESSION_API = `${AUTH_API}/current-session`;
export const LOG_IN_API = `${AUTH_API}/login`;
export const LOG_OUT_API = `${AUTH_API}/logout`;
export const GENERATE_RISKS_FOR_DESIGN_INPUT_API = `${API_DOMAIN}/generate-risks-for-design-input`;
export const CLEAR_ALL_GENERATED_RISKS_FOR_DESIGN_INPUT_API = `${API_DOMAIN}/clear-all-risks-of-design-inputs`;

import React, { useState } from "react";
import { CSVData } from "../../../Components/CSVTable/CSVTable";
import { getSessionIdFromURL } from "../../../utils/commonUtils";

import { HazardTopDownDto, MissingRisk } from "../../../types/HazardTopDown";
import { addMissingRiskToCSVDataAPI } from "../../../apiRequests/missingRisks/missingRisks";

import OriginalRisks_MissingRisks, {
    OriginalRisksRowData,
    OriginalRisks_MissingRisksProps,
} from "../../../Components/OriginalRisks_MissingRisks/OriginalRisks_MissingRisks";
import { SubCategoriesOfEachRisk } from "../../../Components/CategoryRiskTable/Table/CategoryRiskTable";

interface Props {
    csvData: CSVData;
    setCsvData: React.Dispatch<React.SetStateAction<CSVData>>;
    c1CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
    c2CategoryResultOfAllRows: SubCategoriesOfEachRisk[];
    hazardStateOfC1: HazardTopDownDto[];
    hazardStateOfC2: HazardTopDownDto[];
    table: string;
}
const Tab_OriginRisksAndMissingRisks = (props: Props) => {
    const { csvData, setCsvData, c1CategoryResultOfAllRows, c2CategoryResultOfAllRows, hazardStateOfC1, hazardStateOfC2 } = props;

    const [isAddingAMissingRisksToRiskTable, setIsAddingAMissingRisksToRiskTable] = useState<boolean>(false);
    const sessionIdFromUrl = getSessionIdFromURL();

    const renderTableOfOriginRisksAndMissingRisks = (table: string = "C1") => {
        return (
            <div className="row">
                <OriginalRisks_MissingRisks {...getDataTableOfOriginRisksAndMissingRisks(table)} />
            </div>
        );
    };

    const getDataTableOfOriginRisksAndMissingRisks = (table: string = "C1"): OriginalRisks_MissingRisksProps => {
        let categoryResultFromGPT: SubCategoriesOfEachRisk[] = c1CategoryResultOfAllRows;
        let categoriesHazards: HazardTopDownDto[] = hazardStateOfC1;
        if (table === "C2") {
            categoryResultFromGPT = c2CategoryResultOfAllRows;
            categoriesHazards = hazardStateOfC2;
        }
        return {
            headerTableData: csvData.header.filter((item) => item !== "C1" && item !== "C2"),
            risk_summary_column_index: csvData.riskSummaryColumnIndex,
            data: categoriesHazards.map((item) => {
                return {
                    category: item.category,
                    score: item.score,
                    missing_risks: item.missing_risks || [],
                    risks: getOriginalRisksOfCategory(item.category, categoryResultFromGPT),
                };
            }),
            addedMissingRisks: csvData.addedMissingRisks,
            addMissingRisk: addMissingRiskToCSVData,
            isAddingAMissingRisksToRiskTable: isAddingAMissingRisksToRiskTable,
            name: table,
        };
    };

    const getOriginalRisksOfCategory = (category: string, categoryResultFromGPT: SubCategoriesOfEachRisk[]): OriginalRisksRowData[] => {
        if (!categoryResultFromGPT || categoryResultFromGPT.length == 0) return [];
        return csvData.rows
            .filter((item, rowIndex) => {
                try {
                    const categories = categoryResultFromGPT[rowIndex];
                    return categories.subcategories.includes(category);
                } catch (e) {
                    console.log(e);
                    return false;
                }
            })
            .map((item) => {
                return {
                    rowData: item,
                };
            });
    };

    const addMissingRiskToCSVData = async (risk: MissingRisk, category: string) => {
        setIsAddingAMissingRisksToRiskTable(true);
        try {
            const newAddedMissingRisk = await addMissingRiskToCSVDataAPI(sessionIdFromUrl, category, risk);
            setCsvData({
                ...csvData,
                addedMissingRisks: [...csvData.addedMissingRisks, newAddedMissingRisk],
            });
        } catch (e) {
            console.log(e);
        }
        setIsAddingAMissingRisksToRiskTable(false);
    };

    return renderTableOfOriginRisksAndMissingRisks(props.table);
};

export default Tab_OriginRisksAndMissingRisks;

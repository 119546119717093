import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
    COLUMN_CLASS,
    HAZARDS_HEADER,
    HAZARD_COLUMN_CLASS_NAME,
    HAZARD_TABLE_CLASS_NAME,
    SORT_HAZARDS_BTN_CLASS,
    SORT_TYPE_ASC,
    SORT_TYPE_DESC,
    TABLE_COLUMN_CATEGORY,
    TABLE_COLUMN_DESCRIPTION,
    TABLE_COLUMN_SCORE,
    HAZARD_TABLE_ID,
} from "./constants";
import "./styles.css";
import { HazardTopDownDto, HazardTopDownState } from "../../types/HazardTopDown";
import HazardRow from "./HazardRow/HazardRow";
import MasterTable from "../MasterTable/MasterTable";
import { BLANK_STRING } from "../../constants/appConstants";

export interface HazardColumnProps {
    data: HazardTopDownDto[];
    isGeneratingData: boolean;
    onDataChange?: (oldItem: HazardTopDownDto, newItem: HazardTopDownDto) => void;
    onUpdateSortType?: (sortType: string) => void;
    riskCountingForEachHazard?: number[];
    highlighedHazardIndexList: number[];
    isShowMissingRisks: boolean;
    columnWidth?: number[];
    isEditable?: boolean;
    sortType?: string;
    columnName: string;
    fileName: string;
}
const ColumnHazards: React.FC<HazardColumnProps> = (props: HazardColumnProps) => {
    const [sortType, setSortType] = useState<string>(props.sortType || BLANK_STRING);

    const [tableData, setTableData] = useState<HazardTopDownDto[]>([]);

    useEffect(() => {
        const type = props.sortType || BLANK_STRING;
        setSortType(type);
        const sortedData = sortTable(props.data, type);
        setTableData(sortedData);
    }, [props.data, props.sortType]);

    const sortTable = (data: HazardTopDownDto[], sort: string) => {
        if (sort === BLANK_STRING) {
            return data;
        }
        return data.sort((a, b) => {
            if (sort === SORT_TYPE_ASC) {
                return Number(a.score) - Number(b.score);
            } else {
                return Number(b.score) - Number(a.score);
            }
        });
    };

    const handleClickSortBtn = () => {
        const type = sortType === SORT_TYPE_ASC ? SORT_TYPE_DESC : SORT_TYPE_ASC;
        const sortedData = sortTable(tableData, type);
        setTableData(sortedData);
        setSortType(type);
        if (props.onUpdateSortType) {
            props.onUpdateSortType(type);
        }
    };

    const renderHazardTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{TABLE_COLUMN_CATEGORY}</th>
                    <th scope="col">{TABLE_COLUMN_DESCRIPTION}</th>
                    <th scope="col">
                        {TABLE_COLUMN_SCORE}
                        {
                            <span className={SORT_HAZARDS_BTN_CLASS} onClick={handleClickSortBtn}>
                                <FontAwesomeIcon icon={faSort} />
                            </span>
                        }
                    </th>
                    {props.riskCountingForEachHazard && <th scope="col">Risk Counting</th>}
                    {props.isShowMissingRisks && <th scope="col">Missing Risks</th>}
                </tr>
            </thead>
        );
    };

    const handleCategoryChange = (value: string, item: HazardTopDownDto) => {
        const newData: HazardTopDownDto = { ...item, category: value };
        if (props.onDataChange) {
            props.onDataChange(item, newData);
        }
    };

    const handleDescriptionChange = (value: string, item: HazardTopDownDto) => {
        const newData: HazardTopDownDto = { ...item, description: value };
        if (props.onDataChange) {
            props.onDataChange(item, newData);
        }
    };

    const handleScoreChange = (value: string, item: HazardTopDownDto) => {
        const newData: HazardTopDownDto = { ...item, score: value };
        if (props.onDataChange) {
            props.onDataChange(item, newData);
        }
    };

    const renderHazardTableBody = () => {
        return (
            <tbody>
                {tableData.map((item, index) => (
                    <HazardRow
                        key={index}
                        data={item}
                        index={index}
                        handleCategoryChange={(value) => handleCategoryChange(value, item)}
                        handleDescriptionChange={(value) => handleDescriptionChange(value, item)}
                        handleScoreChange={(value) => handleScoreChange(value, item)}
                        count={props.riskCountingForEachHazard?.[index]}
                        isHighLight={props.highlighedHazardIndexList.includes(index)}
                        isShowMissingRisks={props.isShowMissingRisks}
                        isEditable={props.isEditable}
                    />
                ))}
            </tbody>
        );
    };

    const renderColumnWidth = () => {
        return (
            <colgroup>
                {props.columnWidth?.map((width, index) => (
                    <col key={index} style={{ width: `${width}%` }} />
                ))}
            </colgroup>
        );
    };
    const getCsvDataHazard = () => {
        return tableData.map((item) => {
            return {
                Category: item.category,
                Description: item.description,
                Score: item.score,
            };
        });
    };
    return (
        <div className={`${HAZARD_COLUMN_CLASS_NAME}`}>
            <h3 className="h3">{HAZARDS_HEADER}</h3>
            <div className={HAZARD_TABLE_CLASS_NAME}>
                <MasterTable
                    id={HAZARD_TABLE_ID + props.columnName}
                    fileName={props.fileName}
                    data={getCsvDataHazard()}
                    isGeneratingData={props.isGeneratingData}
                >
                    <>
                        {props.columnWidth && renderColumnWidth()}
                        {renderHazardTableHeader()}
                        {renderHazardTableBody()}
                    </>
                </MasterTable>
            </div>
            {/* <Button onClick={() => console.log(props.data)}>Generate</Button> */}
        </div>
    );
};
ColumnHazards.defaultProps = {
    isEditable: true,
};

export default ColumnHazards;

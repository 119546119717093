import { CategoryData, SelectedSubCategory } from "../CategorizeRisk";
import { AddedMissingRisk } from "../../CSVTable/CSVTable";
import { BLANK_STRING } from "../../../constants/appConstants";
import {
    CATEGORY_RISK_ADDED_MISSING_RISK_CELL_KEY,
    CATEGORY_RISK_ADDED_MISSING_RISK_ROW_KEY,
    CATEGORY_RISK_BODY_CELL_KEY,
    CATEGORY_RISK_BODY_ROW_KEY,
    CATEGORY_RISK_CURRENT_DYNAMIC_SUBCATEGORY_KEY,
    CATEGORY_RISK_DYNAMIC_SUBCATEGORY_KEY,
    CATEGORY_RISK_HEADER_KEY,
    CATEGORY_RISK_TABLE_ID,
} from "../constants";
import MasterTable from "../../MasterTable/MasterTable";

export interface SubCategoriesOfEachRisk {
    issueKey: string;
    subcategories: string[];
}

export interface CategoryRiskTableData {
    header: string[];
    rows: string[][];
    addedMissingRisks: AddedMissingRisk[];
    columnWidth: string[];
    c1SubCategories: SubCategoriesOfEachRisk[];
    c2SubCategories: SubCategoriesOfEachRisk[];
    riskSummaryColumnIndex: number;
    is_missing_issue_key: boolean;
    key_of_issue_key: string;
    column_issue_key_index: number;
    isDisplayingMissingRisks?: boolean;
    probability_and_severity_column_index: {
        probability: number;
        severity: number;
    };
}

export const DEFAULT_CSV_DATA: CategoryRiskTableData = {
    header: [],
    rows: [],
    addedMissingRisks: [],
    columnWidth: [],
    c1SubCategories: [],
    c2SubCategories: [],
    riskSummaryColumnIndex: 0,
    is_missing_issue_key: false,
    key_of_issue_key: BLANK_STRING,
    column_issue_key_index: 0,
    probability_and_severity_column_index: {
        probability: -1,
        severity: -1,
    },
};

export const CategoryRiskTable = (props: {
    csvData: CategoryRiskTableData;
    selectedSubCategories: SelectedSubCategory[];
    c1Category: CategoryData;
    c2Category: CategoryData;
}) => {
    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    {props.csvData.header.map((key: string, index: number) => (
                        <th key={CATEGORY_RISK_HEADER_KEY + key + index}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const isSelectedSubCategory = (subCategory: string, index: number, selectedSubCategories: SelectedSubCategory[]) => {
        for (let i = 0; i < selectedSubCategories.length; i++) {
            if (selectedSubCategories[i].text === subCategory) {
                if (selectedSubCategories[i].top === -1 || selectedSubCategories[i].top === index) return true;
            }
        }
        return false;
    };

    const renderSubCategoryContent = (subcategories: string[], color?: string) => (
        <td>
            <p>
                <span style={{ color }}>
                    1. {subcategories}
                    <br />
                </span>
            </p>
        </td>
    );

    const renderC1SubcategoryOfAnAddedRisk = (subcategories: string[]) => {
        const isC1 = props.c1Category.subcategory.includes(subcategories[0]);
        return isC1 ? renderSubCategoryContent(subcategories) : <td></td>;
    };

    const renderC2SubcategoryOfAnAddedRisk = (subcategories: string[]) => {
        const isC2 = props.c2Category.subcategory.includes(subcategories[0]);
        return isC2 ? renderSubCategoryContent(subcategories) : <td></td>;
    };

    const renderDynamicSubCategories = (subCategory: SubCategoriesOfEachRisk[], rowIndex: number, rowType: string) => {
        const currentSubCategories = subCategory[rowIndex]?.subcategories || [];
        return (
            <td key={CATEGORY_RISK_DYNAMIC_SUBCATEGORY_KEY + rowIndex + rowType}>
                <p>
                    {currentSubCategories.map((item: string, index: number) => (
                        <span
                            key={CATEGORY_RISK_CURRENT_DYNAMIC_SUBCATEGORY_KEY + item + index + rowType}
                            style={{
                                color: isSelectedSubCategory(item, index, props.selectedSubCategories) ? "red" : "black",
                            }}
                        >
                            {index + 1}. {item}
                            <br />
                        </span>
                    ))}
                </p>
            </td>
        );
    };

    const renderSubCategory = (subCategory: SubCategoriesOfEachRisk[], rowIndex: number, rowType: string, subcategories?: string[]) => {
        if (subcategories) {
            if (rowType === "C1") {
                return renderC1SubcategoryOfAnAddedRisk(subcategories);
            }
            if (rowType === "C2") {
                return renderC2SubcategoryOfAnAddedRisk(subcategories);
            }
            return <td></td>;
        } else {
            return renderDynamicSubCategories(subCategory, rowIndex, rowType);
        }
    };

    const renderC1SubCategory = (rowIndex: number, subcategories?: string[]) => {
        return renderSubCategory(props.csvData.c1SubCategories, rowIndex, "C1", subcategories);
    };

    const renderC2SubCategory = (rowIndex: number, subcategories?: string[]) => {
        return renderSubCategory(props.csvData.c2SubCategories, rowIndex, "C2", subcategories);
    };

    const setDisplayStatusBasedOnSelectedSubCategory = (rowIndex: number) => {
        if (props.selectedSubCategories.length === 0) return "table-row";
        const c1SubCategory = props.csvData.c1SubCategories[rowIndex];
        const c2SubCategory = props.csvData.c2SubCategories[rowIndex];
        if (hasSelectedSubcategory(c1SubCategory) || hasSelectedSubcategory(c2SubCategory)) return "table-row";

        return "none";
    };

    const hasSelectedSubcategory = (subCategoriesOfColumnRisk: SubCategoriesOfEachRisk): boolean => {
        try {
            for (let i = 0; i < subCategoriesOfColumnRisk.subcategories.length; i++) {
                if (isSelectedSubCategory(subCategoriesOfColumnRisk.subcategories[i], i, props.selectedSubCategories)) return true;
            }
            return false;
        } catch (err) {
            return false;
        }
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.csvData.isDisplayingMissingRisks &&
                    props.csvData.addedMissingRisks &&
                    props.csvData.addedMissingRisks.map((addedMissingRisk: AddedMissingRisk, index: number) => {
                        return renderRow(addedMissingRisk, index + props.csvData.rows.length, true);
                    })}
                {props.csvData.rows.map((row: string[], rowIndex: number) => {
                    return (
                        <tr
                            key={CATEGORY_RISK_BODY_ROW_KEY + rowIndex + row[0] + row[1]}
                            style={{ display: setDisplayStatusBasedOnSelectedSubCategory(rowIndex) }}
                        >
                            <td>{rowIndex + 1}</td>

                            {row.map((cell: string, index: number) => (
                                <td key={CATEGORY_RISK_BODY_CELL_KEY + index + cell}>{cell.toString()}</td>
                            ))}
                            {renderC1SubCategory(rowIndex)}
                            {renderC2SubCategory(rowIndex)}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const renderRow = (addedMissingRisk: AddedMissingRisk, rowIndex: number, isMissingRisks: boolean = false) => {
        return (
            <tr
                key={CATEGORY_RISK_ADDED_MISSING_RISK_ROW_KEY + rowIndex + addedMissingRisk.issueKey}
                style={{
                    backgroundColor: isMissingRisks ? "#ffcccc" : "white",
                }}
            >
                <td>{rowIndex + 1}</td>
                {addedMissingRisk.data.map((cell: string, index: number) => (
                    <td key={CATEGORY_RISK_ADDED_MISSING_RISK_CELL_KEY + cell + index}>{cell.toString()}</td>
                ))}
                {renderC1SubCategory(rowIndex, addedMissingRisk.subcategories)}
                {renderC2SubCategory(rowIndex, addedMissingRisk.subcategories)}
            </tr>
        );
    };

    const renderColumnWidth = () => {
        return (
            <colgroup>
                <col style={{ width: "2%" }} />
                {props.csvData.header.slice(0, props.csvData.header.length - 2).map((text: string, index: number) => (
                    <col key={text + index} />
                ))}
                <col style={{ width: "10%" }}></col>
                <col style={{ width: "10%" }}></col>
            </colgroup>
        );
    };
    return (
        <div
            style={{
                width: "100%",
                height: "90vh",
                overflow: "auto",
            }}
        >
            <MasterTable data={props.csvData} isGeneratingData={false} id={CATEGORY_RISK_TABLE_ID} fileName={CATEGORY_RISK_TABLE_ID}>
                <>
                    {renderColumnWidth()}
                    {renderTableHeader()}
                    {renderTableBody()}
                </>
            </MasterTable>
        </div>
    );
};

import { APP_PAGES_KEY } from "./../../constants/appConstants";
import { UserCredential, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import initFirebaseApp, { database } from "./index";
import { ref, set, onValue } from "firebase/database";

export const signInWithEmailAndPasswordFirebase = async (email: string, password: string): Promise<UserCredential> => {
  initFirebaseApp();
  const auth = getAuth();
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  if (userCredential.user) {
    const uid = userCredential.user.uid;
    const userRef = ref(database, "users/" + uid);
    onValue(
      userRef,
      (snapshot) => {
        if (!snapshot.exists()) {
          const initState = {
            [APP_PAGES_KEY.TOP_DOWN]: {},
            [APP_PAGES_KEY.BOTTOM_UP]: {},
            [APP_PAGES_KEY.COMBINE]: {},
            [APP_PAGES_KEY.RISK]: {},
          };
          set(userRef, initState);
        }
      },
      {
        onlyOnce: true,
      }
    );
  }
  return userCredential;
};

export const signOutFirebase = async () => {
  initFirebaseApp();
  const auth = getAuth();
  await signOut(auth);
};

export const verifyCurrentSessionFirebase = async (callback: any) => {
  initFirebaseApp();
  const auth = getAuth();
  await onAuthStateChanged(auth, callback);
};

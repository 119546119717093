import { CLEAR_ALL_GENERATED_RISKS_FOR_DESIGN_INPUT_API, PAGE_STATES_API } from "../../constants/api";
import axios from "../fetch/fetch";
import { redirectTo } from "../../utils/navigator";

export const loadPageState = async (page: string) => {
    try {
        const res = await axios.get(`${PAGE_STATES_API}/${page}`);
        return res.data;
    } catch (error: any) {
        if ((error.response && error.response.status === 403) || error.response.status === 404 || error.response.status === 401) {
            await redirectTo("/404");
            return { stateDetail: {} };
        }
    }
};

export const deletePageStateDetailAPI = async (sessionId: string) => {
    const body = {
        stateDetail: {},
    };
    await axios.put(`${PAGE_STATES_API}/${sessionId}/detail`, body);
};

export const clearAllRisksOfDesignInputHistoryAPI = async (sessionId: string) => {
  await axios.post(`${CLEAR_ALL_GENERATED_RISKS_FOR_DESIGN_INPUT_API}/${sessionId}`);
}
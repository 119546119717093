import React, { useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { HAZARD_LIST_WRAPPER_CLASS, HAZARD_LIST_TEXT, HAZARD_TEXT, HAZARD_LIST_TABLE_ID } from "./constants";
import "./styles.css";
import { HAZARD_TABLE_CLASS_NAME } from "../ColumnHazards/constants";
import MasterTable from "../MasterTable/MasterTable";
interface Props {
  // groupData: GroupAndSubGroups[];
  // onGenerateHazardTable: () => void;
  // isDisableButton: boolean;
  isGeneratingData: boolean;
  data: string[];
  customClassName: string;
}

const HazardListTable = (props: Props) => {
  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th>#</th>
          <th>{HAZARD_TEXT}</th>
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>
        {props.data.map((item, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{item}</td>
          </tr>
        ))}
      </tbody>
    );
  };
  return (
    <div className={`${HAZARD_LIST_WRAPPER_CLASS} ${props.customClassName}`}>
      <h4>{HAZARD_LIST_TEXT}</h4>
      <div className={HAZARD_TABLE_CLASS_NAME}>
        <MasterTable
          id={HAZARD_LIST_TABLE_ID}
          fileName={HAZARD_LIST_TEXT}
          data={props.data}
          isGeneratingData={props.isGeneratingData}
        >
          <>
            {renderTableHeader()}
            {renderTableBody()}
          </>
        </MasterTable>
      </div>
    </div>
  );
};

export default HazardListTable;

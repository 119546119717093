import { GET_SESSIONS_BY_USER_ID } from "../../constants/api";
import axios from "../fetch/fetch";

export const getSessionsByUserId = async (userId: string): Promise<any> => {
    try {
        const res = await axios.get(`${GET_SESSIONS_BY_USER_ID}/${userId}`);
        return res.data;
    } catch (err) {
        return [];
    }
};

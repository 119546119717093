import { AddedMissingRisk } from "../Components/CSVTable/CSVTable";
import { COOKIE_MAX_AGE, IS_USE_CSV_DATA_SLICE_KEY, SPLIT_QUESTION_PART } from "../constants/appConstants";
import { HAZARD_TYPES } from "../constants/hazards";
import { delayForIgnoreDebounce, useSaveDataToDatabase } from "../hooks/useSaveDataToDatabase";
import { AddedMissingRiskWithGeneratedMissingFields } from "../pages/MissingRisks/Tab_AddedMissingRisks/Tab_AddedMissingRisks";

export const getScreenWidthInPixels = (): number => {
    return window.screen.width;
};

export const getBrowserInnerHeightInPixels = (): number => {
    return document.body.clientHeight;
};

export const getBrowserInnerWidthInPixels = (): number => {
    return document.body.clientWidth;
};

export const getCookieValue = (name: string) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
        }
    }
    return "";
};

export const setCookieValue = (key: string, value: any) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + COOKIE_MAX_AGE);
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = key + "=" + value + "; " + expires + "; path=/";
};

const generateHazardTitleFromKey = (jsonObj: any, searchText: string, currentKey = ""): any => {
    for (const key in jsonObj) {
        const value = jsonObj[key];

        const newKey = currentKey ? `${currentKey} - ${key}` : key;

        if (key.toLowerCase().includes(searchText.toLowerCase()) || searchText.toLowerCase().includes(key.toLowerCase())) {
            return newKey;
        }

        if (typeof value === "object" && value !== null) {
            const result = generateHazardTitleFromKey(value, searchText, newKey);
            if (result) {
                return result;
            }
        }
    }

    return null;
};

export const getTheCategoryMap = (input: string) => {
    const category = generateHazardTitleFromKey(HAZARD_TYPES, input);
    if (category) {
        const result = category.replace(/ hazards - /, " ");
        return result;
    }
    return input;
};

export const saveDataToStorage = (key: string, data: any) => {
    try {
        const serializedData = JSON.stringify(data);
        localStorage.setItem(key, serializedData);
    } catch (error) {
        console.error("Error saving Maude data to local storage:", error);
    }
};

export const loadDataFromStorage = (key: string, defaultData: any) => {
    try {
        const serializedData = localStorage.getItem(key);
        if (serializedData === null) {
            return JSON.parse(defaultData);
        }
        const returnedData = JSON.parse(serializedData);
        return returnedData;
    } catch (error) {
        console.error("Error loading Maude data from local storage:", error);
        return JSON.parse(defaultData);
    }
};

export const subsampleArray = (arr: any[], maxItems: number) => {
    // Check if array has less than or equal to maxItems
    if (arr.length <= maxItems) {
        return arr;
    }

    // Calculate the step to evenly distribute the selected items
    var step = Math.floor(arr.length / maxItems);

    // Create the new array
    var newArr = [];
    for (var i = 0; i < arr.length; i += step) {
        if (newArr.length < maxItems) {
            newArr.push(arr[i]);
        } else {
            break;
        }
    }

    return newArr;
};

export const getSessionIdFromURL = () => {
    return window.location.pathname.split("/")[2];
};

export const setDocumentTitle = (title: string) => {
    document.title = `${title} - AIMedRisk`;
};

export const removeSpecialCharacters = (text: string): string => {
    // Define the pattern to match special characters
    const pattern = /[^a-zA-Z0-9\s]/g;
    // Replace special characters with an empty string
    return text.replace(pattern, "");
};

export const convertIncompleteJSONToCompleteJSON = (incompleteJSON: string, outputFormatObj: any = [], defaultData: any = []) => {
    incompleteJSON = incompleteJSON.replace(/```json|```/g, "");
    incompleteJSON = incompleteJSON.replace(/```|python/g, "");
    const parsedData = tryToParseIncompleteJson(incompleteJSON, defaultData);
    return completeParsedJsonBasedOnOutputFormat(parsedData, outputFormatObj);
};

const completeParsedJsonBasedOnOutputFormat = (parsedData: any, outputFormatObj: any) => {
    if (Array.isArray(parsedData)) {
        return parsedData.map((item) => {
            if (typeof item !== "object") return item;
            if (Array.isArray(item)) return item;
            const firstItemOfOutputFormat = outputFormatObj[0];
            return completeItemBasedOnOutputFormat(item, firstItemOfOutputFormat);
        });
    } else if (typeof parsedData === "object") {
        return completeItemBasedOnOutputFormat(parsedData, outputFormatObj);
    }
    return parsedData;
};

const completeItemBasedOnOutputFormat = (item: any, outputFormatItemObj: any) => {
    const completeJSONObj: any = { ...item };
    for (const key in outputFormatItemObj) {
        if (item[key]) {
            completeJSONObj[key] = item[key];
        } else {
            completeJSONObj[key] = outputFormatItemObj[key];
        }
    }
    return completeJSONObj;
};

const tryToParseIncompleteJson = (incompleteJSON: string, defaultData: any) => {
    const SUFFIX_TO_COMPLETE_GROUP_AND_SUB_GROUP = ["]}]}]", '"}]}]', "}]}]", '""}]}]', '":""}]}]', "]}]"];
    const SUFFIX_TO_COMPLETE_NORMAL_OBJ = [
        "",
        '"',
        "]",
        '"]',
        "']",
        "}]",
        '"}]',
        '""}]',
        ': ""}]',
        '": ""}]',
        '"": ""}]',
        "{}]",
        '"]}]',
        "[]}]",
        "}",
        '"}',
        "[]",
        '"]}]}]',
    ];
    const SUFFIX_TO_COMPLETE_OBJECT = [...SUFFIX_TO_COMPLETE_NORMAL_OBJ, ...SUFFIX_TO_COMPLETE_GROUP_AND_SUB_GROUP];
    const jsonInString = preprocessTheIncompleteJSON(incompleteJSON, defaultData);
    try {
        return JSON.parse(jsonInString);
    } catch (e) {
        for (const surfix of SUFFIX_TO_COMPLETE_OBJECT) {
            try {
                return JSON.parse(jsonInString + surfix);
            } catch (e) {}
        }
        // console.log(e, jsonInString);
    }
    throw new Error("Cannot parse the incomplete JSON");
};

const preprocessTheIncompleteJSON = (incompleteJSON: string, defaultData: any) => {
    let value = incompleteJSON.replaceAll("\n", "");
    value = value.replaceAll(",]", "]");
    const temp = value.trim();
    if (temp.endsWith(",")) {
        value = temp.slice(0, -1);
    }
    if (Array.isArray(defaultData) && !value.startsWith("[")) {
        value = `[${value}`;
    }
    return value;
};

const tryToParseIncompleteJsonFromOutputFormat = (incompleteJSON: string, outputFormatObj: any, defaultData: any) => {
    const outputFormatString = JSON.stringify(outputFormatObj);
    try {
        return JSON.parse(incompleteJSON);
    } catch (e) {
        const suffixToCompleteObj = getCombineOfReverseText(outputFormatString);
        for (const surfix of suffixToCompleteObj) {
            try {
                return JSON.parse(incompleteJSON + surfix);
            } catch (e) {}
        }
    }
    return defaultData;
};

export const getCombineOfReverseText = (text: string) => {
    const combineText = [];
    for (let i = text.length; i >= 0; i--) {
        combineText.push(text.substring(i, text.length));
    }
    return combineText;
};

export const deepCopyJsonFile = (object: any) => JSON.parse(JSON.stringify(object));

export const getDataOfAddedMissingRisks = (
    addedMissingRisk: AddedMissingRisk,
    addedMissingRisksWithGeneratedMissingFields: AddedMissingRiskWithGeneratedMissingFields[]
) => {
    const data = addedMissingRisksWithGeneratedMissingFields.find((item) => item.issueKey === addedMissingRisk.issueKey);
    if (!data?.data) return addedMissingRisk.data;
    let result = [...addedMissingRisk.data];
    for (let index = 0; index < addedMissingRisk.data.length; index++) {
        try {
            if (shoudUseGeneratedData(data, index, addedMissingRisk)) result[index] = data.data[index];
        } catch (error) {}
    }
    return result;
};

function shoudUseGeneratedData(data: AddedMissingRiskWithGeneratedMissingFields, index: number, addedMissingRisk: AddedMissingRisk) {
    return data.data[index].length > addedMissingRisk.data[index].length;
}

export const getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber = (part: number, splitQuestionPart: number) => {
    const quotient = Math.floor(part / splitQuestionPart);
    const remainder = part % splitQuestionPart;
    return remainder === 0 ? quotient - 1 : quotient;
};

export const saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase = async (
    saveDataToDatabase: any,
    sessionIdFromUrl: string,
    split: number,
    isUseCSVDataSlice: string
) => {
    await Promise.all([
        saveDataToDatabase(sessionIdFromUrl, SPLIT_QUESTION_PART, split),
        await delayForIgnoreDebounce(),
        saveDataToDatabase(sessionIdFromUrl, IS_USE_CSV_DATA_SLICE_KEY, isUseCSVDataSlice),
    ]);
};

export const mergeAndRemoveDuplicates = (mergedArray: any[], key: string) => {
    const result: any[] = [];

    mergedArray.forEach((item) => {
        const index = result.findIndex((r) => r[key] === item[key]);
        if (index === -1) {
            result.push(item);
        } else {
            if (JSON.stringify(item).length > JSON.stringify(result[index]).length) {
                result[index] = item;
            }
        }
    });

    return result;
};

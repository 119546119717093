import { UPDATE_PAGE_STATE_BY_KEY } from "../../constants/api";
import axios from "../fetch/fetch";

export const updatePageStateByKeyAPI = async (sessionId: string, keyInState: string, data: any) => {
    const response = await axios.put(`${UPDATE_PAGE_STATE_BY_KEY}/${sessionId}`, {
        keyInState,
        data,
    });
    return response.data;
};

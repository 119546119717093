import { Table } from "react-bootstrap";
import { SelectedSubCategory } from "../CategorizeRisk";

export interface SubCategoriesOfEachRisk {
    issueKey: string;
    subcategories: string[];
}

export interface CategoryRiskTableData {
    header: string[];
    rows: string[][];
    columnWidth: string[];
    c1SubCategories: SubCategoriesOfEachRisk[];
    c2SubCategories: SubCategoriesOfEachRisk[];
}

export const DEFAULT_CSV_DATA: CategoryRiskTableData = {
    header: [],
    rows: [],
    columnWidth: [],
    c1SubCategories: [],
    c2SubCategories: [],
};

export const CategoryRiskTable = (props: { csvData: CategoryRiskTableData; selectedSubCategories: SelectedSubCategory[] }) => {
    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    {props.csvData.header.map((key: string, index: number) => (
                        <th key={index}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const isSelectedSubCategory = (subCategory: string, index: number, selectedSubCategories: SelectedSubCategory[]) => {
        for (let i = 0; i < selectedSubCategories.length; i++) {
            if (selectedSubCategories[i].text === subCategory) {
                if (selectedSubCategories[i].top === -1 || selectedSubCategories[i].top === index) return true;
            }
        }
        return false;
    };

    const renderSubCategory = (subCategory: SubCategoriesOfEachRisk[], rowIndex: number, rowType: string) => {
        try {
            return (
                <td key={rowIndex + rowType}>
                    <p>
                        {subCategory[rowIndex].subcategories.map((item: string, index: number) => {
                            return (
                                <span
                                    style={{
                                        color: isSelectedSubCategory(item, index, props.selectedSubCategories) ? "red" : "black",
                                    }}
                                >
                                    {index + 1}. {item}
                                    <br />
                                </span>
                            );
                        })}
                    </p>
                </td>
            );
        } catch (err) {
            return <td key={rowIndex + rowType}></td>;
        }
    };

    const renderC1SubCategory = (rowIndex: number) => {
        return renderSubCategory(props.csvData.c1SubCategories, rowIndex, "C1");
    };

    const renderC2SubCategory = (rowIndex: number) => {
        return renderSubCategory(props.csvData.c2SubCategories, rowIndex, "C2");
    };

    const setDisplayStatusBasedOnSelectedSubCategory = (rowIndex: number) => {
        if (props.selectedSubCategories.length === 0) return "table-row";
        const c1SubCategory = props.csvData.c1SubCategories[rowIndex];
        const c2SubCategory = props.csvData.c2SubCategories[rowIndex];
        if (hasSelectedSubcategory(c1SubCategory) || hasSelectedSubcategory(c2SubCategory)) return "table-row";

        return "none";
    };

    const hasSelectedSubcategory = (subCategoriesOfColumnRisk: SubCategoriesOfEachRisk): boolean => {
        try {
            for (let i = 0; i < subCategoriesOfColumnRisk.subcategories.length; i++) {
                if (isSelectedSubCategory(subCategoriesOfColumnRisk.subcategories[i], i, props.selectedSubCategories)) return true;
            }
            return false;
        } catch (err) {
            return false;
        }
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.csvData.rows.map((row: string[], rowIndex: number) => {
                    return (
                        <tr key={rowIndex} style={{ display: setDisplayStatusBasedOnSelectedSubCategory(rowIndex) }}>
                            <td>{rowIndex + 1}</td>
                            {row.map((cell: string, index: number) => (
                                <td key={index}>{cell.toString()}</td>
                            ))}
                            {renderC1SubCategory(rowIndex)}
                            {renderC2SubCategory(rowIndex)}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    const renderColumnWidth = () => {
        return (
            <colgroup>
                <col style={{ width: "2%" }} />
                {props.csvData.header.slice(0, props.csvData.header.length - 2).map((text: string, index: number) => (
                    <col key={text} />
                ))}
                <col style={{ width: "10%" }}></col>
                <col style={{ width: "10%" }}></col>
            </colgroup>
        );
    };
    return (
        <div
            style={{
                height: "90vh",
                overflow: "scroll",
            }}
        >
            <Table bordered>
                {renderColumnWidth()}
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        </div>
    );
};

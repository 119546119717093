import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { BOOTSTRAP_CONTAINER_FLUID } from "../../constants/appConstants";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import { clearAllRisksOfDesignInputHistoryAPI, loadPageState } from "../../apiRequests/pageStates/pagestate";
import MasterTable from "../../Components/MasterTable/MasterTable";
import {
    MAX_TOKENS_OF_GENERATING_RISKS_FOR_DESIGN_INPUT,
    RISKS_OF_DESIGN_INPUT_KEY,
    RISKS_OF_DESIGN_INPUT_SYSTEM_PROMPT_TOKENS,
    TOP_DOWN_CLASS_NAME,
} from "./constants";
import { CSVData, DEFAULT_CSV_DATA } from "../../Components/CSVTable/CSVTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../utils/commonUtils";
import { Button } from "react-bootstrap";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";
import { GENERATE_RISKS_FOR_DESIGN_INPUT_API } from "../../constants/api";
import { encode } from "gpt-tokenizer";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";

interface NewRiskData {
    "Summary": string,
    "Hazard": string,
    "Foreseeable_sequence_of_events": string[],
    "Hazardous_situation": string,
    "Harm": string[],


}
export interface RisksOfDesignInput {
    issueKey: string;
    risks: NewRiskData[];
}

const JiraProject = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const [csvData, setCSVData] = useState<CSVData>({ ...DEFAULT_CSV_DATA });
    const [risksData, setRisksData] = useState<RisksOfDesignInput[]>([]);
    const sessionIdFromUrl = getSessionIdFromURL();
    useEffect(() => {
        setCSVData({ ...DEFAULT_CSV_DATA });
        getDataFromAPI();
    }, [sessionIdFromUrl]);

    const getDataFromAPI = async (): Promise<any> => {
        try {
            setIsLoading(true);
            try {
                const stateData = await loadPageState(sessionIdFromUrl);
                try {
                    handleRisksOfDesignInputHistory(stateData);
                } catch(ex) {
                    console.log(ex)
                }
                try {
                    setCSVData(stateData.stateDetail.csvData);
                } catch(ex) {
                    console.log(ex)
                }
                
                
            } catch (ex) {
                console.log(ex)
            }
            
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRisksOfDesignInputHistory = (stateData: any) => {
        for (const key in stateData.stateDetail) {
            if (key.includes(RISKS_OF_DESIGN_INPUT_KEY)) {
                const risksHistoryInString = stateData.stateDetail[key];
                parseStringToRisksOfDesignInput(risksHistoryInString);
            }
        }
    };

    const hasIssuesData = () => {
        try {
            return csvData.rows.length > 0;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const renderRisksOfIssue = (issueKey: string) => {
        const risks = risksData.find((risk: RisksOfDesignInput) => risk.issueKey === issueKey);
        return (
            <td style={{ minWidth: "22rem", textAlign:"left", paddingLeft: 30 }}>
                {risks &&
                    risks.risks &&
                    risks.risks.map((item, idx) => {
                        return (
                        <div style={{
                            borderBottom: '1px solid black'
                        }}>
                            <p>
                                {idx + 1}) {item.Summary}
                            </p>
                            {renderNewRiskItem(item)}
                        </div>
                        );
                    })}
            </td>
        );
    };

    const renderNewRiskItem = (item: NewRiskData) => {
        return (
            <div>
                <p>Hazard: {item.Hazard}</p>
                <p>Foreseeable sequence of events: {item.Foreseeable_sequence_of_events && item.Foreseeable_sequence_of_events.join(", ")}</p>
                <p>Hazardous situation: {item.Hazardous_situation}</p>
                <p>Harm: {item.Harm && item.Harm.join(", ")}</p>
            </div>
        )
    }

    const setStreamingRisksForDesignInput = (fullMessage: string) => {
        parseStringToRisksOfDesignInput(fullMessage);
    };
    const parseStringToRisksOfDesignInput = (fullMessage: string) => {
        const outputFormat = [{ issueKey: "", risks: [] }];
        const data: RisksOfDesignInput[] = convertIncompleteJSONToCompleteJSON(fullMessage, outputFormat, []);
        if (data.length > 0) {
            setRisksData((prevData) => {
                const newData = [...prevData];
                data.forEach((item) => {
                    const index = prevData.findIndex((newItem) => newItem.issueKey === item.issueKey);
                    if (index === -1 && Object.keys(item).length > 1) {
                        newData.push(item);
                    } else if (index !== -1) {
                        newData[index] = item;
                    }
                });
                return newData;
            });
        }
    };

    const getHeaderTokens = () => {
        const header = csvData.header.join(",") + "\n";
        return encode(header).length;
    };

    const convertDesigInputsToCSV = (startIndex: number, endIndex: number, remainToken: number = MAX_TOKENS_OF_GENERATING_RISKS_FOR_DESIGN_INPUT) => {
        let endIdx = endIndex;
        let totalTokens = getHeaderTokens();
        let csv = csvData.header.join(",") + "\n";
        for (let i = startIndex; i < endIndex; i++) {
            const row = csvData.rows[i].join(",") + "\n";
            const tokens = encode(row).length;
            if (totalTokens + tokens > remainToken) {
                endIdx = i;
                break;
            } else {
                totalTokens += tokens;
                csv += row;
            }
        }
        return { csv, endIdx };
    };

    const handleGenerateRisksForDesignInput = (startIndex: number, endIndex: number) => {
        const remainToken = MAX_TOKENS_OF_GENERATING_RISKS_FOR_DESIGN_INPUT - RISKS_OF_DESIGN_INPUT_SYSTEM_PROMPT_TOKENS;
        const { csv, endIdx: totalProcessedRows } = convertDesigInputsToCSV(startIndex, endIndex, remainToken);
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_RISKS_FOR_DESIGN_INPUT_API,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                setStreamingRisksForDesignInput(fullMessage);
            },
            onClose: function (): void {
                if (totalProcessedRows < endIndex) {
                    const newStartIndex = totalProcessedRows;
                    handleGenerateRisksForDesignInput(newStartIndex, endIndex);
                } else {
                    setIsGeneratingData(false);
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: "designInput",
                    value: JSON.stringify(csv),
                },
                {
                    key: "startAndEndIndex",
                    value: startIndex.toString() + "_" + totalProcessedRows.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const onClickGenerateRisksBtn = async () => {
        setIsGeneratingData(true);
        setRisksData([]);
        await clearAllRisksOfDesignInputHistoryAPI(sessionIdFromUrl);
        handleGenerateRisksForDesignInput(0, csvData.rows.length);
    };

    const renderGenerateRisksButton = () => {
        return (
            <Button variant="primary" onClick={onClickGenerateRisksBtn} disabled={isGeneratingData || csvData.rows.length === 0}>
                {isGeneratingData ? <LoadingButton /> : " Generate Risks"}
            </Button>
        );
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    {csvData.header.map((key: string, index: number) => (
                        <th key={index}>{key}</th>
                    ))}
                    <th>Risks</th>
                </tr>
            </thead>
        );
    };

    const rendeTableBody = () => {
        return (
            <tbody>
                {csvData.rows.map((row: string[], index: number) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        {row.map((cell: string, index: number) => (
                            <td key={index} style={{ maxWidth: "35rem" }}>
                                <p>{cell.toString()}</p>
                            </td>
                        ))}
                        {renderRisksOfIssue(row[0])}
                    </tr>
                ))}
            </tbody>
        );
    };

    const renderColGroup = () => {
        return (
            <colgroup>
                <col style={{ width: '5%' }} /> 
                <col style={{ width: '5%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '45%' }} />
            </colgroup>
        )
    }

    const renderDesignInputTable = () => {
        return (
            <MasterTable id={"DesignInputTable"} fileName={"DesignInputTable"} data={csvData} isGeneratingData={false}>
                <>
                    {renderColGroup()}
                    {renderTableHeader()}
                    {rendeTableBody()}
                </>
            </MasterTable>
        );
    };

    const renderIssuesData = () => {
        return (
            <div
                className="col-12"
                style={{
                    marginTop: "20px",
                    height: "90vh",
                }}
            >
                {renderGenerateRisksButton()}
                {renderDesignInputTable()}
            </div>
        );
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="row w-100">{isLoading ? <LoadingPage /> : <div className="row">{hasIssuesData() && renderIssuesData()}</div>}</div>
        </div>
    );
};

export default JiraProject;

import React, { useEffect, useRef, useState } from "react";
import {
    ACTION_ITEM_SESSTION,
    BUTTON_SESSION_CLASS_NAME,
    MAX_LEFT_MENU_WIDTH,
    MESSAGE_CONFIRM_DELETE_SESSION,
    MIN_LEFT_MENU_WIDTH,
    NAVIGATOR_ACTION_AND_MAIN,
    NAVIGATOR_CONTROL_BUTTON,
    NAVIGATOR_MAIN_CLASSNAME,
    NAV_LEFT_MENU_ITEM,
    NAV_LEFT_MENU_ITEM_EXPAND,
} from "./constants";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import LogoutButton from "../LogOutButton/LogoutButton";
import { APP_URLS, BLANK_STRING } from "../../constants/appConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare,
    faTrash,
    faCheck,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import { MenuItem, MenuSession, Session } from "../../types/SessionDto";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
    createSessionThunk,
    endSessionNameThunk,
    updateSessionNameThunk,
} from "../../store/session/thunkMiddlewares";
import Hamburger from "../../assert/hamburger.svg";
import Collapse from "../../assert/collapse.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { setDocumentTitle } from "../../utils/commonUtils";
import { MISSING_RISK_TAB_URLS } from "../../pages/MissingRisks/constants";
const initialSession: MenuSession = {
    [APP_URLS.TOP_DOWN]: [],
    [APP_URLS.BOTTOM_UP]: [],
    [APP_URLS.COMBINE]: [],
    [APP_URLS.RISK]: [],
    [APP_URLS.COMPETITOR]: [],
};
interface Props {
    userId: string;
}
const Navigator = (props: Props) => {
    const sessionState = useAppSelector((state) => state.session);
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<string>("");
    const [session, setSession] = useState<MenuSession>(initialSession);
    const [expanded, setExpanded] = useState<string[]>([]);
    const navigate = useNavigate();
    const [navVisible, setNavVisible] = useState<boolean>(true);
    const [editinOrCreatingSession, setEditingOrCreatingSession] = useState<{
        id: string;
        value: string;
    } | null>(null);
    const [creatingSession, setCreatingSession] =
        useState<string>(BLANK_STRING);
    useEffect(() => {
        if (editinOrCreatingSession?.id) {
            editSessionInputRef.current?.focus();
        }
    }, [editinOrCreatingSession?.id]);

    useEffect(() => {
        if (creatingSession) {
            newSessionInputRef.current?.focus();
        }
    }, [creatingSession]);
    const editSessionInputRef = useRef<HTMLInputElement | null>(null);
    const newSessionInputRef = useRef<HTMLInputElement | null>(null);

    const toggleNav = () => {
        setNavVisible(!navVisible);
    };
    useEffect(() => {
        const path = window.location.pathname.split("/");
        const currentSelected = path[2] || BLANK_STRING;
        setSelected(currentSelected);
    }, []);

    useEffect(() => {
        try {
            if (sessionState.menuSession && sessionState.menuSession.length && sessionState.menuItems && sessionState.menuItems.length) {
                const newMapping: MenuSession = {};
                sessionState.menuItems.forEach((item: MenuItem) => {
                    const sessions = sessionState.menuSession.filter(
                        (session: Session) => session.menuItemId === item._id
                    );
                    newMapping[item.menuItemUrl] = sessions.map(
                        (session: Session) => ({
                            id: session._id,
                            sessionName: session.sessionName,
                        })
                    );
                });
                setSession(newMapping);
                setExpanded(sessionState.menuItems.map((item) => item.menuItemUrl));
            } else {
                setSession(initialSession);
            }
        } catch (e) {
            console.log(e);
        }
        
    }, [sessionState.menuSession, sessionState.menuItems]);

    const renderLeftMenu = () => {
        return (
            <div className="sidebar-sticky">
                <ul className="nav flex-column">
                    {sessionState.menuItems.map((item) => renderMenuItem(item))}
                </ul>
            </div>
        );
    };
    const handleSelectItemOfSession = (
        page: string,
        id: string,
        name: string
    ) => {
        if (editinOrCreatingSession?.id !== id) {
            setEditingOrCreatingSession(null);
        }
        setCreatingSession(BLANK_STRING);
        setDocumentTitle(name);
        setSelected(id);
        let url = `/${page}/${id}`;
        if (page === "missing-risks") {
            url += `/${Object.values(MISSING_RISK_TAB_URLS)[0]}`;
        }
        navigate(url);
    };

    const getButtonClass = (id: string, name: string) => {
        if (id === selected) {
            setDocumentTitle(name);
            return "btn-primary";
        } else {
            return "btn-light";
        }
    };

    const toggleExpanded = (id: string) => {
        if (expanded.includes(id)) {
            setExpanded(expanded.filter((item) => item !== id));
        } else {
            setExpanded([...expanded, id]);
        }
    };

    const renderMenuItem = (navItem: MenuItem) => {
        return (
            <div key={navItem._id}>
                {renderMenuItemNameAndAddAction(navItem)}
                {renderExpandItemOfSession(navItem)}
            </div>
        );
    };

    const renderMenuItemNameAndAddAction = (navItem: MenuItem) => {
        return (
            <>
                <div
                    className={NAV_LEFT_MENU_ITEM}
                    onClick={() => toggleExpanded(navItem.menuItemUrl)}
                >
                    <span>{navItem.menuItemName}</span>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={(e) =>
                            createNewSession(e, navItem.menuItemUrl)
                        }
                    >
                        +
                    </button>
                </div>
            </>
        );
    };

    const renderNewSessionInput = (navItem: MenuItem) => {
        return (
            <div className={NAV_LEFT_MENU_ITEM_EXPAND}>
                <input
                    ref={newSessionInputRef}
                    type="text"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                        setEditingOrCreatingSession({
                            id: "new",
                            value: e.target.value,
                        });
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(
                            e,
                            "new",
                            editinOrCreatingSession?.value || BLANK_STRING,
                            navItem
                        )
                    }
                />
                <FontAwesomeIcon
                    icon={faCheck}
                    onClick={() =>
                        handleConfirm(
                            "new",
                            editinOrCreatingSession?.value || "",
                            navItem
                        )
                    }
                    className={ACTION_ITEM_SESSTION}
                />
                <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => handleCancelEditCreate()}
                    className={ACTION_ITEM_SESSTION}
                />
            </div>
        );
    };

    const renderSessionItem = (
        { id, sessionName }: { id: string; sessionName: string },
        navItem: MenuItem
    ) => {
        return (
            <div key={id} className={NAV_LEFT_MENU_ITEM_EXPAND}>
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{sessionName}</Tooltip>}
                >
                    <button
                        className={`btn ${getButtonClass(
                            id,
                            sessionName
                        )} ${BUTTON_SESSION_CLASS_NAME}`}
                        onClick={() =>
                            handleSelectItemOfSession(
                                navItem.menuItemUrl,
                                id,
                                sessionName
                            )
                        }
                    >
                        {renderSessionButtonContent(id, sessionName, navItem)}
                    </button>
                </OverlayTrigger>
                {renderSessionActions(id, navItem)}
            </div>
        );
    };

    const renderSessionButtonContent = (
        id: string,
        sessionName: string,
        navItem: MenuItem
    ) => {
        if (editinOrCreatingSession?.id === id) {
            return (
                <input
                    ref={editSessionInputRef}
                    type="text"
                    defaultValue={sessionName}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                        setEditingOrCreatingSession({
                            ...editinOrCreatingSession,
                            value: e.target.value,
                        });
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(
                            e,
                            id,
                            editinOrCreatingSession.value,
                            navItem
                        )
                    }
                />
            );
        } else {
            return sessionName;
        }
    };

    const renderSessionActions = (id: string, navItem: MenuItem) => {
        if (editinOrCreatingSession && editinOrCreatingSession?.id === id) {
            return (
                <>
                    <FontAwesomeIcon
                        icon={faCheck}
                        onClick={() =>
                            handleConfirm(
                                id,
                                editinOrCreatingSession.value,
                                navItem
                            )
                        }
                        className={ACTION_ITEM_SESSTION}
                    />
                    <FontAwesomeIcon
                        icon={faClose}
                        onClick={() => handleCancelEditCreate()}
                        className={ACTION_ITEM_SESSTION}
                    />
                </>
            );
        } else {
            return (
                <>
                    <FontAwesomeIcon
                        icon={faPenToSquare}
                        onClick={(e) =>
                            handleEditSessionName(e, id, navItem.menuItemUrl)
                        }
                        className={ACTION_ITEM_SESSTION}
                    />
                    <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() =>
                            handleDeleteSession(navItem.menuItemUrl, id)
                        }
                        className={ACTION_ITEM_SESSTION}
                    />
                </>
            );
        }
    };

    const renderExpandItemOfSession = (navItem: MenuItem) => {
        if (expanded.includes(navItem.menuItemUrl)) {
            return (
                <div>
                    {creatingSession === navItem.menuItemUrl &&
                        renderNewSessionInput(navItem)}
                    {session[navItem.menuItemUrl]?.map((sessionItem) =>
                        renderSessionItem(sessionItem, navItem)
                    )}
                </div>
            );
        }
        return null;
    };

    const handleCancelEditCreate = () => {
        setEditingOrCreatingSession(null);
        setCreatingSession(BLANK_STRING);
    };
    const handleEditSessionName = (
        e: React.MouseEvent<SVGElement>,
        sessionId: string,
        page: string
    ) => {
        e.stopPropagation();
        setSelected(sessionId);
        let url = `/${page}/${sessionId}`;
        if (page === "missing-risks") {
            url += `/${Object.values(MISSING_RISK_TAB_URLS)[0]}`;
        }
        navigate(url);
        setCreatingSession(BLANK_STRING);
        setEditingOrCreatingSession({ id: sessionId, value: BLANK_STRING });
    };
    const handleKeyDown = (
        e: any,
        sessionId: string,
        newName: string,
        navItem: MenuItem
    ) => {
        if (e.code === "Enter") {
            handleConfirm(sessionId, newName, navItem);
        }
    };

    const handleConfirm = (
        sessionId: string,
        newName: string,
        navItem: MenuItem
    ) => {
        if (sessionId === "new") {
            createSession(navItem.menuItemUrl, newName, navItem);
        } else {
            updateSessionName(sessionId, newName, navItem);
        }
    };

    const createSession = async (
        menuItemUrl: string,
        newName: string,
        navItem: MenuItem
    ) => {
        setEditingOrCreatingSession(null);
        setCreatingSession(BLANK_STRING);
        const newSession: Session = await dispatch(
            createSessionThunk(
                newName,
                props.userId,
                navItem._id || BLANK_STRING
            )
        );
        if (newSession) {
            setSession((prevSession) => {
                return {
                    ...prevSession,
                    [menuItemUrl]: [
                        { id: newSession._id, sessionName: newName },
                        ...prevSession[menuItemUrl]||[],
                    ],
                };
            });

            let url = `/${menuItemUrl}/${newSession._id}`;
            if (menuItemUrl === "missing-risks") {
                url += `/${Object.values(MISSING_RISK_TAB_URLS)[0]}`;
            }
            navigate(url);
            setSelected(newSession._id);
            if (!expanded.includes(menuItemUrl)) {
                setExpanded([...expanded, menuItemUrl]);
            }
        }
    };

    const updateSessionName = (
        sessionId: string,
        newName: string,
        navItem: MenuItem
    ) => {
        dispatch(updateSessionNameThunk(sessionId, newName));
        setSession((prevSession) => {
            const updatedMenu = prevSession[navItem.menuItemUrl].map(
                (session) =>
                    session.id === sessionId
                        ? { id: sessionId, sessionName: newName }
                        : session
            );
            prevSession[navItem.menuItemUrl] = updatedMenu;
            return prevSession;
        });
        setEditingOrCreatingSession(null);
    };
    const createNewSession = (
        e: React.MouseEvent<HTMLButtonElement>,
        menuItemUrl: string
    ) => {
        setEditingOrCreatingSession(null);
        setCreatingSession(menuItemUrl);
    };

    const handleDeleteSession = (menuItem: string, sessionId: string) => {
        const isSure = window.confirm(MESSAGE_CONFIRM_DELETE_SESSION);
        if (isSure) {
            dispatch(endSessionNameThunk(sessionId));
            setSession((prevSession) => {
                const sessionItems = prevSession[menuItem].filter(
                    (item) => item.id !== sessionId
                );
                return { ...prevSession, [menuItem]: sessionItems };
            });
            navigate(`/`);
        }
    };
    const renderSignOutBtn = () => {
        return <LogoutButton></LogoutButton>;
    };

    return (
        <nav
            className="col-1 col-md-1 bg-light sidebar"
            style={{
                width: navVisible ? MAX_LEFT_MENU_WIDTH : MIN_LEFT_MENU_WIDTH,
            }}
        >
            <div className={NAVIGATOR_ACTION_AND_MAIN}>
                <img
                    src={navVisible ? Collapse : Hamburger}
                    onClick={toggleNav}
                    className={NAVIGATOR_CONTROL_BUTTON}
                />
                {navVisible && (
                    <>
                        <div className={NAVIGATOR_MAIN_CLASSNAME}>
                            {renderLeftMenu()}
                        </div>
                    </>
                )}
            </div>
            {navVisible && renderSignOutBtn()}
        </nav>
    );
};
export default Navigator;

import { UPDATE_KEY_OF_PAGE_STATE_BY_OLD_KEY } from "../../constants/api";
import axios from "../fetch/fetch";

export const updateKeyOfPageStateByOldKeyAPI = async (sessionId: string, oldKey: string, newKey: string) => {
    const response = await axios.put(`${UPDATE_KEY_OF_PAGE_STATE_BY_OLD_KEY}/${sessionId}`, {
        oldKey,
        newKey,
    });
    return response.data;
};

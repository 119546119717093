import React, { useEffect, useRef, useState } from "react";
import { Button, Col } from "react-bootstrap";
import {
    CHARACTERISTICS_COLUMN_CLASS_NAME,
    CHARACTERISTICS_COLUMN_MAIN_CLASS_NAME,
    CHARACTERISTICS_COLUMN_NAME,
    QUESTION_ANSWER_OPTIONS,
    CHARACTERISTICS_COLUMN_HEADER,
} from "./constants";
import "./styles.css";
import CardCharacteristics from "./CardCharacteristics/CardCharacteristics";
import { BLANK_STRING, GENERATE_TEXT } from "../../constants/appConstants";
import { CharacteristicQuestionDto } from "../../types/CharacteristicQuestion";
import LoadingButton from "../LoadingButton/LoadingButton";
import default_question_characteristics from "./topdown-chracteristic.json";
import { useSaveDataToDatabase } from "../../hooks/useSaveDataToDatabase";
import {
    convertIncompleteJSONToCompleteJSON,
    deepCopyJsonFile,
    getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber,
} from "../../utils/commonUtils";

const DEFAULT_CHARACTERISTICS_KEY = BLANK_STRING;
interface Props {
    onGenerate: (product: string) => void;
    data: string;
    isDisableButton: boolean;
    dataInList: CharacteristicQuestionDto[][];
    setDataInList: (data: CharacteristicQuestionDto[][]) => void;
    isGeneratingHazardTable: boolean;
    sessionId: string;
    needToGenerateHazard: boolean;
    part: number;
    isUseCSVDataSlice: boolean;
    splitQuestionPart: number;
    keyOfPage: string;
}

const ColumnCharacteristics = (props: Props) => {
    const [questionData, setQuestionData] = useState<CharacteristicQuestionDto[][]>(props.dataInList);
    const saveDataToDatabase = useSaveDataToDatabase();
    useEffect(() => {
        setQuestionData(props.dataInList);
    }, [props.dataInList]);
    useEffect(() => {
        addNewQuesionListWhenNewPartCsvCalled(props.part);
    }, [props.part]);

    const addNewQuesionListWhenNewPartCsvCalled = (part: number) => {
        setQuestionData((prevState) => {
            const indexQuesionListOfPart = getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(props.part, props.splitQuestionPart);
            if (!prevState[indexQuesionListOfPart]) {
                prevState[indexQuesionListOfPart] = deepCopyJsonFile(default_question_characteristics);
            }
            return prevState;
        });
    };
    const cardRefs = useRef<any[]>([]);

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, questionData.length);
        questionData[0]?.forEach((_, i) => {
            if (!cardRefs.current[i]) {
                cardRefs.current[i] = React.createRef();
            }
        });
    }, [questionData]);

    useEffect(() => {
        try {
            if (props.data) {
                if (props.isUseCSVDataSlice) {
                    try {
                        const outputFormat = [{ questionID: "", questionText: "", answer: "", explanation: "" }];
                        const characteristics = convertIncompleteJSONToCompleteJSON(props.data, outputFormat, []);

                        const indexLastQuestion = questionData[
                            getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(props.part, props.splitQuestionPart)
                        ].findIndex((item) => item.questionID === characteristics[characteristics.length - 1].questionID);

                        setNewDataQuestion(
                            indexLastQuestion,
                            characteristics,
                            questionData[getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(props.part, props.splitQuestionPart)]
                        );
                        scrollToCardByIndex(indexLastQuestion);
                        return;
                    } catch (e) {}
                } else {
                    try {
                        const outputFormat = [{ questionID: "", questionText: "", answer: "", explanation: "" }];
                        const characteristics = convertIncompleteJSONToCompleteJSON(props.data, outputFormat, []);

                        const indexLastQuestion = questionData[0].findIndex(
                            (item) => item.questionID === characteristics[characteristics.length - 1].questionID
                        );

                        setNewDataQuestion(indexLastQuestion, characteristics, questionData[0]);
                        scrollToCardByIndex(indexLastQuestion);
                        return;
                    } catch (e) {}
                }
            }
        } catch (error) {}
    }, [props.data]);

    const setNewDataQuestion = (indexLastQuestion: number, data: CharacteristicQuestionDto[], questionData: CharacteristicQuestionDto[]) => {
        const part = props.part || DEFAULT_CHARACTERISTICS_KEY;
        questionData[indexLastQuestion].answer = data[data.length - 1].answer;
        questionData[indexLastQuestion].part = `${props.keyOfPage}${part}`;
        questionData[indexLastQuestion].explanation = data[data.length - 1].explanation;
        questionData[indexLastQuestion].isSelected = true;
        questionData[indexLastQuestion - 1].isFinished = true;

        if (indexLastQuestion === questionData.length - 1) {
            questionData[questionData.length - 1].isFinished = true;
        }
        if (props.isUseCSVDataSlice) {
            setQuestionData((prevState) => {
                prevState[getIndexQuestionListOfCsvFromPartAndSplitQuestionNumber(props.part, props.splitQuestionPart)] = questionData;
                props.setDataInList(prevState);
                return prevState;
            });
        } else {
            setQuestionData([questionData]);
            props.setDataInList([questionData]);
        }
    };

    const scrollToCardByIndex = (index: number) => {
        if (cardRefs.current[index] && cardRefs.current[index].current) {
            cardRefs.current[index].current.scrollIntoView({
                behavior: "auto",
                block: "end",
            });
        }
    };
    const saveQuestionDataDBAndState = (data: CharacteristicQuestionDto[], part: string, index: number) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index] = data;
        setQuestionData(updatedQuestionData);
        const characteristics = data.filter((item) => item.part === part);
        saveDataToDatabase(props.sessionId, part, JSON.stringify(characteristics));
    };

    const handleInputQuestionChange = (question: string, index: number, part: string = DEFAULT_CHARACTERISTICS_KEY) => {
        [...questionData].forEach((item, indexList) => {
            if (item[index]) {
                item[index].questionText = question;
            }
            saveQuestionDataDBAndState(item, part, indexList);
        });
    };

    const handleInputAnswerChange = (answer: string, index: number, part: string = DEFAULT_CHARACTERISTICS_KEY, dataIndex: number) => {
        const list = [...questionData[dataIndex]];
        list[index].answer = answer;
        if (answer === QUESTION_ANSWER_OPTIONS.YES) {
            list[index].isSelected = true;
        }
        const newQuesionList = questionData.map((list) => {
            list[index].isSelected = true;
            return list;
        });
        setQuestionData(newQuesionList);
        saveQuestionDataDBAndState(list, part, dataIndex);
    };

    const handleInputExplanationChange = (explanation: string, index: number, part: string = DEFAULT_CHARACTERISTICS_KEY, dataIndex: number) => {
        const list = [...questionData[dataIndex]];
        list[index].explanation = explanation;
        saveQuestionDataDBAndState(list, part, dataIndex);
    };

    const handleSelectAnswerAndExplanationChange = (
        isSelected: boolean,
        index: number,
        part: string = DEFAULT_CHARACTERISTICS_KEY,
        dataIndex: number
    ) => {
        const list = [...questionData[dataIndex]];
        list[index].isSelected = isSelected;
        saveQuestionDataDBAndState(list, part, dataIndex);
    };

    const renderCardsQuestion = () => {
        const transpose = (matrix: CharacteristicQuestionDto[][]) => matrix[0]?.map((_, colIndex) => matrix?.map((row) => row[colIndex]));
        const transposedQuestionData = transpose(questionData);

        return transposedQuestionData?.map((questions, itemIndex) => {
            return (
                <div key={questions[0].questionText + itemIndex} ref={cardRefs.current[itemIndex]}>
                    <CardCharacteristics
                        dataSet={questions}
                        index={itemIndex}
                        handleInputQuestionChange={(question: string, index: number) => handleInputQuestionChange(question, index)}
                        handleInputAnswerChange={(answer: string, index: number, part: string, dataIndex: number) =>
                            handleInputAnswerChange(answer, index, part, dataIndex)
                        }
                        handleInputExplanationChange={(explanation: string, index: number, part: string, dataIndex: number) =>
                            handleInputExplanationChange(explanation, index, part, dataIndex)
                        }
                        handleSelectAnswerAndExplanationChange={(isSelected: boolean, index: number, part: string, dataIndex: number) =>
                            handleSelectAnswerAndExplanationChange(isSelected, index, part, dataIndex)
                        }
                    />
                </div>
            );
        });
    };

    const handleGenerateCharacteristics = () => {
        const filterDataAnswerYes = questionData
            .flat()
            .filter((item) => item.answer === QUESTION_ANSWER_OPTIONS.YES)
            .map((question) => {
                return {
                    questionID: question.questionID,
                    questionText: question.questionText,
                    answer: question.answer,
                    explanation: question.explanation,
                };
            });
        props.onGenerate(JSON.stringify(filterDataAnswerYes));
    };

    const renderButtonGenerate = () => {
        return (
            <Button onClick={() => handleGenerateCharacteristics()} disabled={props.isDisableButton}>
                {props.isGeneratingHazardTable ? <LoadingButton /> : GENERATE_TEXT}
            </Button>
        );
    };

    return (
        <div className={`${CHARACTERISTICS_COLUMN_CLASS_NAME}`}>
            <h5 className={`${CHARACTERISTICS_COLUMN_NAME} h5`}>{CHARACTERISTICS_COLUMN_HEADER}</h5>
            <div className={CHARACTERISTICS_COLUMN_MAIN_CLASS_NAME}>{renderCardsQuestion()}</div>
            {props.needToGenerateHazard && renderButtonGenerate()}
        </div>
    );
};
ColumnCharacteristics.defaultProps = {
    isEditable: false,
};
export default ColumnCharacteristics;

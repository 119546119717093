import { APP_URLS, BLANK_STRING, MESSAGE_FOR_NOT_ALLOWED_SITE, REDIRECT_AFTER_LOGIN_KEY } from "../../constants/appConstants";
import { AppThunk } from "../store";
import { setIsLoading, setLoginState, setUserInfo } from "./auth-reducer";
import { signInWithEmailAndPasswordFirebase, signOutFirebase, verifyCurrentSessionFirebase } from "../../apiRequests/firebase/authentication";
import { setMenuItems, setMenuSession } from "../session/session-reducer";
import { getSessionsByUserId } from "../../apiRequests/session/getSessionsByUserId";
import { getMenuItems } from "../../apiRequests/session/getMenuItems";
import { loginAPI } from "../../apiRequests/auth/login";
import { logoutAPI } from "../../apiRequests/auth/logout";
import { verifyCurrentSessionAPI } from "../../apiRequests/auth/verifyCurrentSession";
import { ACCOUNT_TYPE_KEY, ATLASSIAN_ACCOUNT_TYPE, EMAIL_ACCOUNT_TYPE } from "../../constants/authentication";
import Cookies from "js-cookie";
import { getSessionsBySiteUrl } from "../../apiRequests/session/getSessionsBySiteUrl";
import { toast } from "react-toastify";

export const loginAtlassianThunk =
    (authorizationCode: string, navigate: (url: string) => void): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setIsLoading(true));
                const loginData = await loginAPI(ATLASSIAN_ACCOUNT_TYPE, authorizationCode, { id: BLANK_STRING, email: BLANK_STRING });
                dispatch(setUserInfo(loginData.userData.id));
                dispatch(setLoginState(true));
                Cookies.set(ACCOUNT_TYPE_KEY, ATLASSIAN_ACCOUNT_TYPE);
                redirectAfterLogin(navigate);
            } catch (error: any) {
                if (error.response.status === 403) {
                    toast.error(MESSAGE_FOR_NOT_ALLOWED_SITE, {
                        position: "top-right",
                        autoClose: 100000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                }
                dispatch(setLoginState(false));
                navigate(`../${APP_URLS.SIGN_IN}`);
            } finally {
                dispatch(setIsLoading(false));
            }
        };

export const logoutThunk =
    (navigate: (url: string) => void): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setIsLoading(true));
                await signOutFirebase();
                await logoutAPI();
                dispatch(setMenuSession([]));
                dispatch(setMenuItems([]));
                dispatch(setUserInfo(BLANK_STRING));
                dispatch(setLoginState(false));
                Cookies.remove(ACCOUNT_TYPE_KEY);
                navigate(`../${APP_URLS.SIGN_IN}`);
            } catch (error) {
            } finally {
                dispatch(setIsLoading(false));
            }
        };

export const verifySessionAtlassianThunk =
    (navigate: (url: string) => void): AppThunk =>
        async (dispatch) => {
            try {
                const data = await verifyCurrentSessionAPI();

                dispatch(setUserInfo(data.user.id));
                const [session, menuItems, jiraSession] = await Promise.all([
                    getSessionsByUserId(data.user.id),
                    getMenuItems(),
                    getSessionsBySiteUrl(data.resources[0].url),
                ]);
                dispatch(setMenuSession([...session, ...jiraSession]));
                dispatch(setMenuItems(menuItems));
                dispatch(setLoginState(true));
            } catch (error) {
                localStorage.setItem(REDIRECT_AFTER_LOGIN_KEY, window.location.pathname);
                dispatch(setLoginState(false));
                navigate(`../${APP_URLS.SIGN_IN}`);
            }
            dispatch(setIsLoading(false));
        };


export const loginThunk =
    (email: string, password: string, navigate: (url: string) => void): AppThunk =>
        async (dispatch) => {
            console.log("call");
            try {
                dispatch(setIsLoading(true));
                const firebaseData = await signInWithEmailAndPasswordFirebase(email, password);
                const loginData = await loginAPI(EMAIL_ACCOUNT_TYPE, BLANK_STRING, {
                    id: firebaseData.user.uid,
                    email: firebaseData.user.email || BLANK_STRING,
                });
                dispatch(setUserInfo(loginData.userData.id));
                dispatch(setLoginState(true));
                Cookies.set(ACCOUNT_TYPE_KEY, EMAIL_ACCOUNT_TYPE);
                redirectAfterLogin(navigate);
            } catch (error) {
                dispatch(setUserInfo(BLANK_STRING));
                dispatch(setLoginState(false));
                navigate(`../${APP_URLS.SIGN_IN}`);
            } finally {
                dispatch(setIsLoading(false));
            }
        };

export const verifySessionThunk =
    (navigate: (url: string) => void): AppThunk =>
        async (dispatch) => {
            const callback = async (user: any) => {
                if (user) {
                    dispatch(setIsLoading(true));
                    dispatch(setUserInfo(user.uid));
                    const [session, menuItems] = await Promise.all([getSessionsByUserId(user.uid), getMenuItems()]);
                    dispatch(setMenuSession(session));
                    dispatch(setMenuItems(menuItems));
                    dispatch(setLoginState(true));
                } else {
                    localStorage.setItem(REDIRECT_AFTER_LOGIN_KEY, window.location.pathname);
                    dispatch(setLoginState(false));
                    navigate(`../${APP_URLS.SIGN_IN}`);
                }
                dispatch(setIsLoading(false));
            };

            await verifyCurrentSessionFirebase(callback);
        };

const redirectAfterLogin = (navigate: (url: string) => void) => {
    const redirectAfterLogin = localStorage.getItem(REDIRECT_AFTER_LOGIN_KEY);
    if (redirectAfterLogin) {
        navigate(redirectAfterLogin);
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_KEY);
    } else {
        navigate(`../${APP_URLS.HOMEPAGE}`);
    }
};

import React, { useState, useEffect } from "react";
import "./style.css";
import { ACCEPT_EXCEL_INPUT, ERROR_MESSAGES_NO_FILE, ERROR_MESSAGES_NO_FILE_SELECTED, FILE_KEY, INPUT_FILE_CLASS_NAME, INPUT_GROUP_CLASS_NAME, INPUT_TYPE_FILE, INPUT_UPLOAD_FILE_ID, TOP_DOWN_CLASS_NAME, UPLOAD_TEXT, } from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID, BLANK_STRING, DEFAULT_INDEX_OF_RISK_SUMMARY_COLUMN } from "../../constants/appConstants";
import { Button, Table } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { readExcelFileData } from "../../apiRequests/generateCharacteristicsForSafetyFromRisk/readExcelFileData";
import { getSessionIdFromURL } from "../../utils/commonUtils";
import { CATEGORIZE_RISK } from "../../constants/api";
import { CSVDataToText, convertCSVToString } from "../../utils/convertToCSVDataInString";
import { CategoryRiskTable, CategoryRiskTableData, SubCategoriesOfEachRisk } from "./CategoryRiskTable/CategoryRiskTable";
import { CSVData } from "../../Components/CSVTable/CSVTable";
import { getRiskCategoryAPI } from "../../apiRequests/categorizeRisk/getRiskCategoryAPI";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";
import { encode } from "gpt-tokenizer";
import { clearOldSubcategoryRiskAPI } from "../../apiRequests/categorizeRisk/clearOldCategoryRiskAPI";



export const RISK_TABLE_NAME = "Risk";
export const CATEGORIES_TABLE = ["C1", "C2"]
export const MAX_TIKTOKEN = 3000;

export const DEFAULT_CSV_DATA_FOR_RISK_DATA: CategoryRiskTableData = {
  header: [],
  rows: [],
  columnWidth: [],
  c1SubCategories: [],
  c2SubCategories: [],
}

export interface SelectedSubCategory {
  text: string;
  top: number;
}


export interface CategoryData {
  subcategory: string[];
}

export interface SubCategoryWithCountRisk {
  text: string;
  topCount: number[];
}

export interface CategoryDataWithCountRisk {
  subcategory: SubCategoryWithCountRisk[]
}

interface CategorizeRiskProps {
  isShowFileUpload: boolean;
}

const CategorizeRisk = (props: CategorizeRiskProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<CategoryRiskTableData>({ ...DEFAULT_CSV_DATA_FOR_RISK_DATA });
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [c1Category, setC1Category] = useState<CategoryData>({ subcategory: [] });
  const [c2Category, setC2Category] = useState<CategoryData>({ subcategory: [] });
  const sessionIdFromUrl = getSessionIdFromURL();
  const [selectedSubcategories, setSelectedSubcategories] = useState<SelectedSubCategory[]>([]);
  useEffect(() => {
    const loadSessionData = async () => {
      setIsLoading(true);
      setCsvData({ ...DEFAULT_CSV_DATA_FOR_RISK_DATA });
      try {
        const [sessionData, riskCategoryData] = await Promise.all([getDataFromAPI(), getRiskCategoryAPI()]);
        setC1Category(extractSubcategories(riskCategoryData["C1"]));
        setC2Category(extractSubcategories(riskCategoryData["C2"]));
        if (sessionData != null) {
          try {
            setCustomCSVData(parseStateData(sessionData));
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);

    };

    loadSessionData();
  }, [sessionIdFromUrl]);

  const extractSubcategories = (data: any): CategoryData => {
    const allSubcategories: string[][] = data.map((item: any) => {
      return item.subcategory;
    })
    return {
      subcategory: allSubcategories.flat()
    }
  }

  const initRiskSubcategories = (rows: any): SubCategoriesOfEachRisk[] => {
    return rows.map((item: any, index: number) => {
      return {
        index: index + 1,
        subcategories: []
      }
    })
  }

  const getSubCategoryOfColumn = (columnIndex: number, csvData: CategoryRiskTableData): SubCategoriesOfEachRisk[] => {
    if (columnIndex == 0) {
      return csvData.c1SubCategories;
    }
    return csvData.c2SubCategories;
  }

  const parseStateData = (data: any) => {
    let csvData: CategoryRiskTableData = {
      ...DEFAULT_CSV_DATA_FOR_RISK_DATA,
      ...data.csvData,
      header: [...data.csvData.header, ...CATEGORIES_TABLE],
      c1SubCategories: initRiskSubcategories(data.csvData.rows),
      c2SubCategories: initRiskSubcategories(data.csvData.rows),
    }

    
    for (let riskColumnIndex = 0; riskColumnIndex < CATEGORIES_TABLE.length; riskColumnIndex++) {
      const key = CATEGORIES_TABLE[riskColumnIndex];
      for (const dataKey in data) {
        if (dataKey.startsWith(key)) {
          const columnCateroryData = tryToParseRisksFromString(data[dataKey].trim());
          let subCategoriesOfColumnRisk = getSubCategoryOfColumn(riskColumnIndex, csvData)          
            try {
              columnCateroryData.forEach((item: any, index: number) => {
                const indexInRow = item.index;
                if (item.subcategories)
                subCategoriesOfColumnRisk[indexInRow - 1].subcategories = item.subcategories;
              })
            } catch (ex) {
              console.log(ex, dataKey, data[dataKey].trim(), columnCateroryData)
            } 
        }
      }
    }
    console.log(csvData)
    return csvData;
  }

  const tryToParseRisksFromString = (value: string) => {
    try {
      if (value.endsWith(",")) {
        value = value.substring(0, value.length - 1);
      }
      const postFixToCloseArrayList = ["",'[]}]' , '"]}]', ']}]', '}]', ']']
      for (const postFix of postFixToCloseArrayList) {
        try {
          const risks = JSON.parse(value + postFix)
          return risks;
        }
        catch (e) {
          // console.log(e);
        }
      }
    } catch (e) {
      // console.log(e);
      return [];
    }
  }




  const getDataFromAPI = async (): Promise<any> => {
    try {
      const stateData = await loadPageState(sessionIdFromUrl);
      return stateData.stateDetail;
    } catch (e) {
      return null;
    }
  };

  const renderTable = () => {
    return (
      <div className="row">
        <div className="col-12">
          <CategoryRiskTable 
            csvData={csvData} selectedSubCategories={selectedSubcategories}
            />
        </div>
      </div>
    );
  };

  const renderInputFile = () => {
    return (
      <>
          <input
            type={INPUT_TYPE_FILE}
            className={INPUT_FILE_CLASS_NAME}
            id={INPUT_UPLOAD_FILE_ID}
            accept={ACCEPT_EXCEL_INPUT}
            onChange={handleFileInputChange}
          />
          {renderButtonUploadFile()}
          </>
    );
  };

  const renderInputAndButtonUploadFile = () => {
    return (
      <div className="col-6">
        <div className={INPUT_GROUP_CLASS_NAME}>
          {props.isShowFileUpload && renderInputFile()}
          {renderButtonCategorizeRisk()}
        </div>
      </div>
    );
  };

  const renderButtonCategorizeRisk = () => {
    return (
      <Button
        onClick={handleCategoryRisk}
        disabled={isGeneratingData}
        style={{
          marginLeft: "20px",
        }}
      >
        {isGeneratingData ? <LoadingButton /> : "Categorize Risk"}
      </Button>
    )
  }


  const renderButtonUploadFile = () => {
    return (
      <Button
        onClick={handleUploadButtonClick}
        disabled={!isFileSelected || isGeneratingData}
      >
        {isGeneratingData ? <LoadingButton /> : UPLOAD_TEXT}
      </Button>
    )
  }

  const handleUploadButtonClick = async () => {
    const fileInput = document.getElementById(
      INPUT_UPLOAD_FILE_ID
    ) as HTMLInputElement;
    if (!fileInput) return console.error(ERROR_MESSAGES_NO_FILE);
    if (!fileInput.files) return console.error(ERROR_MESSAGES_NO_FILE);
    const file = fileInput.files[0];
    if (!file) {
      console.error(ERROR_MESSAGES_NO_FILE_SELECTED);
      return;
    }
    setCsvData({ ...DEFAULT_CSV_DATA_FOR_RISK_DATA });
    const formData = new FormData();
    formData.append(FILE_KEY, file);
    setIsGeneratingData(true);
    try {
      const data = await readExcelFileData(formData, sessionIdFromUrl);
      setCustomCSVData(addInitAdditionData(data))
    }
    catch (e) {
      console.log(e);
    }
    setIsGeneratingData(false);
  }

  const addInitAdditionData = (csvData: CategoryRiskTableData) => {
    csvData.header = [...csvData.header, ...CATEGORIES_TABLE];
    csvData.c1SubCategories = initRiskSubcategories(csvData.rows);
    csvData.c2SubCategories = initRiskSubcategories(csvData.rows);
    return csvData;
  }

  const setCustomCSVData = (csvData: CategoryRiskTableData) => {
    setCsvData(prevState => {
      return {
        ...prevState,
        ...csvData
      }
    })
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsFileSelected(!!event.target.files?.length);
  };

  const handleCategoryRisk = async() => {
    const defaultData = {
      ...DEFAULT_CSV_DATA_FOR_RISK_DATA,
      header: csvData.header,
      rows: csvData.rows,
      columnWidth: csvData.columnWidth,
      c1SubCategories: csvData.c1SubCategories.map((item) => {
        return {
          ...item,
          subcategories: [],
        };
      }),
      c2SubCategories: csvData.c2SubCategories.map((item) => {
        return {
          ...item,
          subcategories: [],
        };
      }),
    };
    setSelectedSubcategories([]);
    setCsvData(defaultData);
    await clearOldSubcategoryRiskAPI(sessionIdFromUrl);
    categorizeRiskAPI(0);
  };

  const getKeyOfRiskColumnData = (riskColumnIndex: number) => {
    if (riskColumnIndex === 0) {
      return "c1SubCategories";
    }
    return "c2SubCategories";
  };

  const handleAddNewCategoriesToAdditionalData = (
    fullMessage: string,
    additionalColumnIndex: number
  ) => {
    const dataKey = getKeyOfRiskColumnData(additionalColumnIndex);
    try {
      const data = tryToParseRisksFromString(fullMessage);
      if (!Array.isArray(data)) {
        return;
      }
      if (data)
        setCsvData((prevData) => {
          try {
            let subCategory = prevData[dataKey];
            const lastItemData = data[data.length - 1];
            if (lastItemData) {
              const item = subCategory.find(
                (item: any) =>
                  item.index.toString() === lastItemData.index.toString()
              );
              if (item) {
                item.subcategories = lastItemData.subcategories;
              }
            }
            if (data.length >= 2) {
              const secondLastItemData = data[data.length - 2];
              if (secondLastItemData) {
                const item = subCategory.find(
                  (item: any) =>
                    item.index.toString() ===
                    secondLastItemData.index.toString()
                );
                if (item) {
                  item.subcategories = secondLastItemData.subcategories;
                }
              }
            }
            return {
              ...prevData,
              [dataKey]: [...subCategory],
            };
          } catch (e) {
            return { ...prevData };
          }
        });
    } catch (e) {}
  };

 
  const computeMaximumNumberOfRowsToConvert = (startRowIndex: number) => {
    for (let i = startRowIndex; i <= csvData.rows.length - 1; i++) {
      const csvDataToProcess = sliceAndConvertToCsvData(
        startRowIndex,
        i
      );
      const tokens = encode(
        JSON.stringify(convertCSVToString(csvDataToProcess, startRowIndex))
      );
      const tokensLength = tokens.length;

      if (tokensLength > MAX_TIKTOKEN) {
        return i - 1 - startRowIndex;
      }
    }
    return csvData.rows.length - startRowIndex;
  };

  const sliceAndConvertToCsvData = (startIndex: number, endIndex: number): CSVDataToText => {
    const rows = csvData.rows.slice(startIndex, endIndex);
    const csvDataToProcess: CSVDataToText = {
      header: csvData.header,
      rows: rows,
    };
    return csvDataToProcess;
  };

  const categorizeRiskAPI = async (
    additionalColumnIndex: number,
    startRowIndex: number = 0
  ) => {
    const numberOfRowsEachQuery =
      computeMaximumNumberOfRowsToConvert(startRowIndex);
    const endRowNumber = startRowIndex + numberOfRowsEachQuery;
    setIsGeneratingData(true);

    const csvDataToProcess: CSVDataToText = sliceAndConvertToCsvData(
      startRowIndex,
      endRowNumber
    );

    let fullMessagePart = BLANK_STRING;

    const paramsFetchEventSource: ParamsFetchEventSource = {
      url: CATEGORIZE_RISK,
      sessionIdFromUrl: sessionIdFromUrl,
      onOpen: () => {
        setIsGeneratingData(true);
      },
      onMessage: function (fullMessage: string): void {
        fullMessagePart = fullMessage;
        handleAddNewCategoriesToAdditionalData(
          fullMessage,
          additionalColumnIndex
        );
      },
      onClose: function (): void {
        handleAddNewCategoriesToAdditionalData(
          fullMessagePart,
          additionalColumnIndex
        );
        if (stillHaveDataToProcess(endRowNumber)) {
          categorizeRiskAPI(additionalColumnIndex, endRowNumber);
        } else {
          additionalColumnIndex = additionalColumnIndex + 1;
          if (additionalColumnIndex < CATEGORIES_TABLE.length) {
            categorizeRiskAPI(additionalColumnIndex);
          } else {
            setIsGeneratingData(false);
          }
        }
      },
      onError: () => {
        setIsGeneratingData(false);
      },
      formDataKeyValue: [
        {
          key: "riskData",
          value: JSON.stringify(
            convertCSVToString(csvDataToProcess, startRowIndex)
          ),
        },
        {
          key: "category",
          value: CATEGORIES_TABLE[additionalColumnIndex],
        },
        {
          key: "startEndIndex",
          value: startRowIndex.toString() + "_" + endRowNumber.toString(),
        },
      ],
    };
   
    await commonFetchEventSource(paramsFetchEventSource);
  };

  const stillHaveDataToProcess = (endRowNumber: number) => {
    return endRowNumber < csvData.rows.length;
  }

  const renderTableC1Category = () => {
    return renderTableCategory(c1Category, csvData.c1SubCategories, CATEGORIES_TABLE[0])
  }

  const renderTableC2Category = () => {
    return renderTableCategory(c2Category, csvData.c2SubCategories, CATEGORIES_TABLE[1])
  }



  const countSubcategory = (categoryType: CategoryData, additionalData: SubCategoriesOfEachRisk[]): CategoryDataWithCountRisk => {
    return {
      subcategory: categoryType.subcategory.map((item) => {
        const topCount = [0,0,0];
        additionalData.forEach((additionalItem: SubCategoriesOfEachRisk) => {
          try {
            if(additionalItem.subcategories && additionalItem.subcategories.length > 0) {
              for (let i = 0; i < additionalItem.subcategories.length; i++) {
                if (additionalItem.subcategories[i] === item) {
                  topCount[i] = topCount[i] + 1;
                }
              }
            }
          } catch (e) {
          }
        })
        return {
          topCount: topCount,
          text: item
        }
      }).sort((a, b) => {
        return sumNumbers(b.topCount) - sumNumbers(a.topCount);
      })
    }
  }

  const sumNumbers = (numbers: number[]) => {
    try {
      return numbers.reduce((a, b) => a + b, 0);
    } catch (e) {
      return 0;
    }
  }
  const isSelectedSubCategory = (subCategory: string, top: number, selectedSubCategories: SelectedSubCategory[]) => {
    const item = selectedSubCategories.find((item: SelectedSubCategory) => item.text === subCategory);
    if(item) {
      if(top === -1) {
        return true;
      }
      return item.top === top;
    }
    return false;
  }

  const isSelectedSubCategoryWithoutCheckTop = (subCategory: string, selectedSubCategories: SelectedSubCategory[]) => {
    const item = selectedSubCategories.find((item: SelectedSubCategory) => item.text === subCategory);
    if(item) {
      return true;
    }
    return false;
  }

  const selectSubcategory = (subcategory: string, top: number) => {
    if (isSelectedSubCategory(subcategory, -1, selectedSubcategories)) {
      const selectedItem = selectedSubcategories.find((item: SelectedSubCategory) => item.text === subcategory);
      if(selectedItem?.top === top) {
        setSelectedSubcategories(prevState => {
          return prevState.filter(item => item.text !== subcategory);
        })
      } else {
        setSelectedSubcategories(prevState => {
          return prevState.map(item => {
            if(item.text === subcategory) {
              console.log("change top", top)
              return {
                ...item,
                top: top,
              }
            }
            return item;
          });
        })
      }
      
    } else {
      setSelectedSubcategories(prevState => {
        return [...prevState, {
          text: subcategory,
          top: top,
        }];
      })
    }
  }

  const handleSelectSubcategory = (event: any, subcategory: string, top: number) => {
    event.stopPropagation();
    selectSubcategory(subcategory, top);
  }

  const renderTableCategory = (categoryType: CategoryData, columnRiskData: SubCategoriesOfEachRisk[], tableName: string) => {
    const categoryTypeWithCount = countSubcategory(categoryType, columnRiskData);
    return (
      <div className="col-2">
        <h2>{tableName}</h2>
        <div
          style={{
            height: "90vh",
            overflow: "scroll",
          }}
        >
          <Table bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Subcategories</th>
              </tr>
            </thead>
            <tbody>
              {categoryTypeWithCount.subcategory.map((item, index) => {
                return (
                  <tr key={index} 
                  style={{
                    cursor: "pointer",
                    backgroundColor: isSelectedSubCategory(item.text, -1, selectedSubcategories) ? "yellow" : "white",
                  }}
                  onClick={() => selectSubcategory(item.text, -1)}>
                    <td>{index + 1}</td>
                    <td>
                      <span>
                        {item.text} ({sumNumbers(item.topCount)}) 
                      </span>
                      [{
                      
                        item.topCount.map((count, top) => {
                          return (
                            <span 
                              onClick={(e) => handleSelectSubcategory(e,item.text, top)}
                              style={{
                                color: isSelectedSubCategory(item.text, top, selectedSubcategories) && selectedSubcategories.find((selectedItem: SelectedSubCategory) => selectedItem.text === item.text && selectedItem.top === top) ? "red" : "black",
                              }}
                            >
                              {count}{top === item.topCount.length - 1 ? "" : ", "}
                            </span>
                          )
                        })
                      }]
                    </td>
                  </tr>
                )
              })}
            </tbody>

          </Table>
        </div>

      </div>
    )
  }


  return (
    <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
      <div className="w-100">
        {isLoading ?
          <LoadingPage /> :
          <div className="row">
            <div className="col-8">
              {renderInputAndButtonUploadFile()}
              {csvData.rows.length > 0 && renderTable()}
            </div>
            {renderTableC1Category()}
            {renderTableC2Category()}
          </div>
        }
      </div>
    </div>
  );
};

export default CategorizeRisk;

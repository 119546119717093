import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_URLS } from "../../constants/appConstants";

interface AuthenticationRouteProp {
  component: JSX.Element;
  authed: boolean;
  [key: string]: any;
}

const AuthenticationRoute = (props: AuthenticationRouteProp) => {
  const navigate = useNavigate();
  let location: any = useLocation();
  let from = location?.state?.from?.pathname || APP_URLS.HOMEPAGE;

  useEffect(() => {
    if (props.authed) {
      navigate(`../${from}`);
    }
  }, [props.authed]);

  return props.component;
};

export default AuthenticationRoute;

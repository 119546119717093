import { COMPETITOR_API, GENEREATE_SCENARIOS_AND_PROBABILITY, SERCH_MAUDE, STANDZARD_OCCURENCE_SEVERITY } from '../../constants/api';
import axios from '../fetch/fetch';

export const standardOcurrenceAndSeverity = async (
    formData: FormData,
    session_id: string
): Promise<any> => {
    const res = await axios.post(`${STANDZARD_OCCURENCE_SEVERITY}/${session_id}`, formData);
    return res.data;
};

import React from "react";
import {
    TABLE_RISK_WITH_CHARACTERISTIC_ID,
} from "../constants";

import { CSVData, CSVDataTable } from "../../../Components/CSVTable/CSVTable";
import { AddedMissingRiskWithGeneratedMissingFields } from "../Tab_AddedMissingRisks/Tab_AddedMissingRisks";
import { renderPieChart } from "../../../Components/Chart_OriginalRisk_Added_Missing_Risks/PieChartOriginalRisksAndMisisngRisks";



const RISK_TABLE_NAME = "Risk";
interface Props {
    csvData: CSVData;
    addedMissingRisksWithGeneratedMissingFields: AddedMissingRiskWithGeneratedMissingFields[]
}
const Tab_Dashboard = (props: Props) => {
    const {
        csvData,
    } = props;

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CSVDataTable
                        tableName={RISK_TABLE_NAME}
                        csvData={csvData}
                        fileName={RISK_TABLE_NAME}
                        tableId={TABLE_RISK_WITH_CHARACTERISTIC_ID}
                        isDisplayingMissingRisks={true}
                        addedMissingRisksWithGeneratedMissingFields={props.addedMissingRisksWithGeneratedMissingFields}
                    />
                </div>
            </div>
        );
    };

   

    

    const renderTableWRiskWithCharacteristic = () => {
        return (
            <div className="row">
                <div className="col-9">
                    {csvData.rows.length > 0 && renderTable()}
                </div>
                <div className="col-3">
                    {csvData.rows.length > 0 && renderPieChart(csvData.rows.length, csvData.addedMissingRisks.length)}
                </div>
            </div>
        );
    };

    return renderTableWRiskWithCharacteristic();
};

export default Tab_Dashboard;

export const RISK_PAGE_CLASS_NAME = "risk-page";
export const HAZARD_LIST_RISK_TABLE_ID = "hazard-list-risk-table";
export const RISK_TABLE_ID = "risk-table";
export const TAB_NAME_CLASS = "tab-name";
export const TABS_CONTAINER_CLASS_NAME = "tab-container";
export const RISK_TABLE_CONTAINER = "tableContainer";
export const RISK_TABLE_CLASS_NAME = "risk-table";
export const RISK_CONTENT_CLASSNAME = "container row";
export const SCENARIO_TEXT = "Scenario";
export const JUSTIFICATION_TEXT = "Justification for Probability of Occurrence";
export const SEVERITY_TEXT = "Severity";
export const PROBABILITY_TEXT = "Probability";
export const RISK_RATING_TEXT = "Risk Rating";
export const MITIGATION_INHERENTLY_SAFE_DESIGN_TEXT =
  "Mitigation - Inherently Safe Design";
export const MITIGATION_PROTECTIVE_MEASURE_TEXT =
  "Mitigation - Protective Measure";
export const MITIGATION_INFORMATION_SAFETY_TEXT =
  "Mitigation - Information for Safety";
export const RISK_HAZARD_KEY = "hazard";
export const RISK_CHARACTERISTICS_KEY = "characteristics";
export const RISK_CATEGORIES_KEY = "categories";

export const GENERATE_PROBABILITY_TEXT = "Generate Probability";
export const GENERATE_MITIGATION_TEXT = "Generate Mitigation";
export const PROBABILITY_LOW = "Low";
export const PROBABILITY_MEDIUM = "Medium";
export const PROBABILITY_HIGH = "High";
export const COLOR_LISTS = [
  "rgba(255, 87, 51, 0.1)", // Red
  "rgba(51, 255, 87, 0.1)", // Green
  "rgba(51, 115, 255, 0.1)", // Blue
  "rgba(255, 51, 181, 0.1)", // Pink
  "rgba(51, 255, 247, 0.1)", // Cyan
  "rgba(230, 51, 255, 0.1)", // Purple
  "rgba(255, 196, 51, 0.1)", // Orange
  "rgba(51, 175, 255, 0.1)", // Sky Blue
  "rgba(255, 51, 51, 0.1)", // Bright Red
  "rgba(51, 255, 171, 0.1)", // Turquoise
  "rgba(163, 51, 255, 0.1)", // Violet
  "rgba(255, 136, 51, 0.1)", // Dark Orange
  "rgba(153, 255, 51, 0.1)", // Lime Green
  "rgba(51, 68, 255, 0.1)", // Indigo
  "rgba(255, 51, 121, 0.1)", // Fuchsia
  "rgba(51, 255, 203, 0.1)", // Aquamarine
  "rgba(204, 51, 255, 0.1)", // Electric Purple
  "rgba(255, 158, 51, 0.1)", // Gold
  "rgba(51, 255, 235, 0.1)", // Turquoise Blue
  "rgba(204, 51, 255, 0.1)", // Electric Purple
  "rgba(255, 71, 51, 0.1)", // Tomato
  "rgba(102, 255, 51, 0.1)", // Lime
  "rgba(51, 85, 255, 0.1)", // Ultramarine
  "rgba(255, 51, 243, 0.1)", // Bright Pink
  "rgba(51, 255, 159, 0.1)", // Light Green
  "rgba(204, 51, 255, 0.1)", // Electric Purple
  "rgba(255, 99, 51, 0.1)", // Coral
  "rgba(128, 255, 51, 0.1)", // Chartreuse
  "rgba(51, 102, 255, 0.1)", // Sapphire
  "rgba(255, 51, 203, 0.1)", // Hot Pink
  "rgba(51, 255, 123, 0.1)", // Spring Green
  "rgba(255, 158, 51, 0.1)", // Gold
  "rgba(51, 255, 219, 0.1)", // Medium Aquamarine
  "rgba(179, 51, 255, 0.1)", // Purple
  "rgba(255, 107, 51, 0.1)", // Orange-Red
  "rgba(179, 255, 51, 0.1)", // Yellow-Green
  "rgba(51, 119, 255, 0.1)", // Cornflower Blue
  "rgba(255, 51, 181, 0.1)", // Pink
  "rgba(51, 255, 203, 0.1)", // Aquamarine
  "rgba(204, 51, 255, 0.1)", // Electric Purple
  "rgba(255, 99, 51, 0.1)", // Coral
  "rgba(128, 255, 51, 0.1)", // Chartreuse
  "rgba(51, 102, 255, 0.1)", // Sapphire
  "rgba(255, 51, 203, 0.1)", // Hot Pink
  "rgba(51, 255, 123, 0.1)", // Spring Green
  "rgba(255, 158, 51, 0.1)", // Gold
  "rgba(51, 255, 219, 0.1)", // Medium Aquamarine
  "rgba(179, 51, 255, 0.1)", // Purple
  "rgba(255, 107, 51, 0.1)", // Orange-Red
  "rgba(179, 255, 51, 0.1)", // Yellow-Green
  "rgba(51, 119, 255, 0.1)", // Cornflower Blue
];

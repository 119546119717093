export const TOP_DOWN_CLASS_NAME = "top-down";
export const MAUDE_TABLE_SEARCH_RESULT_ID = "maude-table-search-result";
export const FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY = "hazard_master_list_maude";
export const FORM_DATA_GROUP_AND_SUB_GROUP_KEY = "groups_and_sub_groups";
export const FORM_DATA_INCIDENTS_KEY = "incidents";
export const TR_TAG = "tr";
export const TABLE_COLOR_BLACK = "black";
export const TABLE_COLOR_BLUE = "blue";
export const INPUT_UPLOAD_FILE_ID = "inputGroupFile02";
export const ERROR_MESSAGES_NO_FILE = "No file input";
export const ERROR_MESSAGES_NO_FILE_SELECTED = "No file selected";
export const FILE_KEY = "file";
export const UPLOAD_TEXT = "Upload";
export const ACCEPT_JSON_INPUT = "application/pdf";
export const INPUT_FILE_CLASS_NAME = "form-control";
export const INPUT_TYPE_FILE = "file";
export const INPUT_GROUP_CLASS_NAME = "input-group mb-3";
export const COMPETIOR_PAGE = "competitor"
export const TABLE_NAME_HAZARD = "Hazard";
export const ACCEPT_EXCEL_INPUT = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
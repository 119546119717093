import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuItem, Session } from "../../types/SessionDto";

export interface SessionState {
  menuSession: Session[];
  menuItems: MenuItem[];
}

const initialState: SessionState = {
  menuSession: [],
  menuItems: [],
};


const removeDuplicateSessions = (sessions: any[]) => {
  const map = new Map();
  sessions.forEach((session) => {
      map.set(session._id, session);
  });
  return Array.from(map.values());
}

export const sessionState = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMenuSession: (state, action: PayloadAction<Session[]>) => {
      state.menuSession = removeDuplicateSessions(action.payload);
    },
    setMenuItems: (state, action: PayloadAction<MenuItem[]>) => {
      state.menuItems = action.payload;
    },
  },
});

export const { setMenuSession, setMenuItems } = sessionState.actions;
export default sessionState.reducer;

import { SERCH_MAUDE } from "../../constants/api";
import axios from "../fetch/fetch";

export const searchMaude = async (
  keywords: string,
  brandName: string,
  sessionId: string
): Promise<any> => {
  const body = {
    keywords,
    brandName,
    sessionId,
  };
  const res = await axios.post(SERCH_MAUDE, body);
  return res.data;
};

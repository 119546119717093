import { fetchEventSource } from "@microsoft/fetch-event-source";
import { BLANK_STRING, HEADER_ACCEPT_TEXT_EVENT_STREAM_OBJECT, POST_ACTION } from "../constants/appConstants";
import { toast } from "react-toastify";

const MAX_TIMES_TO_RETRY = 3;
const ERROR_MESSAGE_NOTIFICATION = "Please try again later!";
export interface ParamsFetchEventSource {
    url: string;
    sessionIdFromUrl: string;
    onOpen?: () => void;
    onMessage: (fullMessage: string) => void;
    onClose: () => void;
    onError?: () => void;
    formDataKeyValue: {
        key: string;
        value: any;
    }[];
}
export const commonFetchEventSource = async (params: ParamsFetchEventSource, retryIdx = 0) => {
    try {
        const { url, sessionIdFromUrl, onMessage, onClose, formDataKeyValue, onOpen, onError } = params;
        const formData = new FormData();
        let fullMessage = BLANK_STRING;
        formDataKeyValue.forEach((item) => {
            formData.append(item.key, item.value);
        });

        await fetchEventSource(`${url}/${sessionIdFromUrl}`, {
            method: POST_ACTION,
            headers: HEADER_ACCEPT_TEXT_EVENT_STREAM_OBJECT,
            body: formData,
            onopen(res) {
                onOpen && onOpen();
                return Promise.resolve();
            },
            onmessage(event) {
                try {
                    fullMessage += event.data;
                    onMessage(fullMessage);
                } catch (error) {
                    if (retryIdx < MAX_TIMES_TO_RETRY) {
                        console.log("Retry fetchEventSource: ", retryIdx);
                        commonFetchEventSource(params, retryIdx + 1);
                    } else {
                        toast(ERROR_MESSAGE_NOTIFICATION, { type: "error" });
                    }
                    throw event;
                }
            },
            onclose() {
                onClose();
            },
            onerror(err) {
                if (retryIdx === MAX_TIMES_TO_RETRY) {
                    onError && onError();
                }
                throw err;
            },
        });
    } catch (error) {
        console.error("Error in fetchEventSource: ", error);
    }
};

import { GET_MENU_ITEMS } from "../../constants/api";
import axios from "../fetch/fetch";

export const getMenuItems = async (): Promise<any> => {
  try {
    console.log("getMenuItems", GET_MENU_ITEMS);
    const res = await axios.get(GET_MENU_ITEMS);
    return res.data;
  } 
  catch (error) {
    console.log(error);
    return [];
  }
};

import { LOG_IN_API } from "../../constants/api";
import axios from "../fetch/fetch";

export const loginAPI = async (accountType: string, authorizationCode: string, userData: { id: string; email: string }): Promise<any> => {
    const body = {
        accountType,
        authorizationCode,
        userData,
    };
    const response = await axios.post(LOG_IN_API, body);
    return response.data;
};

export const PAGE_DESIGN_WIDTH_IN_PIXEL = 1920;
export const RANDOM_SORTING_OFFSET = 0.5;
export const APP_URLS = {
    HOMEPAGE: "",
    TOP_DOWN: "product",
    BOTTOM_UP: "maude",
    COMBINE: "combine",
    SIGN_IN: "login",
    SIGN_IN_CALLBACK: "auth/callback",
    RISK: "risk",
    COMPETITOR: "competitor",
    FILE: "file",
    STANDARDIZE_OCCURRENCE_AND_SEVERITY: "standardize-occurrence-and-severity",
    GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE: "characteristics-for-safety",
    CATEGORIZE_RISK: "categorize-risk",
    MISSING_RISKS: "missing-risks",
    JIRA_RISK: "jira-design-input",
};
export const ID_PARAMS = "/:id";
export const TAB_ID_PARAMS = "/:tabId";
export const APP_PAGES_KEY = {
    TOP_DOWN: "topdown",
    BOTTOM_UP: "bottomup",
    COMBINE: "combine",
    RISK: "risk",
    COMPETITOR: "competitor",
};
export const LINK_PARAM = "link";

export const BLANK_STRING = "";
export const BLANK_ARRAY = "[]";
export const TARGET_TO_OPEN_NEW_TAB = "_blank";

export const COOKIE_MAX_AGE = 86400 * 30 * 1000; // 30 days
export const USER_ID_IN_COOKIE_KEY = "userId";
export const POPUP_CLASS = "training-system-popup";
export const MAUDE_SEARCH_RESULT_IN_DB = "maudeData";
export const SELECTED_PRODUCTS_IN_DB = "selectedProducts";
export const GROUP_AND_SUB_GROUP_IN_DB = "groupAndSubGroup";
export const HAZARD_MASTER_LIST_MAUDE_IN_DB = "hazardMasterListMaude";
export const HAZARD_LIST_IN_DB = "hazardList";
export const HAZARD_LIST_IN_COMBINE_PAGE = "hazardListInCombinePage";
export const HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX = "hazards_with_score";
export const HAZARD_TOP_DOWN_SORT_TYPE = "hazardListSortType";
export const PRODUCT_LIST_IN_TOPDOWN_PAGE = "product";
export const CHARACTERISTICS_LIST_IN_TOPDOWN_PAGE = "characteristics_for_safety_";
export const COMPETITOR_WARNINGS = "warnings";
export const COMPETITOR_CAUTIONS = "cautions";
export const INPUT_IN_TABLE_CALSS_NAME = "input-in-table";
export const PROCESS_DATA_TEXT = "Process Data";
export const GENERATE_TEXT = "Generate";
export const POST_ACTION = "POST";
const HEADER_ACCEPT_TEXT_EVENT_STREAM = "text/event-stream";
export const HEADER_ACCEPT_TEXT_EVENT_STREAM_OBJECT = {
    Accept: HEADER_ACCEPT_TEXT_EVENT_STREAM,
};
export const BOOTSTRAP_CONTAINER_FLUID = "container-fluid";
export const PREFIX_TO_CONVERT_DATA_TO_CSV_FILE = "text/csv;charset=utf-8;";
export const DEFAULT_INDEX_OF_RISK_SUMMARY_COLUMN = 1;
export const TIME_TO_AUTO_CLOSE_ALERT_IN_MS = 4000;
export const MISSING_RISKS_BACKGROUND_COLOR = "rgba(255,0,0,0.05)";
export const MISSING_RISKS_BACKGROUND_COLOR_20 = "rgba(255,0,0,0.2)";

export const IS_USE_CSV_DATA_SLICE_KEY = "isUseCSVDataSlice";
export const SPLIT_QUESTION_PART = "splitQuestionPart";

export const SYSTEM_PROMPT_TOKEN = 166;
export const MIN_TOKEN_PER_CALL_CHARACTERISTIC = 3000;
export const TOKEN_SPLIT_4_TIMES = 127000;
export const TOKEN_SPLIT_3_TIMES = 126500;
export const TOKEN_SPLIT_2_TIMES = 126000;
export const TOKEN_SPLIT_1_TIME = 124000;
export const ATLASSIAN_PROJECT_LINK = "https://pascallqms.atlassian.net";
export const MESSAGE_FOR_NOT_ALLOWED_SITE =
    "Access Denied: Your Atlassian Site credentials are currently not allowed to log in to our system. To request access, please contact the administrator via email at *admin@aimedrisk.com*. We apologize for any inconvenience.";

export const REDIRECT_AFTER_LOGIN_KEY = "redirectAfterLogin";

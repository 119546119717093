import { Session } from "../../types/SessionDto";
import { CREATE_SESSIONS } from "../../constants/api";
import axios from "../fetch/fetch";

export const createSessionAPI = async (
  sessionName: string,
  userId: string,
  menuItemId: string,
  startTime: string,
  endTime: string | null = null,
  sessionStatus: string = "Active"
): Promise<Session> => {
  const body = {
    sessionName,
    userId,
    menuItemId,
    startTime,
    endTime,
    sessionStatus,
  };

  const response = await axios.post(CREATE_SESSIONS, body);
  return response.data;
};

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { MISSING_RISKS_BACKGROUND_COLOR_20 } from '../../constants/appConstants';
export const renderPieChart = (numberOfOriginalRisks: number, numberOfAddedMissingRisks: number, nameOfMissingRisks: string = "Added Missing Risks") => {
    const options: Highcharts.Options = {
        title: {
            text: ''
        },
        chart: {
            type: "pie",
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.percentage:.0f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y} ({point.percentage:.0f}%)',
                    verticalAlign: 'top',
                    alignTo: 'plotEdges'
                }
            }
        },
        series: [{
            type: 'pie',
            data: [{
                name: 'Original Risks',
                y: numberOfOriginalRisks,
            }, {
                name: nameOfMissingRisks,
                y: numberOfAddedMissingRisks,
                color: MISSING_RISKS_BACKGROUND_COLOR_20
            }]
        }]
    };

    return <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />

}
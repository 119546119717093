import React, { useEffect, useRef, useState } from "react";
import { INPUT_IN_TABLE_CALSS_NAME, MISSING_RISKS_BACKGROUND_COLOR } from "../../../constants/appConstants";
import { HazardTopDownDto } from "../../../types/HazardTopDown";
import { HEIGHT_INHERIT, MIN_TEXTAREA_HEIGHT } from "../constants";
interface Props {
    data: HazardTopDownDto;
    index: number;
    handleCategoryChange: (category: string) => void;
    handleDescriptionChange: (description: string) => void;
    handleScoreChange: (score: string) => void;
    count?: number;
    isHighLight?: boolean;
    isShowMissingRisks: boolean;
    isEditable?: boolean;
}
const HazardRow = (props: Props) => {
    const [data, setData] = useState<HazardTopDownDto>(props.data);
    const {
        index,
        handleCategoryChange,
        handleDescriptionChange,
        handleScoreChange,
    } = props;
    useEffect(() => {
        setData(props.data);
        setCategory(props.data.category);
        setDescription(props.data.description);
    }, [props.data]);
    const textareaCategoryRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [category, setCategory] = useState<string>(data.category);
    
    React.useLayoutEffect(() => {
        if (props.isEditable) {
            textareaCategoryRef.current!.style.height = HEIGHT_INHERIT;
            textareaCategoryRef.current!.style.height = `${Math.max(
                textareaCategoryRef.current!.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [category]);

    const textareaDescriptionRef = React.useRef<HTMLTextAreaElement | null>(
        null
    );
    const [description, setDescription] = useState<string>(data.description);

    React.useLayoutEffect(() => {
        if (props.isEditable) {
            textareaDescriptionRef.current!.style.height = HEIGHT_INHERIT;
            textareaDescriptionRef.current!.style.height = `${Math.max(
                textareaDescriptionRef.current!.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
        
    }, [description]);

    return (
        <tr
            key={index}
            style={{
                backgroundColor: props.isHighLight
                    ? MISSING_RISKS_BACKGROUND_COLOR
                    : "white",
            }}
        >
            <td>{index + 1}</td>
            <td>
                {props.isEditable ? (
                    <textarea
                        className={INPUT_IN_TABLE_CALSS_NAME}
                        value={data.category}
                        ref={textareaCategoryRef}
                        onChange={(e) => {
                            handleCategoryChange(e.target.value);
                            setCategory(e.target.value);
                        }}
                    />
                ) : (
                    data.category
                )}
            </td>
            <td>
                {props.isEditable ? (
                    <textarea
                        ref={textareaDescriptionRef}
                        className={INPUT_IN_TABLE_CALSS_NAME}
                        value={data.description}
                        onChange={(e) => {
                            handleDescriptionChange(e.target.value);
                            setDescription(e.target.value);
                        }}
                    />
                ) : (
                    data.description
                )}
            </td>
            <td>
                {" "}
                {props.isEditable ? (
                    <input
                        className={INPUT_IN_TABLE_CALSS_NAME}
                        value={data.score}
                        onChange={(e) => handleScoreChange(e.target.value)}
                    />
                ) : (
                    data.score
                )}
            </td>
            {props.count != undefined && <td>{props.count}</td>}
            {props.isShowMissingRisks && (
                <td
                    style={{
                        textAlign: "left",
                    }}
                >
                    {data.missing_risks?.map((item, index) => (
                        <p key={index + item.risk}>
                            {index + 1}. {item.risk} ({"Severity: "} {item.severity}; {"Prob: "} {item.prob})
                        </p>
                    ))}
                </td>
            )}
        </tr>
    );
};
export default HazardRow;

import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ConnectedRouter from "./router/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastContainer } from "react-toastify";
import { TIME_TO_AUTO_CLOSE_ALERT_IN_MS } from "./constants/appConstants";

const App = () => {
    return (
        <BrowserRouter>
            <ConnectedRouter />
            <ToastContainer autoClose={TIME_TO_AUTO_CLOSE_ALERT_IN_MS} closeOnClick={false} position="bottom-right" />
        </BrowserRouter>
    );
};

export default App;

import { HazardTopDownState } from "../types/HazardTopDown";

export const HAZARD_TYPES: any = {
    "C.1.1": {
        category: "Energy hazards",
        hazards: {
            "Acoustic energy": {
                infrasound: null,
                "sound pressure": null,
                ultrasonic: null,
            },
        },
        "Electric energy": {
            "Electric fields": null,
            "Leakage current": {
                "earth leakage": null,
                "enclosure leakage": null,
            },
        },
        "Magnetic fields": null,
        "Static discharge": null,
        Voltage: null,
    },
    "C.1.2.1": {
        category: "Biological and chemical hazards",
        hazards: {
            "Biological agents": {
                Bacteria: null,
                Fungi: null,
                Parasites: null,
                Prions: null,
                Toxins: null,
                Viruses: null,
            },
            "Immunological agents": {
                Allergenic: {
                    "antiseptic substances": null,
                    latex: null,
                },
                Immunosuppressive: null,
                Irritants: {
                    "cleaning residues": null,
                },
                Sensitizing: null,
            },
        },
    },
    "C.1.2.2": {
        category: "Biological and chemical hazards",
        hazards: {
            "Chemical agents": {
                "Carcinogenic, mutagenic, reproductive": null,
                "Caustic, corrosive": {
                    acidic: null,
                    alkaline: null,
                    oxidants: null,
                },
                "Flammable, combustible, explosive": null,
                "Fumes, vapors": null,
                Osmotic: null,
                "Particles (including micro- and nano-particles)": null,
                Pyrogenic: null,
                Solvents: null,
                Toxic: {
                    asbestos: null,
                    "heavy metals": null,
                    "inorganic toxicants": null,
                    "organic toxicants": null,
                    silica: null,
                },
            },
        },
    },
    "C.1.3": {
        category: "Performance-related hazards",
        hazards: {
            Data: {
                access: null,
                availability: null,
                confidentiality: null,
                transfer: null,
                integrity: null,
            },
            Delivery: {
                quantity: null,
                rate: null,
            },
            "Diagnostic information": {
                "examination result": null,
                "image artifacts": null,
                "image orientation": null,
                "image resolution": null,
                "patient identity/information": null,
            },
            Functionality: {
                alarm: null,
                "critical performance": null,
                measurement: null,
            },
        },
    },
};

export const DEFAULT_HAZARDS_TABLE_DATA: HazardTopDownState = {
    hazards_with_score_1: [],
    hazards_with_score_2: [],
    hazards_with_score_3: [],
    hazards_with_score_4: [],
};

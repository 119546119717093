export const TOP_DOWN_CLASS_NAME = "top-down";
export const CATEGORY_KEY_OF_HAZARD_LIST = "hazard";
export const TABLE_HAZARD_PRODUCT_COLUMN_NAME = "hazard-product";
export const TABLE_HAZARD_PRODUCT_FILE_NAME = "Hazard-Product";
export const ERROR_MESSAGE_WHEN_GENERATING_DATA = "There was an error from server!";
export const HAZARD_CATEGORIES: any = {
    "1": "C.1.1",
    "2.1": "C.1.2",
    "2.2": "C.1.2",
    "3": "C.1.3",
};
export const HAZARD_TABLE_PARTS_LIST = ["1", "2", "3", "4"];

export const FORM_DATA_PART_KEY = "part";
export const FORM_DATA_SPLIT_KEY = "split";
export const FORM_DATA_PRODUCT_DESCRIPTION_KEY = "product_description";
export const FORM_DATA_CHARACTERISTIC_AFFECTING_SAFETY_KEY = "characteristics_affecting_safety";
export const HAZARD_CATEGORY_KEY = "category";
export const HAZARD_DESCRIPTION_KEY = "description";
export const HAZARD_SCORE_KEY = "score";

export const TOP_DOWN_ROW = "row top-down-row flex-nowrap";
export const HARMS_COLUMN_NAME = "Harms";
export const COLUMN_HARMS_WIDTH = "col-1";

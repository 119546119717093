import { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BLANK_STRING, MISSING_RISKS_BACKGROUND_COLOR } from "../../constants/appConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
    DEFAULT_COLOR_HIGHLIGHT_TEXT,
    INPUT_SEARCH_KEYWORDS_CLASSNAME,
    INPUT_SEARCH_PLACEHOLDER,
    MISSING_RISKS_COLUMN_CLASSNAME,
    ORIGINAL_MISSING_RISKS_TABLE_ID,
    REGEX_HIGHLIGHT_TEXT,
    TEXT_ALIGN_MISSING_RISKS,
} from "./constants";
import { AddedMissingRisk } from "../CSVTable/CSVTable";
import LoadingButton from "../LoadingButton/LoadingButton";
import MasterTable from "../MasterTable/MasterTable";
import { MissingRisk } from "../../types/HazardTopDown";
import './styles.css';
import { renderPieChart } from "../Chart_OriginalRisk_Added_Missing_Risks/PieChartOriginalRisksAndMisisngRisks";

export interface CategoryDataWithRisksAndMissingRisks {
    category: string;
    risks: OriginalRisksRowData[];
    missing_risks: MissingRisk[];
    score: string;
}

export interface OriginalRisksRowData {
    rowData: string[];
}

export interface OriginalRisks_MissingRisksProps {
    data: CategoryDataWithRisksAndMissingRisks[];
    risk_summary_column_index: number;
    headerTableData: string[];
    addedMissingRisks: AddedMissingRisk[];
    addMissingRisk: (risk: MissingRisk, cagetory: string) => void;
    isAddingAMissingRisksToRiskTable: boolean;
    name: string;
}
const OriginalRisks_MissingRisks = (props: OriginalRisks_MissingRisksProps) => {
    const [inputValue, setInputValue] = useState<string>(BLANK_STRING);
    const [searchText, setSearchText] = useState<string>(BLANK_STRING);
    const [filteredData, setFilteredData] = useState<CategoryDataWithRisksAndMissingRisks[]>(props.data);
    const [isFilteredData, setIsFilteredData] = useState<boolean>(false);
    useEffect(() => {
        const newFilteredData = getFilteredDataBySearchText(inputValue);
        const sortedData = newFilteredData.sort(sortByTotalRisks);
        setFilteredData(sortedData);
    }, []);
    const handleFilterDataBySearchText = () => {
        setSearchText(inputValue);
        const newFilteredData = getFilteredDataBySearchText(inputValue);
        const sortedData = newFilteredData.sort(sortByTotalRisks);
        setFilteredData(sortedData);
        if (inputValue) {
            setIsFilteredData(true);
        } else {
            setIsFilteredData(false);
        }
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Missing Risks</th>
                    {props.headerTableData.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const hasOriginalRisks = (item: CategoryDataWithRisksAndMissingRisks) => {
        return item.risks.length > 0;
    };

    const renderTheFirstOriginalRisksAsFirstRow = (item: CategoryDataWithRisksAndMissingRisks, rowIndex: number) => {
        let rowSpan = item.risks.length;
        if (rowSpan === 0) {
            rowSpan = 1;
        }
        return (
            <tr>
                <td rowSpan={rowSpan}>{rowIndex + 1}</td>
                {generateCategoryCell(item, rowSpan)}
                {generateMissingRisks(item, rowSpan)}
                {item.risks[0].rowData.map((item, index) => (
                    <td key={index}>{highlightText(item)}</td>
                ))}
            </tr>
        );
    };

    const renderCategoryWithEmptyRisks = (item: CategoryDataWithRisksAndMissingRisks, rowIndex: number) => {
        return (
            <tr>
                <td>{rowIndex + 1}</td>
                {generateCategoryCell(item)}
                {generateMissingRisks(item)}
                {props.headerTableData.map((header, index) => {
                    return <td key={index}>{}</td>;
                })}
            </tr>
        );
    };

    const renderTheRestRows = (item: CategoryDataWithRisksAndMissingRisks) => {
        return <>{item.risks.length > 1 && renderTheRestOfRowOfOriginalRisks(item)}</>;
    };

    const renderFirstRowWithRowSpan = (item: CategoryDataWithRisksAndMissingRisks, rowIndex: number) => {
        if (hasOriginalRisks(item)) {
            return renderTheFirstOriginalRisksAsFirstRow(item, rowIndex);
        }
        return renderCategoryWithEmptyRisks(item, rowIndex);
    };

    const renderCategoryData = (item: CategoryDataWithRisksAndMissingRisks, rowIndex: number) => {
        return (
            <>
                {renderFirstRowWithRowSpan(item, rowIndex)}
                {renderTheRestRows(item)}
            </>
        );
    };

    const sortByTotalRisks = (a: CategoryDataWithRisksAndMissingRisks, b: CategoryDataWithRisksAndMissingRisks) => {
        return getTotalRisks(a) - getTotalRisks(b);
    };

    const getTotalRisks = (item: CategoryDataWithRisksAndMissingRisks) => {
        return item.risks.length + item.missing_risks.length;
    };

    const getFilteredDataBySearchText = (searchText: string) => {
        return props.data.filter(
            (item) =>
                item.category.toLowerCase().includes(searchText.toLowerCase()) ||
                item.missing_risks.some((risk) => risk.risk?.toLowerCase().includes(searchText.toLowerCase())) ||
                item.risks.some((risk) => risk.rowData.join(" ").toLowerCase().includes(searchText.toLowerCase()))
        );
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {filteredData.map((item, index) => {
                    return renderCategoryData(item, index);
                })}
            </tbody>
        );
    };

    const generateCategoryCell = (item: CategoryDataWithRisksAndMissingRisks, rowSpan: number = 1) => {
        return (
            <td rowSpan={rowSpan} style={{
                width: "10%"
            }}>
                <p>
                    {highlightText(item.category)}[{item.score}]
                </p>
                { item.risks.length + item.missing_risks.length > 0 &&  renderPieChart(item.risks.length, item.missing_risks.length, "Missing Risks")}
                <p>Original Risks: {item.risks.length}</p>
                <p>Missing Risks: {item.missing_risks.length}</p>
            </td>
        );
    };

    const generateMissingRisks = (item: CategoryDataWithRisksAndMissingRisks, rowSpan: number = 1) => {
        return (
            <td
                rowSpan={rowSpan}
                style={{
                    textAlign: TEXT_ALIGN_MISSING_RISKS,
                }}
                className={MISSING_RISKS_COLUMN_CLASSNAME}
            >
                {item.missing_risks.map((risk, index) => {
                    const addedMissingRisks = findAddedMissingRisk(risk.risk);
                    return (
                        <p key={index}>
                            {index + 1}. {highlightText(getDisplayTextOfMissingRisk(risk))}
                            {addedMissingRisks
                                ? renderIssueKeyofAddedMissingRisk(addedMissingRisks)
                                : renderButtonAddMissingRiskToCSVTable(risk, item.category)}
                        </p>
                    );
                })}
            </td>
        );
    };

    const getDisplayTextOfMissingRisk = (risk: MissingRisk) => {
        if (!risk.risk) return "";
        return risk.risk + " (Severity: " + (risk.severity ? risk.severity : "")  + "; Prob: " + (risk.prob ? risk.prob : "") + ")";
    }

    const renderIssueKeyofAddedMissingRisk = (addedMissingRisk: AddedMissingRisk) => {
        return <span style={{ color: "red" }}> [{addedMissingRisk.issueKey}]</span>;
    };

    const renderButtonAddMissingRiskToCSVTable = (risk: MissingRisk, category: string) => {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="button-add-missing-risk-to-csv-table">
                        Add To Risk Table
                    </Tooltip>
                }
            >
            <Button 
                variant="outline-primary"
                onClick={() => props.addMissingRisk(risk, category)} 
                disabled={props.isAddingAMissingRisksToRiskTable}
            >
                {props.isAddingAMissingRisksToRiskTable ? <LoadingButton /> : "+"}
            </Button>
            </OverlayTrigger>
        );
    };

    const findAddedMissingRisk = (risk: string): AddedMissingRisk | undefined => {
        return props.addedMissingRisks ? props.addedMissingRisks.find((item) => item.risk === risk) : undefined;
    };

    const renderTheRestOfRowOfOriginalRisks = (item: CategoryDataWithRisksAndMissingRisks) => {
        return (
            <>
                {item.risks.slice(1).map((risk, index) => (
                    <tr key={index}>
                        {risk.rowData.map((item, index) => (
                            <td key={index}>{highlightText(item)}</td>
                        ))}
                    </tr>
                ))}
            </>
        );
    };

    const highlightText = (text: string) => {
        if (isFilteredData) {
            const parts = text.split(new RegExp(`(${searchText})`, REGEX_HIGHLIGHT_TEXT));
            return (
                <span>
                    {parts.map((part, i) =>
                        part.toLowerCase() === searchText.toLowerCase() ? (
                            <b key={i} style={{ background: DEFAULT_COLOR_HIGHLIGHT_TEXT }}>
                                {part}
                            </b>
                        ) : (
                            part
                        )
                    )}
                </span>
            );
        } else return text;
    };
    const renderInputSearchKeyword = () => {
        return (
            <InputGroup className={INPUT_SEARCH_KEYWORDS_CLASSNAME} style={{ marginTop: "1rem", marginLeft: "70%", width: "30%" }}>
                <FormControl
                    placeholder={INPUT_SEARCH_PLACEHOLDER}
                    onChange={(e) => setInputValue(e.currentTarget.value)}
                    value={inputValue}
                    onKeyDown={(e) => handleKeyDown(e.code)}
                />
                <Button onClick={handleFilterDataBySearchText}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
            </InputGroup>
        );
    };

    const handleKeyDown = (code: string) => {
        if (code === "Enter") {
            handleFilterDataBySearchText();
        }
    };

    const renderTable = () => {
        return (
            <div
                style={{
                    marginTop: "1rem",
                    overflow: "scroll",
                    height: "90vh",
                }}
            >
                <MasterTable
                    data={props.data}
                    isGeneratingData={false}
                    id={ORIGINAL_MISSING_RISKS_TABLE_ID + props.name}
                    fileName={ORIGINAL_MISSING_RISKS_TABLE_ID + props.name}
                >
                    <>
                        {renderTableHeader()}
                        {renderTableBody()}
                    </>
                </MasterTable>
            </div>
        );
    };

    return (
        <div>
            {renderInputSearchKeyword()}
            {renderTable()}
        </div>
    );
};

export default OriginalRisks_MissingRisks;

import { Hazard } from "../../types/RiskDataType";

export const MOCK_HAZARD_LIST: Hazard[] = [
  {
    hazard: "Risk of inaccurate patient monitoring",
    severity: "4. Critical",
  },
  {
    hazard: "Potential for incorrect treatment decisions",
    severity: "5. Catastrophic",
  },
  {
    hazard: "Risk of delayed patient monitoring",
    severity: "4. Critical",
  },
  {
    hazard: "Risk of patient injury",
    severity: "4. Critical",
  },
];

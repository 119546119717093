import { PAGE_DESIGN_WIDTH_IN_PIXEL } from "../../constants/appConstants";

export const PRODUCT_COLUMN_CLASS_NAME = "product-column";
export const PRODUCT_COLUMN_TEXT = "Product";
export const NAV_LEFT_MENU_ITEM = "left-menu-item";
export const DEFAULT_BACKGROUND_COLOR_OF_MENU_ITEM = "white";
export const BACKGROUND_COLOR_OF_SELECTED_ITEM = "antiquewhite";
export const PRODUCT_COLUMN_NAME = "product-column-name";
export const PRODUCT_COLUMN_INPUT = "mb-3 product-column-input";
export const PRODUCT_INPUT_PLACE_HOLDER = "Insert your product";
export const TEXTAREA_INPUT_TYPE = "textarea";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logoutThunk } from "../../store/auth/thunkMiddlewares";
import { useAppDispatch } from "../../store/hooks";
import classes from "./styles.module.css";

const LogoutButton = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClickLogoutBtn = () => {
        dispatch(logoutThunk(navigate));
    };

    return (
        <Button className={classes["logout-btn"]} onClick={onClickLogoutBtn}>
            Logout
        </Button>
    );
};

export default LogoutButton;

import React, { useEffect, useState } from "react";
import {
    ACCEPT_EXCEL_INPUT,
    CHARACTERISTICS_KEY_FOR_MISSING_RISK,
    ERROR_MESSAGES_NO_FILE,
    ERROR_MESSAGES_NO_FILE_SELECTED,
    FILE_KEY,
    INPUT_FILE_CLASS_NAME,
    INPUT_GROUP_CLASS_NAME,
    INPUT_TYPE_FILE,
    INPUT_UPLOAD_FILE_ID,
    TABLE_NAME_HAZARD,
    TABLE_RISK_WITH_CHARACTERISTIC_ID,
    UPLOAD_TEXT,
} from "../constants";
import {
    BLANK_STRING,
    IS_USE_CSV_DATA_SLICE_KEY,
    MIN_TOKEN_PER_CALL_CHARACTERISTIC,
    SPLIT_QUESTION_PART,
    SYSTEM_PROMPT_TOKEN,
    TOKEN_SPLIT_1_TIME,
    TOKEN_SPLIT_2_TIMES,
    TOKEN_SPLIT_3_TIMES,
} from "../../../constants/appConstants";
import { Button, Form, InputGroup } from "react-bootstrap";

import LoadingButton from "../../../Components/LoadingButton/LoadingButton";

import { CSVData, CSVDataTable } from "../../../Components/CSVTable/CSVTable";
import { readExcelFileData } from "../../../apiRequests/generateCharacteristicsForSafetyFromRisk/readExcelFileData";

import {
    convertIncompleteJSONToCompleteJSON,
    getSessionIdFromURL,
    saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase,
} from "../../../utils/commonUtils";
import { GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE, TOP_DOWN_GENERATE_HAZARD_API } from "../../../constants/api";
import { CSVDataToText, convertCSVToString } from "../../../utils/convertToCSVDataInString";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../../utils/commonFetchEventSource";
import ColumnCharacteristics from "../../../Components/ColumnCharacteristics/ColumnCharacteristics";
import { HazardTopDownDto } from "../../../types/HazardTopDown";
import { FORM_DATA_CHARACTERISTIC_AFFECTING_SAFETY_KEY, FORM_DATA_PART_KEY, HAZARD_TABLE_PARTS_LIST } from "../../TopDown/constants";
import { QUESTION_ANSWER_OPTIONS } from "../../../Components/ColumnCharacteristics/constants";
import { findIndexOfRiskSummaryColumnInCSVFile, updateIssueKeyPrefixInCSVDataAPI } from "../../../apiRequests/missingRisks/missingRisks";

import { renderColumnHazardWithScore } from "../Categories_MissingRisks";
import { CategoryRiskTableData } from "../../../Components/CategoryRiskTable/Table/CategoryRiskTable";
import { deletePageStateDetailAPI } from "../../../apiRequests/pageStates/pagestate";
import { encode } from "gpt-tokenizer";
import { useSaveDataToDatabase } from "../../../hooks/useSaveDataToDatabase";
import { CharacteristicQuestionDto } from "../../../types/CharacteristicQuestion";

const RISK_TABLE_NAME = "Risk";
interface Props {
    csvData: CSVData;
    characteristic: CharacteristicQuestionDto[][];
    setCharacteristic: React.Dispatch<React.SetStateAction<CharacteristicQuestionDto[][]>>;
    hazardStateOfC1: HazardTopDownDto[];
    isGeneratingData: boolean;
    issueKeyPrefix: string;
    isDisableButton: boolean;
    setCSVDataWithRelateAttributes: (csvData: CSVData) => void;
    setIsGeneratingData: (isGeneratingData: boolean) => void;
    setIssueKeyPrefix: (issueKeyPrefix: string) => void;
    setHazardStateOfC1: React.Dispatch<React.SetStateAction<HazardTopDownDto[]>>;
    setIsDisableButton: (isDisableButton: boolean) => void;
    removeCharacteristicsForSafetyPageState: () => void;
    removeStateData: () => void;
    isUseCSVDataSlice: boolean;
    setIsUseCSVDataSlice: (isUseCSVDataSlice: boolean) => void;
    splitQuestionPart: number;
    setSpitQuestionPart: (splitQuestionPart: number) => void;
}
const Tab_CharacteristicsForSafety = (props: Props) => {
    const {
        csvData,
        characteristic,
        setCharacteristic,
        hazardStateOfC1,
        isGeneratingData,
        setCSVDataWithRelateAttributes,
        setIsGeneratingData,
        issueKeyPrefix,
        setIssueKeyPrefix,
        setHazardStateOfC1,
        setIsDisableButton,
        isDisableButton,
        removeCharacteristicsForSafetyPageState,
        removeStateData,
        setIsUseCSVDataSlice,
        isUseCSVDataSlice,
        splitQuestionPart,
        setSpitQuestionPart,
    } = props;
    const sessionIdFromUrl = getSessionIdFromURL();

    useEffect(() => {
        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
    }, [sessionIdFromUrl]);
    const [characteristicsPart, setCharacteristicsPart] = useState<number>(0);
    const [streamingCharacteristicsForSafetyFromRisk, setStreamingCharacteristicsForSafetyFromRisk] = useState<string>(BLANK_STRING);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const saveDataToDatabase = useSaveDataToDatabase();
    const renderInputAndButtonUploadFile = () => {
        return (
            <div className="row">
                <div className={INPUT_GROUP_CLASS_NAME}>
                    <input
                        type={INPUT_TYPE_FILE}
                        className={INPUT_FILE_CLASS_NAME}
                        id={INPUT_UPLOAD_FILE_ID}
                        accept={ACCEPT_EXCEL_INPUT}
                        onChange={handleFileInputChange}
                    />
                    <Button onClick={handleUploadButtonClick} disabled={!isFileSelected || isGeneratingData}>
                        {isGeneratingData ? <LoadingButton /> : UPLOAD_TEXT}
                    </Button>
                </div>
            </div>
        );
    };

    const handleUploadButtonClick = async () => {
        const fileInput = document.getElementById(INPUT_UPLOAD_FILE_ID) as HTMLInputElement;
        if (!fileInput) return console.error(ERROR_MESSAGES_NO_FILE);
        if (!fileInput.files) return console.error(ERROR_MESSAGES_NO_FILE);
        const file = fileInput.files[0];
        if (!file) {
            console.error(ERROR_MESSAGES_NO_FILE_SELECTED);
            return;
        }
        removeStateData();
        await deletePageStateDetailAPI(sessionIdFromUrl);
        const formData = new FormData();
        formData.append(FILE_KEY, file);
        setIsGeneratingData(true);
        try {
            let data: CategoryRiskTableData = await readExcelFileData(formData, sessionIdFromUrl);
            if (data.rows) {
                const indexOfRiskSummaryColumn: number = await findIndexOfRiskSummaryColumnInCSVFile(sessionIdFromUrl, data.header);
                data.riskSummaryColumnIndex = indexOfRiskSummaryColumn;
                setCSVDataWithRelateAttributes(data);
            }
        } catch (e) {
            console.log(e);
        }
        setIsGeneratingData(false);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFileSelected(!!event.target.files?.length);
    };

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CSVDataTable
                        tableName={RISK_TABLE_NAME}
                        csvData={csvData}
                        fileName={RISK_TABLE_NAME}
                        tableId={TABLE_RISK_WITH_CHARACTERISTIC_ID}
                        addedMissingRisksWithGeneratedMissingFields={[]}
                    />
                </div>
            </div>
        );
    };

    const renderButtonGenerateCharacteristicsForSafetyFromRisk = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Button
                        onClick={handleGenerateCharacteristicsForSafetyFromRiskButtonClick}
                        disabled={isGeneratingData || csvData.rows.length === 0}
                    >
                        {isGeneratingData ? <LoadingButton /> : "Generate Characteristics for Safety from Risk"}
                    </Button>
                </div>
            </div>
        );
    };

    const handleGenerateCharacteristicsForSafetyFromRiskButtonClick = async () => {
        setIsGeneratingData(true);
        removeCharacteristicsForSafetyPageState();
        let split = 0;
        const tokens = encode(JSON.stringify(convertCSVToString(csvData)));
        const totalTokens = tokens.length + SYSTEM_PROMPT_TOKEN;

        if (totalTokens < TOKEN_SPLIT_3_TIMES) {
            if (totalTokens < TOKEN_SPLIT_1_TIME) {
                split = 1;
            } else if (totalTokens < TOKEN_SPLIT_2_TIMES) {
                split = 2;
            } else if (totalTokens >= TOKEN_SPLIT_3_TIMES) {
                split = 3;
            }
            fetchCharacteristicsForSafetyFromRisk(split, 1, 0, false, totalTokens);
            saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase(saveDataToDatabase, sessionIdFromUrl, split, BLANK_STRING);
            setIsUseCSVDataSlice(false);
            setSpitQuestionPart(split);
        } else {
            let maxTokenPerCall = 0;

            if (totalTokens % TOKEN_SPLIT_3_TIMES > MIN_TOKEN_PER_CALL_CHARACTERISTIC) {
                split = 3;
                maxTokenPerCall = TOKEN_SPLIT_3_TIMES;
            } else {
                split = 2;
                maxTokenPerCall = TOKEN_SPLIT_2_TIMES;
            }

            fetchCharacteristicsForSafetyFromRisk(split, 1, 0, true, maxTokenPerCall);
            saveSplitQuestionPartAndIsUseCsvDataSliceToDatabase(saveDataToDatabase, sessionIdFromUrl, split, "true");
            setIsUseCSVDataSlice(true);
            setSpitQuestionPart(split);
        }
    };
    const fetchCharacteristicsForSafetyFromRisk = async (
        split: number,
        part: number,
        startRowIndex: number,
        isUseCSVDataSlice: boolean,
        maxTokenPerCall: number,
        partCsv: number = 0
    ) => {
        let formDataKeyValue = [];
        let csvDataToProcess: CSVDataToText = {
            header: [],
            rows: [],
        };
        let endRowIndex = 0;

        if (isUseCSVDataSlice) {
            const numberOfRowsEachQuery = computeMaximumNumberOfRowsToConvert(startRowIndex, maxTokenPerCall);
            endRowIndex = startRowIndex + numberOfRowsEachQuery;
            csvDataToProcess = sliceAndConvertToCsvData(startRowIndex, endRowIndex);
            formDataKeyValue.push({ key: "riskData", value: JSON.stringify(convertCSVToString(csvDataToProcess)) });
        } else {
            formDataKeyValue.push({ key: "riskData", value: JSON.stringify(convertCSVToString(csvData)) });
        }

        formDataKeyValue.push({ key: "part", value: (part + partCsv).toString() }, { key: "split", value: split.toString() });
        setCharacteristicsPart(part + partCsv);
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_CHARACTERISTICS_FOR_SAFETY_FROM_RISK_TABLE,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                setStreamingCharacteristicsForSafetyFromRisk(fullMessage);
            },
            onClose: function (): void {
                part = part + 1;

                if (isUseCSVDataSlice) {
                    if (part <= split) {
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, part, startRowIndex, true, maxTokenPerCall, partCsv);
                    } else if (stillHaveDataToProcess(endRowIndex)) {
                        partCsv = part - 1;
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, 1, endRowIndex, true, maxTokenPerCall, partCsv);
                    } else {
                        setIsGeneratingData(false);
                    }
                } else {
                    if (part <= split) {
                        setStreamingCharacteristicsForSafetyFromRisk(BLANK_STRING);
                        fetchCharacteristicsForSafetyFromRisk(split, part, 0, false, maxTokenPerCall);
                    } else {
                        setIsGeneratingData(false);
                    }
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: formDataKeyValue,
        };

        commonFetchEventSource(paramsFetchEventSource);
    };

    const computeMaximumNumberOfRowsToConvert = (startRowIndex: number, maxTokenPerCall: number) => {
        for (let i = startRowIndex; i <= csvData.rows.length - 1; i++) {
            const csvDataToProcess: CSVDataToText = sliceAndConvertToCsvData(startRowIndex, i);
            const tokens = encode(JSON.stringify(convertCSVToString(csvDataToProcess, startRowIndex)));
            const tokensLength = tokens.length;

            if (tokensLength > maxTokenPerCall) {
                return i - 1 - startRowIndex;
            }
        }
        return csvData.rows.length - startRowIndex;
    };

    const sliceAndConvertToCsvData = (startIndex: number, endIndex: number): CSVDataToText => {
        const rows = csvData.rows.slice(startIndex, endIndex);
        const csvDataToProcess: CSVDataToText = {
            header: csvData.header,
            rows: rows,
        };
        return csvDataToProcess;
    };
    const stillHaveDataToProcess = (endRowIndex: number) => {
        return endRowIndex < csvData.rows.length;
    };

    const renderButtonGenerateHazardList = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Button onClick={scoreSubcategoriesInC1Table} disabled={isGeneratingData || isDisableButton}>
                        {isGeneratingData ? <LoadingButton /> : "Generate Hazards"}
                    </Button>
                </div>
            </div>
        );
    };

    const scoreSubcategoriesInC1Table = () => {
        let questionListMergedExplanation = [];
        if (characteristic.length > 1) {
            const flatCharacteristic = characteristic.flat();

            const mappedCharacteristic = flatCharacteristic.map((item: any) => {
                return {
                    questionID: item.questionID,
                    questionText: item.questionText,
                    answer: item.answer,
                    explanation: item.explanation,
                    isSelected: item.isSelected,
                };
            });

            const filteredDataAnswerYesAndSelected = mappedCharacteristic.filter((item) => {
                if (item.answer !== QUESTION_ANSWER_OPTIONS.YES) return false;
                console.log(item);
                const sameIdQuestions = flatCharacteristic.filter((question) => question.questionID === item.questionID);
                const isAnyAnswerYes = sameIdQuestions.some((question) => question.answer === QUESTION_ANSWER_OPTIONS.YES);
                return !isAnyAnswerYes || item.isSelected;
            });
            questionListMergedExplanation = characteristic[0]
                .map((item) => {
                    const listFilteredQuestionSameId = filteredDataAnswerYesAndSelected
                        .filter((question) => item.questionID === question.questionID)
                        .map((item) => item.explanation);
                    if (listFilteredQuestionSameId.length) {
                        return {
                            ...item,
                            explanation: listFilteredQuestionSameId.join(" - "),
                            answer: QUESTION_ANSWER_OPTIONS.YES,
                        };
                    } else {
                        return {
                            ...item,
                            answer: QUESTION_ANSWER_OPTIONS.NO,
                        };
                    }
                })
                .filter((item) => item.answer === QUESTION_ANSWER_OPTIONS.YES)
                .map((question) => {
                    return {
                        questionID: question.questionID,
                        questionText: question.questionText,
                        answer: question.answer,
                        explanation: question.explanation,
                    };
                });
        } else {
            questionListMergedExplanation = characteristic[0]
                .filter((item) => item.answer === QUESTION_ANSWER_OPTIONS.YES)
                .map((question) => {
                    return {
                        questionID: question.questionID,
                        questionText: question.questionText,
                        answer: question.answer,
                        explanation: question.explanation,
                    };
                });
        }
        setHazardStateOfC1([]);
        fetchAllHazardData(JSON.stringify(questionListMergedExplanation));
    };

    const fetchAllHazardData = async (characteristic: string) => {
        fetchHazardData(characteristic);
    };

    const [hazardInString, setHazardInString] = useState<string[]>([]);

    const fetchHazardData = async (characteristic: string, partIndex: number = 0) => {
        const part = HAZARD_TABLE_PARTS_LIST[partIndex];
        let prevHazard: HazardTopDownDto[] = [];
        for (let i = 0; i < partIndex && i < hazardInString.length; i++) {
            let parsedArray = JSON.parse(hazardInString[i]);
            prevHazard = prevHazard.concat(parsedArray);
        }
        let fullMessagePart = BLANK_STRING;
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: TOP_DOWN_GENERATE_HAZARD_API,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                fullMessagePart = BLANK_STRING;
                setIsGeneratingData(true);
                setIsDisableButton(true);
            },
            onMessage: function (fullMessage: string): void {
                fullMessagePart = fullMessage;
                const outputFormat = [{ category: "", description: "", score: 0 }];
                const parsedData = convertIncompleteJSONToCompleteJSON(fullMessage, outputFormat, []);
                setHazardStateOfC1([...prevHazard, ...parsedData]);
            },
            onClose: async function () {
                setHazardInString((prevState) => {
                    prevState[partIndex] = fullMessagePart;
                    return prevState;
                });
                fullMessagePart = BLANK_STRING;
                partIndex = partIndex + 1;
                if (partIndex >= HAZARD_TABLE_PARTS_LIST.length) {
                    setIsDisableButton(false);
                    setIsGeneratingData(false);
                    setHazardInString([]);
                } else {
                    await fetchHazardData(characteristic, partIndex);
                }
            },
            onError: () => {
                setIsDisableButton(false);
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_CHARACTERISTIC_AFFECTING_SAFETY_KEY,
                    value: JSON.stringify(characteristic),
                },
                {
                    key: FORM_DATA_PART_KEY,
                    value: part.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const updateIssueKeyPrefix = async () => {
        try {
            setIsGeneratingData(true);
            await updateIssueKeyPrefixInCSVDataAPI(sessionIdFromUrl, issueKeyPrefix);
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
        setIsGeneratingData(false);
    };

    const IsNotChangingIssueKeyPrefix = () => {
        return issueKeyPrefix.trim() === csvData.key_of_issue_key.trim();
    };

    const renderButtonUpdateIssueKeyPrefix = () => {
        return (
            <Button onClick={updateIssueKeyPrefix} disabled={isGeneratingData || IsNotChangingIssueKeyPrefix()}>
                {isGeneratingData ? <LoadingButton /> : "Update"}
            </Button>
        );
    };

    const renderInputToUpdateIssueKeyPrefix = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">Issue Key Prefix</InputGroup.Text>
                        <Form.Control
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={issueKeyPrefix}
                            onChange={(e) => setIssueKeyPrefix(e.target.value)}
                        />
                        {renderButtonUpdateIssueKeyPrefix()}
                    </InputGroup>
                </div>
            </div>
        );
    };

    const renderTableWRiskWithCharacteristic = () => {
        return (
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">{renderInputAndButtonUploadFile()}</div>
                        <div className="col-6">{csvData.is_missing_issue_key && renderInputToUpdateIssueKeyPrefix()}</div>
                    </div>
                    {csvData.rows.length > 0 && renderTable()}
                </div>
                <div className="col-3">
                    {renderButtonGenerateCharacteristicsForSafetyFromRisk()}
                    <ColumnCharacteristics
                        data={streamingCharacteristicsForSafetyFromRisk}
                        dataInList={characteristic}
                        setDataInList={setCharacteristic}
                        sessionId={sessionIdFromUrl}
                        onGenerate={() => {}}
                        isDisableButton={false}
                        isGeneratingHazardTable={false}
                        needToGenerateHazard={false}
                        isUseCSVDataSlice={isUseCSVDataSlice}
                        part={characteristicsPart}
                        splitQuestionPart={splitQuestionPart}
                        keyOfPage={CHARACTERISTICS_KEY_FOR_MISSING_RISK}
                    />
                </div>
                <div className="col-3">
                    {renderButtonGenerateHazardList()}
                    {renderColumnHazardWithScore(hazardStateOfC1, [], false, false, TABLE_NAME_HAZARD, TABLE_NAME_HAZARD)}
                </div>
            </div>
        );
    };

    return renderTableWRiskWithCharacteristic();
};

export default Tab_CharacteristicsForSafety;

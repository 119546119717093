import React, { useState, useEffect } from "react";
import "./style.css";
import {
    ACCEPT_EXCEL_INPUT,
    ERROR_MESSAGES_NO_FILE,
    ERROR_MESSAGES_NO_FILE_SELECTED,
    FILE_KEY,
    INPUT_FILE_CLASS_NAME,
    INPUT_GROUP_CLASS_NAME,
    INPUT_TYPE_FILE,
    INPUT_UPLOAD_FILE_ID,
    TABLE_NAME_HAZARD,
    STANDARDIZE_OCCURERENCE_AND_SEVERITY_TABLE_ID,
    STANDARDIZE_OCCURERENCE_AND_SEVERITY_FILE_NAME,
    TOP_DOWN_CLASS_NAME,
    UPLOAD_TEXT,
} from "./constants";
import { BOOTSTRAP_CONTAINER_FLUID, BLANK_STRING } from "../../constants/appConstants";
import { Button } from "react-bootstrap";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { standardOcurrenceAndSeverity } from "../../apiRequests/standardOcurrenceAndSeverity/standardOcurrenceAndSeverity";
import { CSVData, CSVDataTable, DEFAULT_CSV_DATA } from "../../Components/CSVTable/CSVTable";

const StandardizeOccurenceAndSeverity = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGeneratingData, setIsGeneratingData] = useState<boolean>(false);
    const sessionIdFromUrl = window.location.pathname.split("/")[2];
    const [csvData, setCsvData] = useState<CSVData>({ ...DEFAULT_CSV_DATA });
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

    useEffect(() => {
        clearCSVData();
        const loadSessionData = async () => {
            setIsLoading(true);
            try {
                const data = await getDataFromAPI({ ...DEFAULT_CSV_DATA });
                setCsvData(data);
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        };
        loadSessionData();
    }, [sessionIdFromUrl]);

    const getDataFromAPI = async (defaultData: any): Promise<CSVData> => {
        try {
            const stateData = await loadPageState(sessionIdFromUrl);
            const data = stateData.stateDetail.csvData;
            if (data && data.rows && data.header) {
                return data;
            }
            return defaultData;
        } catch (e) {
            return defaultData;
        }
    };

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <CSVDataTable
                        tableId={STANDARDIZE_OCCURERENCE_AND_SEVERITY_TABLE_ID}
                        tableName={TABLE_NAME_HAZARD}
                        csvData={csvData}
                        fileName={STANDARDIZE_OCCURERENCE_AND_SEVERITY_FILE_NAME}
                        addedMissingRisksWithGeneratedMissingFields={[]}
                    />
                </div>
            </div>
        );
    };

    const clearCSVData = () => {
        setCsvData({ ...DEFAULT_CSV_DATA });
    };

    const renderInputAndButtonUploadFile = () => {
        return (
            <div className="col-6">
                <div className={INPUT_GROUP_CLASS_NAME}>
                    <input
                        type={INPUT_TYPE_FILE}
                        className={INPUT_FILE_CLASS_NAME}
                        id={INPUT_UPLOAD_FILE_ID}
                        accept={ACCEPT_EXCEL_INPUT}
                        onChange={handleFileInputChange}
                    />
                    <Button onClick={handleUploadButtonClick} disabled={!isFileSelected || isGeneratingData}>
                        {isGeneratingData ? <LoadingButton /> : UPLOAD_TEXT}
                    </Button>
                </div>
            </div>
        );
    };

    const handleUploadButtonClick = async () => {
        const fileInput = document.getElementById(INPUT_UPLOAD_FILE_ID) as HTMLInputElement;
        if (!fileInput) return console.error(ERROR_MESSAGES_NO_FILE);
        if (!fileInput.files) return console.error(ERROR_MESSAGES_NO_FILE);
        const file = fileInput.files[0];
        if (!file) {
            console.error(ERROR_MESSAGES_NO_FILE_SELECTED);
            return;
        }
        setCsvData({ ...DEFAULT_CSV_DATA });
        const formData = new FormData();
        formData.append(FILE_KEY, file);
        setIsGeneratingData(true);
        try {
            const data = await standardOcurrenceAndSeverity(formData, sessionIdFromUrl);
            setCsvData(data);
        } catch (e) {
            console.log(e);
        }
        setIsGeneratingData(false);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFileSelected(!!event.target.files?.length);
    };

    return (
        <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>
            <div className="row w-100">
                {isLoading ? (
                    <LoadingPage />
                ) : (
                    <div className="row">
                        {renderInputAndButtonUploadFile()}
                        {csvData.rows.length > 0 && renderTable()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StandardizeOccurenceAndSeverity;

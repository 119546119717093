import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { AddedMissingRisk, CSVData } from "../CSVTable/CSVTable";
import MasterTable from "../MasterTable/MasterTable";
import { AddedMissingRiskWithGeneratedMissingFields } from "../../pages/MissingRisks/Tab_AddedMissingRisks/Tab_AddedMissingRisks";
import { ATLASSIAN_PROJECT_LINK, MISSING_RISKS_BACKGROUND_COLOR } from "../../constants/appConstants";
import { getDataOfAddedMissingRisks } from "../../utils/commonUtils";
import { MitigationForJira } from "../../pages/MissingRisks/MissingRisks";
import LoadingButton from "../LoadingButton/LoadingButton";

export interface CSVDataWithMitiagtionProps {
    csvData: CSVData;
    mitigationData: MitigationData[];
    isDisplayOriginalRisk: boolean;
    isDisplayAddedMissingRisk: boolean;
    id: string;
    fileName: string;
    addedMissingRisksWithGeneratedMissingFields: AddedMissingRiskWithGeneratedMissingFields[];
    mitigationForJira: MitigationForJira[];
    setMitigationForJira: (mitigation: MitigationForJira[]) => void;
    isAddingOrRemovingMitigationForJira: boolean;
    setIsAddingOrRemovingMitigationForJira: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface MitigationData {
    issueKey: string;
    mitigation_inherently_safe_design: string;
    mitigation_protective_measure: string;
    mitigation_information_for_safety: string;
}
const CSVDataWithMitigation = (props: CSVDataWithMitiagtionProps) => {
    const isRenderProbability = props.csvData.probability_and_severity_column_index.probability === -1;
    const isRenderSeverity = props.csvData.probability_and_severity_column_index.severity === -1;
    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    {props.csvData.header.map((key: string, index: number) => (
                        <th key={index}>{key}</th>
                    ))}
                    {isRenderProbability && <th>Severity</th>}
                    {isRenderSeverity && <th>Probability</th>}
                    <th>Mitigation Inherently Safe Design</th>
                    <th>Mitigation Protective Measure</th>
                    <th>Mitigation Information for Safety</th>
                </tr>
            </thead>
        );
    };

    const renderRow = (rowIndex: number, addedMissingRisk: AddedMissingRisk) => {
        const row = getDataOfAddedMissingRisks(addedMissingRisk, props.addedMissingRisksWithGeneratedMissingFields);
        const mitigationData = findMigitationData(row[props.csvData.column_issue_key_index]);
        return (
            <tr
                key={rowIndex}
                style={{
                    backgroundColor: MISSING_RISKS_BACKGROUND_COLOR,
                }}
            >
                <td>{rowIndex + 1}</td>
                {row.map((cell: string, index: number) => {
                    return <td key={index}>{cell}</td>;
                })}
                {isRenderProbability && renderSeverityOfAddedMissingRisk(addedMissingRisk)}
                {isRenderSeverity && renderProbabilityOfAddedMissingRisk(addedMissingRisk)}
                {mitigationData ? (
                    <td>
                        {mitigationData.mitigation_inherently_safe_design}
                        {renderButtonToAddOrRemoveMitigationOnJira(
                            mitigationData.mitigation_inherently_safe_design,
                            "mitigation_inherently_safe_design",
                            row
                        )}
                    </td>
                ) : (
                    <td></td>
                )}
                {mitigationData ? (
                    <td>
                        {mitigationData.mitigation_protective_measure}
                        {renderButtonToAddOrRemoveMitigationOnJira(
                            mitigationData.mitigation_protective_measure,
                            "mitigation_protective_measure",
                            row
                        )}
                    </td>
                ) : (
                    <td></td>
                )}
                {mitigationData ? (
                    <td>
                        {mitigationData.mitigation_information_for_safety}
                        {renderButtonToAddOrRemoveMitigationOnJira(
                            mitigationData.mitigation_information_for_safety,
                            "mitigation_information_for_safety",
                            row
                        )}
                    </td>
                ) : (
                    <td></td>
                )}
            </tr>
        );
    };
    const isMitigationDataExist = (mitigationData: MitigationForJira[], mitigation: string, type: string) => {
        return mitigationData.some((item) => item.mitigation === mitigation && item.type === type);
    };

    const findMitigationCreatedJiraIssue = (mitigationData: MitigationForJira[], mitigation: string, type: string) => {
        return mitigationData.find((item) => item.mitigation === mitigation && item.type === type && item.issueKey);
    };

    const renderButtonToAddOrRemoveMitigationOnJira = (mitigation: string, type: string, rowData: any) => {
        const isMitigationExist = isMitigationDataExist(props.mitigationForJira, mitigation, type);
        const mitigationCreatedIssue = findMitigationCreatedJiraIssue(props.mitigationForJira, mitigation, type);
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-add-mitigation"> {isMitigationExist ? "Remove from Jira" : "Add to Jira"}</Tooltip>}
            >
                {mitigationCreatedIssue ? (
                    <a href={`${ATLASSIAN_PROJECT_LINK}/browse/${mitigationCreatedIssue.issueKey}`} target="_blank">
                        {mitigationCreatedIssue.issueKey}
                    </a>
                ) : (
                    <Button
                        variant={isMitigationExist ? "outline-danger" : "outline-primary"}
                        onClick={() => handleAddOrRemoveMitigationOnJira(mitigation, type, rowData)}
                        disabled={props.isAddingOrRemovingMitigationForJira}
                    >
                        {props.isAddingOrRemovingMitigationForJira ? <LoadingButton /> : isMitigationExist ? "-" : "+"}
                    </Button>
                )}
            </OverlayTrigger>
        );
    };

    const handleAddOrRemoveMitigationOnJira = (mitigation: string, type: string, rowData: any) => {
        const mitigationForJira: MitigationForJira = {
            mitigation,
            type,
            rowData,
        };

        if (isMitigationDataExist(props.mitigationForJira, mitigation, type)) {
            props.setMitigationForJira(props.mitigationForJira.filter((item) => item.mitigation !== mitigation));
        } else {
            props.setMitigationForJira([...props.mitigationForJira, mitigationForJira]);
        }
    };
    const renderSeverityOfAddedMissingRisk = (addedMissingRisk: AddedMissingRisk) => {
        return addedMissingRisk.severity ? <td>{addedMissingRisk.severity}</td> : <td></td>;
    };

    const renderProbabilityOfAddedMissingRisk = (addedMissingRisk: AddedMissingRisk) => {
        return addedMissingRisk.prob ? <td>{addedMissingRisk.prob}</td> : <td></td>;
    };

    const findMigitationData = (issueKey: string) => {
        return props.mitigationData.find((mitigationData: MitigationData) => mitigationData.issueKey === issueKey);
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.isDisplayAddedMissingRisk &&
                    props.csvData.addedMissingRisks &&
                    props.csvData.addedMissingRisks.map((addedMissingRisk: AddedMissingRisk, index: number) => {
                        return renderRow(index, addedMissingRisk);
                    })}
            </tbody>
        );
    };

    const renderTable = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <MasterTable data={props.csvData} isGeneratingData={false} id={props.id} fileName={props.fileName}>
                        <>
                            {renderTableHeader()}
                            {renderTableBody()}
                        </>
                    </MasterTable>
                </div>
            </div>
        );
    };
    return (
        <div
            style={{
                overflow: "scroll",
                height: "90vh",
            }}
        >
            {renderTable()}
        </div>
    );
};

export default CSVDataWithMitigation;

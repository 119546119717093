import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BUTTON_PROCESS_DATA_CLASS_NAME, keywords_COLUMN_CLASS_NAME, keywords_COLUMN_NAME, keywords_COLUMN_INPUT, KEYWORD_PLACEHOLDER, ARIA_LABEL_KEYWORD, BRAND_NAME_PLACEHOLDER, ARIA_LABEL_BRANDNAME, BUTTON_START_SEARCH_TEXT, INPUT_LABEL_KEYWORD, INPUT_LABEL_BRAND_NAME, } from "./constants";
import "./styles.css";
import MaudeTable from "./MaudeTable";
import { BLANK_STRING, PROCESS_DATA_TEXT } from "../../constants/appConstants";
import LoadingButton from "../LoadingButton/LoadingButton";
import { MauDeRowData, Product } from "../../pages/BottomUp/types";

interface Props {
  onSearch: (keywords: string, brandName: string) => void;
  onProcessData: () => void;
  isDisableAllButtons: boolean;
  isSearch: boolean;
  isProcessingData: boolean;
  data: MauDeRowData[];
  products: Array<Product>;
  isShowPopupSelectProduct: boolean;
  setIsShowPopupSelectProduct: (isShow: boolean) => void;
  selectedProducts: Array<string>;
  onSaveSelectedProducts: (newSelectedProducts:string[]) => void;
}

const NUMBER_OF_MAX_FOI_TEXT_TO_DISPLAY_FOR_EACH_PRODUCT = 3;

const BottomUp1stColumn = (props: Props) => {
  const [keywords, setKeyword] = useState<string>(BLANK_STRING);
  const [brandName, setBrandName] = useState<string>(BLANK_STRING);
  const [tempSelectedProducts, setTempSelectedProducts] = useState<string[]>([]);

  useEffect(() => {
    if (props.selectedProducts.length > 0) {
      setTempSelectedProducts(props.selectedProducts);
    }
  }
  , [props.selectedProducts]);
  const shouldDisableButtonSearch = () => {
    return !(keywords.length > 0 || brandName.length > 0);
  };

  const renderStartSearchButton = () => {
    return (
      <Button disabled={props.isDisableAllButtons || shouldDisableButtonSearch()} onClick={() => props.onSearch(keywords, brandName)}>
        {props.isSearch ? (
          <LoadingButton />
        ) : (
          BUTTON_START_SEARCH_TEXT
        )}
      </Button>
    );
  };

  const renderButtonProcessData = () => {
    return (
      <Button disabled={props.isDisableAllButtons || props.data.length == 0} onClick={() => props.onProcessData()} className={BUTTON_PROCESS_DATA_CLASS_NAME}>
        {props.isProcessingData ? (
          <LoadingButton />
        ) : (
          PROCESS_DATA_TEXT
        )}
      </Button>
    );
  };

  const renderInputGroupBrandName = () => {
    return (
      <>
        <Form.Label className={keywords_COLUMN_NAME}>{INPUT_LABEL_BRAND_NAME}</Form.Label>
        <InputGroup className={keywords_COLUMN_INPUT}>
          <Form.Control placeholder={BRAND_NAME_PLACEHOLDER} defaultValue={brandName} onChange={(e) => setBrandName(e.target.value)} aria-label={ARIA_LABEL_BRANDNAME} disabled={props.isSearch} />
        </InputGroup>
      </>
    );
  };

  const renderInputGroupKeywords = () => {
    return (
      <>
        <Form.Label className={keywords_COLUMN_NAME}>{INPUT_LABEL_KEYWORD}</Form.Label>
        <InputGroup className={keywords_COLUMN_INPUT}>
          <Form.Control placeholder={KEYWORD_PLACEHOLDER} defaultValue={keywords} onChange={(e) => setKeyword(e.target.value)} aria-label={ARIA_LABEL_KEYWORD} as="input" disabled={props.isSearch} />
        </InputGroup>
      </>
    );
  };

  const renderButtonShowPopupSelectProduct = () => {
    return (
      <Button disabled={props.isDisableAllButtons || props.products.length == 0} onClick={() => props.setIsShowPopupSelectProduct(true)}>
        Select Product
      </Button>
    );
  };

  const renderPopupToSelectProduct = () => {
    return (
      <Modal
        show={props.isShowPopupSelectProduct}
        onHide={() => props.setIsShowPopupSelectProduct(false)}
        keyboard={false}
        
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.products.map((product, index) => (
            <Form.Check
              key={index} 
              type="checkbox"
              label={product.name +" ("+ product.count +")"} 
              name="formHorizontalRadios"
              checked={tempSelectedProducts.includes(product.name)}
              onChange={() => handleChangeSelected(product.name)}
              style={{
                backgroundColor: product.color,
                margin: 10
              }}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onCancelSelectProducts()}>
            Close
          </Button>
          <Button variant="primary" onClick={()=> props.onSaveSelectedProducts(tempSelectedProducts)}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const onCancelSelectProducts = () => {
    setTempSelectedProducts(props.selectedProducts);
    props.setIsShowPopupSelectProduct(false);
  }

  const handleChangeSelected=(productName: string)=>{
    if (tempSelectedProducts.includes(productName)) {
      setTempSelectedProducts(tempSelectedProducts.filter((product) => product != productName));
    } else {
      setTempSelectedProducts([...tempSelectedProducts, productName]);
    }
  }

  const renderkeywordsMenu = () => {
    const dataToDisplayInTable: Array<MauDeRowData[]> = props.selectedProducts.map((product) => {
      return props.data.filter((data) => data.brandName == product).slice(0,NUMBER_OF_MAX_FOI_TEXT_TO_DISPLAY_FOR_EACH_PRODUCT);
    });
    return (
      <div>
        <div className={keywords_COLUMN_CLASS_NAME}>
          {renderInputGroupKeywords()}
          {renderInputGroupBrandName()}
          {renderStartSearchButton()}
          {renderButtonProcessData()}
          {renderButtonShowPopupSelectProduct()}
          {renderPopupToSelectProduct()}
          {<MaudeTable data={dataToDisplayInTable.flat()} products={props.products} />}

        </div>
      </div>
    );
  };

  return <div className="col-3 col-sm-3">{renderkeywordsMenu()}</div>;
};

export default BottomUp1stColumn;

import { APP_URLS, BLANK_STRING } from "./../../constants/appConstants";
import { AppDispatch, AppThunk } from "../store";
import { createSessionAPI } from "../../apiRequests/session/createSessionAPI";
import { Session } from "../../types/SessionDto";
import { updateSessionNameAPI } from "../../apiRequests/session/updateSessionNameAPI";
import { endSessionAPI } from "../../apiRequests/session/endSessionAPI";

export const createSessionThunk =
  (sessionName: string, userId: string, menuItemId: string) =>
  async (dispatch: AppDispatch): Promise<Session> => {
    return new Promise(async (resolve) => {
      try {
        const startTime = new Date().getTime().toString();
        const response = await createSessionAPI(
          sessionName,
          userId,
          menuItemId,
          startTime
        );
        resolve(response);
      } catch (error) {
        console.error(error);
        return null;
      }
    });
  };

export const updateSessionNameThunk =
  (sessionId: string, sessionName: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await updateSessionNameAPI(sessionId, sessionName);
        resolve(response);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

export const endSessionNameThunk =
  (sessionId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const endTime = new Date().getTime().toString();
      try {
        await endSessionAPI(sessionId, endTime);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

import {  READ_EXCEL_FILE_DATA } from '../../constants/api';
import axios from '../fetch/fetch';

export const readExcelFileData = async (
    formData: FormData,
    session_id: string
): Promise<any> => {
    const res = await axios.post(`${READ_EXCEL_FILE_DATA}/${session_id}`, formData);
    return res.data;
};

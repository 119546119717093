import React, { useState, useEffect } from "react";
import ColumnProduct from "../../Components/ColumnProduct/ColumnProduct";
import ColumnCharacteristics from "../../Components/ColumnCharacteristics/ColumnCharacteristics";
import {
    TOP_DOWN_ROW,
    COLUMN_HARMS_WIDTH,
    FORM_DATA_CHARACTERISTIC_AFFECTING_SAFETY_KEY,
    FORM_DATA_PART_KEY,
    FORM_DATA_PRODUCT_DESCRIPTION_KEY,
    HARMS_COLUMN_NAME,
    HAZARD_TABLE_PARTS_LIST,
    TOP_DOWN_CLASS_NAME,
    TABLE_HAZARD_PRODUCT_COLUMN_NAME,
    TABLE_HAZARD_PRODUCT_FILE_NAME,
    FORM_DATA_SPLIT_KEY,
} from "./constants";
import {
    BLANK_STRING,
    BOOTSTRAP_CONTAINER_FLUID,
    CHARACTERISTICS_LIST_IN_TOPDOWN_PAGE,
    HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX,
    HAZARD_TOP_DOWN_SORT_TYPE,
    PRODUCT_LIST_IN_TOPDOWN_PAGE,
    SPLIT_QUESTION_PART,
    SYSTEM_PROMPT_TOKEN,
    TOKEN_SPLIT_1_TIME,
    TOKEN_SPLIT_2_TIMES,
    TOKEN_SPLIT_3_TIMES,
    TOKEN_SPLIT_4_TIMES,
} from "../../constants/appConstants";
import ColumnHazards from "../../Components/ColumnHazards/ColumnHazards";
import { TOP_DOWN_GENERATE_CHARACTERISTIC_API, TOP_DOWN_GENERATE_HAZARD_API } from "../../constants/api";
import "./style.css";
import { CharacteristicQuestionDto } from "../../types/CharacteristicQuestion";
import { HazardTopDownDto, HazardTopDownState } from "../../types/HazardTopDown";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import default_question_characteristics from "../../Components/ColumnCharacteristics/topdown-chracteristic.json";
import default_pascall_data_product from "../../Components/ColumnProduct/pascallProduct.json";
import { convertIncompleteJSONToCompleteJSON, deepCopyJsonFile, getSessionIdFromURL } from "../../utils/commonUtils";
import { loadPageState } from "../../apiRequests/pageStates/pagestate";
import { useSaveDataToDatabase } from "../../hooks/useSaveDataToDatabase";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";
import { DEFAULT_HAZARDS_TABLE_DATA } from "../../constants/hazards";
import { SORT_TYPE_ASC } from "../../Components/ColumnHazards/constants";
import { encode } from "gpt-tokenizer";
import { convertCSVToString } from "../../utils/convertToCSVDataInString";

const TopDown = () => {
    const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
    const [product, setProduct] = useState<string>(JSON.stringify(default_pascall_data_product));
    const [characteristics, setCharacteristics] = useState<CharacteristicQuestionDto[][]>([deepCopyJsonFile(default_question_characteristics)]);
    const [hazardState, setHazardState] = useState<HazardTopDownState>(DEFAULT_HAZARDS_TABLE_DATA);
    const [hazardSortType, setHazardSortType] = useState<string>(SORT_TYPE_ASC);
    const [isGeneratingHazardList, setIsGeneratingHazardList] = useState<boolean>(false);
    const [isGeneratingCharaceristicData, setIsGeneratingCharaceristicData] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [questionInCharacteristicColumn, setQuestionInCharacteristicColumn] = useState<string>(BLANK_STRING);
    const [characteristicsPart, setCharacteristicsPart] = useState<number>(0);
    const [splitQuestionPart, setSpitQuestionPart] = useState<number>(0);
    const sessionIdFromUrl = getSessionIdFromURL();
    const saveDataToDatabase = useSaveDataToDatabase();
    useEffect(() => {
        try {
            getLatestPageStateFromAPI();
        } catch (e) {}
    }, [sessionIdFromUrl]);

    const getLatestPageStateFromAPI = async () => {
        setIsLoading(true);
        try {
            const stateData = await loadPageState(sessionIdFromUrl);
            const product = stateData.stateDetail[PRODUCT_LIST_IN_TOPDOWN_PAGE];
            setProduct(product || JSON.stringify(default_pascall_data_product));
            setCharacteristics(extractCharacteristicsFromDb(stateData.stateDetail));
            setupSortTypeFromDB(stateData);
            setHazardState(extractHazardsFromDb(stateData.stateDetail));
        } catch (e) {
            setProduct(JSON.stringify(default_pascall_data_product));
            setCharacteristics([deepCopyJsonFile(default_question_characteristics)]);
            setHazardState(DEFAULT_HAZARDS_TABLE_DATA);
            setHazardSortType(BLANK_STRING);
        }
        setIsLoading(false);
    };

    const extractCharacteristicsFromDb = (stateDetail: any) => {
        let newChraracteristic = deepCopyJsonFile(default_question_characteristics);
        setSpitQuestionPart(stateDetail.splitQuestionPart);
        for (const key in stateDetail) {
            if (key.startsWith(CHARACTERISTICS_LIST_IN_TOPDOWN_PAGE)) {
                const characteristicsInString = stateDetail[key];
                try {
                    const outputFormat = [{ questionID: "", questionText: "", answer: "", explanation: "" }];
                    const characteristicsList = convertIncompleteJSONToCompleteJSON(characteristicsInString, outputFormat, []);
                    newChraracteristic = newChraracteristic.map((item: CharacteristicQuestionDto) => {
                        const existedItem = (characteristicsList as any[]).find((characteristic) => characteristic.questionID === item.questionID);
                        if (existedItem) {
                            return {
                                ...item,
                                ...existedItem,
                                part: key,
                                isFinished: true,
                                isSelected: true,
                            };
                        }
                        return item;
                    });
                } catch (e) {
                    return [deepCopyJsonFile(default_question_characteristics)];
                }
            }
        }
        return [newChraracteristic];
    };

    const setupSortTypeFromDB = (stateData: any) => {
        const type = stateData.stateDetail[HAZARD_TOP_DOWN_SORT_TYPE];
        setHazardSortType(type || BLANK_STRING);
    };

    const extractHazardsFromDb = (stateData: any) => {
        let hazardData: HazardTopDownState = {};
        try {
            for (const key in stateData) {
                if (key.startsWith(HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX)) {
                    const hazardsInString = stateData[key];
                    const outputFormat = [{ category: "", description: "", score: 0, part: key }];
                    const hazardPart = convertIncompleteJSONToCompleteJSON(hazardsInString, outputFormat, []);
                    hazardData[key] = hazardPart;
                }
            }
        } catch (e) {
            console.log(e);
        }
        return hazardData;
    };

    const handleGenerateProduct = (product: string) => {
        clearStateDetailProduct();
        setQuestionInCharacteristicColumn(BLANK_STRING);
        setCharacteristics([deepCopyJsonFile(default_question_characteristics)]);

        let split = 0;
        const tokens = encode(JSON.stringify(product));
        const totalTokens = tokens.length + SYSTEM_PROMPT_TOKEN;

        if (totalTokens <= TOKEN_SPLIT_4_TIMES) {
            if (totalTokens < TOKEN_SPLIT_2_TIMES) {
                split = 2;
            } else if (totalTokens < TOKEN_SPLIT_3_TIMES) {
                split = 3;
            } else {
                split = 4;
            }

            fetchQuestionForCharacteristicColumn(product, split);
            saveDataToDatabase(sessionIdFromUrl, SPLIT_QUESTION_PART, split);
            setSpitQuestionPart(split);
        } else {
            alert("The product description is too long. Please try again with a shorter description.");
        }
    };

    const clearStateDetailProduct = async () => {
        const promises = [];
        for (let i = 1; i <= splitQuestionPart; i++) {
            promises.push(saveDataToDatabase(sessionIdFromUrl, `${CHARACTERISTICS_LIST_IN_TOPDOWN_PAGE}${i}`, JSON.stringify([])));
        }
        await Promise.all(promises);
    };

    const handleGenratingHazard = async (characteristics: string) => {
        setIsDisableButton(true);
        setIsGeneratingHazardList(true);
        setHazardState({ ...DEFAULT_HAZARDS_TABLE_DATA });
        setHazardSortType(BLANK_STRING);
        handleUpdateSortType(BLANK_STRING);
        await fetchAllHazardData(JSON.stringify(characteristics), DEFAULT_HAZARDS_TABLE_DATA);
    };

    const fetchQuestionForCharacteristicColumn = async (product: string, split: number, part: number = 1) => {
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: TOP_DOWN_GENERATE_CHARACTERISTIC_API,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsDisableButton(true);
                setIsGeneratingCharaceristicData(true);
            },
            onMessage: function (fullMessage: string): void {
                setCharacteristicsPart(part);
                setQuestionInCharacteristicColumn(fullMessage);
            },
            onClose: function (): void {
                part = part + 1;
                if (part > split) {
                    setIsDisableButton(false);
                    setIsGeneratingCharaceristicData(false);
                    return;
                } else {
                    setQuestionInCharacteristicColumn(BLANK_STRING);
                    fetchQuestionForCharacteristicColumn(product, split, part);
                }
            },
            onError: () => {
                setIsDisableButton(false);
                setIsGeneratingCharaceristicData(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_PRODUCT_DESCRIPTION_KEY,
                    value: JSON.stringify(product),
                },
                {
                    key: FORM_DATA_PART_KEY,
                    value: part.toString(),
                },
                {
                    key: FORM_DATA_SPLIT_KEY,
                    value: split.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const fetchAllHazardData = async (characteristic: string, currentHazardState: HazardTopDownState) => {
        await fetchHazardData(characteristic, 0, currentHazardState);
    };

    const [hazardInString, setHazardInString] = useState<string[]>([]);

    const fetchHazardData = async (characteristic: string, partIndex: number = 0, currentHazardState: HazardTopDownState) => {
        const part = HAZARD_TABLE_PARTS_LIST[partIndex];
        let prevHazardState = { ...currentHazardState };
        // let prevHazard: HazardTopDownDto[] = [];
        // for (let i = 0; i < partIndex && i < hazardInString.length; i++) {
        //     let parsedArray = JSON.parse(hazardInString[i]);
        //     prevHazard = prevHazard.concat(parsedArray);
        // }
        const partKey = `${HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX}_${part}`;
        let fullMessagePart = BLANK_STRING;
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: TOP_DOWN_GENERATE_HAZARD_API,
            sessionIdFromUrl: sessionIdFromUrl,
            onMessage: function (fullMessage: string): void {
                fullMessagePart = fullMessage;
                const outputFormat = [{ category: "", description: "", score: 0 }];
                const hazardData = convertIncompleteJSONToCompleteJSON(fullMessage, outputFormat, []);
                const newData = {
                    ...currentHazardState,
                    [partKey]: [...currentHazardState[partKey], ...hazardData],
                };
                setHazardState(newData);
                prevHazardState = { ...newData };
            },
            onClose: async function () {
                setHazardInString((prevState) => {
                    prevState[partIndex] = fullMessagePart;
                    return prevState;
                });
                fullMessagePart = BLANK_STRING;
                partIndex = partIndex + 1;
                if (partIndex >= HAZARD_TABLE_PARTS_LIST.length) {
                    setIsDisableButton(false);
                    setIsGeneratingHazardList(false);
                    setHazardInString([]);
                } else {
                    await fetchHazardData(characteristic, partIndex, prevHazardState);
                }
            },
            onError: () => {
                setIsDisableButton(false);
                setIsGeneratingHazardList(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_CHARACTERISTIC_AFFECTING_SAFETY_KEY,
                    value: JSON.stringify(characteristic),
                },
                {
                    key: FORM_DATA_PART_KEY,
                    value: part.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const renderColumnProduct = () => {
        return (
            <ColumnProduct
                data={product}
                onGenerate={handleGenerateProduct}
                isDisableButton={isDisableButton}
                isGeneratingCharacteristicsTable={isGeneratingCharaceristicData}
                sessionId={sessionIdFromUrl}
            ></ColumnProduct>
        );
    };

    const renderColumnCharacteristics = () => {
        return (
            <div className=" col-4 col-sm-4">
                <ColumnCharacteristics
                    dataInList={characteristics}
                    onGenerate={handleGenratingHazard}
                    isDisableButton={isDisableButton}
                    data={questionInCharacteristicColumn}
                    isGeneratingHazardTable={isGeneratingHazardList}
                    sessionId={sessionIdFromUrl}
                    needToGenerateHazard={true}
                    part={characteristicsPart}
                    isUseCSVDataSlice={false}
                    splitQuestionPart={splitQuestionPart}
                    setDataInList={setCharacteristics}
                    keyOfPage={CHARACTERISTICS_LIST_IN_TOPDOWN_PAGE}
                />
            </div>
        );
    };

    const renderColumnHazards = () => {
        const hazards = Object.keys(hazardState)
            .map((key) => {
                const value = hazardState[key].map((hazard) => {
                    return {
                        ...hazard,
                        part: key,
                    };
                });
                return [...value];
            })
            .flat();
        return (
            <div className="col-4 col-sm-4">
                <ColumnHazards
                    onDataChange={handleHazarDataChange}
                    onUpdateSortType={(sortType) => handleUpdateSortType(sortType)}
                    sortType={hazardSortType}
                    data={hazards}
                    isGeneratingData={isGeneratingHazardList}
                    highlighedHazardIndexList={[]}
                    isShowMissingRisks={false}
                    columnName={TABLE_HAZARD_PRODUCT_COLUMN_NAME}
                    fileName={TABLE_HAZARD_PRODUCT_FILE_NAME}
                />
            </div>
        );
    };
    const handleHazarDataChange = (oldItem: HazardTopDownDto, newItem: HazardTopDownDto) => {
        const part = oldItem.part || "";
        const newData: HazardTopDownDto[] = hazardState[part].map((hazard) => {
            if (hazard.category === oldItem.category) {
                return { ...hazard, ...newItem };
            }
            return { ...hazard };
        });

        setHazardState((prevState) => {
            return {
                ...prevState,
                [part]: newData,
            };
        });
        saveDataToDatabase(sessionIdFromUrl, part, JSON.stringify(newData));
    };

    const handleUpdateSortType = (sortType: string) => {
        saveDataToDatabase(sessionIdFromUrl, HAZARD_TOP_DOWN_SORT_TYPE, sortType);
        setHazardSortType(sortType);
    };

    const renderColumnHarms = () => {
        return <div className={COLUMN_HARMS_WIDTH}>{HARMS_COLUMN_NAME}</div>;
    };

    const renderContent = () => {
        return (
            <div className={TOP_DOWN_ROW}>
                {renderColumnProduct()}
                {renderColumnCharacteristics()}
                {renderColumnHazards()}
                {renderColumnHarms()}
            </div>
        );
    };

    return <div className={`${TOP_DOWN_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>{isLoading ? <LoadingPage /> : renderContent()}</div>;
};

export default TopDown;

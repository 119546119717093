import _ from "lodash";
import { useCallback } from "react";
import { updatePageStateByKeyAPI } from "../apiRequests/pageStates/updatePageStateByKeyAPI";

export const useSaveDataToDatabase = () => {
    return useCallback(
        _.debounce(async (sessionId: string, keyInState: string, data: any) => {
            await updatePageStateByKeyAPI(sessionId, keyInState, data);
        }, 2000),
        []
    );
};

export const delayForIgnoreDebounce = async () => {
    return await new Promise((resolve) => setTimeout(resolve, 2100));
};

export const TOP_DOWN_CLASS_NAME = "top-down";
export const MAUDE_TABLE_SEARCH_RESULT_ID = "maude-table-search-result";
export const FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY = "hazard_master_list_maude";
export const FORM_DATA_GROUP_AND_SUB_GROUP_KEY = "groups_and_sub_groups";
export const FORM_DATA_INCIDENTS_KEY = "incidents";
export const TR_TAG = "tr";
export const TABLE_COLOR_BLACK = "black";
export const TABLE_COLOR_BLUE = "blue";
export const INPUT_UPLOAD_FILE_ID = "inputGroupFile02";
export const ERROR_MESSAGES_NO_FILE = "No file input";
export const ERROR_MESSAGES_NO_FILE_SELECTED = "No file selected";
export const FILE_KEY = "file";
export const UPLOAD_TEXT = "Upload";
export const ACCEPT_JSON_INPUT = "application/pdf";
export const INPUT_FILE_CLASS_NAME = "form-control";
export const INPUT_TYPE_FILE = "file";
export const INPUT_GROUP_CLASS_NAME = "input-group mb-3";
export const COMPETIOR_PAGE = "competitor";
export const TABLE_NAME_HAZARD = "Hazard";
export const ACCEPT_EXCEL_INPUT = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

export const TABLE_RISK_WITH_CHARACTERISTIC_ID = "table-risk-with-characteristic";

export const TABLE_ADDED_MISSING_RISK_ID = "table-added-missing-risk";
export const TABLE_ADDED_MISSING_RISK_FILE_NAME = "added-missing-risk";

export const MISSING_RISK_TABS = [
    "Risk - Characteristics for Safety",
    "Risk - Categorize Risks",
    "Missing Risks C1",
    "Missing Risks C2",
    "(C1) Original Risks & Missing Risks",
    "(C2) Original Risks & Missing Risks",
    "Added Missing Risks - Generate Mitigation",
    "Dashboard",
];

export const MISSING_RISK_TAB_URLS: any = {
    "Risk - Characteristics for Safety": "risk-characteristics",
    "Risk - Categorize Risks": "risk-categorize",
    "Missing Risks C1": "missing-risks-c1",
    "Missing Risks C2": "missing-risks-c2",
    "(C1) Original Risks & Missing Risks": "original-risks-missing-risks-c1",
    "(C2) Original Risks & Missing Risks": "original-risks-missing-risks-c2",
    "Added Missing Risks - Generate Mitigation": "added-missing-risks-generate-mitigation",
    Dashboard: "dashboard",
};

export const CHARACTERISTICS_KEY_FOR_MISSING_RISK = "characteristics_for_safety_from_risk_";
export const ADDED_MISSING_FIELDS_SYSTEM_PROMPT_TOKENS = 179;
export const MAX_TOKENS_OF_ADD_MISSING_FIELDS = 3000;
export const MAX_TOKENS_OF_GENERATING_MITIGATION = 1700;
export const MITIGATION_SYSTEM_PROMPT_TOKENS = 198;
export const MITIGATION_FOR_JIRA_KEY = "mitigation_for_jira";

export const MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX = "missing_fields_data_of_added_missing_risks_";
export const MITIGATION_FOR_RISK_DATA_PREFIX = "mitigation_for_risk_data_";
export const CATEGORY_RISK_C1_PREFIX = "C1_";
export const CATEGORY_RISK_C2_PREFIX = "C2_";

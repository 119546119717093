import { AddedMissingRisk } from "../../Components/CSVTable/CSVTable";
import {  ADD_MISSING_RISK_TO_CSV_DATA, CLEAR_ALL_GENERATED_MISSING_FIELDS_FOR_ADDED_RISK, CLEAR_ALL_MITIGATION_FOR_RISKS, INDEX_OF_RISK_SUMARY_COLUMN, REMOVE_MISSING_RISKS, UPDATE_ISSUE_KEY_PREFIX } from "../../constants/api";
import { DEFAULT_INDEX_OF_RISK_SUMMARY_COLUMN } from "../../constants/appConstants";
import { MissingRisk } from "../../types/HazardTopDown";
import axios from "../fetch/fetch";



export const removeMissingRisks = async (sessionId: string, tableCategory: string) => {
  await axios.post(`${REMOVE_MISSING_RISKS}/${sessionId}`,{
    tableCategory
  });
};

export const findIndexOfRiskSummaryColumnInCSVFile = async (sessionId: string, headers: string[]) => {
  try {
    const res = await axios.post(`${INDEX_OF_RISK_SUMARY_COLUMN}/${sessionId}`,{
      headers: JSON.stringify(headers)
    });
    return Number(res.data);
  } catch (error) {
    console.log(error);
  }
  return DEFAULT_INDEX_OF_RISK_SUMMARY_COLUMN;

}

export const updateIssueKeyPrefixInCSVDataAPI = async (sessionId: string, issueKeyPrefix: string) => {
  await axios.post(`${UPDATE_ISSUE_KEY_PREFIX}/${sessionId}`,{
    issueKeyPrefix
  });
}

export const addMissingRiskToCSVDataAPI = async (sessionId: string, category: string, risk: MissingRisk): Promise<AddedMissingRisk>=> {
    const res = await axios.post(`${ADD_MISSING_RISK_TO_CSV_DATA}/${sessionId}`,{
      category,
      risk,
    });
    if(res.status != 200) throw new Error("Error adding missing risk to csv data");
    return res.data
}

export const clearAllMitigationForRisksAPI = async (sessionId: string) => {
  await axios.post(`${CLEAR_ALL_MITIGATION_FOR_RISKS}/${sessionId}`);
}

export const clearAllGeneratedMissingFieldsAPI = async (sessionId: string) => {
  await axios.post(`${CLEAR_ALL_GENERATED_MISSING_FIELDS_FOR_ADDED_RISK}/${sessionId}`);
}
import { Session } from "../../types/SessionDto";
import { END_SESSION_API } from "../../constants/api";
import axios from "../fetch/fetch";

export const endSessionAPI = async (
  sessionId: string,
  endTime: string
): Promise<Session> => {
  const body = { endTime };

  const response = await axios.put(`${END_SESSION_API}/${sessionId}`, body);
  return response.data;
};

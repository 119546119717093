import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Form, InputGroup, Card } from "react-bootstrap";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import {
    BACKGROUND_COLOR_OF_SELECTED_ITEM,
    CHARACTERISTICS_COLUMN_CARD,
    COLOR_NO_QUESTION,
    COLOR_YES_QUESTION,
    HEIGHT_INHERIT,
    INPUT_ANSWER_NAME,
    INPUT_EXLANATION_NAME,
    INPUT_QUESTION_TEXT_NAME,
    INPUT_TYPE_TEXT_AREA,
    MIN_TEXTAREA_HEIGHT,
    QUESTION_ANSWER_OPTIONS,
    TEXTAREA_EXPLAINATION,
    TEXTAREA_QUESTION,
} from "../constants";
import { CharacteristicQuestionDto } from "../../../types/CharacteristicQuestion";
import { BLANK_STRING } from "../../../constants/appConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    dataSet: CharacteristicQuestionDto[];
    index: number;
    handleInputQuestionChange: (question: string, index: number) => void;
    handleInputAnswerChange: (answer: string, index: number, part: string, dataIndex: number) => void;
    handleInputExplanationChange: (explanation: string, index: number, part: string, dataIndex: number) => void;
    handleSelectAnswerAndExplanationChange: (isSelected: boolean, index: number, part: string, dataIndex: number) => void;
}
const CardCharacteristics = (props: Props) => {
    const [data, setData] = useState<CharacteristicQuestionDto[]>(props.dataSet);
    const { index, handleInputAnswerChange, handleInputExplanationChange, handleInputQuestionChange, handleSelectAnswerAndExplanationChange } = props;
    const isShowCheckBox = props.dataSet.filter((item) => item.answer === "Yes").length > 1;

    useEffect(() => {
        setData(props.dataSet);
    }, [props.dataSet]);

    const selectAnswerRef = React.useRef<HTMLSelectElement | null>(null);

    const textareaQuestionTextRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [questionText, setQuestionText] = useState<string>();
    React.useLayoutEffect(() => {
        textareaQuestionTextRef.current!.style.height = HEIGHT_INHERIT;
        textareaQuestionTextRef.current!.style.height = `${Math.max(textareaQuestionTextRef.current!.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
    }, [data[0].questionText]);
    const renderAnswerExplanationGroups = (data: CharacteristicQuestionDto, dataIndex: number) => (
        <>
            {<FontAwesomeIcon icon={faMinus} />}
            {isShowCheckBox && (
                <Form.Check
                    type="checkbox"
                    label={BLANK_STRING}
                    checked={data.answer === QUESTION_ANSWER_OPTIONS.YES && data.isSelected}
                    onChange={(event) => {
                        handleSelectAnswerAndExplanationChange(event.currentTarget.checked, index, data.part || BLANK_STRING, dataIndex);
                    }}
                    name={INPUT_ANSWER_NAME}
                    disabled={!data?.isFinished || data.answer !== QUESTION_ANSWER_OPTIONS.YES}
                />
            )}
            {renderInputGroupAnswer(data, dataIndex)}
            {renderInputGroupExplaination(data, dataIndex)}
        </>
    );
    const renderInputGroupQuestion = () => {
        return (
            <InputGroup className="mb-1">
                <InputGroup.Text>Question</InputGroup.Text>
                <Form.Control
                    ref={textareaQuestionTextRef}
                    onChange={(event) => {
                        handleInputQuestionChange(event.currentTarget.value, index);
                        setQuestionText(event.currentTarget.value);
                    }}
                    as={INPUT_TYPE_TEXT_AREA}
                    className={TEXTAREA_QUESTION}
                    value={props.dataSet[0].questionText}
                    name={INPUT_QUESTION_TEXT_NAME}
                    disabled={!data[0]?.isFinished}
                />
            </InputGroup>
        );
    };

    const renderInputGroupAnswer = (data: CharacteristicQuestionDto, dataIndex: number) => {
        return (
            <InputGroup className="mb-1">
                <InputGroup.Text
                    style={{
                        backgroundColor:
                            data?.answer === QUESTION_ANSWER_OPTIONS.YES
                                ? isShowCheckBox && data.isSelected
                                    ? BACKGROUND_COLOR_OF_SELECTED_ITEM
                                    : COLOR_YES_QUESTION
                                : COLOR_NO_QUESTION,
                    }}
                >
                    Answer
                </InputGroup.Text>
                <Form.Select
                    ref={selectAnswerRef}
                    value={data?.answer}
                    onChange={(event) => {
                        handleInputAnswerChange(event.currentTarget.value, index, data.part || BLANK_STRING, dataIndex);
                    }}
                    name={INPUT_ANSWER_NAME}
                    disabled={!data?.isFinished}
                >
                    <option value={QUESTION_ANSWER_OPTIONS.NO}>{QUESTION_ANSWER_OPTIONS.NO}</option>
                    <option value={QUESTION_ANSWER_OPTIONS.YES}>{QUESTION_ANSWER_OPTIONS.YES}</option>
                    <option value={QUESTION_ANSWER_OPTIONS.INSUFFICIENT_IMFORMATION_PROVIDED}>
                        {QUESTION_ANSWER_OPTIONS.INSUFFICIENT_IMFORMATION_PROVIDED}
                    </option>
                </Form.Select>
            </InputGroup>
        );
    };

    const renderInputGroupExplaination = (data: CharacteristicQuestionDto, dataIndex: number) => {
        return (
            <InputGroup className="mb-1">
                <InputGroup.Text
                    style={{
                        backgroundColor:
                            data?.answer === QUESTION_ANSWER_OPTIONS.YES
                                ? isShowCheckBox && data.isSelected
                                    ? BACKGROUND_COLOR_OF_SELECTED_ITEM
                                    : COLOR_YES_QUESTION
                                : COLOR_NO_QUESTION,
                    }}
                >
                    Explanation
                </InputGroup.Text>
                <TextAreaAutoResize
                    className={TEXTAREA_EXPLAINATION}
                    as={INPUT_TYPE_TEXT_AREA}
                    value={data?.explanation}
                    onChange={(event) => {
                        handleInputExplanationChange(event.currentTarget.value, index, data.part || BLANK_STRING, dataIndex);
                    }}
                    name={INPUT_EXLANATION_NAME}
                    disabled={!data?.isFinished}
                />
            </InputGroup>
        );
    };

    return (
        <Card className={CHARACTERISTICS_COLUMN_CARD}>
            <Card.Body>
                {renderInputGroupQuestion()}
                {props.dataSet.map((data, dataIndex) => {
                    return <div key={data?.questionText + dataIndex + data?.part}>{renderAnswerExplanationGroups(data, dataIndex)}</div>;
                })}
            </Card.Body>
        </Card>
    );
};

CardCharacteristics.defaultProps = {
    isEditable: false,
};

interface TextAreaAutoResizeProps {
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    minHeight?: number;
    className?: string;
    name?: string;
    disabled?: boolean;
    as?: any;
}

const TextAreaAutoResize: React.FC<TextAreaAutoResizeProps> = ({ value, onChange, minHeight = 50, className, name, disabled, as }) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minHeight)}px`;
        }
    }, [value]);

    return <Form.Control ref={textareaRef} as={as} className={className} value={value} onChange={onChange} name={name} disabled={disabled} />;
};

export default CardCharacteristics;

import React, { useState } from "react";
import { CSVData } from "../../../Components/CSVTable/CSVTable";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../../utils/commonUtils";
import { GENERATE_MISSING_FIELDS_FOR_RISKS, GENERATE_MITIGATION_FOR_RISKS } from "../../../constants/api";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../../utils/commonFetchEventSource";
import { clearAllGeneratedMissingFieldsAPI, clearAllMitigationForRisksAPI } from "../../../apiRequests/missingRisks/missingRisks";
import CSVDataWithMitigation, { MitigationData } from "../../../Components/CSVDataWIthMitigation/CSVDataWithMitigation";
import LoadingButton from "../../../Components/LoadingButton/LoadingButton";
import { Button } from "react-bootstrap";
import {
    ADDED_MISSING_FIELDS_SYSTEM_PROMPT_TOKENS,
    MAX_TOKENS_OF_ADD_MISSING_FIELDS,
    MAX_TOKENS_OF_GENERATING_MITIGATION,
    MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX,
    MITIGATION_FOR_JIRA_KEY,
    MITIGATION_FOR_RISK_DATA_PREFIX,
    MITIGATION_SYSTEM_PROMPT_TOKENS,
    TABLE_ADDED_MISSING_RISK_FILE_NAME,
    TABLE_ADDED_MISSING_RISK_ID,
} from "../constants";
import { encode } from "gpt-tokenizer";
import { MitigationForJira } from "../MissingRisks";
import { delayForIgnoreDebounce, useSaveDataToDatabase } from "../../../hooks/useSaveDataToDatabase";
import { updateKeyOfPageStateByOldKeyAPI } from "../../../apiRequests/pageStates/updateKeyOfPageStateByOldKeyAPI";

export interface AddedMissingRiskWithGeneratedMissingFields {
    issueKey: string;
    data: string[];
}

interface Props {
    csvData: CSVData;
    mitigationData: MitigationData[];
    setMitigationData: React.Dispatch<React.SetStateAction<MitigationData[]>>;
    isGeneratingData: boolean;
    setIsGeneratingData: (isGeneratingData: boolean) => void;
    addedMissingRisksWithGeneratedMissingFields: AddedMissingRiskWithGeneratedMissingFields[];
    setAddedMissingRisksWithGeneratedMissingFields: React.Dispatch<React.SetStateAction<AddedMissingRiskWithGeneratedMissingFields[]>>;
    mitigationForJira: MitigationForJira[];
    setMitigationForJira: React.Dispatch<React.SetStateAction<MitigationForJira[]>>;
}
const Tab_AddedMissingRisks = (props: Props) => {
    const { csvData, mitigationData, setMitigationData, isGeneratingData, setIsGeneratingData, setAddedMissingRisksWithGeneratedMissingFields } =
        props;
    const sessionIdFromUrl = getSessionIdFromURL();
    const [isAddingOrRemovingMitigationForJira, setIsAddingOrRemovingMitigationForJira] = useState<boolean>(false);
    const saveDataToDatabase = useSaveDataToDatabase();
    const renderAddedMissingRisksToGenerateMitigation = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {renderButtonGenerateMissingFieldsForAllAddedRisks()}
                        {renderButtonGenerateMitigationForAllAddedRisks()}
                    </div>

                    {renderAddedRisksWithMitigation()}
                </div>
            </div>
        );
    };

    const renderButtonGenerateMitigationForAllAddedRisks = () => {
        return (
            <div className="col-3">
                <Button onClick={handleGenerateMitigationForAllAddedRisksButtonClick} disabled={isGeneratingData || csvData.rows.length === 0}>
                    {isGeneratingData ? <LoadingButton /> : "Generate Mitigation"}
                </Button>
            </div>
        );
    };

    const renderButtonGenerateMissingFieldsForAllAddedRisks = () => {
        return (
            <div className="col-3">
                <Button onClick={handleGenerateMissingFieldsForAllAddedRisksButtonClick} disabled={isGeneratingData || csvData.rows.length === 0}>
                    {isGeneratingData ? <LoadingButton /> : "Generate Missing Fields"}
                </Button>
            </div>
        );
    };

    const renderAddedRisksWithMitigation = () => {
        return (
            <CSVDataWithMitigation
                csvData={csvData}
                mitigationData={mitigationData}
                isDisplayAddedMissingRisk={true}
                isDisplayOriginalRisk={false}
                id={TABLE_ADDED_MISSING_RISK_ID}
                fileName={TABLE_ADDED_MISSING_RISK_FILE_NAME}
                addedMissingRisksWithGeneratedMissingFields={props.addedMissingRisksWithGeneratedMissingFields}
                mitigationForJira={props.mitigationForJira}
                setMitigationForJira={(mitigation: MitigationForJira[]) => handleAddOrRemoveMitigationOnJira(mitigation)}
                isAddingOrRemovingMitigationForJira={isAddingOrRemovingMitigationForJira}
                setIsAddingOrRemovingMitigationForJira={setIsAddingOrRemovingMitigationForJira}
            />
        );
    };
    const handleAddOrRemoveMitigationOnJira = async (mitigation: MitigationForJira[]) => {
        setIsAddingOrRemovingMitigationForJira(true);

        try {
            const newMitigation = await saveDataToDatabase(sessionIdFromUrl, MITIGATION_FOR_JIRA_KEY, mitigation);
            await delayForIgnoreDebounce();
            props.setMitigationForJira(mitigation);
        } catch (e) {
            console.log(e);
        }
        setIsAddingOrRemovingMitigationForJira(false);
    };
    const handleGenerateMitigationForAllAddedRisksButtonClick = async () => {
        setIsGeneratingData(true);
        setMitigationData([]);
        await clearAllMitigationForRisksAPI(sessionIdFromUrl);
        handleGenerateMitigationForAddedRisks(0, csvData.addedMissingRisks.length);
    };

    const handleGenerateMissingFieldsForAllAddedRisksButtonClick = async () => {
        setIsGeneratingData(true);
        setAddedMissingRisksWithGeneratedMissingFields([]);
        await clearAllGeneratedMissingFieldsAPI(sessionIdFromUrl);
        const exampleData = JSON.stringify(getFirst20RowsInOriginalRiskAsExampleData());
        handleGenerateMissingFieldsForAddedRisks(exampleData, 0, csvData.addedMissingRisks.length);
    };

    const handleGenerateMitigationForAddedRisks = (startIndex: number, endIndex: number) => {
        const remainToken = MAX_TOKENS_OF_GENERATING_MITIGATION - MITIGATION_SYSTEM_PROMPT_TOKENS;
        const { csv, endIdx: totalAddedMissingRisks } = convertAddedMissingRisksToCSV(startIndex, endIndex, remainToken);
        let lastItemIndex = 0;

        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_MITIGATION_FOR_RISKS,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                const index = setStreamingMitigationForAllAddedRisks(fullMessage);
                lastItemIndex = index;
            },
            onClose: function (): void {
                if (lastItemIndex < endIndex - 1) {
                    const newStartIndex = lastItemIndex;
                    if (totalAddedMissingRisks !== newStartIndex) {
                        updateKeyOfPageStateByOldKeyAPI(
                            sessionIdFromUrl,
                            MITIGATION_FOR_RISK_DATA_PREFIX + startIndex.toString() + "_" + totalAddedMissingRisks.toString(),
                            MITIGATION_FOR_RISK_DATA_PREFIX + startIndex.toString() + "_" + newStartIndex.toString()
                        );
                    }
                    handleGenerateMitigationForAddedRisks(newStartIndex, endIndex);
                } else {
                    setIsGeneratingData(false);
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: "riskData",
                    value: JSON.stringify(csv),
                },
                {
                    key: "startAndEndIndex",
                    value: startIndex.toString() + "_" + totalAddedMissingRisks.toString(),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const handleGenerateMissingFieldsForAddedRisks = (exampleData: string, startIndex: number, endIndex: number) => {
        const exampleDataToken = encode(exampleData);
        const remainToken = MAX_TOKENS_OF_ADD_MISSING_FIELDS - (exampleDataToken.length + ADDED_MISSING_FIELDS_SYSTEM_PROMPT_TOKENS);
        const { csv, endIdx: totalAddedMissingRisks } = convertAddedMissingRisksToCSV(startIndex, endIndex, remainToken);
        const riskData = JSON.stringify(csv);
        let lastItemIndex = 0;

        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_MISSING_FIELDS_FOR_RISKS,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingData(true);
            },
            onMessage: function (fullMessage: string): void {
                const index = setStreamingGenerateMissingFieldsData(fullMessage);
                lastItemIndex = index;
            },
            onClose: function (): void {
                if (lastItemIndex < endIndex - 1) {
                    const newStartIndex = lastItemIndex;
                    if (totalAddedMissingRisks !== newStartIndex) {
                        updateKeyOfPageStateByOldKeyAPI(
                            sessionIdFromUrl,
                            MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX + startIndex.toString() + "_" + totalAddedMissingRisks.toString(),
                            MISSING_FIELDS_DATA_OF_ADDED_MISSING_RISKS_PREFIX + startIndex.toString() + "_" + newStartIndex.toString()
                        );
                    }
                    handleGenerateMissingFieldsForAddedRisks(exampleData, newStartIndex, endIndex);
                } else {
                    setIsGeneratingData(false);
                }
            },
            onError: () => {
                setIsGeneratingData(false);
            },
            formDataKeyValue: [
                {
                    key: "riskData",
                    value: riskData,
                },
                {
                    key: "startAndEndIndex",
                    value: startIndex.toString() + "_" + totalAddedMissingRisks.toString(),
                },
                {
                    key: "exampleData",
                    value: exampleData,
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };

    const getFirst20RowsInOriginalRiskAsExampleData = () => {
        let csv = "";
        csv += csvData.header.join(",") + "\n";
        const endIndex = Math.min(20, csvData.rows.length);
        for (let i = 0; i < endIndex; i++) {
            const row = csvData.rows[i].join(",") + "\n";
            csv += row;
        }
        return csv;
    };

    const setStreamingMitigationForAllAddedRisks = (fullMessage: string) => {
        const data: MitigationData[] = convertIncompleteJSONToCompleteJSON(fullMessage, [], []);
        let index = -1;
        if (data.length > 0) {
            setMitigationData((prevData) => {
                let newData = [...prevData];
                data.forEach((item) => {
                    const itemIndex = prevData.findIndex((newItem) => newItem.issueKey === item.issueKey);
                    if (itemIndex === -1 && Object.keys(item).length > 1) {
                        newData.push(item);
                    } else if (itemIndex !== -1) {
                        newData[itemIndex] = item;
                        index = itemIndex;
                    }
                });
                return newData;
            });
        }
        return index;
    };

    const setStreamingGenerateMissingFieldsData = (fullMessage: string) => {
        const data: AddedMissingRiskWithGeneratedMissingFields[] = convertIncompleteJSONToCompleteJSON(fullMessage, [], []);
        let index = -1;
        if (data.length > 0) {
            setAddedMissingRisksWithGeneratedMissingFields((prevData) => {
                const newData = [...prevData];
                data.forEach((item) => {
                    const itemIndex = prevData.findIndex((newItem) => newItem.issueKey === item.issueKey);
                    if (itemIndex === -1 && Object.keys(item).length > 1) {
                        newData.push(item);
                    } else if (itemIndex !== -1) {
                        newData[itemIndex] = item;
                        index = itemIndex;
                    }
                });

                return newData;
            });
        }
        return index;
    };

    const getHeaderTokens = () => {
        const header = csvData.header.join(",") + "\n";
        return encode(header).length;
    };

    const convertAddedMissingRisksToCSV = (startIndex: number, endIndex: number, remainToken: number = MAX_TOKENS_OF_ADD_MISSING_FIELDS) => {
        let endIdx = endIndex;
        let totalTokens = getHeaderTokens();
        let csv = csvData.header.join(",") + "\n";
        for (let i = startIndex; i < endIndex; i++) {
            const row = csvData.addedMissingRisks[i].data.join(",") + "\n";
            const tokens = encode(row).length;
            if (totalTokens + tokens > remainToken) {
                endIdx = i;
                break;
            } else {
                totalTokens += tokens;
                csv += row;
            }
        }
        return { csv, endIdx };
    };

    return renderAddedMissingRisksToGenerateMitigation();
};

export default Tab_AddedMissingRisks;

export interface CSVDataToText {
    header: string[]
    rows: string[][]
}

export const convertCSVToString = (csvInput: CSVDataToText, startIndex: number = 0): string => {
    let csv = ""
    csv += "index," + csvInput.header.join(",") + "\n"
    for (let i = 0; i < csvInput.rows.length; i++) {
        csv += (startIndex+i+1) + "," + csvInput.rows[i].join(",") + "\n"
    }
    return csv
}
export const CATEGORY_RISK_CLASS_NAME = "category-risk-table-component";
export const CATEGORY_RISK_TABLE_ID = "category-risk-table";
export const CATEGORY_RISK_HEADER_KEY = "category_risk_header";
export const CATEGORY_RISK_BODY_ROW_KEY = "category_risk_body_row";
export const CATEGORY_RISK_BODY_CELL_KEY = "category_risk_body_cell";
export const CATEGORY_RISK_ADDED_MISSING_RISK_ROW_KEY = "category_risk_added_missing_risk_row";
export const CATEGORY_RISK_ADDED_MISSING_RISK_CELL_KEY = "category_risk_added_missing_risk_cell";
export const CATEGORY_RISK_DYNAMIC_SUBCATEGORY_KEY = "category_risk_dynamic_subcategory";
export const CATEGORY_RISK_CURRENT_DYNAMIC_SUBCATEGORY_KEY = "category_risk_current_dynamic_subcategory";
export const ITEM_TOP_COUNT_KEY = "item_top_count";
export const CATEGORY_WITH_COUNT_KEY = "category_with_count";

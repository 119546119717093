import React, { useEffect, useState } from "react";
import {
  PRODUCT_COLUMN_CLASS_NAME,
  PRODUCT_COLUMN_NAME,
  PRODUCT_COLUMN_INPUT,
  PRODUCT_COLUMN_TEXT,
  PRODUCT_INPUT_PLACE_HOLDER,
  TEXTAREA_INPUT_TYPE,
} from "./constants";
import "./styles.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import { GENERATE_TEXT, PRODUCT_LIST_IN_TOPDOWN_PAGE } from "../../constants/appConstants";
import LoadingButton from "../LoadingButton/LoadingButton";
import pascall_product from "./pascallProduct.json";
import { useSaveDataToDatabase } from "../../hooks/useSaveDataToDatabase";
interface Props {
  onGenerate: (product: string) => void;
  isDisableButton: boolean;
  data: string;
  isGeneratingCharacteristicsTable: boolean;
  sessionId: string;
}
const ColumnProduct = (props: Props) => {
  const [product, setProduct] = useState<string>(
    JSON.stringify(pascall_product)
  );
  const saveDataToDatabase = useSaveDataToDatabase();
  useEffect(() => {
    setProduct(props.data);
  }, [props.data]);

  const handleDataProductChange = (data: string) => {
    setProduct(data);
    saveDataToDatabase(props.sessionId, PRODUCT_LIST_IN_TOPDOWN_PAGE, data);
  };

  const renderButtonGenerate = () => {
    return (
      <Button
        disabled={props.isDisableButton}
        onClick={() => props.onGenerate(product)}
      >
        {props.isGeneratingCharacteristicsTable ? (
          <LoadingButton />
        ) : (
          GENERATE_TEXT
        )}
      </Button>
    );
  };

  const renderInputGroupProduct = () => {
    return (
      <>
        <Form.Label className={PRODUCT_COLUMN_NAME}>
          {PRODUCT_COLUMN_TEXT}
        </Form.Label>
        <InputGroup className={PRODUCT_COLUMN_INPUT}>
          <Form.Control
            placeholder={PRODUCT_INPUT_PLACE_HOLDER}
            defaultValue={product || JSON.stringify(pascall_product)}
            value={product}
            onChange={(e) => handleDataProductChange(e.target.value)}
            as={TEXTAREA_INPUT_TYPE}
          />
        </InputGroup>
      </>
    );
  };
  const renderProductMenu = () => {
    return (
      <span>
        <div className={PRODUCT_COLUMN_CLASS_NAME}>
          {renderInputGroupProduct()}
          {renderButtonGenerate()}
        </div>
      </span>
    );
  };

  return <div className="col-3 col-sm-3">{renderProductMenu()}</div>;
};
export default ColumnProduct;

import { COMPETITOR_API, CONVERT_TEXT_TO_CSV_FORMAT, GENEREATE_SCENARIOS_AND_PROBABILITY, SERCH_MAUDE } from '../../constants/api';
import axios from '../fetch/fetch';

export const convertTextToCSVFormat = async (
    text: string,
    session_id: string
): Promise<any> => {
    const res = await axios.post(CONVERT_TEXT_TO_CSV_FORMAT, {
        text,
        session_id
    });
    return res.data;
};

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDHhtQ5zDPkakiyoDd1brDYpn0Xp0oMKtE",
  authDomain: "aimedrisk.firebaseapp.com",
  projectId: "aimedrisk",
  storageBucket: "aimedrisk.appspot.com",
  messagingSenderId: "759418036513",
  appId: "1:759418036513:web:49d136174584cd87d9de60",
  databaseURL: "https://aimedrisk-default-rtdb.firebaseio.com",
};

const initFirebaseApp = () => {
  return initializeApp(firebaseConfig);
};
export const database = getDatabase(initFirebaseApp());
export default initFirebaseApp;

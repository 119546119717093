import { Button } from "react-bootstrap";
import { HazardTopDownDto } from "../../types/HazardTopDown";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import ColumnHazards, { HazardColumnProps } from "../../Components/ColumnHazards/ColumnHazards";

export interface MissingRisksCategoriesProps {
    subCategoriesHazardData: HazardTopDownDto[];
    countCategoryResult: number[];
    isShowCounting: boolean;
    isShowMissingRisks: boolean;
    handleGenerateMissingRisks: (subCategoriesHazardData: HazardTopDownDto[], countCategoryResult: number[], subCategoriesTable: string) => void;
    subCategoriesTable: string;
    isGeneratingData: boolean;
    fileName: string;
}

export const renderMissingRisksTab = (props: MissingRisksCategoriesProps) => {
    return (
        <div className="row">
            <div className="col-11">
                {generateButtonGenerateMissingRisks(
                    props.subCategoriesHazardData,
                    props.countCategoryResult,
                    props.handleGenerateMissingRisks,
                    props.isGeneratingData,
                    props.subCategoriesTable
                )}
                {renderColumnHazardWithScore(
                    props.subCategoriesHazardData,
                    props.countCategoryResult,
                    props.isShowCounting,
                    props.isShowMissingRisks,
                    props.subCategoriesTable,
                    props.fileName
                )}
            </div>
        </div>
    );
};

const generateButtonGenerateMissingRisks = (
    subCategoriesHazardData: HazardTopDownDto[],
    countCategoryResult: number[],
    handleGenerateMissingRisks: (subCategoriesHazardData: HazardTopDownDto[], countCategoryResult: number[], subCategoriesTable: string) => void,
    isGeneratingData: boolean = false,
    subCategoriesTable: string = "C1"
) => {
    return (
        <div className="row">
            <div className="col-12">
                {generateButtonWithLoadingAndDisable(
                    isGeneratingData,
                    false,
                    () => {
                        handleGenerateMissingRisks(subCategoriesHazardData, countCategoryResult, subCategoriesTable);
                    },
                    "Generate Missing Risks"
                )}
            </div>
        </div>
    );
};

const generateButtonWithLoadingAndDisable = (isGeneratingData: boolean, isDisableButton: boolean, onClick: () => void, text: string) => {
    return (
        <Button onClick={onClick} disabled={isGeneratingData || isDisableButton}>
            {isGeneratingData ? <LoadingButton /> : text}
        </Button>
    );
};

export const renderColumnHazardWithScore = (
    data: HazardTopDownDto[],
    riskCountingForEachHazard: number[],
    isShowCounting: boolean = false,
    isShowMissingRisks: boolean = false,
    columnName: string = "C1",
    fileName: string
) => {
    const props: HazardColumnProps = {
        data: data,
        isGeneratingData: false,
        highlighedHazardIndexList: getHazardIndexListOfHazardsNeedToGenerateMissingRisk(data, riskCountingForEachHazard),
        isShowMissingRisks: false,
        isEditable: false,
        columnName: columnName,
        fileName: fileName,
    };
    if (isShowCounting) {
        props.riskCountingForEachHazard = riskCountingForEachHazard;
    }

    if (isShowMissingRisks) {
        props.isShowMissingRisks = true;
        if (columnName === "C1") {
            props.columnWidth = [5, 10, 30, 5, 5, 55];
        } else {
            props.columnWidth = [5, 20, 5, 5, 5, 60];
        }
    }

    return <ColumnHazards {...props} />;
};

const getHazardIndexListOfHazardsNeedToGenerateMissingRisk = (hazards: HazardTopDownDto[], c1Data: number[]) => {
    let result: number[] = [];
    for (let i = 0; i < c1Data.length; i++) {
        if (isNeedToGenerateRisk(hazards[i], c1Data[i])) {
            result.push(i);
        }
    }
    return result;
};

export const isNeedToGenerateRisk = (hazard: HazardTopDownDto, count: number) => {
    try {
        return Number(hazard.score) > 0 && count < 20;
    } catch (e) {
        return false;
    }
};

import { Action, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/auth-reducer";
import { ThunkAction } from "redux-thunk";
import sessionReducer from "./session/session-reducer";

const store = configureStore({
    reducer: {
        auth: authReducer,
        session: sessionReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default store;

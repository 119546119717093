import React, { useEffect, useState } from "react";
import { GroupAndSubGroups } from "../../types/GroupAndSubGroups";
import { Button, Table } from "react-bootstrap";
import {
    GROUP_AND_SUB_GROUP_TABLE_ID,
    GROUP_AND_SUB_GROUP_WRAPPER_CLASS,
    GROUP_COLUMN_NAME,
    INCIDENT_CLASSIFICATION_COLUMN_NAME,
    INCIDENT_INDEX,
    INCIDENT_INDEX_COLUMN_NAME,
    SUB_GROUP_COLUMN_NAME,
    INCIDENT_INDEX_WRAPPER_CLASS,
} from "./constants";
import { BUTTON_PROCESS_DATA_CLASS_NAME } from "../BottomUp1stColumn/constants";
import { HAZARD_TABLE_CLASS_NAME } from "../ColumnHazards/constants";
import "./styles.css";
import { BLANK_STRING, INPUT_IN_TABLE_CALSS_NAME, PROCESS_DATA_TEXT } from "../../constants/appConstants";
import LoadingButton from "../LoadingButton/LoadingButton";
import MasterTable from "../MasterTable/MasterTable";
interface Props {
    groupData: GroupAndSubGroups[];
    onGenerateHazardTable: () => void;
    isDisableButton: boolean;
    isProcessingData: boolean;
    selectIncident: (incidentIndex: number) => void;
    setGroupData: (data: GroupAndSubGroups[]) => void;
}

const renderIncidentIndex = (incidentIndex: number[], props: Props) => {
    return (
        <td className={INCIDENT_INDEX_WRAPPER_CLASS}>
            {incidentIndex
                ? incidentIndex.map((incident) => {
                      return (
                          <span className={INCIDENT_INDEX} onClick={() => props.selectIncident(incident)}>
                              {incident},
                          </span>
                      );
                  })
                : BLANK_STRING}
        </td>
    );
};

const GroupAndSubGroupsTable = (props: Props) => {
    const [csvData, setCsvData] = useState<any>();
    useEffect(() => {
        try {
            const newCsvData = props.groupData.flatMap((data) => {
                const groups: any = [];

                data.sub_groups.forEach((item, index) => {
                    const subGroup = item.sub_group_name;
                    const incidentIndex = item.incidents_index.join(",");

                    if (index === 0) {
                        groups.push({
                            [GROUP_COLUMN_NAME]: data.group,
                            [SUB_GROUP_COLUMN_NAME]: subGroup,
                            [INCIDENT_INDEX_COLUMN_NAME]: incidentIndex,
                        });
                    } else {
                        groups.push({
                            [GROUP_COLUMN_NAME]: BLANK_STRING,
                            [SUB_GROUP_COLUMN_NAME]: subGroup,
                            [INCIDENT_INDEX_COLUMN_NAME]: incidentIndex,
                        });
                    }
                });

                return groups;
            });

            setCsvData(newCsvData.flat());
        } catch (e) {
            console.log(e);
        }
    }, [props.groupData]);

    const handleGroupChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let newData = [...props.groupData];
        newData[index].group = event.target.value;
        props.setGroupData(newData);
    };

    const handleSubGroupChange = (groupIndex: number, subGroupIndex: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let newData = [...props.groupData];
        newData[groupIndex].sub_groups[subGroupIndex].sub_group_name = event.target.value;
        props.setGroupData(newData);
    };
    const renderButtonProcessData = () => {
        return (
            <Button
                disabled={props.isDisableButton || props.groupData.length == 0}
                onClick={() => props.onGenerateHazardTable()}
                className={BUTTON_PROCESS_DATA_CLASS_NAME}
            >
                {props.isProcessingData ? <LoadingButton /> : PROCESS_DATA_TEXT}
            </Button>
        );
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th style={{ minWidth: "6rem" }}>{GROUP_COLUMN_NAME}</th>
                    <th style={{ minWidth: "9rem" }}>{SUB_GROUP_COLUMN_NAME}</th>
                    <th>{INCIDENT_INDEX_COLUMN_NAME}</th>
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.groupData.map((group, groupIndex) => (
                    <>
                        <tr>
                            {renderGroupCell(group, groupIndex)}
                            {renderSubGroupCell(group, groupIndex)}
                            {renderIncidentIndex(group.sub_groups[0]?.incidents_index, props)}
                        </tr>
                        {renderOtherSubGroupCell(group, groupIndex)}
                    </>
                ))}
            </tbody>
        );
    };

    const renderGroupCell = (group: GroupAndSubGroups, groupIndex: number) => {
        return (
            <td rowSpan={group.sub_groups.length}>
                <textarea
                    className={INPUT_IN_TABLE_CALSS_NAME}
                    defaultValue={group.group}
                    onChange={(event) => handleGroupChange(groupIndex, event)}
                />
            </td>
        );
    };

    const renderSubGroupCell = (group: GroupAndSubGroups, groupIndex: number) => {
        return (
            <td>
                <textarea
                    className={INPUT_IN_TABLE_CALSS_NAME}
                    defaultValue={group.sub_groups[0]?.sub_group_name || ""}
                    onChange={(event) => handleSubGroupChange(groupIndex, 0, event)}
                />
            </td>
        );
    };

    const renderOtherSubGroupCell = (group: GroupAndSubGroups, groupIndex: number) => {
        return group.sub_groups.slice(1).map((subGroup, subGroupIndex) => (
            <tr>
                <td>
                    <textarea
                        className={INPUT_IN_TABLE_CALSS_NAME}
                        defaultValue={subGroup.sub_group_name}
                        onChange={(event) => handleSubGroupChange(groupIndex, subGroupIndex + 1, event)}
                    />
                </td>
                {renderIncidentIndex(subGroup.incidents_index, props)}
            </tr>
        ));
    };

    return (
        <div className={`${GROUP_AND_SUB_GROUP_WRAPPER_CLASS} col-3`}>
            <h4>{INCIDENT_CLASSIFICATION_COLUMN_NAME}</h4>
            {renderButtonProcessData()}
            <div className={HAZARD_TABLE_CLASS_NAME}>
                <MasterTable
                    id={GROUP_AND_SUB_GROUP_TABLE_ID}
                    fileName={INCIDENT_CLASSIFICATION_COLUMN_NAME}
                    data={csvData}
                    isGeneratingData={props.isProcessingData}
                >
                    <>
                        {renderTableHeader()}
                        {renderTableBody()}
                    </>
                </MasterTable>
            </div>
        </div>
    );
};

export default GroupAndSubGroupsTable;

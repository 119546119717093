export const NAV_LEFT_CLASS_NAME = "left-menu";
export const NAV_LEFT_MENU_ITEM = "left-menu-item";
export const ACTION_ITEM_SESSTION = "action-item-session";
export const NAV_LEFT_MENU_ITEM_EXPAND = "left-menu-item-expand";
export const DEFAULT_BACKGROUND_COLOR_OF_MENU_ITEM = "none";
export const BACKGROUND_COLOR_OF_SELECTED_ITEM = "#e9ecef";
export const BUTTON_SESSION_CLASS_NAME = "button-session";

export const MESSAGE_ADD_NEW_SESSION = "Enter a name for the new session";
export const MESSAGE_CONFIRM_DELETE_SESSION =
  "Are you sure you want to delete this item?";
export const MESSAGE_EDIT_SESSION = "Enter a new name for the session item";
export const NAVIGATOR_CONTROL_BUTTON = "navigator-control-button";
export const NAVIGATOR_MAIN_CLASSNAME = "navigator-main";
export const MAX_LEFT_MENU_WIDTH = "8.8rem";
export const MIN_LEFT_MENU_WIDTH = "3rem";
export const NAVIGATOR_ACTION_AND_MAIN = "navigator-action-and-main";

import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BRAND_NAME_PLACEHOLDER, FOI_TEXT_COLUMN, MAUDE_TABLE_CLASS_NAME } from "./constants";
import { MAUDE_TABLE_SEARCH_RESULT_ID } from "../../pages/BottomUp/constants";
import MasterTable from "../MasterTable/MasterTable";
import { MauDeRowData, Product } from "../../pages/BottomUp/types";

interface Props {
    data: MauDeRowData[];
    products: Array<Product>;
}

const MaudeTable = (props: Props) => {
    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>#</th>
                    <th>{FOI_TEXT_COLUMN}</th>
                </tr>
            </thead>
        );
    };

    const findBackgroundColor = (brandName: string) => {
        const product = props.products.find((product) => product.name === brandName);
        if (product) {
            return product.color;
        }
        return "white";
    };

    const renderTableBody = () => {
        return (
            <tbody>
                {props.data.map((row, index) => (
                    <tr
                        key={index}
                        style={{
                            backgroundColor: findBackgroundColor(row.brandName),
                        }}
                    >
                        <td>{index + 1}</td>
                        <td>{row.foi.toLowerCase()}</td>
                    </tr>
                ))}
            </tbody>
        );
    };
    const getCsvData = () => {
        return props.data.map((item) => {
            return {
                FOI_TEXT: item.foi.replace(/,/g, ""),
            };
        });
    };
    return (
        <MasterTable
            fileName={FOI_TEXT_COLUMN}
            data={getCsvData()}
            id={MAUDE_TABLE_SEARCH_RESULT_ID}
            isHover={true}
            isGeneratingData={false}
            className={MAUDE_TABLE_CLASS_NAME}
        >
            <>
                {renderTableHeader()}
                {renderTableBody()}
            </>
        </MasterTable>
    );
};

export default MaudeTable;

import { ref, get, set } from "firebase/database";
import { database } from "./index";

export const getLatestPageDataFromFirebase = async (
  userId: string,
  page: string,
  key: string,
  defaultData: any
) => {
  try {
    // const userRef = ref(database, `users/${userId}/${page}/${key}`);
    // console.log(userId, page, key, userRef)
    // const data = await get(userRef);
    // return data.val();
    return defaultData;
  } catch (e) {
    console.log(e);
    return defaultData;
  }
  
};


export const savePageStateToFirebase = async (
  userId: string,
  page: string,
  key: string,
  value: any
) => {
  const dbRef = ref(database, `users/${userId}/${page}/${key}`);
  set(dbRef, value);
};

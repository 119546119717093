import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { BOTTOM_UP_COLUMN_3RD_NAME, CATEGORY_COLUMN_NAME, GROUP_AND_SUB_GROUP_WRAPPER_CLASS, HAZARD_MASTER_LIST_MAUDE_TABLE_ID, POSSIBLE_HAZARDS_COLUMN_NAME, SUB_CATEGORY_COLUMN_NAME } from "./constants";
import { BUTTON_PROCESS_DATA_CLASS_NAME } from "../BottomUp1stColumn/constants";
import { HazardMasterListMaude } from "../../types/HazardMasterListMaude";
import { HAZARD_TABLE_CLASS_NAME } from "../ColumnHazards/constants";
import { INPUT_IN_TABLE_CALSS_NAME, PROCESS_DATA_TEXT } from "../../constants/appConstants";
import "./styles.css";
import LoadingButton from "../LoadingButton/LoadingButton";
import MasterTable from "../MasterTable/MasterTable";
interface Props {
  onGenrateHazardList: () => void;
  isDisableButton: boolean;
  isProcessingData: boolean;
  isGeneratingData: boolean;
  data: HazardMasterListMaude[];
  shoudDisplayButtonGenerate: boolean;
  onDataChange: (data: HazardMasterListMaude[]) => void;
}

const HazardMasterListMaudeTable = (props: Props) => {
  const tFootRef = useRef<any>(null);
  const [csvData, setCsvData] = useState<any>();
  useEffect(() => {
    try {
      if (props.isGeneratingData) {
        tFootRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
      const newCsvData = props.data.flatMap((data) => {
        return {
          [CATEGORY_COLUMN_NAME]: data.main_category,
          [SUB_CATEGORY_COLUMN_NAME]: data.sub_category,
          [POSSIBLE_HAZARDS_COLUMN_NAME]: data.hazards.join(),
        };
      });

      setCsvData(newCsvData.flat());
    } catch (e) {
      console.log(e);
    }
  }, [props.data]);

  const onMainCategoryChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newData = [...props.data];
    newData[index].main_category = event.target.value;
    props.onDataChange(newData);
  };

  const onSubCategoryChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newData = [...props.data];
    newData[index].sub_category = event.target.value;
    props.onDataChange(newData);
  };

  const onHazardChange = (index: number, hazards: string[]) => {
    let newData = [...props.data];
    newData[index].hazards = hazards;
    props.onDataChange(newData);
  };

  const renderMainCategoryCell = (mainCategory: string, index: number) => {
    return (
      <td>
        <textarea className={INPUT_IN_TABLE_CALSS_NAME} defaultValue={mainCategory} onChange={(e) => onMainCategoryChange(index, e)} />
      </td>
    );
  };

  const renderSubCategoryCell = (subCategory: string, index: number) => {
    return (
      <td>
        <textarea className={INPUT_IN_TABLE_CALSS_NAME} defaultValue={subCategory} onChange={(e) => onSubCategoryChange(index, e)} />
      </td>
    );
  };

  const renderHazardsCell = (hazards: string[], index: number) => {
    return (
      <td>
        {hazards &&
          hazards.map((hazard, hazardIndex) => {
            return (
              <textarea
                className={INPUT_IN_TABLE_CALSS_NAME}
                key={hazardIndex}
                defaultValue={hazard}
                onChange={(e) => {
                  const updatedHazards = [...hazards];
                  updatedHazards[hazardIndex] = e.target.value;
                  onHazardChange(index, updatedHazards);
                }}
              />
            );
          })}
      </td>
    );
  };

  const renderButtonProcessData = () => {
    return (
      <Button disabled={props.isDisableButton || props.data.length == 0} onClick={() => props.onGenrateHazardList()} className={BUTTON_PROCESS_DATA_CLASS_NAME}>
        {props.isProcessingData ? (
          <LoadingButton />
        ) : (
          PROCESS_DATA_TEXT
        )}
      </Button>
    );
  };
  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th>{CATEGORY_COLUMN_NAME}</th>
          <th>{SUB_CATEGORY_COLUMN_NAME}</th>
          <th>{POSSIBLE_HAZARDS_COLUMN_NAME}</th>
        </tr>
      </thead>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>
        {props.data.map((item, index) => (
          <tr key={index}>
            {renderMainCategoryCell(item.main_category, index)}
            {renderSubCategoryCell(item.sub_category, index)}
            {renderHazardsCell(item.hazards, index)}
          </tr>
        ))}
      </tbody>
    );
  };
  return (
    <div className={`${GROUP_AND_SUB_GROUP_WRAPPER_CLASS} col-4 col-sm-4`}>
      <h4>{BOTTOM_UP_COLUMN_3RD_NAME}</h4>
      {renderButtonProcessData()}
      <div className={HAZARD_TABLE_CLASS_NAME}>
        <MasterTable
          id={HAZARD_MASTER_LIST_MAUDE_TABLE_ID}
          fileName={BOTTOM_UP_COLUMN_3RD_NAME}
          data={csvData}
          isGeneratingData={props.isGeneratingData}
        >
          <>
            {renderTableHeader()}
            {renderTableBody()}
          </>
        </MasterTable>
      </div>
    </div>
  );
};

export default HazardMasterListMaudeTable;

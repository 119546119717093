import React, { useState, useEffect } from "react";
import { GENERATE_HAZARD_LIST_FROM_MAUDE } from "../../constants/api";
import HazardMasterListMaudeTable from "../../Components/BottomUp3rd/HazardMasterListMaudeTable";
import { HazardMasterListMaude } from "../../types/HazardMasterListMaude";
import HazardListTable from "../../Components/HazardListTable/HazardList";
import { COMBINE_CLASS_NAME, FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY } from "./constants";
import ColumnHazards from "../../Components/ColumnHazards/ColumnHazards";
import {
    APP_PAGES_KEY,
    BLANK_STRING,
    BOOTSTRAP_CONTAINER_FLUID,
    HAZARD_LIST_IN_COMBINE_PAGE,
    HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX,
    HAZARD_MASTER_LIST_MAUDE_IN_DB,
} from "../../constants/appConstants";
import "./style.css";
import { HazardTopDownDto } from "../../types/HazardTopDown";
import { getLatestPageDataFromFirebase, savePageStateToFirebase } from "../../apiRequests/firebase/utils";
import { AuthState } from "../../store/auth/auth-reducer";
import { useAppSelector } from "../../store/hooks";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import { ParamsFetchEventSource, commonFetchEventSource } from "../../utils/commonFetchEventSource";
import { convertIncompleteJSONToCompleteJSON, getSessionIdFromURL } from "../../utils/commonUtils";

const Combine = () => {
    const [hazardMasterListMaudeData, sethazardMasterListMaudeData] = useState<HazardMasterListMaude[]>([]);
    const [combinePageHazardListData, setHazardListCombinePage] = useState<string[]>([]);
    const [isGeneratingHazardList, setIsGeneratingHazardList] = useState<boolean>(false);
    const [hazardListInTopDownPage, setHazardListInTopDownPage] = useState<HazardTopDownDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const auth: AuthState = useAppSelector((state: any) => state.auth);
    const sessionIdFromUrl = getSessionIdFromURL();
    useEffect(() => {
        const loadDataFromFirebase = async () => {
            setIsLoading(true);
            sethazardMasterListMaudeData(await getHazardListInBottomUpPageFromFirebase());
            setHazardListCombinePage(await getCombineDataFromFirebase(HAZARD_LIST_IN_COMBINE_PAGE, []));
            setHazardListInTopDownPage(await getHazardListInTopDownPageFromFirebase());
            setIsLoading(false);
        };
        loadDataFromFirebase();
    }, []);

    const getHazardListInBottomUpPageFromFirebase = async () => {
        const data = await getLatestPageDataFromFirebase(auth.userId, APP_PAGES_KEY.BOTTOM_UP, HAZARD_MASTER_LIST_MAUDE_IN_DB, []);
        return data ? data : [];
    };

    const getHazardListInTopDownPageFromFirebase = async () => {
        const data = await getLatestPageDataFromFirebase(auth.userId, APP_PAGES_KEY.TOP_DOWN, HAZARD_LIST_IN_TOPDOWN_PAGE_PREFIX, []);
        return data ? data : [];
    };

    const getCombineDataFromFirebase = async (key: string, defaultData: any) => {
        const data = await getLatestPageDataFromFirebase(auth.userId, APP_PAGES_KEY.COMBINE, key, defaultData);
        return data ? data : defaultData;
    };

    const saveDataToFirebase = async (key: string, data: any) => {
        return savePageStateToFirebase(auth.userId, APP_PAGES_KEY.COMBINE, key, data);
    };

    function setHardListOfCombinePageDataAndSaveToStorage(data: string[]) {
        setHazardListCombinePage(data);
        saveDataToFirebase(HAZARD_LIST_IN_COMBINE_PAGE, data);
    }

    const combineTopDownAndHazardMasterListMaude = () => {
        return [
            ...hazardMasterListMaudeData,
            ...hazardListInTopDownPage
                .filter((hazard) => hazard.score != "0")
                .map((hazard) => {
                    return {
                        main_category: hazard.category,
                        hazard: [hazard.description],
                        sub_category: BLANK_STRING,
                    };
                }),
        ];
    };

    const generateHazardList = async () => {
        const hazardTopDownWithHazardMasterListMaude = combineTopDownAndHazardMasterListMaude();
        const paramsFetchEventSource: ParamsFetchEventSource = {
            url: GENERATE_HAZARD_LIST_FROM_MAUDE,
            sessionIdFromUrl: sessionIdFromUrl,
            onOpen: () => {
                setIsGeneratingHazardList(true);
            },
            onMessage: function (fullMessage: string): void {
                const hazard = convertIncompleteJSONToCompleteJSON(fullMessage, [], []);
                setHardListOfCombinePageDataAndSaveToStorage(JSON.parse(hazard));
            },
            onClose: function (): void {
                setIsGeneratingHazardList(false);
            },
            onError: () => {
                setIsGeneratingHazardList(false);
            },
            formDataKeyValue: [
                {
                    key: FORM_DATA_HAZARD_MASTER_LIST_MAUDE_KEY,
                    value: JSON.stringify(hazardTopDownWithHazardMasterListMaude),
                },
            ],
        };
        commonFetchEventSource(paramsFetchEventSource);
    };
    const renderColumnHazards = () => {
        return (
            <ColumnHazards
                data={hazardListInTopDownPage}
                isGeneratingData={false}
                highlighedHazardIndexList={[]}
                isShowMissingRisks={false}
                columnName={""}
                fileName=""
            />
        );
    };
    const renderHazardMasterListMaudeTable = () => {
        return (
            <HazardMasterListMaudeTable
                onDataChange={sethazardMasterListMaudeData}
                data={hazardMasterListMaudeData}
                isProcessingData={isGeneratingHazardList}
                isDisableButton={isGeneratingHazardList}
                onGenrateHazardList={generateHazardList}
                shoudDisplayButtonGenerate={false}
                isGeneratingData={false}
            />
        );
    };

    const renderHazardListTable = () => {
        return <HazardListTable data={combinePageHazardListData} customClassName="col-4" isGeneratingData={isGeneratingHazardList} />;
    };

    const renderContent = () => {
        return (
            <div className="row w-100">
                {renderColumnHazards()}
                {renderHazardMasterListMaudeTable()}
                {renderHazardListTable()}
            </div>
        );
    };

    return <div className={`${COMBINE_CLASS_NAME} ${BOOTSTRAP_CONTAINER_FLUID}`}>{isLoading ? <LoadingPage /> : renderContent()}</div>;
};

export default Combine;

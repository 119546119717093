import axios from "../fetch/fetch";
import { UPDATE_SESSION_NAME } from "../../constants/api";

export const updateSessionNameAPI = async (
  sessionId: string,
  sessionName: string
): Promise<void> => {
  const body = {
    sessionName: sessionName,
  };

  const response = await axios.put(`${UPDATE_SESSION_NAME}/${sessionId}`, body);
  return response.data;
};

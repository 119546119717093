import { PAGE_DESIGN_WIDTH_IN_PIXEL } from "../../constants/appConstants";

export const keywords_COLUMN_CLASS_NAME = "keywords-column";
export const NAV_LEFT_MENU_ITEM = "left-menu-item";
export const DEFAULT_BACKGROUND_COLOR_OF_MENU_ITEM = "white";
export const BACKGROUND_COLOR_OF_SELECTED_ITEM = "antiquewhite";
export const keywords_COLUMN_NAME = "keywords-column-name";
export const keywords_COLUMN_INPUT = "mb-3 keywords-column-input";
export const BUTTON_PROCESS_DATA_CLASS_NAME = "bottom-up-column1-process-data-button";
export const MAUDE_TABLE_CLASS_NAME = "maude-table";
export const KEYWORD_PLACEHOLDER = "Insert your keywords here";
export const BRAND_NAME_PLACEHOLDER = "Insert the brand name here";
export const ARIA_LABEL_KEYWORD = "keywords";
export const ARIA_LABEL_BRANDNAME = "brandname";
export const BUTTON_START_SEARCH_TEXT = "Start Search";
export const INPUT_LABEL_KEYWORD = "Keyword";
export const INPUT_LABEL_BRAND_NAME = "Brand Name";

export const FOI_TEXT_COLUMN = "FOI Text";


export const SEDLINE_MOCK_DATA = [
  [
    "4170474",
    14880243,
    "D",
    1,
    null,
    "CUSTOMER REPORTED THAT \"JUST WANTED YOU TO KNOW THAT I HAD A PT WITH THIS READ OUT ON HER SEDLINE. ISOELECTRIC EEG (SEE PIC BELOW), PRESSED ON THE DOTS AND GOT A REACTION THROUGH THE CABLE TO THE DEVICE, ALL THE DOTS WERE GREEN ON THE MONITOR DEMONSTRATING GOOD CONTACT, SO WE WERE THINKING SHE HAD A BAD PROBLEM. I CHANGED THE SWEEP SPEED AND VOLTAGE JUST TO BE CERTAIN THAT WASN'T AN ISSUE, BUT THE READOUT REALLY DIDN'T CHANGE. AFTER DISCUSSION WITH DR RAMSAY AND OUR PHARMACIST WE STARTED TITRATING DOWN HER BENZOS AND FENTANYL. RETURNED TO SEE THE PT SEVERAL HOURS LATER AND EEG READOUT WAS UNCHANGED. I DISCUSSED THE CASE WITH OUR INTENSIVIST AND HE PUT IN AN ORDER FOR A FULL EEG. WHILE WAITING, OUT OF CURIOSITY, I CHANGED THE BOX AND THE CABLE AND STARTED GETTING THIS READOUT. SWITCHED THE NEW CABLE TO THE OLD BOX, AND THE EEG CONTINUED TO READ CORRECTLY, NO LONGER ISOELECTRIC. THIS IS NOT THE FIRST TIME I HAVE HAD THIS ISSUE WITH A CABLE THAT IS NOT PICKING UP THE EEG CORRECTLY, YET WE ARE STILL GETTING PSI AND DSA. THERE DOESN'T APPEAR TO BE ANY WARNINGS THAT THERE IS A CABLE ISSUE. I HAVE THE CABLE ON MY DESK. PLEASE ADVISE.\"",
    "SEDLINE MODULE PATIENT CABLE",
  ],
  [
    "4170474",
    14880243,
    "D",
    1,
    null,
    "CUSTOMER REPORTED THAT \"JUST WANTED YOU TO KNOW THAT I HAD A PT WITH THIS READ OUT ON HER SEDLINE. ISOELECTRIC EEG (SEE PIC BELOW), PRESSED ON THE DOTS AND GOT A REACTION THROUGH THE CABLE TO THE DEVICE, ALL THE DOTS WERE GREEN ON THE MONITOR DEMONSTRATING GOOD CONTACT, SO WE WERE THINKING SHE HAD A BAD PROBLEM. I CHANGED THE SWEEP SPEED AND VOLTAGE JUST TO BE CERTAIN THAT WASN'T AN ISSUE, BUT THE READOUT REALLY DIDN'T CHANGE. AFTER DISCUSSION WITH DR RAMSAY AND OUR PHARMACIST WE STARTED TITRATING DOWN HER BENZOS AND FENTANYL. RETURNED TO SEE THE PT SEVERAL HOURS LATER AND EEG READOUT WAS UNCHANGED. I DISCUSSED THE CASE WITH OUR INTENSIVIST AND HE PUT IN AN ORDER FOR A FULL EEG. WHILE WAITING, OUT OF CURIOSITY, I CHANGED THE BOX AND THE CABLE AND STARTED GETTING THIS READOUT. SWITCHED THE NEW CABLE TO THE OLD BOX, AND THE EEG CONTINUED TO READ CORRECTLY, NO LONGER ISOELECTRIC. THIS IS NOT THE FIRST TIME I HAVE HAD THIS ISSUE WITH A CABLE THAT IS NOT PICKING UP THE EEG CORRECTLY, YET WE ARE STILL GETTING PSI AND DSA. THERE DOESN'T APPEAR TO BE ANY WARNINGS THAT THERE IS A CABLE ISSUE. I HAVE THE CABLE ON MY DESK. PLEASE ADVISE.\"",
    "SEDLINE MODULE PATIENT CABLE",
  ],
  [
    "4465518",
    5183973,
    "D",
    1,
    null,
    'CUSTOMER REPORTED: "PSI AROUND 25 AND MESS-UP DSA WAVEFORM CAME UP REGARDLESS OF ALERT WAKEFULNESS CONDITION BY THE MODULE" OBSERVED WHILE SALES REP WAS CHECKING EQUIPMENT. THERE WAS NO PT INVOLVEMENT.',
    "SEDLINE",
  ],
  [
    "5193716",
    30495060,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT (B)(6) FELT THE "BIS NUMBER" IS NOT ALIGNING OR CORRESPONDING WITH HER PATIENTS STATE DURING THE VARIOUS STAGES OF HER CASES. SHE FEELS THAT SEDLINE IS TAKING TOO LONG TO REACT AND THAT HER PATIENTS ARE MOVING WHEN THE NUMBER CLEARLY STATES THAT THEY SHOULD BE WELL WITHIN THE AMNESTIC RANGE. SHE ALSO STATED THAT THIS IS NOT PATIENT SPECIFIC, OR DEVICE SPECIFIC AND SHE JUST DOESN\'T FEEL COMFORTABLE USING SEDLINE WITHOUT FURTHER EDUCATION. THE DEVICE ARE NOT BEING RETURNED BECAUSE THEY ARE IN EVERY OPERATING ROOM AND ARE BEING USED BY OTHER ANESTHESIA STAFF WITHOUT COMPLAINT. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE MODULE",
  ],
  [
    "4694400",
    5671333,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT THE "DISPLAYED VALUE IS NOT ACCURATE." CUSTOMER STATED "AFTER APPLYING ANESTHETIC AGENT, PSI STILL REMAINS > 50. NORMALLY, THE PSI WOULD BE 50 OR LOWER." NO KNOWN IMPACT OR CONSEQUENCE TO PT.',
    "SEDLINE MONITOR",
  ],
  [
    "4694397",
    5671343,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT THE "DISPLAYED VALUE IS NOT ACCURATE." CUSTOMER STATED "AFTER APPLYING ANESTHETIC AGENT, PSI STILL REMAINS > 50. NORMALLY, THE PSI WOULD BE 50 OR LOWER." NO KNOWN IMPACT OR CONSEQUENCE TO PT.',
    "SEDLINE MODULE",
  ],
  [
    "5193731",
    30018726,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT DR (B)(6) FELT THE "BIS NUMBER" IS NOT ALIGNING OR CORRESPONDING WITH HER PATIENTS STATE DURING THE VARIOUS STAGES OF HER CASES. SHE FEELS THAT SEDLINE IS TAKING TOO LONG TO REACT AND THAT HER PATIENTS ARE MOVING WHEN THE NUMBER CLEARLY STATES THAT THEY SHOULD BE WELL WITHIN THE AMNESTIC RANGE. SHE ALSO STATED THAT THIS IS NOT PATIENT SPECIFIC, OR DEVICE SPECIFIC AND SHE JUST DOESN\'T FEEL COMFORTABLE USING SEDLINE WITHOUT FURTHER EDUCATION. THE DEVICE ARE NOT BEING RETURNED BECAUSE THEY ARE IN EVERY OR AND ARE BEING USED BY OTHER ANESTHESIA STAFF WITHOUT COMPLAINT. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE MODULE",
  ],
  [
    "5193725",
    30018890,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT DR (B)(6) FELT THE \"BIS NUMBER\" IS NOT ALIGNING OR CORRESPONDING WITH HER PATIENTS' STATE DURING THE VARIOUS STAGES OF HER CASES. SHE FEELS THAT SEDLINE IS TAKING TOO LONG TO REACT AND THAT HER PATIENTS ARE MOVING WHEN THE NUMBER CLEARLY STATES THAT THEY SHOULD BE WELL WITHIN THE AMNESTIC RANGE. SHE ALSO STATED THAT THIS IS NOT PATIENT SPECIFIC, OR DEVICE SPECIFIC AND SHE JUST DOESN'T FEEL COMFORTABLE USING SEDLINE WITHOUT FURTHER EDUCATION. THE DEVICE ARE NOT BEING RETURNED BECAUSE THEY ARE IN EVERY OR AND ARE BEING USED BY OTHER ANESTHESIA STAFF WITHOUT COMPLAINT. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE MODULE",
  ],
  [
    "5193749",
    30019118,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT DR ACEVEDO FELT THE "BIS NUMBER" IS NOT ALIGNING OR CORRESPONDING WITH HER PATIENTS STATE DURING THE VARIOUS STAGES OF HER CASES. SHE FEELS THAT SEDLINE IS TAKING TOO LONG TO REACT AND THAT HER PATIENTS ARE MOVING WHEN THE NUMBER CLEARLY STATES THAT THEY SHOULD BE WELL WITHIN THE AMNESTIC RANGE. SHE ALSO STATED THAT THIS IS NOT PATIENT SPECIFIC, OR DEVICE SPECIFIC AND SHE JUST DOESN\'T FEEL COMFORTABLE USING SEDLINE WITHOUT FURTHER EDUCATION. THE DEVICE ARE NOT BEING RETURNED BECAUSE THEY ARE IN EVERY OR AND ARE BEING USED BY OTHER ANESTHESIA STAFF WITHOUT COMPLAINT. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE MODULE",
  ],
  [
    "5194870",
    30058882,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT DR (B)(6) FELT THE "BIS NUMBER" IS NOT ALIGNING OR CORRESPONDING WITH HER PATIENTS STATE DURING THE VARIOUS STAGES OF HER CASES. SHE FEELS THAT SEDLINE IS TAKING TOO LONG TO REACT AND THAT HER PATIENTS ARE MOVING WHEN THE NUMBER CLEARLY STATES THAT THEY SHOULD BE WELL WITHIN THE AMNESTIC RANGE. SHE ALSO STATED THAT THIS IS NOT PATIENT SPECIFIC, OR DEVICE SPECIFIC AND SHE JUST DOESN\'T FEEL COMFORTABLE USING SEDLINE WITHOUT FURTHER EDUCATION. THE DEVICE ARE NOT BEING RETURNED BECAUSE THEY ARE IN EVERY OR AND ARE BEING USED BY OTHER ANESTHESIA STAFF WITHOUT COMPLAINT. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE MODULE",
  ],
  ["5226869", 31330576, "D", 1, null, "IT WAS REPORTED THAT THE EEG IS DAMAGED. THE SIGNAL GIVES BAD VALUES. THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE KIT"],
  [
    "5485277",
    39839196,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE PATIENT HAS DEVELOPED A DTI (DEEP TISSUE INJURY) WHERE THE SEDLINE SENSOR WAS PLACED WHILE THE PATIENT WAS IN THE PRONE POSITION.",
    "SEDLINE SENSOR",
  ],
  [
    "5243901",
    31967302,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE PSI MEASUREMENT VALUE SHOWED AROUND LATTER HALF OF 70 - 80, THE PATIENT WAS NOT RECOVERED FROM ANESTHESIA (SO, IT SHOULD BE 25 - 50). THE BIS MONITOR SHOWED 40-60 AS ACCEPTABLE RANGE AT THE PATIENT MONITORING. THE PHYSICIAN HAD MONITORED 5 TIMES, BUT, SAME CONDITION SHOWED UP. NO ALERT OR/AND ERROR MESSAGE CAME UP. THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE KIT",
  ],
  ["5299146", 33560020, "D", 1, null, 'THE CUSTOMER REPORTED "FAILED SEDLINE CABLE, WORKS INTERMITTENTLY". THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.', "SEDLINE KIT"],
  [
    "5335280",
    34682362,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE ANESTHESIA FIGURES IS ABNORMALLY DISPLAYED ON THE MONITOR. THEIR CUSTOMER SAID 'THE PATIENT IS DEFINITELY ANESTHETIZED BUT ROOT-SEDLINE SHOWS 90 LEVELS OF ANESTHESIA'. THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE",
  ],
  [
    "5355936",
    35491430,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT WE GOT EMG WHEN THE PATIENT WAS UNDER NMB, AND WHILE THE SURGEON USED THE ELECTROCAUTERISATION. DURING THIS TIME, WE HAVE NEVER GOT ART. THE MEDICAL TEAM HAS TOLD US THAT THEIR MRI SYSTEM IS INSTALLED, IN THE HOSPITAL, EXACTLY AT THE FLOOR UNDERNEATH THE OPERATIVE ROOMS. THEY HAVE ALREADY OBSERVED ANY ELECTRONICAL/TECHNICAL ABNORMALITIES WITH OTHER DEVICES WHEN THE MRI IS WORKING. THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE KIT",
  ],
  [
    "5384718",
    36590322,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THIS CUSTOMER IS USING ROOT SEDLINE SINCE A YEAR. THE DOCTOR INITIATED AN ANESTHESIA FOR A PLASTIC SURGERY CASE. AFTER CLEANING THE SKIN WITH ALCOHOL HE ATTACHED A SEDLINE ELECTRODE. DUE TO INITIAL ARTIFACTS BEFORE INDUCTION, NO DSA WAS VISIBLE. INTUBATION WAS DIFFICULT AND TAKES SOME TIME. ANESTHESIA WAS DONE WITH DIPRIVAN (PROPOFOL), SUFENTANYL, SOME LOW DOSE OF KETAMINE, NO CURARE WAS USED. FROM THE BEGINNING, HE OBSERVED A STRONG DSA DISSYMMETRY. CONTACT QUALITY WAS GOOD, ALL ELECTRODES WERE GREEN. NO ARTF, NO EMG, L EEG TRACE WAS NOISY, WHICH I ASSUME IS THE REASON OF THE STRANGE ASPECT OF THE L- DSA. DESPITE THE FACT THAT THE PATIENT WAS NOT SWEATING AND CONTACT QUALITY WAS OK (ACCORDING TO THE ROOT DISPLAY), HE TRIED TO PRESS ON THE VARIOUS ELECTRODES, NO EFFECT. ANXIOUS, ESPECIALLY BECAUSE THE INTUBATION WAS NOT THAT EASY, THE DOCTOR PLACED INVOS NIRS ELECTRODES AND GOT REASSURING VALUES OF 72-75 ON BOTH SIDE, NOT INDICATING REALLY ABNORMAL DISSYMMETRICAL BRAIN ACTIVITY. HE THEN DECIDED TO REPLACE THE SEDLINE ELECTRODES AND THEN GOT A CORRECT SIGNAL TAKEN FEW MINUTES AFTER ELECTRODES REPLACEMENT. THIS CUSTOMER ALSO OBSERVED THAT SINCE FEW WEEKS (MORE OR LESS WHEN THE PACKAGING CHANGED), THEY ARE UNDER THE IMPRESSION THAT THE ADHESIVENESS OF THE SEDLINE ELECTRODES ARE NOT AS GOOD AS THEY WERE BEFORE. ELECTRODES SEEMS LESS STICKY, ESPECIALLY THE 2 LATERALS (L2-R2) AND THE 2 MEDIANS (CB-CT), THAT, SEVERAL MINUTES AFTER APPLICATION, THEY HAVE TO PRESS AGAIN ON THE ADHESIVE TO INSURE GOOD CONTACT, AND SOMETIME HAVE TO REPLACE THE ELECTRODE. THIS WAS NOT THE CASE WHEN THEY STARTED USING SEDLINE IN (B)(6) 2015. WE REVIEWED APPLICATION PROCEDURE (CLEANING WITH ALCOHOL AND DRYING, STORAGE CONDITIONS NOT TOO WARM). NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE EEG ELECTRODE",
  ],
  ["5411871", 37525487, "D", 1, null, "IT WAS REPORTED THAT THE ROOT DOES NOT DISPLAY SEDLINE CURVE AND MEASUREMENTS PROPERLY. THERE WERE NO CONSEQUENCES OR IMPACT TO PATIENT REPORTED.", "SEDLINE"],
  [
    "5300853",
    34352427,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT DESPITE NORMAL MANAGEMENT OF ANESTHESIA, ABNORMAL SEDLINE VALUES WITHOUT CORRELATION WITH ANESTHESIA DELIVERY WERE OBSERVED. THERE WAS NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE KIT",
  ],
  ["5810727", 50074694, "D", 1, null, "IT WAS REPORTED THAT THE DATA ARE ASYMMETRIC. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE MODULE"],
  [
    "5622691",
    44122930,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE EEG MEASUREMENT FAILED. ADDITIONAL INFORMATION RECEIVED FROM THE DISTRIBUTOR INDICATED THAT POSSIBLY THE VALUES DID NOT APPEAR AS EXPECTED. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE MODULE",
  ],
  ["5810736", 50075077, "D", 1, null, "IT WAS REPORTED THAT THE R1 DERIVATION DOES NOT MEASURE PROPERLY. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE MODULE"],
  ["6110248", 60254759, "D", 1, null, "IT WAS REPORTED THAT THERE WAS MEASUREMENT FAILURES. BROKEN PROTECTOR AND CABLE DAMAGED. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE"],
  ["6080882", 59219474, "D", 1, null, "THE CUSTOMER REPORTED SHARP EDGES ON SEDLINE SENSOR (PIGTAIL PORTION) CAUSE PRESSURE IRRITATION TO PATIENT'S FOREHEAD IN THE OPERATING ROOM.", "SEDLINE"],
  [
    "5832071",
    50713560,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE DEVICE DISPLAYED SIGNAL ARTIFACTS AND BLURS. ADDITIONALLY, THE EMG DATA IS INACCURATE. THE SEDLINE MODULE WAS REPLACED AND A GOOD SIGNAL WAS DISPLAYED WITH CORRECT DATA. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE MODULE",
  ],
  [
    "5878555",
    52189526,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT TWO SEDLINE SENSORS DID NOT WORK DURING THE FIRST SURGERY. THE L1 [ELECTRODE ICON] WERE CONSTANTLY BLUE AND OTHERS [ELECTRODE ICON] WERE YELLOW AND A FEW WERE GREEN. THE CUSTOMER TRIED USING DIFFERENT SEDLINE SENSORS AND THE RESULTS WERE THE SAME. DURING A SECOND SURGERY, THE L1 [ELECTRODE ICON] WAS BLUE IN THE BEGINNING AND THE OTHER [ELECTRODE ICONS] WERE GREEN. SUDDENLY, AFTER THE SURGEON USED ELECTROCAUTERY, THE L1 TURNED GREEN AND THE SEDLINE SENSOR BEGAN TO FUNCTION. HOWEVER, AFTER A FEW MINUTES IT STOPPED, THEN IT WORKED AFTER THE SURGEON USED ELECTROCAUTERY AGAIN. IT SEEMS LIKE THE SENSORS WITH THIS LOT NUMBER IS THE PROBLEM AS ALL SENSOR L1 [ELECTRODE ICON] WERE BLUE. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE SENSOR",
  ],
  [
    "5878579",
    52190425,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT TWO SEDLINE SENSORS DID NOT WORK DURING THE FIRST SURGERY. THE L1 [ELECTRODE ICON] WERE CONSTANTLY BLUE AND OTHERS [ELECTRODE ICON] WERE YELLOW AND A FEW WERE GREEN. THE CUSTOMER TRIED USING DIFFERENT SEDLINE SENSORS AND THE RESULTS WERE THE SAME. DURING A SECOND SURGERY, THE L1 [ELECTRODE ICON] WAS BLUE IN THE BEGINNING AND THE OTHER [ELECTRODE ICONS] WERE GREEN. SUDDENLY, AFTER THE SURGEON USED ELECTROCAUTERY, THE L1 TURNED GREEN AND THE SEDLINE SENSOR BEGAN TO FUNCTION. HOWEVER, AFTER A FEW MINUTES IT STOPPED, THEN IT WORKED AFTER THE SURGEON USED ELECTROCAUTERY AGAIN. IT SEEMS LIKE THE SENSORS WITH THIS LOT NUMBER IS THE PROBLEM AS ALL SENSOR L1 [ELECTRODE ICON] WERE BLUE. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE SENSOR",
  ],
  ["6049453", 58133417, "D", 1, null, "THE CUSTOMER REPORTED THAT THE SEDLINE PROVIDES INACCURATE READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  ["6115382", 60423713, "D", 1, null, "IT WAS REPORTED THAT THERE WERE MEASUREMENT FAILURES. BROKEN PROTECTOR AND CABLE DAMAGED. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE"],
  [
    "6113599",
    60427380,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE SEDLINE WAS UNABLE TO GET THE VALUE OF ARTF. THE ARTF WAS NOT INCREASE OR DECREASE AT ALL. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.",
    "SEDLINE",
  ],
  ["6111079", 60292114, "D", 1, null, "IT WAS REPORTED THAT THERE WAS MEASUREMENT FAILURES, BROKEN PROTECTOR AND INTERMITTENT FAILURE. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE"],
  ["6116821", 60549527, "D", 1, null, "IT WAS REPORTED THAT THERE WAS MEASUREMENT FAILURES, BROKEN PROTECTOR AND CABLE DAMAGED. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE"],
  [
    "6395067",
    69609057,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT "THE CUSTOMER HAD A NUMBER OF FAULTY SENSORS AGAIN WHICH WERE ON HIS DESK. CONFIRMED ISSUE IS SAME AS REPORTED PREVIOUSLY WHEREBY THE DISPLAY DROPS OUT DURING PROCEDURE AND DOESN\'T RETURN UNTIL AFTER SENSOR HAS BEEN CHANGED AT LEAST ONCE". ADDITIONAL INFORMATION RECEIVED, "NO ERROR MESSAGE OR AUDIBLE ALARM WAS OBSERVED". NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE SENSOR",
  ],
  ["6117098", 60631289, "D", 1, null, "IT WAS REPORTED THAT THERE WAS MEASUREMENT FAILURES. WITH THE SEDLINE TESTER, NO STRAIGHT LINE IS SHOWN. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE"],
  ["6170056", 62261068, "D", 1, null, "IT WAS REPORTED THAT ABNORMAL PEAKS SHOWN DURING MEASUREMENTS, VALUES NOT RELIABLE. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.", "SEDLINE MODULE"],
  ["6211815", 63470228, "D", 1, null, "THE CUSTOMER REPORTED RETURN OF A DEFECTIVE SEDLINE V2 : INTERMITTENT ISSUE. LOOSE CONNECTION. NO PATIENT IMPACT OR CONSEQUENCE REPORTED.", "SEDLINE"],
  ["6231971", 64128464, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE STOPPED MEASURING, AND NO READING COULD BE OBTAINED. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  [
    "6269116",
    65458906,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THAT SOMETIMES THE EEG SIGNAL DISAPPEARS, AND THE BEND RELIEF SLEEVE HAS COME LOOSE FROM THE MODULE, LEAVING EXPOSED WIRES. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE",
  ],
  [
    "6296368",
    66361447,
    "D",
    1,
    null,
    'CUSTOMER REPORTED THAT "THIS EQUIPMENT APPEARS TO HAVE A HIGH LEVEL OF SYMPTOMS THAN OTHER ROOT + SEDLINE MODULE EQUIPMENT." CUSTOMER INDICATED THAT ALTHOUGH THE PATIENT WAS UNDER ANESTHESIA, THE PSI WAS ABOVE 90. ADDITIONALLY, THE SENSOR IS NOT BEING RECOGNIZED. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE MODULE",
  ],
  ["6325664", 67249885, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE WILL NOT STAY POWERED ON. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE MONITOR"],
  [
    "6352668",
    68132787,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE DOCTOR TRADITIONALLY USES THE EMG AS A LOGARITHMIC SCALE ON THE BIS. MEANING THAT WHEN THE EMG (WITHOUT THE INTERFERENCE OF DIATHERMIA) IS ABOVE 30%, IT IS AN INDICATION OF A PAIN NOXIOUS STIMULI. THEREBY, HE WOULD TREAT THE PATIENT WITH ANALGESIA RATHER THAN SEDATION. HOWEVER, WITH THE SEDLINE EMG, THE DOCTOR NOTICED WHEN USING IT ON A LAP CHOL WITHOUT THE USE OF A SPINAL OR A BLOCK, THE SEDLINE EMG REMAINED AT 0% THROUGHOUT THE CASE. WHEREAS THE BIS WAS INDICATING THAT THE PATIENT HAD NOXIOUS STIMULI, WHICH THE DOCTOR FELT FITTED TO THE CLINICAL SITUATION. HE HAS SEEN THIS SITUATION DURING SEVERAL OTHER CASES.",
    "SEDLINE",
  ],
  [
    "6355381",
    68221071,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED MULTIPLE EVENTS OF INTERRUPTION OF MONITORING WITH SENSORS. LIMITED INFORMATION REGARDING THE NUMBER OF PATIENTS INVOLVED OR NUMBER OF EVENTS WAS PROVIDED; HOWEVER THE CUSTOMER HAS INDICATED THAT THE ISSUE HAS OCCURRED IN MULTIPLE OPERATING THEATERS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE SENSOR",
  ],
  [
    "6540942",
    74315249,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT "THE CUSTOMER IS COMPLAINING ABOUT INTERMITTENT FAILURE: EEG TRACES VISIBLE ON THE SCREEN, BUT NO PSI CALCULATION, DESPITE CLEAR WAVEFORMS, GOOD QUALITY CONTACT (GREEN ELECTRODES INDICATOR) AND NO EMG OR ARTF. THE ISSUE CAN OCCUR FROM THE BEGINNING OF THE MONITORING PERIOD OR IN THE MIDDLE OF A SURGERY".  NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT WAS REPORTED.',
    "SEDLINE",
  ],
  [
    "6503695",
    73237009,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT THE CUSTOMER EXPERIENCED A LOT OF INTERFERENCE ON THE EEG SIGNAL, A DISTURBED PSI, AND SOME TIMES LATER NO VALUE. CUSTOMER HAS A LOT OF MEASUREMENT PROBLEMS. THERE ARE POSSIBLE MULTIPLE INTERFERENCE IN THE ROOMS. THE CABLES WERE POSSIBLY CLEANED WITH A DAMP SHEET. ADDITIONAL INFORMATION RECEIVED INDICATED THAT AT THE BEGINNING, THE PSI VALUE WAS DIFFICULT TO OBTAIN ON THE SCREEN. SOMETIMES THE PSI APPEARED AND SEEM TO BE ACCURATE AND SOMETIMES THE VALUE WAS INACCURATE. THE EEG LINE WAS ONLY A RIGHT LINE AND NO PSI VALUE. THERE WERE NO ERROR MESSAGES FOR THE ELECTRODE STATUS AND NO AUDIBLE ALARM. WHEN THE PSI WAS "----", THE EEG HAD A RIGHT LINE, THERE WAS AN ERROR MESSAGE "NO CABLE PATIENT CONNECTED". NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE PATIENT CABLE",
  ],
  [
    "6503700",
    73243154,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT THE CUSTOMER EXPERIENCED A LOT OF INTERFERENCE ON THE EEG SIGNAL, A DISTURBED PSI, AND SOME TIMES LATER NO VALUE. CUSTOMER HAS A LOT OF MEASUREMENT PROBLEMS. THERE ARE POSSIBLE MULTIPLE INTERFERENCE IN THE ROOMS. THE CABLES WERE POSSIBLY CLEANED WITH A DAMP SHEET. ADDITIONAL INFORMATION RECEIVED INDICATED THAT AT THE BEGINNING, THE PSI VALUE WAS DIFFICULT TO OBTAIN ON THE SCREEN. SOMETIMES THE PSI APPEARED AND SEEM TO BE ACCURATE AND SOMETIMES THE VALUE WAS INACCURATE. THE EEG LINE WAS ONLY A RIGHT LINE AND NO PSI VALUE. THERE WERE NO ERROR MESSAGES FOR THE ELECTRODE STATUS AND NO AUDIBLE ALARM. WHEN THE PSI WAS "----", THE EEG HAD A RIGHT LINE, THERE WAS AN ERROR MESSAGE "NO CABLE PATIENT CONNECTED". NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT.',
    "SEDLINE PATIENT CABLE",
  ],
  [
    "6565028",
    75091824,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED THAT "WHEN THE CABLE MOVES JUST SO, MEANING IT BENDS JUST A LITTLE ON EITHER SIDE OF THE SEDLINE BRAIN, SO EITHER THE PATIENT SIDE OF THE CABLE OR THE OTHER SIDE. THE SIGNAL DROPS OUT AND NO INFORMATION IS BEING PROVIDED ON THE SCREEN". NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT WAS REPORTED.',
    "SEDLINE KIT",
  ],
  [
    "6607107",
    76509361,
    "D",
    1,
    null,
    'THE FOLLOWING ISSUE WAS REPORTED FROM THE CUSTOMER. "AN (B)(6) YEAR OLD THORACIC CASE. DESPITE STANDARD DRUGS HAD A PERSISTENT READING OF 85 PSI WITH SEFL OF 24 ON BOTH SIDES. FURTHER BONUS OF PROPOFOL 200 + FENT 100 + ET SEV INCREASED TO 2.9 (MAC 2.0) - NO CHANGE. BP DOWN . ABANDONED RELIANCE ON SED AND SEV REDUCED TO MAC 1 FURTHER 5 MINS LATER SED DOWN TO 35 BUT INTERMITTENTLY BACK UP TO 65. EVENTUALLY SETTLED READINGS." NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT WAS REPORTED.',
    "SEDLINE",
  ],
  [
    "6572100",
    75345995,
    "D",
    1,
    null,
    'IT WAS REPORTED THAT "THERE WAS SOME CASES THAT PSI GOT HIGH ALTHOUGH THE PATIENT WAS UNDER SEDATION". NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT WAS REPORTED.',
    "SEDLINE",
  ],
  ["6806473", 83096771, "D", 1, null, "THE CUSTOMER REPORTED PATIENT IS ANESTHETIZED ENOUGH BUT PSI SHOWED HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE SENSOR"],
  [
    "6746030",
    81143718,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE DSA WAS DISPLAYING A HORIZONTAL PATTERN THAT WAS NOT ASSOCIATED WITH THE PATIENT. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE",
  ],
  [
    "6752856",
    81378605,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED WHILE PERFORMING A MASTECTOMY PSI WAS 20-21 DURING INTUBATION, SO DR (B)(6) THOUGHT THE DEPTH OF ANESTHESIA WAS FINE. HOWEVER, SUDDENLY HR WENT UP. THEREFORE, HE INCREASED DOSE OF REMIFENTANIL. AFTER SURGERY HE ASKED THE PATIENT ABOUT INTUBATION. SHE WAS NOT SUFFERED FROM INTUBATION BUT REMEMBERED ABOUT INTUBATION. (SHE DID NOT OPEN HER EYES BECAUSE OF MUSCLE RELAXANT.) HE ASKED POSSIBLE REASON WHY PSI WAS BELOW 25 EVEN, SHE REMEMBERED THIS EVENT. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE",
  ],
  [
    "6731067",
    80634954,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED THE FOLLOWING ISSUE: "THERE HAPPENED THE ISSUE THAT CT IMPEDANCE INDICATOR BECAME BLUE. HOWEVER THE REPLACEMENT MODULE RESOLVED THE ISSUE. THERE WAS NO PROBLEM WITH THE SENSOR. THE IMPEDANCE READING WAS 10 TIMES COMPARED TO THE TYPICAL. ALSO, THERE WAS ANOTHER ISSUE. ALTHOUGH A PATIENT WAS UNDER SEDATION, THE ISSUE THAT PSI VALUE WAS VERY HIGH OCCURRED SEVERAL TIMES." NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.',
    "SEDLINE KIT",
  ],
  [
    "6734965",
    80784619,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED THE FOLLOWING ISSUE: "DSA DISPLAY SHOWED RED LINES ACROSS THE FIELD, NOT HAVING SEEN THIS PATTERN BEFORE, PHYSICIAN CALLED ME IN THE ROOM TO VERIFY THE INFORMATION, I GUIDED HIM TO CHECK LEAD PLACEMENT, AND PROCEEDED TO EXCHANGE THE PATIENT CABLE, AT WHICH POINT THE PROBLEM WAS RESOLVED. PICTURE ATTACHED. TROUBLESHOOTING STEPS: THE PATIENT SENSOR WAS CHANGED, LEAD PLACEMENT WAS CONFIRMED, BUT, SAME BAD RESULTS. DSA CONTINUED TO SHOW ALTERED. CHANGED PATIENT CABLE, AND DSA SHOWED MORE IN ACCORDING TO CLINICAL SCENARIO. NEVER CHANGED THE SEDLINE MODULE, BUT CHANGING THE SEDLINE PATIENT CABLE SOLVED THE ISSUE." NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE PATIENT CABLE",
  ],
  ["6806458", 83099532, "D", 1, null, "THE CUSTOMER REPORTED PSI TOO HIGH WHILE PATIENT WAS ANESTHETIZED. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE SENSOR"],
  ["6806494", 83099829, "D", 1, null, "THE CUSTOMER REPORTED PATIENT IS ANESTHETIZED ENOUGH BUT PSI SHOWED HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE SENSOR"],
  [
    "6806442",
    83100578,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED PSI TOO HIGH DURING USE (MORE THAN 90 THOUGH PT. WAS WELL ANESTHETIZED). NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE SENSOR",
  ],
  ["6806466", 83100455, "D", 1, null, "THE CUSTOMER REPORTED PATIENT IS ANESTHETIZED ENOUGH BUT PSI SHOWED HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE SENSOR"],
  ["6805908", 83111859, "D", 1, null, "THE CUSTOMER REPORTED PATIENT IS ANESTHETIZED ENOUGH BUT PSI SHOWED HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE SENSOR"],
  [
    "6889288",
    87211921,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THAT DURING AN INTERVENTION IN THE OR WITH A PATIENT SEDATED, THE SCREEN SHOWS AN UNAPPROPRIATED PSI VALUE (90 INSTEAD OF A VALUE <50), EEG TRACES SEEMS PERTURBED, NO VALUE FOR OTHER PARAMETERS. THE CUSTOMER HAS TRIED TO CHANGE THE SENSOR, BUT IT HAS NO EFFECT ON THE MEASUREMENT AND CLAIMS THAT THIS KIND OF ISSUES APPEARS RANDOMLY. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "6822977",
    83747540,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT DURING SIDE BY SIDE EVALUATIONS ON BIS VS SEDLINE, THE PATIENT AWOKE AND OPENED EYES AT PSI VALUES BELOW 50 (EVEN IF IN INCREASING TREND) WHILE BIS VALUE WAS HIGHER THAN 60 AND WITH BETA ACTIVITY SINCE 2 MINUTES. NO ART. AVERAGING TIME SELECTED ON BIS: 10 SEC. NO KNOWN IMPACT OR CONSEQUENCE TO PATIENT WAS REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "6840671",
    84942676,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE DOCTOR TRADITIONALLY USES THE EMG AS A LOGARITHMIC SCALE ON THE BIS. MEANING THAT WHEN THE EMG (WITHOUT THE INTERFERENCE OF DIATHERMIA) IS ABOVE 30%, IT IS AN INDICATION OF A PAIN NOXIOUS STIMULI. THEREBY, HE WOULD TREAT THE PATIENT WITH ANALGESIA RATHER THAN SEDATION. HOWEVER, WITH THE SEDLINE EMG, THE DOCTOR NOTICED WHEN USING IT ON A LAP CHOL WITHOUT THE USE OF A SPINAL OR A BLOCK, THE SEDLINE EMG REMAINED AT 0% THROUGHOUT THE CASE. WHEREAS THE BIS WAS INDICATING THAT THE PATIENT HAD NOXIOUS STIMULI, WHICH THE DOCTOR FELT FITTED TO THE CLINICAL SITUATION. HE HAS SEEN THIS SITUATION DURING SEVERAL OTHER CASES. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.",
    "SEDLINE",
  ],
  [
    "6840669",
    85141045,
    "D",
    1,
    null,
    "IT WAS REPORTED THAT THE DOCTOR TRADITIONALLY USES THE EMG AS A LOGARITHMIC SCALE ON THE BIS. MEANING THAT WHEN THE EMG (WITHOUT THE INTERFERENCE OF DIATHERMIA) IS ABOVE 30%, IT IS AN INDICATION OF A PAIN NOXIOUS STIMULI. THEREBY, HE WOULD TREAT THE PATIENT WITH ANALGESIA RATHER THAN SEDATION. HOWEVER, WITH THE SEDLINE EMG, THE DOCTOR NOTICED WHEN USING IT ON A LAP CHOL WITHOUT THE USE OF A SPINAL OR A BLOCK, THE SEDLINE EMG REMAINED AT 0% THROUGHOUT THE CASE. WHEREAS THE BIS WAS INDICATING THAT THE PATIENT HAD NOXIOUS STIMULI, WHICH THE DOCTOR FELT FITTED TO THE CLINICAL SITUATION. HE HAS SEEN THIS SITUATION DURING SEVERAL OTHER CASES. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED",
    "SEDLINE",
  ],
  [
    "6869182",
    86335801,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "WHEN REMIFENTANIL WAS INJECTIONED IN THE ANESTHETIC, THE PATIENT CONSCIOUSNESS LEVEL WAS CLEAR. ALTHOUGH THE PSI VALUE WAS 25, THE PATIENT COULD TALK TO THE DOCTOR. AFTER THAT, MUSCLE RELAXANTS WAS INJECTIONED. THEN ALTHOUGH THE PATIENT CONSCIOUSNESS LEVEL WAS LOW, PSI VALUE ROSE TO 70." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE",
  ],
  ["7097367", 94410870, "D", 1, null, "THE CUSTOMER REPORTED INTERMITTENT DETECTION PROBLEMS WHEN THE SENSOR IS BEING USED. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "6886311",
    88075385,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "ROOT MONITOR RANDOMLY DISCONNECTING FROM SEDLINE DURING PATIENT MONITORING". ADDITIONALLY, "THERE WAS NO ERROR MESSAGE ON THE SCREEN OR ALARM (TURNED OFF DURING THE TRIAL), THE ROOT APPEARS TO LOSE CONNECTION WITH THE SEDLINE MODULE. YOU LOSE DSA HISTORY. THIS HAPPENS RANDOMLY DURING THE CASE AND NOT WHEN TOUCHING THE ROOT MONITOR." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE",
  ],
  [
    "7045810",
    93219597,
    "D",
    1,
    null,
    'IT WAS REPORTED BY MASIMO CLINICAL SPECIALIST: "AT SOME POINT DURING THE CARDIAC SURGERY, THE PATIENT STARTED TO SWEAT MASSIVELY, VEGETATIVELY. THE SEDLINE SENSOR ALL OF A SUDDEN COULDN¿T GET A PROPER EEG READING ANYMORE, THE SENSOR¿S ELECTRODES SAID TO HAVE HIGH IMPEDANCE ON THE ROOT MONITOR FOR A VERY SHORT TIME AND THEN CONTINUED TO BE GREEN ON THE MONITOR WHICH MEANS THE SENSOR HAS NORMAL IMPEDANCE AND GETS EEG DATA. NEVERTHELESS, THE MONITOR SHOWED A HIGH NUMBER OF ARTIFACTS, AT A 100%. TOGETHER WITH THE ANESTHETIST WE COULD EXCLUDE EXTERNAL REASONS FOR HAVING THOSE ARTIFACTS LIKE ELECTROCAUTERY OR MANIPULATION CLOSE TO THE SENSOR ITSELF. THEN, I DISCONNECTED THE SEDLINE SENSOR FROM THE PATIENT CABLE AND COULD SEE IT WAS SEVERELY WET FROM THE PATIENT SWEATING EXTREMELY. WE DRIED THE CONNECTION AND RECONNECTED IT, BY THEN THE EEG READING WAS OK AGAIN AND WE COULD SEE THE DSA ALSO. NOW, WE COULD SEE EXTERNAL INTERFERENCES IN THE DSA THAT SHOWED UP ALL OF A SUDDEN. SINCE THE SETTING IN THE OR HAS NOT CHANGED AND THE ROOT MONITOR WAS ON THE SAME PLACE THAN BEFORE AND EVEN GROUNDED WE COULD NOT SEE THE SOURCE OF INTERFERENCES. BY LOOKING AT THE IMPEDANCE ALL THE TIME IT WAS ¿GREEN¿, SO IT SEEMED TO BE FINE. STILL, THE MEASUREMENT WAS NOT AS STABLE AS BEFORE." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE SENSOR",
  ],
  [
    "7372419",
    103563983,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED "HIGH PSI VALUE WAS DISPLAYED BY ROOT WHILE PATIENT WAS ENOUGH ANESTHETIZED." NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.',
    "SEDLINE EEG SENSOR",
  ],
  ["7233096", 98982936, "D", 1, null, 'IT WAS REPORTED "PSI SHOWS TOO HIGH VALUE WHILE PATIENT WAS ANESTHETIZED." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.', "SEDLINE SENSOR"],
  ["7233291", 98764541, "D", 1, null, "THE CUSTOMER REPORTED PSI SHOWS TOO HIGH VALUE WHILE PATIENT WAS ANESTHETIZED.  NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["7391249", 104426003, "D", 1, null, "THE CUSTOMER REPORTED SIGNAL CUTTING IN AND OUT. TRACKING EVERY 10 MINUTES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE PATIENT CABLE"],
  [
    "7405772",
    104660556,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED PSI VALUE DOESN'T SEEM ACCURATE. ADDITIONALLY, THE VALUES WENT UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "7405646",
    104664637,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE CABLE IS INTERMITTENTLY DISCONNECTED BETWEEN SENSOR AND MONITOR. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.",
    "RD SEDLINE PATIENT CABLE",
  ],
  [
    "7405771",
    104666269,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED PSI VALUE DOESN'T SEEM ACCURATE. ADDITIONALLY, THE VALUES WENT UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "7505412",
    107973984,
    "D",
    1,
    null,
    'IT WAS REPORTED: "PATIENTS SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS V/S, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI WENT UP TO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT (EMG)." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7471277",
    106844458,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "PSI VALUE SHOWED OVER 85 AND EMG SHOWED 65% THEN IT IS MAINTAINED PSI AT 80 AND EMG AT 60% EVEN THOUGH PATIENT IS WELL ANESTHETIZED". NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE EEG SENSOR",
  ],
  [
    "7471278",
    106844338,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "PSI VALUE SHOWS OVER 80 EVEN THOUGH PATIENT IS WELL ANESTHETIZED". NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE EEG SENSOR",
  ],
  [
    "7471287",
    106844918,
    "D",
    1,
    null,
    'IT WAS REPORTED: "DURING A SURGERY EEG & SEDLINE VALUES WERE COMPATIBLE WITH A DEEP ANESTHESIA INSTEAD OF A PSI>80. CHANGING DEVICE AND SENSOR THE SITUATION WAS THE SAME". NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE",
  ],
  [
    "7471288",
    106845203,
    "D",
    1,
    null,
    'IT WAS REPORTED: "DURING A SURGERY EEG & SEDLINE VALUES WERE COMPATIBLE WITH A DEEP ANESTHESIA INSTEAD OF A PSI>80. CHANGING DEVICE AND SENSOR THE SITUATION WAS THE SAME". NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE",
  ],
  [
    "7505416",
    107975412,
    "D",
    1,
    null,
    'IT WAS REPORTED: "PATIENTS SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS V/S, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI WENT UP TO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT (EMG)." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "RD SEDLINE EEG SENSOR",
  ],
  [
    "7505407",
    107975602,
    "D",
    1,
    null,
    'IT WAS REPORTED: "PATIENTS SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS V/S, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI WENT UP TO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT (EMG)." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7661228",
    113329186,
    "D",
    1,
    null,
    'IT WAS REPORTED: "THE DISPLAY WILL SUDDENLY READ "DISCONNECTED", FOR A FEW SECONDS, THEN RETURN TO CONNECTED, AND START MEASURING AGAIN. UPON RECONNECTION PSI PEAKING AT 100 MID CASE." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE MODULE",
  ],
  [
    "7528286",
    108729173,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "PATIENT SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS VITAL SIGNS, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI WENT UP TOO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT EMG." NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7528287",
    108729277,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED: "PATIENT SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS VITAL SIGNS, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI WENT UP TOO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT EMG." NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7605247",
    111172301,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE PATIENT SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS V/S, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER, THE PSI WENT UP TO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT (EMG). NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "7656981",
    112998303,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THAT THE CLINICIAN'S IMPRESSION OF THE PATIENT'S STATE (MOVING) WAS NOT CONSISTENT WITH THE PATIENT STATE INDEX VALUES PROVIDED BY THE DEVICE. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE MODULE",
  ],
  [
    "7701430",
    114424207,
    "D",
    1,
    null,
    'PER THE CUSTOMER: "PATIENTS SEEMED TO BE FULLY WELL ANESTHETIZED BASED UPON OTHER PARAMETERS SUCH AS VITAL SIGNS, DOSAGE OF INHALATION ANESTHETICS, IV ANESTHETICS, AND THE CONDITION OF PATIENTS WHO WERE UNDERGOING FOR SURGERY. HOWEVER PSI (PATIENT STATE INDEX) WENT UP TO HIGH UP TO AROUND 80 WITHOUT OTHER SIGNS OF WAKING UP OR MUSCLE MOVEMENT (EMG)." NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7676297",
    113635469,
    "D",
    1,
    null,
    'IT WAS REPORTED: "THE SPECTRAL EDGE READ COMPLETELY DIFFERENTLY ON BOTH SIDES OF THE BRAIN. THERE WAS NOTHING TO SUGGEST THESE READINGS WERE ACCURATE IN TERMS OF BRAIN FUNCTION. THE CASE WAS COSMETIC SURGERY AND ONE PART OF THE SURGERY, AN EYE LIFT. THERE WAS A LOT OF INTERFERENCE WITH THE DIATHERMY AND THE SEF WAS, AS MENTIONED TOTALLY DIFFERENT. THE PSI WAS OFTEN AFFECTED EVEN WHEN THERE WAS NO MOVEMENT OR INTERFERENCE THE SEF APPEARED COMPLETELY ASYMMETRICAL AND NOT WHAT YOU WOULD HAVE EXPECTED WITH THIS PATIENT". NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  [
    "7684512",
    113886310,
    "D",
    1,
    null,
    'AS REPORTED BY THE ANESTHESIST NURSE: "THIS MORNING, I USED 2 SEDLINE SENSORS. THE FIRST ONE WORKED FOR 5 MINUTES, THE SECOND ONE JUST A FEW MINUTES MORE, JUST THE TIME TO POSITION THE PATIENT FROM SUPINE TO PRONE POSITION, THEN NO MORE SIGNAL. ONCE THE PATIENT IS IN PRONE POSITION IT IS QUITE DIFFICULT TO PUT ANOTHER SENSOR ON. I COULD JUST TRY TO MOVE THE SENSOR A LITTLE TO HAVE A SIGNAL THAT ALLOWED ME TO KNOW ABOUT MY PATIENT\'S DEPTH OF SEDATION". NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "RD SEDLINE SENSOR",
  ],
  ["7899257", 121444567, "D", 1, null, "CUSTOMER STATES THAT THE SEDLINE MODULE IS SHOWING HIGHER THAN NORMAL PSI. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  [
    "7795755",
    117576920,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED PSI VALUES WERE SOMETIMES HIGH IN SEDATED STATE OR LOW IN WAKEFULNESS STATE. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "7817212",
    118252809,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED THAT THEY OBSERVED HIGHER MEASUREMENTS NOT RELATED WITH THE "REAL STATUS" OF THE PATIENT. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE KIT",
  ],
  [
    "7861881",
    119826689,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED INACCURATE VALUES WITH THE PRODUCT WHEREIN THE PSI NUMBER APPEARED INACCURATELY HIGH DURING A PATIENT SURGERY. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["7729306", 115371151, "D", 1, null, "THE CUSTOMER REPORTED INTERMITTENT READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.", "RD SEDLINE PATIENT CABLE"],
  ["7729305", 115371213, "D", 1, null, "THE CUSTOMER REPORTED INTERMITTENT READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.", "RD SEDLINE PATIENT CABLE"],
  [
    "7729324",
    115371375,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THAT WHEN THIS CABLE WAS CONNECTED TO A SEDLINE SENSOR, THE PSI VALUE REMAINED ABOVE 70. THE CUSTOMER THEN USED ANOTHER SENSOR BUT SAME PROBLEM OCCURRED. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE MODULE",
  ],
  [
    "7760267",
    116391487,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE ROOT DEVICE WITH SEDLINE SYSTEM WAS GIVING A READING OF 36 WHILE THE PATIENT WAS AWAKE. THE READING SHOULD HAVE BEEN 100. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "7861878",
    119826645,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED INACCURATE VALUES WITH THE PRODUCT WHEREIN THE PSI NUMBER APPEARED INACCURATELY HIGH DURING A PATIENT SURGERY. NO. CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8000508", 125149425, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI VALUES DESPITE FULLY SEDATED PATIENTS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["7921234", 122159865, "D", 1, null, "THE CUSTOMER REPORTED THAT THE PSI VALUE IS ALWAYS ABOVE 81. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "7921009",
    122163772,
    "D",
    1,
    null,
    "INACCURATE VALUES WERE REPORTED AGAINST A SEDLINE MODULE UNDER EVALUATION. WHEN SUPPORTING A CASE, THE EMG WAS READING HIGH EVEN THOUGH PATIENT WAS GIVEN PARALYSIS DRUGS. THE ANESTHETIST GAVE A SECOND DOSE OF PARALYSIS DRUGS AND EMG WAS STILL 40%. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "SEDLINE KIT",
  ],
  ["8035669", 126220521, "D", 1, null, "THE CUSTOMER REPORTED THE PSI VALUE DID NOT DECREASE WHEN A PATIENT WAS UNDER ANESTHESIA. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8035679", 126220863, "D", 1, null, "THE CUSTOMER REPORTED THE PSI REMAINED HIGH WHILE THE PATIENT WAS UNDER ANESTHESIA. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8172371", 131006897, "D", 1, null, "THE CUSTOMER REPORTED THEY COULD NOT MEASURE PSI VALUE CORRECTLY WITH THE SEDLINE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "8057084",
    127866891,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE CURVE WAS INCORRECT AND THE DEVICE PROVIDES DIFFERENT VALUES THAN EXPECTED. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "7817212",
    287349268,
    "D",
    0,
    null,
    'THE CUSTOMER REPORTED THAT THEY OBSERVED HIGHER MEASUREMENTS NOT RELATED WITH THE "REAL STATUS" OF THE PATIENT. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE KIT",
  ],
  ["8162805", 130473754, "D", 1, null, "THE CUSTOMER REPORTED THE PSI VALUE WAS HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "8162821",
    130474540,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE PSI VALUE IS OVER 60, BUT THEY CHANGED OTHER MODULES ITS VALUE WAS NORMAL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  [
    "7661228",
    280810455,
    "D",
    0,
    null,
    'IT WAS REPORTED: "THE DISPLAY WILL SUDDENLY READ "DISCONNECTED", FOR A FEW SECONDS, THEN RETURN TO CONNECTED, AND START MEASURING AGAIN. UPON RECONNECTION PSI PEAKING AT 100 MID CASE." NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.',
    "SEDLINE MODULE",
  ],
  [
    "7817212",
    285472490,
    "D",
    0,
    null,
    'THE CUSTOMER REPORTED THAT THEY OBSERVED HIGHER MEASUREMENTS NOT RELATED WITH THE "REAL STATUS" OF THE PATIENT. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE KIT",
  ],
  ["7729305", 283688749, "D", 0, null, "THE CUSTOMER REPORTED INTERMITTENT READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.", "RD SEDLINE PATIENT CABLE"],
  ["7729306", 283751377, "D", 0, null, "THE CUSTOMER REPORTED INTERMITTENT READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WAS REPORTED.", "RD SEDLINE PATIENT CABLE"],
  ["8204467", 131717840, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE VALUES AND INABILITY TO OBTAIN READINGS.  NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8204422", 131718797, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE VALUES AND INABILITY TO OBTAIN READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8204546", 131771227, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI READINGS. NO CONSEQUENCES OR IMPACT TO THE PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8203705", 131922144, "D", 1, null, "THE CUSTOMER REPORTED INACCURATELY HIGH PSI VALUES. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8204573", 131922282, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED INACCURATELY LOW READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8203674", 131922767, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI VALUES. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "7861881",
    281177322,
    "D",
    0,
    null,
    "THE CUSTOMER REPORTED INACCURATE VALUES WITH THE PRODUCT WHEREIN THE PSI NUMBER APPEARED INACCURATELY HIGH DURING A PATIENT SURGERY. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8000508", 281494967, "D", 0, null, "THE CUSTOMER REPORTED HIGH PSI VALUES DESPITE FULLY SEDATED PATIENTS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8204422", 281735079, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE VALUES AND INABILITY TO OBTAIN READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "7861878",
    292838760,
    "D",
    0,
    null,
    "THE CUSTOMER REPORTED INACCURATE VALUES WITH THE PRODUCT WHEREIN THE PSI NUMBER APPEARED INACCURATELY HIGH DURING A PATIENT SURGERY. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8057084",
    312132941,
    "D",
    0,
    null,
    "THE CUSTOMER REPORTED THE CURVE WAS INCORRECT AND THE DEVICE PROVIDES DIFFERENT VALUES THAN EXPECTED. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  ["7921234", 291196367, "D", 0, null, "THE CUSTOMER REPORTED THAT THE PSI VALUE IS ALWAYS ABOVE 81. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8220240", 132205911, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8283511", 134308866, "D", 1, null, "THE CUSTOMER REPORTED THE SEDLINE MODULE PROVIDE HIGH PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8259412", 133767767, "D", 1, null, "THE CUSTOMER REPORTED PSI VALUE WAS ALWAYS 100. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8502162", 141578097, "D", 1, null, "THE CUSTOMER REPORTED THE CABLE HAS LOOSE CONTACT AND THE DISPLAYED VALUES ARE UNRELIABLE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8299519", 134875892, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8299531", 134878061, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8299525", 134878356, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI ALWAYS OVER 89. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "8396301",
    138096214,
    "D",
    1,
    null,
    'THE CUSTOMER REPORTED THAT THE "NURSE COULDN\'T DETECT R2 AND L2, AND R1,CT, L2 MEASUREMENTS WERE NOT ACCURATE." NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE EEG SENSOR",
  ],
  ["8430621", 139165616, "D", 1, null, "THE CUSTOMER REPORTED THE PSI READINGS WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8430564", 139168522, "D", 1, null, "THE CUSTOMER SAID THAT THE PSI VALUE WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8430448", 139169383, "D", 1, null, "THE CUSTOMER REPORTED THAT THE PSI VALUE WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8360226", 136849745, "D", 1, null, "THE CUSTOMER REPORTED THE PSI VALUE WAS NOT ACCURATE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8360399", 136854176, "D", 1, null, "THE CUSTOMER REPORTED THE PSI VALUE WAS HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8360218", 136854935, "D", 1, null, "THE CUSTOMER REPORTED THAT THE PSI VALUE WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8450325", 139813509, "D", 1, null, "THE CUSTOMER REPORTED THE PSI WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8502163", 141578117, "D", 1, null, "THE CUSTOMER REPORTED THAT THEY COULDN'T MEASURE PROPER PSI. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8502166", 141578368, "D", 1, null, "THE CUSTOMER REPORTED THE SEDLINE (PSI) NUMBER IS DROPPING OUT AND NOT SHOWING ON THE SCREEN. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "8507058",
    142337412,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507062",
    141749508,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507063",
    141751233,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507059",
    141754045,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507070",
    141762488,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8507172", 141765432, "D", 1, null, "THE CUSTOMER REPORTED SHE COULDN'T MEASURE NORMAL RANGE PSI. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8507175", 141766259, "D", 1, null, "THE CUSTOMER REPORTED SHE COULDN'T MEASURE NORMAL RANGE PSI. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "8507047",
    141798459,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8505325", 141799193, "D", 1, null, "THE CUSTOMER REPORTED HIGH READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8530163", 142481783, "D", 1, null, "THE CUSTOMER REPORTED THAT THE DEVICE COULDN'T DETECT PROPER PSI. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "8536113",
    142638154,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SEDLINE SENSOR COULDN'T BE WELL DETECTED AND PROPER PSI COULDN'T BE DETECTED ALSO. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8536116",
    142638167,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SEDLINE SENSOR COULDN'T BE WELL DETECTED AND PROPER PSI COULDN'T BE DETECTED ALSO. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507046",
    142544845,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8536128", 142644804, "D", 1, null, "THE CUSTOMER REPORTED THAT THE DEVICE PROVIDED INACCURATE MEASUREMENTS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  [
    "8507060",
    142327236,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507057",
    142327955,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8507054",
    142545246,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSORS COULD NOT MEASURE PSI PROPERLY, SOME SENSORS PSI WERE HIGHER THAN THE NORMAL RANGE AND OTHERS COULD NOT MEASURE PSI AT ALL. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8619983",
    145390749,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE PSI DIDN'T GO DOWN INTO THE NORMAL RANGE DESPITE THE PATIENT'S ANESTHESIA. AFTER CHANGING TO A NEW SEDLINE SENSOR, THE PSI WAS IN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8580152", 144144884, "D", 1, null, "THE CUSTOMER REPORTED THE SENSOR PROVIDED PSI VALUES HIGHER THAN THE NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8571393", 143816629, "D", 1, null, "THE CUSTOMER REPORTED THE SENSOR PROVIDED PSI VALUES HIGHER THAN THE NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8575826", 143953353, "D", 1, null, "THE CUSTOMER REPORTED THE SENSOR PROVIDED PSI VALUES HIGHER THAN THE NORMAL RANGE.  NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "8645972",
    146237410,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THAT THE SENSOR PROVIDED HIGH MEASUREMENTS THAT DIDN'T MATCH THE PATIENT STATE AND THEN THE VALUES DISAPPEARED. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  ["8632616", 145795201, "D", 1, null, "THE CUSTOMER REPORTED THE MODULE DOESN'T WORK PROPERLY, THE VALUES ARE NOT CORRECT. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8700347", 148035753, "D", 1, null, "THE CUSTOMER REPORTED THE PSI WAS HIGHER THAN THE NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8701432", 148159159, "D", 1, null, "THE CUSTOMER REPORTED THE PSI WAS HIGHER THAN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8729701", 149136377, "D", 1, null, "THE CUSTOMER REPORTED THE PSI WAS HIGHER THAN THE NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8798814", 151323535, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8741507", 149499597, "D", 1, null, "THE CUSTOMER REPORTED THAT THE DEVICE PROVIDED HIGH READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  ["8746274", 149634941, "D", 1, null, "THE CUSTOMER REPORTED THE PSI VALUE DID NOT GO DOWN INTO NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "8746509",
    149643522,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSOR WASN'T RECOGNIZED PROPERLY. THE CUSTOMER TRIED TO DETACH AND ATTACH AGAIN TO GET PSI BUT PSI WASN'T IN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8746508",
    149644699,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SENSOR WASN'T RECOGNIZED PROPERLY. THE CUSTOMER TRIED TO DETACH AND ATTACH AGAIN TO GET PSI BUT THE PSI WASN'T IN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "RD SEDLINE SENSOR",
  ],
  [
    "8798912",
    151327734,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED WHILST THE SEDLINE SENSOR WAS ON THE PATIENT, THE PSI DROPPED DOWN TO AS LOW AS 30. IT THEN RETURNED TO READINGS OF AROUND 90 BEFORE DROPPING AGAIN TO 44. THE PATIENT CABLE WAS DISCONNECTED AND RECONNECTED AND THE CASE CONTINUED WITH NO PROBLEMS AND ACCEPTABLE PSI READINGS FOR SEDATION. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  ["8800233", 151361064, "D", 1, null, "THE CUSTOMER REPORTED THAT THE DEVICE PROVIDED LOW PSI VALUES. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8829090", 153280824, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI MEASUREMENTS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8812552", 151757983, "D", 1, null, "THE CUSTOMER REPORTED LOWER PSI VALUE THAN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851622", 152977106, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851616", 152977348, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8897878", 154461480, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED INACCURATE PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851621", 153095875, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI READINGS. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8876241", 153845689, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED PSI VALUES LOWER THAN THE NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCE WERE REPORTED.", "RD SEDLINE PATIENT CABLE"],
  ["8876430", 153858510, "D", 1, null, "THE CUSTOMER REPORTED HIGH PSI DETECTED, OVER 90. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8902117", 154624615, "D", 1, null, "THE CUSTOMER REPORTED THAT THE DEVICE PROVIDED HIGH PSI VALUES. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8902319", 154743001, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI DETECTED. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9132097", 160434998, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE MEASUREMENTS. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8299531", 261885708, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8299525", 165346297, "D", 1, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI ALWAYS OVER 89. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8259412", 261886747, "D", 1, null, "THE CUSTOMER REPORTED PSI VALUE WAS ALWAYS 100. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8812552", 309478303, "D", 0, null, "THE CUSTOMER REPORTED LOWER PSI VALUE THAN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8283511", 310438534, "D", 0, null, "THE CUSTOMER REPORTED THE SEDLINE MODULE PROVIDE HIGH PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8360226", 309877049, "D", 0, null, "THE CUSTOMER REPORTED THE PSI VALUE WAS NOT ACCURATE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8360399", 312501203, "D", 0, null, "THE CUSTOMER REPORTED THE PSI VALUE WAS HIGH.  NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9132097", 310859697, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE MEASUREMENTS. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8220240", 311711399, "D", 0, null, "THE CUSTOMER REPORTED HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8220240", 312500414, "D", 0, null, "THE CUSTOMER REPORTED HIGH PSI VALUE.  NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8299519", 312501730, "D", 0, null, "THE CUSTOMER REPORTED THE DEVICE PROVIDED A HIGH PSI VALUE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8283511", 312501928, "D", 0, null, "THE CUSTOMER REPORTED THE SEDLINE MODULE PROVIDE HIGH PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "8396301",
    312505628,
    "D",
    0,
    null,
    'THE CUSTOMER REPORTED THAT THE "NURSE COULDN\'T DETECT R2 AND L2, AND R1,CT, L2 MEASUREMENTS WERE NOT ACCURATE." NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.',
    "SEDLINE EEG SENSOR",
  ],
  ["8502166", 312502841, "D", 0, null, "THE CUSTOMER REPORTED THE SEDLINE (PSI) NUMBER IS DROPPING OUT AND NOT SHOWING ON THE SCREEN. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8360218", 312503078, "D", 0, null, "THE CUSTOMER REPORTED THAT THE PSI VALUE WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8502162", 312504374, "D", 0, null, "THE CUSTOMER REPORTED THE CABLE HAS LOOSE CONTACT AND THE DISPLAYED VALUES ARE UNRELIABLE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8430564", 312505596, "D", 0, null, "THE CUSTOMER SAID THAT THE PSI VALUE WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8430621", 312506065, "D", 0, null, "THE CUSTOMER REPORTED THE PSI READINGS WAS TOO HIGH. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8536128", 312506439, "D", 0, null, "THE CUSTOMER REPORTED THAT THE DEVICE PROVIDED INACCURATE MEASUREMENTS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE KIT"],
  ["8502163", 312506414, "D", 0, null, "THE CUSTOMER REPORTED THAT THEY COULDN'T MEASURE PROPER PSI. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8502166", 312506554, "D", 0, null, "THE CUSTOMER REPORTED THE SEDLINE (PSI) NUMBER IS DROPPING OUT AND NOT SHOWING ON THE SCREEN. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8530163", 312507861, "D", 0, null, "THE CUSTOMER REPORTED THAT THE DEVICE COULDN'T DETECT PROPER PSI. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851624", 314762013, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE PSI VALUES. IT DID NOT GO DOWN UNDER 80. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8701432", 314594623, "D", 0, null, "THE CUSTOMER REPORTED THE PSI WAS HIGHER THAN NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851616", 314669402, "D", 0, null, "THE CUSTOMER REPORTED HIGH PSI READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851621", 314669422, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE PSI READINGS. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851622", 314669806, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE PSI VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851633", 314669841, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE PSI READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8876430", 314669924, "D", 0, null, "THE CUSTOMER REPORTED HIGH PSI DETECTED, OVER 90. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["8851620", 314670109, "D", 0, null, "THE CUSTOMER REPORTED INACCURATE PSI READINGS. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9634944", 179250739, "D", 1, null, "THE CUSTOMER REPORTED THE SEDLINE MODULE STOPPED WORKING DURING A CASE IN THE OR. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8632616", 314848767, "D", 0, null, "THE CUSTOMER REPORTED THE MODULE DOESN'T WORK PROPERLY, THE VALUES ARE NOT CORRECT. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["8746274", 314905548, "D", 0, null, "THE CUSTOMER REPORTED THE PSI VALUE DID NOT GO DOWN INTO NORMAL RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  [
    "936876",
    713496,
    "D",
    1,
    null,
    "THE CUSTOMER CONTACT REPORTED INACCURATE READINGS; SUBSEQUENTLY, THE PATIENT WAS TREATED BASED ON THE READINGS. IN SURGERY WITH THE EEG ELECTRODES IN PLACE, THE PATIENT WAS PLACED FACE DOWN AND GIVEN 2.5 LITERS OF FLUID WHICH REPORTEDLY COLLECTED IN THE PATIENT'S FACE, HEAD, AND CHEST. AFTER AN UNSPECIFIED LENGTH OF TIME DURING THE SURGICAL PROCEDURE, THE PATIENT STATE INDEX (PSI) REPORTEDLY INCREASED IN NUMBERS, INDICATING THE PATIENT WAS COMING OUT OF THE SEDATION. THE PATIENT WAS TREATED WITH UNSPECIFIED BOLUS DOSES OF FENTANYL AND PROPOFOL, AND AN UNSPECIFED ANESTHETIC GAS. A CONTINUOUS DELIVERY OF FENTANYL WAS INCREASED FROM A RATE OF 75MCG/HR TO A RATE OF 125MCG/HR. AFTER AN UNSPECIFIED LENGTH OF TIME, THE NURSE ANESTHETIST WAS ALERTED THAT THE PATIENT'S EKG PACING WAS BEING INTERPRETED BY THE MONITOR AS THE PATIENT'S PSI; THEREFORE, THE PATIENT WAS NOT COMING OUT OF SEDATION. THE UNSPECIFIED ANESTHETIC GAS AND CONTINUOUS FENTANYL DELIVERIES WERE THEN TITRATED DOWN. TWENTY FIVE MINUTES AFTER THE COMPLETION OF THE SIX HOUR SURGERY, THE PATIENT WAS AWAKE AND MOVED ALL EXTREMITIES. THERE WERE NO REPORTED ADVERSE PATIENT EFFECTS. NO MEDICAL INTERVENTIONS WERE NECESSARY. THOUGH REQUESTED, NO ADDITIONAL INFORMATION WAS PROVIDED.",
    "SEDLINE MONITOR",
  ],
  ["9832858", 183472496, "D", 1, null, "THE CUSTOMER REPORTED THE PATIENT STATE INDEX (PSI) WAS NOT IN THE EXPECTED RANGE. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9832927", 183473082, "D", 1, null, "THE CUSTOMER REPORTED PSI MEASUREMENTS WHICH WERE NOT IN THE EXPECTED RANGE. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9860061", 184817829, "D", 1, null, "THE CUSTOMER REPORTED THAT THE PATIENT STATE INDEX (PSI) DID NOT DROP BELOW 88. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  [
    "9950282",
    187553610,
    "D",
    1,
    null,
    "THE CUSTOMER REPORTED THE SEDLINE MODULE DOES NOT DETECT PROPERLY, UNRELIABLE OVERESTIMATED MEASUREMENTS. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.",
    "SEDLINE KIT",
  ],
  ["10071468", 191584521, "D", 1, null, "THE CUSTOMER REPORTED THE VALUE DID NOT DROP BELOW 50 DURING ANESTHESIA. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "RD SEDLINE SENSOR"],
  ["9714871", 180201924, "D", 1, null, "THE CUSTOMER REPORTED THE SEDLINE DOES NOT WORK WELL. THE CLAIM IS ABOUT WRONG VALUES. NO PATIENT IMPACT OR CONSEQUENCES WERE REPORTED.", "SEDLINE KIT"],
  ["9774754", 182910499, "D", 1, null, "THE CUSTOMER REPORTED INACCURATE PSI MEASUREMENT. NO CONSEQUENCES OR IMPACT TO PATIENT WERE REPORTED.", "SEDLINE MODULE ONLY"],
];

export const HAZARD_COLUMN_CLASS_NAME = "hazard-column";

export const HAZARD_TABLE_CLASS_NAME = "hazard-table";

export const HAZARD_TABLE_ID = "hazard-table";
export const TABLE_COLUMN_CATEGORY = "Category";
export const TABLE_COLUMN_DESCRIPTION = "Description";
export const TABLE_COLUMN_SCORE = "Score";
export const SORT_TYPE_ASC = "asc";
export const SORT_TYPE_DESC = "desc";
export const NO_ANIMATION_ROW = "no-animation-row";
export const COLUMN_CLASS = "col-4 col-sm-4";
export const HAZARDS_HEADER = "Hazards";
export const TABLE_CLASS = "table table-bordered";
export const SORT_HAZARDS_BTN_CLASS = "sort-hazards-btn";
export const HEIGHT_INHERIT = "inherit";
export const MIN_TEXTAREA_HEIGHT = 30;

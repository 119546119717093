import { PAGE_DESIGN_WIDTH_IN_PIXEL } from "../../constants/appConstants";
export const CHARACTERISTICS_COLUMN_HEADER = "Characteristics affecting safety";
export const CHARACTERISTICS_COLUMN_CLASS_NAME = "characteristics-column";
export const NAV_LEFT_MENU_ITEM = "left-menu-item";
export const DEFAULT_BACKGROUND_COLOR_OF_MENU_ITEM = "white";
export const BACKGROUND_COLOR_OF_SELECTED_ITEM = "#89CFF0";
export const CHARACTERISTICS_COLUMN_NAME = "characteristics-column-name";

export const COLOR_YES_QUESTION = "#e6ffe6";
export const COLOR_NO_QUESTION = "#808080";
export const TEXTAREA_EXPLAINATION = "characteristics-column-textarea-explaination";
export const TEXTAREA_QUESTION = "characteristics-column-textarea-question";
export const CHARACTERISTICS_COLUMN_CARD = "characteristics-column-card";
export const CHARACTERISTICS_COLUMN_MAIN_CLASS_NAME = "characteristics-column-main";
export const MIN_TEXTAREA_HEIGHT = 30;
export const CHARACTERISTICS_QUESTION_TEXT_KEY = "questionText";
export const QUESTION_ANSWER_OPTIONS = {
  YES: "Yes",
  NO: "No",
  INSUFFICIENT_IMFORMATION_PROVIDED: "Insufficient information provided.",
};

export const INPUT_QUESTION_TEXT_NAME = "questionText";
export const INPUT_EXLANATION_NAME = "explanation";
export const INPUT_ANSWER_NAME = "answer";
export const INPUT_TYPE_TEXT_AREA = "textarea";

export const HEIGHT_INHERIT = "inherit";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../store/hooks";
import { loginAtlassianThunk } from "../../store/auth/thunkMiddlewares";

const CallBackSignPage = () => {
    let { search } = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(search);
        const authorizationCode = query.get("code");
        dispatch(loginAtlassianThunk(authorizationCode!, navigate));
    }, []);
    return <div></div>;
};

export default CallBackSignPage;
